import React from "react";
import cx from "classnames";
import styles from "./SectionDivider.module.scss";
import chevronDownIcon from "images/chevron-downV2.svg";
import chevronUpIcon from "images/chevron-upV2.svg";

interface SectionDividerProps {
  children: React.ReactNode;
  className?: string;
  version?: number;
  visible?: boolean;
  onClickVisible?: () => void;
}

export const SectionDivider: React.FC<SectionDividerProps> = ({
  children,
  className,
  version,
  visible,
  onClickVisible,
}) => {
  if (version === 2) {
    return (
      <div
        aria-hidden="true"
        className={cx(styles.divV2, className)}
        onClick={onClickVisible}
      >
        {children}
        <img src={visible ? chevronUpIcon : chevronDownIcon} alt="hh" />
      </div>
    );
  }
  return <div className={cx(styles.div, className)}>{children}</div>;
};
