import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isUndefined } from "lodash";
import dayjs from "dayjs";
import { externalRedirect } from "utils/externalRedirect";
import * as paths from "features/routing/paths";
import { RootState } from "app/store";
import {
  fetchOfficeInfo,
  fetchOfficeV1Info,
  OptionalOffice,
  selectOffice,
  setDoctorsByOffice,
} from "features/officesSlice";
import { setTimeSlots } from "features/scheduling/timeSlotsSlice";
import { setOfficeAvailability } from "features/scheduling/availabilitySummarySlice";
import { Office } from "types/office";
import { Doctor } from "types/doctor";

interface WithOfficeProps {
  children: (props: {
    office: Office;
    officeId: string;
    doctors: any;
  }) => JSX.Element;
  officeId?: string;
  redirect?: boolean; // Redirects if the office 404's or has scheduling_allowed: false (e.g. a closed office). True by default.
  version?: number;
}

const redirectUnlessSchedulingAllowed = (
  office: OptionalOffice | undefined
) => {
  if (!office) {
    return;
  }

  if (office === "not-found" || !office.schedulingAllowed) {
    externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
  }
};

export const WithOffice: React.FC<WithOfficeProps> = (props) => {
  const routeParams =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const officeId = props.officeId || routeParams.officeId;
  const redirect = isUndefined(props.redirect) ? true : props.redirect;
  const version = isUndefined(props.version) ? 1 : props.version;

  const dispatch = useDispatch();
  if (!officeId) {
    throw Error(
      "officeId is required to be passed as a prop or inferred from the path"
    );
  }

  const thisOffice = useSelector((state: RootState) =>
    selectOffice(state, officeId)
  );
  const doctorsList: {
    id: any;
    firstName: any;
    lastName: any;
    headshotUrl: any;
    offersVEH: any;
    providerId: any;
  }[] = [];
  const slots: {
    doctorId: any;
    startTime: any;
    endTime: any;
    date: any;
    timeZoneName: string;
    providerId: string;
  }[] = [];
  let doctorsListObj = {};
  const doctorsIdList: any[] = [];
  const officeAvail = {
    officeId: "",
    body: {
      loaded: true,
      doctors: {},
    },
  };
  if (thisOffice) {
    const totalSlotsDetails =
      thisOffice && thisOffice?.totalSlotsDetails
        ? JSON.parse(thisOffice.totalSlotsDetails)
        : [];
    for (const slotDetails of totalSlotsDetails) {
      doctorsList.push({
        id: slotDetails.id.toString(),
        firstName: slotDetails.first_name,
        lastName: slotDetails.last_name,
        headshotUrl: slotDetails.headshot_url,
        offersVEH: slotDetails.offers_veh,
        providerId: slotDetails.provider_ids,
      });
      doctorsIdList[slotDetails.id] = null;
      if (
        slotDetails.appointment_slots &&
        slotDetails.appointment_slots.length > 0
      ) {
        for (const appt_slots of slotDetails.appointment_slots) {
          const endTimeFull = dayjs(appt_slots).add(5, "m");
          slots.push({
            doctorId: slotDetails.id.toString(),
            startTime: appt_slots.split("T")[1],
            endTime: endTimeFull.format("HH:mm:ss"),
            date: appt_slots.split("T")[0],
            timeZoneName: "America/New_York",
            providerId: slotDetails.provider_ids,
          });
        }
      }
    }
    doctorsListObj = Object.assign({}, doctorsList);
  }

  React.useEffect(() => {
    if (!thisOffice) {
      if (version === 2) {
        dispatch(fetchOfficeInfo(officeId));
      } else {
        dispatch(fetchOfficeV1Info(officeId));
      }
    } else {
      if (version === 2) {
        const offId = thisOffice.id;
        officeAvail.officeId = offId;
        officeAvail.body.doctors = Object.assign({}, doctorsIdList);

        const docListTemp = {};
        docListTemp[offId] = doctorsList;
        dispatch(setDoctorsByOffice(docListTemp));
        dispatch(setTimeSlots(slots));
        dispatch(setOfficeAvailability(officeAvail));
      }
    }
  }, [
    dispatch,
    officeId,
    thisOffice,
    slots,
    doctorsList,
    doctorsIdList,
    version,
  ]);

  if (redirect) {
    redirectUnlessSchedulingAllowed(thisOffice);
  }

  if (isUndefined(thisOffice) || thisOffice === "not-found") {
    return null;
  } else {
    return props.children({
      office: thisOffice,
      officeId: thisOffice.id,
      doctors: doctorsList,
    });
  }
};
