import React from "react";
import styles from "./WearOption.module.scss";

interface WearOptionProps {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  icon: string;
}

export const WearOption: React.FC<WearOptionProps> = ({
  label,
  onClick,
  icon,
}) => {
  return (
    <button onClick={onClick} className={styles.wearOption}>
      <img src={icon} alt="" />
      <div className={styles.wearOptionNameContainer}>
        <div className={styles.wearOptionName}>{label}</div>
      </div>
    </button>
  );
};
