import React, { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { min } from "lodash";
import styles from "./AppointmentSelectSlot.module.scss";
import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";
import {
  fetchFirstAvailability,
  selectFirstAvailableDatesForAllDoctorsInOffice,
  selectFirstAvailablityLoadedForOffice,
} from "features/scheduling/firstAvailabilitySlice";
import { RootState } from "app/store";
import {
  DesktopBreadcrumb,
  MobileAccordionContainer,
  MobileAccordionSteps,
} from "features/scheduling/components/nav";
import { updateSelectedAppointment } from "features/scheduling/appointmentCreationSlice";
import { fetchOfficeAvailability } from "features/scheduling/availabilitySummarySlice";
import {
  fetchNearbyOffices,
  selectNearbyOffices,
} from "features/scheduling/nearbyOfficesSlice";
import { validatePatient } from "api/validatePatient";
import { today } from "features/scheduling/utils/today";
import {
  setBrazePatientDetail,
  setServerError,
  selectBrazePatientDetail,
} from "features/scheduling/brazePatientDetailSlice";
import * as paths from "features/routing/paths";
import { Layout } from "features/layout";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Navbar } from "components/Navbar";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Slot } from "types/slot";
import { API_DATE_FORMAT } from "api/constants";
import { useQueryParam } from "hooks/useQueryParam";
import { WithOffice } from "components/WithOffice";
import { Office } from "types/office";
import { Doctor } from "types/doctor";
import { WithOfficeDoctors } from "components/WithOfficeDoctors";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";

export interface AppointmentSelectSlotRouteProps {
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

interface ComponentProps {
  selectedOffice: Office;
  doctors: Doctor[];
  officeId: string;
}

const UnconnectedAppointmentSelectSlot: React.FC<ComponentProps> = ({
  selectedOffice,
  officeId,
  doctors,
}) => {
  const { examType, patientReturnStatus } =
    useParams<AppointmentSelectSlotRouteProps>();
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const patientId = new URLSearchParams(search).get("eid");
  const [searchDateOrNull, setSearchDate] = useQueryParam("date", null);
  const [brazeValidate, setBrazeValidate] = React.useState(false);
  const searchDate = searchDateOrNull || dayjs().format(API_DATE_FORMAT);
  const brazePatientDetail = useSelector(selectBrazePatientDetail);

  usePageViewTracking("step-4-selected-patient-type");
  useEffect(() => {
    if (patientId && selectedOffice.restrictVEH) {
      (async () => {
        const patientDetails = {
          patientId: patientId,
          firstName: null,
          lastName: null,
          phoneNumber: null,
          dateOfBirth: null,
          email: null,
        };
        // eslint-disable-next-line no-useless-catch
        try {
          const vaildPatient = await validatePatient(patientDetails);

          if (vaildPatient.patient !== "Not found") {
            dispatch(setBrazePatientDetail({ patientId: patientId }));
          }
        } catch (e) {
          if (e.code === "503") {
            dispatch(setServerError({ serverError: true }));
            return;
          }
          throw e;
        }
        setBrazeValidate(true);
      })();
    } else {
      setBrazeValidate(true);
    }
  }, [dispatch, patientId, selectedOffice]);

  useEffect(() => {
    if (doctors.length === 0) {
      return;
    }
    if (!brazeValidate) {
      return;
    }
    const newdoctorIds: string[] = [];
    for (let i = 0; i < doctors.length; i++) {
      if (selectedOffice.restrictVEH) {
        if (
          patientReturnStatus === PatientReturnStatus.Returning ||
          (brazePatientDetail && brazePatientDetail.patientId)
        ) {
          newdoctorIds.push(doctors[i].id);
        } else if (!doctors[i].offersVEH) {
          newdoctorIds.push(doctors[i].id);
        }
      } else {
        newdoctorIds.push(doctors[i].id);
      }
    }
    dispatch(
      fetchOfficeAvailability({
        officeId,
        searchDate,
        examType,
        doctorIds: newdoctorIds,
        patientReturnStatus,
      })
    );
  }, [
    dispatch,
    searchDate,
    officeId,
    examType,
    doctors,
    patientReturnStatus,
    selectedOffice,
    patientId,
    brazeValidate,
    brazePatientDetail,
  ]);

  useEffect(() => {
    if (doctors.length === 0) {
      return;
    }
    if (areFirstAvailableApptsByDefaultEnabled()) {
      dispatch(
        fetchFirstAvailability({
          officeId,
          examType,
          doctorIds: doctors.map((doctor) => doctor.id),
          patientReturnStatus,
        })
      );
    }
  }, [dispatch, officeId, examType, doctors, patientReturnStatus]);

  const onSelectSlot = (slot: Slot) => {
    dispatch(
      updateSelectedAppointment({
        officeId,
        ...slot,
      })
    );
    history.push(
      paths.appointmentsSummary({ officeId, examType, patientReturnStatus })
    );
  };

  const nearbyOffices = useSelector((state: RootState) =>
    selectNearbyOffices(state, officeId)
  );

  useEffect(() => {
    if (!brazeValidate) {
      return;
    }
    dispatch(fetchNearbyOffices({ officeId, examType, patientReturnStatus }));
  }, [
    dispatch,
    officeId,
    examType,
    patientReturnStatus,
    patientId,
    brazeValidate,
  ]);

  const firstAvailability = useSelector((state: RootState) =>
    selectFirstAvailableDatesForAllDoctorsInOffice(state, selectedOffice.id)
  );
  const firstAvailabilityLoaded = useSelector((state: RootState) =>
    selectFirstAvailablityLoadedForOffice(state, selectedOffice.id)
  );
  useEffect(() => {
    if (
      areFirstAvailableApptsByDefaultEnabled() &&
      searchDateOrNull === null &&
      firstAvailabilityLoaded
    ) {
      const firstAvailableDate = min(Object.values(firstAvailability));
      if (firstAvailableDate !== undefined) {
        setSearchDate(firstAvailableDate as string);
      }
    }
  }, [
    searchDateOrNull,
    firstAvailability,
    firstAvailabilityLoaded,
    setSearchDate,
  ]);

  return (
    <Layout
      navbar={
        <Navbar office={selectedOffice}>
          <DesktopBreadcrumb
            step={3}
            officeId={officeId}
            patientReturnStatus={patientReturnStatus}
            examType={examType}
          />
        </Navbar>
      }
    >
      <MobileAccordionContainer>
        <MobileAccordionSteps
          step={3}
          officeId={officeId}
          patientReturnStatus={patientReturnStatus}
          examType={examType}
        />
        <div className={styles.container}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <DesktopView
              doctors={doctors}
              nearbyOffices={nearbyOffices}
              selectedOffice={selectedOffice}
              searchDate={searchDate}
              onSelectSlot={onSelectSlot}
            />

            <MobileView
              doctors={doctors}
              officeId={officeId}
              onSelectSlot={onSelectSlot}
              examType={examType}
              nearbyOffices={nearbyOffices}
              patientReturnStatus={patientReturnStatus}
              selectedOffice={selectedOffice}
              searchDate={searchDate}
              setSearchDate={setSearchDate}
            />
          </form>
        </div>
      </MobileAccordionContainer>
    </Layout>
  );
};

declare let isEnabledV2: string;
export const AppointmentSelectSlot: React.FC = () => {
  const { officeId } =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const history = useHistory();
  if (isEnabledV2) {
    history.push(paths.v2appointmentStart(officeId, today));
    return null;
  }
  return (
    <WithOffice version={1}>
      {({ office, officeId }) => (
        <WithOfficeDoctors officeId={officeId}>
          {({ doctors }) => (
            <UnconnectedAppointmentSelectSlot
              selectedOffice={office}
              officeId={officeId}
              doctors={doctors}
            />
          )}
        </WithOfficeDoctors>
      )}
    </WithOffice>
  );
};
