import React from "react";
import { Toast } from "./Toast";
import styles from "./DefaultToast.module.scss";
import closeIcon from "images/close.svg";
import clockIcon from "images/clock.svg";
import reloadArrowsIcon from "images/reload-arrows.svg";
import handbagIcon from "images/medical-handbag.svg";

interface DefaultToastProps {
  visible: boolean;
  onDismiss: () => void;
  sideIcon: React.ReactNode;
  children: React.ReactNode;
  toastClassName?: string;
}

const Reload: React.FC = () => (
  <img src={reloadArrowsIcon} alt="" className={styles.icon} />
);

const Clock: React.FC = () => (
  <img src={clockIcon} alt="" className={styles.icon} />
);

const Handbag: React.FC = () => (
  <img src={handbagIcon} alt="" className={styles.icon} />
);

export const defaultToastIcons = {
  Reload,
  Clock,
  Handbag,
};

export const DefaultToast: React.FC<DefaultToastProps> = ({
  sideIcon,
  visible,
  onDismiss,
  children,
  toastClassName,
}) => {
  return (
    <Toast visible={visible} toastClassName={toastClassName}>
      <div className={styles.container}>
        <div className={styles.action}>{sideIcon}</div>
        <div>
          <div className={styles.text}>{children}</div>
        </div>
        <div>
          <button onClick={onDismiss} className={styles.dismissButton}>
            <img src={closeIcon} alt="Dismiss" className={styles.dismissIcon} />
          </button>
        </div>
      </div>
    </Toast>
  );
};
