import React from "react";
import styles from "./AppointmentReview/RescheduleAppointmentModal.module.scss";
import { toTel } from "features/scheduling/utils/toTel";
import { Button } from "components/Button";

interface ValidatePatientModalProps {
  setShowRescheduleModal: (val: boolean) => void;
  goBack: () => void;
  formattedPhone: string;
}
export const validatePatientModalAria = {
  labelledby: "reschedule-appointment-modal-label",
  describedby: "reschedule-appointment-modal-description",
};
export const ValidatePatientModal: React.FC<
  ValidatePatientModalProps
> = ({ goBack, formattedPhone }) => {
  return (
    <div className={styles.container}>      
      <div id={validatePatientModalAria.describedby}>
        <p>
        Video Assisted  Appointments are not available for the new patients at this location, 
        For scheduling the appointments please call <a href={toTel(formattedPhone)}>{formattedPhone}</a>
        </p>        
      </div>
      <div className={styles.ctas}>
        <Button
          text="Back"
          onClick={ () => goBack() }
          className={styles.button} 
          size="large"
          autoFocus
        />
      </div>
    </div>
  );
};
