import React from "react";
import cx from "classnames";
import styles from "./TextButton.module.scss";

interface TextButtonProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
  theme?: "standard" | "inline";
  type?: "button" | "submit";
  disabled?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  children,
  onClick,
  className,
  theme = "inline",
  type = "button",
  disabled = false,
}) => (
  <button
    className={cx(styles.anchorButton, className, {
      [styles.standard]: theme === "standard",
      [styles.inline]: theme === "inline",
    })}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </button>
);
