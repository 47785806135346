import dayjs from "dayjs";
import { min, partition } from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { getNearbyOfficeAvailabilityRequest } from "api/getNearbyOfficeAvailabilityRequest";
import { Doctor } from "types/doctor";
import { DateString } from "types/dateString";
import { DoctorAvailability } from "types/doctorAvailability";
import { Office } from "types/office";

const SLICE_NAME = "nearbyOffices";

type OfficesMap = {
  [officeId: string]: Office[];
};

type AvailabilityMap = {
  [officeId: string]: DoctorAvailability[];
};

export type NearbyOfficesState = {
  nearbyOffices: OfficesMap;
  availability: AvailabilityMap;
  isLoading: boolean;
};

export const initialState: NearbyOfficesState = {
  nearbyOffices: {},
  availability: {},
  isLoading: false,
};

export const fetchNearbyOffices = createAsyncThunk(
  `${SLICE_NAME}/fetchNearbyOffices`,
  getNearbyOfficeAvailabilityRequest
);

export const nearbyOfficesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNearbyOffices.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchNearbyOffices.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(fetchNearbyOffices.fulfilled, (state, action) => {
        const offices = action.payload.map((summary) => summary.office);
        const availablity = action.payload.reduce((acc, summary) => {
          acc[summary.office.id] = summary.doctorAvailabilities;
          return acc;
        }, {} as AvailabilityMap);

        return {
          ...state,
          nearbyOffices: {
            ...state.nearbyOffices,
            [action.meta.arg.officeId]: offices,
          },
          availability: {
            ...state.availability,
            ...availablity,
          },
          isLoading: false,
        };
      });
  },
});

export const reducer = nearbyOfficesSlice.reducer;

const selectTodayAndLaterAvailabilities = (
  state: RootState,
  officeId: string
): { today: DoctorAvailability[]; later: DoctorAvailability[] } => {
  const now = dayjs();
  const availability = state.nearbyOffices.availability[officeId] || [];
  const [today, later] = partition(availability, (avail) =>
    dayjs(avail.predictedAvailableDate).isSame(now, "day")
  );
  return { today, later };
};

export const selectNearbyOffices = (
  state: RootState,
  officeId: string
): Office[] => state.nearbyOffices.nearbyOffices[officeId] || [];

export const selectDoctorsAvailableTodayInOffice = (
  state: RootState,
  officeId: string
): Doctor[] => {
  const { today } = selectTodayAndLaterAvailabilities(state, officeId);
  return today.map((avail) => avail.doctor);
};

export const selectNextAvailableDateForOffice = (
  state: RootState,
  officeId: string
): DateString | undefined => {
  const { later } = selectTodayAndLaterAvailabilities(state, officeId);
  return min(later.map((avail) => avail.predictedAvailableDate));
};

export const selectDoctorsAvailableLaterForOffice = (
  state: RootState,
  officeId: string
): Doctor[] => {
  const { later } = selectTodayAndLaterAvailabilities(state, officeId);
  return later.map((avail) => avail.doctor);
};

export const selectNearbyOfficeLoadingState = (state: RootState): boolean => {
  return state.nearbyOffices.isLoading;
};

export const selectMedicaidNearbyOffices = (
  state: RootState,
  officeId: string
): Office[] => {
  const nearbyOfficesByOfficeId =
    state.nearbyOffices.nearbyOffices[officeId] || [];
  return nearbyOfficesByOfficeId.filter((r) => r.supportsMedicaid);
};
