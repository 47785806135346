import dayjs from "dayjs";
import { DoctorAvailability } from "features/scheduling/availabilitySummarySlice";

export const availabilityInfo = (availability: DoctorAvailability): string => {
  if (availability === null) {
    return "Limited Availability";
  }

  if (availability === "loading") {
    return "Loading...";
  }

  const availableDate = dayjs(availability);

  return `Available ${
    availableDate.isToday()
      ? "Today"
      : `From ${availableDate.format("dddd, MMM D")}`
  }`;
};
