import React from "react";
import styles from "./DescriptiveCheckbox.module.scss";
import { Checkbox, CheckboxProps } from "components/Checkbox";

interface DescriptiveCheckboxProps extends CheckboxProps {
  label?: string;
  description?: string;
}

export const DescriptiveCheckbox = React.forwardRef<
  HTMLInputElement,
  DescriptiveCheckboxProps
>(({ label = "", description = "", ...inputProps }, ref) => {
  return (
    <label className={styles.label}>
      <span className={styles.heading}>{label}</span>
      <div className={styles.content}>
        <div className={styles.checkmarkContainer}>
          <Checkbox ref={ref} {...inputProps} />
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </label>
  );
});
