import React from "react";
import { RevealButton } from "./RevealButton";
import styles from "./FieldReview.module.scss";

const display = (
  value: string | undefined,
  revealed: boolean,
  maskString?: string
) => (revealed ? value : maskString || "●●●●●●●●●");

export const FieldReview: React.FC<{
  label: string;
  value: string;
  maskString?: string;
  maskable?: boolean;
  style?: React.CSSProperties;
}> = ({ label, value, maskString, maskable, style }) => {
  const [revealed, setRevealed] = React.useState<boolean>(!maskable);

  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>
        <span style={style}>{display(value, revealed, maskString)}</span>
        {maskable && (
          <RevealButton
            label={label}
            revealed={revealed}
            onClick={() => setRevealed((v) => !v)}
          />
        )}
      </div>
    </div>
  );
};
