import React from "react";
import cx from "classnames";
import styles from "./OfficeInfo.module.scss";
import { OfficeAddress } from "components/OfficeAddress";
import { useOffice } from "features/patientDashboard/hooks/useOffice";
import { toTel } from "features/scheduling/utils/toTel";
import officePlaceholderImage from "images/office-placeholder.svg";

type OfficeInfoProps = {
  officeId: string;
  className?: string;
};

export const OfficeInfo: React.FC<OfficeInfoProps> = ({
  officeId,
  className,
}) => {
  const { office } = useOffice(officeId);
  if (!office) return null;

  const { formattedPhone } = office;

  return (
    <div className={cx(styles.grid, className)}>
      <img src={officePlaceholderImage} alt="" />
      <div>
        <OfficeAddress office={office} />
        <div>
          <a href={toTel(formattedPhone)}>{formattedPhone}</a>
        </div>
      </div>
    </div>
  );
};
