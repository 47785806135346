import React, { createContext, useRef, useState } from "react";
import { CurrentAppointment } from "types/currentAppointment";

export type CurrentAppointmentState = CurrentAppointment | null | undefined;

type ContextType = {
  currentAppointment: CurrentAppointmentState;
  setCurrentAppointment: (
    currentAppointment: CurrentAppointment | null
  ) => void;
  isFetching: React.MutableRefObject<boolean>;
};

export const CurrentAppointmentContext = createContext<ContextType | undefined>(
  undefined
);

export const CurrentAppointmentProvider: React.FC = ({ children }) => {
  const [currentAppointment, setCurrentAppointment] =
    useState<CurrentAppointmentState>(undefined);

  const isFetching = useRef<boolean>(false);
  return (
    <CurrentAppointmentContext.Provider
      value={{ currentAppointment, setCurrentAppointment, isFetching }}
    >
      {children}
    </CurrentAppointmentContext.Provider>
  );
};
