import React from "react";
import dayjs from "dayjs";
import styles from "./FutureAvailability.module.scss";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import contactsIcon from "images/contacts.svg";
import { ButtonLinkInternal, ButtonRole } from "components/Button";
import * as paths from "features/routing/paths";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { doctorFullName } from "utils/doctorFullName";

interface FutureAvailabilityProps {
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  doctor: Doctor;
  availability: string;
  selectedOffice?: Office;
}

export const FutureAvailability: React.FC<FutureAvailabilityProps> = ({
  doctor,
  availability,
  officeId,
  examType,
  patientReturnStatus,
  selectedOffice,
}) => {
  const availabilityString = dayjs(availability).format("dddd, MMMM Do");

  return (
    <div className={styles.container}>
      <img src={contactsIcon} alt="Contacts" className={styles.icon} />
      <h1 className={styles.header}>
        {doctorFullName(doctor, selectedOffice?.namechangeVEH)}
        {doctor.firstName === "Open Network" ? "s are" : " is"} available
        starting {availabilityString}
      </h1>
      <div className={styles.buttonContainer}>
        <ButtonLinkInternal
          to={`${paths.appointmentsAvailableAppointments({
            officeId,
            examType,
            patientReturnStatus,
            query: {
              date: availability,
              doctorId: doctor.id,
            },
          })}`}
          text={"View Next Available"}
          role={ButtonRole.Standard}
          className={styles.button}
        />
      </div>
    </div>
  );
};
