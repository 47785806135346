import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./DoctorButtonV2.module.scss";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { RetiringLabel } from "features/scheduling/components/RetiringLabel";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullNameVEH } from "utils/doctorFullName";

interface DoctorButtonProps {
  selected: boolean;
  doctor: Doctor;
  availabilityInfo: React.ReactNode;
  loaded: boolean;
  selectedOffice: Office;
}

export const DoctorButtonV2: React.FC<DoctorButtonProps> = ({
  doctor,
  availabilityInfo,
  loaded,
  selectedOffice,
}) => {
  const doctorName = loaded ? (
    doctorFullNameVEH(doctor, selectedOffice.namechangeVEH)
  ) : (
    <ContentLoader
      speed={2}
      width={100}
      height={19}
      viewBox="0 0 100 18"
      backgroundColor="var(--gray-02)"
      foregroundColor="var(--gray-04)"
    >
      <rect x="0" y="8" rx="4" ry="4" width="100" height="8" />
    </ContentLoader>
  );

  return (
    <>
      <DoctorAvatar
        doctor={doctor}
        size="small"
        selectedOffice={selectedOffice}
      />
      <div className={styles.doctorName}>{doctorName}</div>
      {doctor.retiringDate && <RetiringLabel doctor={doctor} />}
      <div className={styles.doctorAddress}>{availabilityInfo}</div>
    </>
  );
};
