import React from "react";
import cx from "classnames";
import { times } from "lodash";
import styles from "./ProgressBar.module.scss";
import checkmarkIcon from "images/checkmark-white.svg";

type ProgressBarProps = {
  className?: string;
  totalNodes: number;
  completedNodes: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  totalNodes,
  completedNodes,
}) => (
  <div className={cx(styles.wrapper, className)}>
    <span className={styles.emptyBar} />
    <div className={styles.nodes}>
      {times(totalNodes + 1).map((n) => (
        <span
          key={n}
          className={cx(styles.node, styles.emptyNode, {
            [styles.lastCompletedNode]: n === completedNodes,
          })}
        />
      ))}
    </div>

    <span
      className={styles.completedBar}
      style={{ width: `${(completedNodes / totalNodes) * 100}%` }}
    />
    <div className={styles.nodes}>
      {times(totalNodes + 1).map((n) => (
        <span key={n} className={styles.node}>
          {n <= completedNodes && (
            <span className={styles.completedNode}>
              {n > 0 && (
                <img
                  src={checkmarkIcon}
                  className={styles.checkmark}
                  alt="Checkmark"
                />
              )}
            </span>
          )}
        </span>
      ))}
    </div>
  </div>
);
