import React from "react";
import styles from "./CheckInCard.module.scss";
import { LegacyCard } from "components/LegacyCard";
import { ButtonLinkInternal } from "components/Button";
import { timeUntil } from "features/appointmentTime";
import { checkInHome } from "features/routing/paths";
import { SelectedAppointment } from "types/selectedAppointment";

interface CheckInCardProps {
  appointment: SelectedAppointment;
}

export const CheckInCard: React.FC<CheckInCardProps> = ({ appointment }) => (
  <LegacyCard className={styles.card}>
    <h4 className={styles.heading}>Save time in the office.</h4>
    <div className={styles.description}>
      Prepare for your visit and complete your paperwork now. It’s quick, easy,
      and takes less than 5 mins.
    </div>
    <div className={styles.notice}>
      Your appointment is {timeUntil(appointment)} away.
    </div>
    <ButtonLinkInternal
      className={styles.button}
      text="Check-In Online"
      size="large"
      to={checkInHome()}
    />
  </LegacyCard>
);
