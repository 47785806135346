import React from "react";
import cx from "classnames";
import styles from "./RadioButtonGroup.module.scss";

interface RadioButtonGroupProps {
  children: React.ReactNode;
  errorMessage?: string | React.ReactNode;
  className?: string;
  errorClassName?: string;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  children,
  className,
  errorClassName,
  errorMessage,
}) => (
  <div className={className}>
    <div className={styles.group}>{children}</div>
    {!!errorMessage && (
      <p className={cx(styles.error, errorClassName)}>Error: {errorMessage}</p>
    )}
  </div>
);
