import React from "react";
import cx from "classnames";
import styles from "./StepStatus.module.scss";
import CheckmarkImage from "images/checkmark-white.svg";

type StepStatusProps = {
  number: number;
  isSubmitted: boolean;
  name: string;
  note?: string;
};

export const StepStatus: React.FC<StepStatusProps> = ({
  number,
  isSubmitted,
  name,
  note,
}) => {
  return (
    <div
      className={cx(styles.container, { [styles.containerDark]: isSubmitted })}
    >
      {isSubmitted ? (
        <span className={styles.check}>
          <img src={CheckmarkImage} alt="Completed" />
        </span>
      ) : (
        <span className={styles.number}>{number}</span>
      )}
      <div>
        <h2 className={styles.heading}>{name}</h2>
        {note && <p className={styles.note}>{note}</p>}
      </div>
    </div>
  );
};
