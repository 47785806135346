import React from "react";

type UseTimerReturn = [
  timerId: number | undefined,
  startTimer: (onTimeout?: () => unknown) => void
];

export const useTimer = (durationMs: number): UseTimerReturn => {
  const [timerId, setTimerId] = React.useState<number>();

  const startTimer = React.useCallback(
    (onTimeout?: () => unknown) => {
      setTimerId(
        window.setTimeout(() => {
          setTimerId(undefined);
          onTimeout && onTimeout();
        }, durationMs)
      );
    },
    [durationMs]
  );

  React.useEffect(() => {
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [timerId]);

  return [timerId, startTimer];
};
