import React from "react";
import { Redirect, RouteProps } from "react-router-dom";
import { externalRedirect } from "utils/externalRedirect";
import { AppRoute } from "features/routing/AppRoute";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import * as paths from "features/routing/paths";

export interface ConditionalRouteProps extends RouteProps {
  enabled: boolean;
}

export const ConditionalRoute: React.FC<ConditionalRouteProps> = ({
  enabled,
  ...otherProps
}) => {
  const { sessionStatus, fetchCurrentPatient } = useCurrentPatient();

  React.useEffect(() => {
    if (sessionStatus === "uninitialized" && !enabled) {
      fetchCurrentPatient();
    }
  }, [enabled, sessionStatus, fetchCurrentPatient]);

  if (enabled) {
    return <AppRoute {...otherProps} />;
  } else if (sessionStatus === "uninitialized") {
    return null;
  } else if (sessionStatus === "logged-in") {
    return <Redirect to={paths.portalHome()} />;
  } else {
    externalRedirect(MYEYEDR_WEBSITE_URL);
    return null;
  }
};
