import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import {
  CreateAppointmentArgs,
  createAppointmentRequest,
  CreateAppointmentResponse,
} from "api/createAppointmentRequest";
import { RootState } from "app/store";
import { Patient } from "types/patient";
import { SelectedAppointment } from "types/selectedAppointment";
import { CalendarLinks } from "types/calendarLinks";

export enum CreateAppointmentResult {
  Created,
  Failed,
  SlotTaken,
  Unknown,
  DuplicateAppointment,
}

type UpdateSelectedAppointmentActionPayload = Partial<SelectedAppointment>;

interface UpdatePatientActionPayload {
  field: keyof Patient;
  value: string | boolean;
}

export type State = {
  selectedAppointment?: SelectedAppointment;
  patient?: Partial<Patient>;
  appointmentBooked: CreateAppointmentResult;
  createdAppointment?: CreateAppointmentResponse;
};

const SLICE_NAME = "appointmentCreation";

export const initialState: State = {
  selectedAppointment: undefined,
  patient: undefined,
  appointmentBooked: CreateAppointmentResult.Unknown,
};

export const createAppointment = createAsyncThunk<
  CreateAppointmentResponse,
  CreateAppointmentArgs
>(`${SLICE_NAME}/createAppointment`, createAppointmentRequest);

export const appointmentCreationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateSelectedAppointment: (
      state,
      action: PayloadAction<UpdateSelectedAppointmentActionPayload>
    ) => {
      return Object.assign({}, state, {
        selectedAppointment: {
          ...state.selectedAppointment,
          ...action.payload,
        },
      });
    },
    updatePatient: (
      state,
      action: PayloadAction<UpdatePatientActionPayload>
    ) => {
      return Object.assign({}, state, {
        patient: {
          ...state.patient,
          [action.payload.field]: action.payload.value,
        },
      });
    },
    clearPatientDateOfBirth: (state) => {
      const newState = cloneDeep(state);
      delete newState.patient?.dateOfBirth;
      return newState;
    },
    reinitialize: () => initialState,
    resetAppointmentBooked: (state) => ({
      ...state,
      appointmentBooked: CreateAppointmentResult.Unknown,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAppointment.rejected, (state, action) => {
        if (action.error.code === "404") {
          return {
            ...state,
            appointmentBooked: CreateAppointmentResult.SlotTaken,
          };
        }

        if (action.error.code === "422") {
          return {
            ...state,
            appointmentBooked: CreateAppointmentResult.DuplicateAppointment,
          };
        }

        return {
          ...state,
          appointmentBooked: CreateAppointmentResult.Failed,
        };
      })
      .addCase(createAppointment.fulfilled, (state, action) => ({
        ...state,
        appointmentBooked: CreateAppointmentResult.Created,
        createdAppointment: action.payload,
      }))
      .addCase(createAppointment.pending, (state) => ({
        ...state,
        appointmentBooked: CreateAppointmentResult.Unknown,
      }));
  },
});

export const reducer = appointmentCreationSlice.reducer;

export const {
  updatePatient,
  updateSelectedAppointment,
  clearPatientDateOfBirth,
  reinitialize,
  resetAppointmentBooked,
} = appointmentCreationSlice.actions;

export const selectSelectedAppointment = (
  state: RootState
): SelectedAppointment | undefined =>
  state.appointmentCreation.selectedAppointment;

export const selectAppointmentBookedStatus = (
  state: RootState
): CreateAppointmentResult => state.appointmentCreation.appointmentBooked;

export const selectPatient = (state: RootState): Partial<Patient> | undefined =>
  state.appointmentCreation.patient;

export const selectCalendarLinks = (
  state: RootState
): CalendarLinks | undefined => {
  const appointment = state.appointmentCreation.createdAppointment;

  if (appointment) {
    return {
      appleUrl: appointment.appleUrl,
      googleUrl: appointment.googleUrl,
      outlookUrl: appointment.outlookUrl,
    };
  }
};

export const selectCreatedAppointment = (
  state: RootState
): CreateAppointmentResponse | undefined => {
  return state.appointmentCreation.createdAppointment;
};
