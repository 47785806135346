import dayjs, { Dayjs } from "dayjs";
import { TimeWindow } from "types/timeWindow";

export const parseStartTime = ({ date, startTime }: TimeWindow): Dayjs =>
  dayjs(`${date} ${startTime}`);

export const parseStartTimeWithZone = ({
  date,
  startTime,
  timeZoneName,
}: TimeWindow): Dayjs => {
  return dayjs.tz(`${date} ${startTime}`, timeZoneName);
};

export const timeUntil = ({
  date,
  startTime,
  timeZoneName,
}: TimeWindow): string => {
  const humanizedDiff = dayjs()
    .tz()
    .to(dayjs.tz(`${date} ${startTime}`, timeZoneName), true);
  return humanizedDiff.match(/day|month/) ? humanizedDiff : "less than a day";
};
