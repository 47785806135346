import React from "react";
import styles from "./MedicaidToast.module.scss";
import { DefaultToast, defaultToastIcons } from "components/Toast/DefaultToast";
import { Button } from "components/Button";
import { useTrackEvent } from "hooks/useTrackEvent";

interface ComponentProps {
  visible: boolean;
  onDismiss: () => void;
  onShowModal: () => void;
  isLoading: boolean;
  showMoreLocation: boolean;
}

export const MedicaidToast: React.FC<ComponentProps> = ({
  visible,
  onDismiss,
  onShowModal,
  isLoading,
  showMoreLocation,
}) => {
  const { trackEvent } = useTrackEvent();

  React.useEffect(() => {
    if (visible) {
      trackEvent("medicaid-banner-displayed");
    }
  }, [visible, trackEvent]);

  return (
    <DefaultToast
      visible={visible}
      sideIcon={<defaultToastIcons.Handbag />}
      onDismiss={onDismiss}
      toastClassName={styles.toast}
    >
      <>
        <div className={styles.text}>
          This location does not accept Medicaid coverage.
        </div>
        <div>
          <Button
            text="More Locations"
            className={styles.btnLink}
            disabled={isLoading || !showMoreLocation}
            onClick={onShowModal}
            loading={isLoading}
          ></Button>
          <span className={styles.noLocationFound}>
            {!showMoreLocation && !isLoading ? " ( No location found )" : ""}{" "}
          </span>
        </div>
        <div className={styles.link}>
          <a
            onClick={() => trackEvent("medicaid-banner-clicked")}
            href="https://locations.myeyedr.com/"
          >
            Back to Locations
          </a>
        </div>
      </>
    </DefaultToast>
  );
};
