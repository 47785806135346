import React from "react";
import styles from "./CheckInCardNew.module.scss";
import { LegacyCard } from "components/LegacyCard";
import { Button } from "components/Button";
import { timeUntil } from "features/appointmentTime";
import { SelectedAppointment } from "types/selectedAppointment";

interface CheckInCardProps {
  appointment: SelectedAppointment;
  onClick: any;
  isSubmit: boolean;
}

export const CheckInCardNew: React.FC<CheckInCardProps> = ({
  appointment,
  onClick,
  isSubmit,
}) => (
  <LegacyCard className={styles.card}>
    <div className={styles.notice}>
      Your appointment is {timeUntil(appointment)} away.
    </div>
    <Button
      text="Submit"
      onClick={onClick}
      size="large"
      className={styles.button}
      disabled={isSubmit}
      loading={isSubmit}
      autoFocus
    />
  </LegacyCard>
);
