import React from "react";
import { SnackbarContext } from "./SnackbarContext";
import { Message } from "./types";

const generateRandomId = (): string => `${Date.now()}-${Math.random()}`;
export const SNACKBAR_MESSAGE_DURATION = 6800;

export const useSnackbar = (): {
  messages: Array<Message>;
  displayMessage: (message: {
    icon: "checkmark";
    text: string;
  }) => Promise<unknown>;
} => {
  const context = React.useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }

  const { state, dispatch } = context;

  const displayMessage = React.useCallback(
    (message: { icon: "checkmark"; text: string }) => {
      const id = generateRandomId();
      // 1. show the message
      dispatch({ type: "add", message: { id, ...message } });

      // 2. remove the message <SNACKBAR_MESSAGE_DURATION>ms later
      return new Promise(() =>
        setTimeout(() => {
          dispatch({ type: "remove", id });
          return;
        }, SNACKBAR_MESSAGE_DURATION)
      );
    },
    [dispatch]
  );

  return { displayMessage, messages: state.messages };
};
