import React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";
import styles from "./AvatarSkeleton.module.scss";

export const AvatarSkeleton: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 66 66"
    backgroundColor="var(--gray-02)"
    foregroundColor="var(--gray-04)"
    className={styles.contentLoader}
    {...props}
  >
    <path d="M66 33C66 51.2254 51.2254 66 33 66C14.7746 66 0 51.2254 0 33C0 14.7746 14.7746 0 33 0C51.2254 0 66 14.7746 66 33ZM84 15C84 12.7909 85.7909 11 88 11H226C228.209 11 230 12.7909 230 15V23C230 25.2091 228.209 27 226 27H88C85.7909 27 84 25.2091 84 23V15ZM88 36C85.7909 36 84 37.7909 84 40V48C84 50.2091 85.7909 52 88 52H226C228.209 52 230 50.2091 230 48V40C230 37.7909 228.209 36 226 36H88Z" />
  </ContentLoader>
);
