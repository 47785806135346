import * as React from "react";
import cx from "classnames";
import styles from "./Card.module.scss";

type CardTheme = "desktopOnly";

type CardProps = {
  children?: React.ReactNode;
  className?: string;
  theme?: CardTheme;
};

export const Card: React.FC<CardProps> = ({ children, className, theme }) => {
  return (
    <section
      className={cx(styles.card, className, {
        [styles.desktopOnly]: theme === "desktopOnly",
      })}
    >
      {children}
    </section>
  );
};
