import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type State = {
  patientId: string | null;
  serverError: boolean;
};

const SLICE_NAME = "brazePatientDetail";

export const initialState: State = {
  patientId: null,
  serverError: false,
};

export const brazePatientDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setBrazePatientDetail: (state, action: PayloadAction<any>) => ({
      ...state,
      patientId: action.payload.patientId,
    }),
    setServerError: (state, action: PayloadAction<any>) => ({
      ...state,
      serverError: action.payload.serverError,
    }),
  },
});

export const reducer = brazePatientDetailSlice.reducer;
export const { setBrazePatientDetail, setServerError } =
  brazePatientDetailSlice.actions;

export const selectBrazePatientDetail = (state: RootState): State => {
  if (state.brazePatientDetail.patientId) {
    return state.brazePatientDetail;
  }

  return initialState;
};

export const selectServerError = (state: RootState): boolean =>
  state.brazePatientDetail.serverError;

export const brazePatientDetailReducer = brazePatientDetailSlice.reducer;
