import React from "react";
import cx from "classnames";
import { useButtonTemplateV2 } from "components/Button/useButtonTemplateV2";
import { ButtonRole, CommonButtonProps } from "components/Button/types";

interface ButtonProps extends CommonButtonProps {
  onClick: (e: React.MouseEvent) => void;
  type?: "button" | "submit";
  disabled?: boolean;
  autoFocus?: boolean;
  form?: string;

  // this won't set the disabled prop on the button, but will style as if
  // it was disabled, allowing the click event to still trigger.
  renderAsDisabled?: boolean;
}

const ButtonV2: React.FC<ButtonProps> = ({
  text,
  role = ButtonRole.Standard,
  onClick,
  className,
  type = "button",
  size = "standard",
  disabled = false,
  loading = false,
  renderAsDisabled = false,
  autoFocus = false,
  form,
}) => {
  const [inner, classNames] = useButtonTemplateV2({
    role,
    size,
    loading,
    text,
    renderAsDisabled,
  });

  return (
    <button
      className={cx(classNames, className)}
      onClick={onClick}
      type={type}
      disabled={disabled}
      aria-disabled={disabled || renderAsDisabled ? true : undefined}
      autoFocus={autoFocus}
      form={form}
    >
      {inner}
    </button>
  );
};

export { ButtonV2 };
export type { ButtonProps };
