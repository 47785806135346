import React from "react";
import styles from "./Navbar.module.scss";
import {
  ProgressHeader,
  ProgressHeaderProps,
} from "features/checkIn/components/ProgressHeader";
import { Navbar as NavbarBase } from "components/Navbar";
interface NavbarProps
  extends Pick<ProgressHeaderProps, "currentStep" | "totalSteps"> {
  title: string;
}
export const Navbar: React.FC<NavbarProps> = ({
  currentStep,
  totalSteps,
  title,
}) => {
  return (
    <NavbarBase
      below={
        <ProgressHeader
          title={title}
          currentStep={currentStep}
          totalSteps={totalSteps}
          visibility="mobile"
        />
      }
    >
      <div className={styles.header}>
        <div className={styles.container}>
          <ProgressHeader
            title={title}
            currentStep={currentStep}
            totalSteps={totalSteps}
            visibility="desktopLarge"
          />
        </div>
      </div>
    </NavbarBase>
  );
};
