import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { SchedulingRouteParams } from "types/schedulingRouteParams";

export const useTrackEvent = (): {
  trackEvent: (
    eventName: string,
    eventProperties?: Record<string, unknown>
  ) => void;
} => {
  const { officeId, examType, patientReturnStatus } =
    useParams<SchedulingRouteParams>();

  const trackEvent = useCallback(
    (eventName, eventProperties) => {
      const event = {
        event: eventName,
        ...(officeId ? { location: officeId } : {}),
        ...(examType ? { selected_type: examType } : {}),
        ...(patientReturnStatus ? { patient_type: patientReturnStatus } : {}),
        ...(eventProperties ?? {}),
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(event);
    },
    [officeId, examType, patientReturnStatus]
  );

  return { trackEvent };
};
