import React from "react";
import styles from "./LimitedAvailability.module.scss";
import { Doctor } from "types/doctor";
import contactPhoneIcon from "images/contact-phone.svg";
import { doctorFullName } from "utils/doctorFullName";

interface LimitedAvailabilityProps {
  doctor: Doctor;
}

export const LimitedAvailability: React.FC<LimitedAvailabilityProps> = ({
  doctor,
}) => {
  return (
    <div className={styles.container}>
      <img src={contactPhoneIcon} alt="Contact Phone" className={styles.icon} />
      <h1 className={styles.header}>
        {doctor.firstName === "Open Network"
          ? `Limited Availability ${doctorFullName(doctor)}s`
          : `${doctorFullName(doctor)} has Limited Availability`}
      </h1>
      <p className={styles.description}>
        We&apos;d love to further assist you. Please choose another doctor from
        the list above.
      </p>
    </div>
  );
};
