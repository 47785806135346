import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./AppointmentFailure.module.scss";
import { ButtonLinkExternal } from "components/Button";
import * as paths from "features/routing/paths";
import { resetAppointmentBooked } from "features/scheduling/appointmentCreationSlice";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { WithOffice } from "components/WithOffice";
import { TextLink } from "components/TextLink";
import { SadLayout } from "features/layout/SadLayout";
import { toTel } from "features/scheduling/utils/toTel";
import { Office } from "types/office";

const UnconnectedAppointmentFailure: React.FC<{
  office: Office;
}> = ({ office }) => {
  const dispatch = useDispatch();
  const { officeId } =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentBooked());
    };
  }, [dispatch]);

  const cta = (
    <>
      <ButtonLinkExternal
        className={styles.button}
        href={toTel(office.formattedPhone)}
        size="large"
        text="Call the office"
      />
      <TextLink to={paths.appointmentBookingStart({ officeId })}>
        Return to home
      </TextLink>
    </>
  );

  return (
    <SadLayout
      headline="We're sorry!"
      message={
        <p className={styles.message}>
          We are unable to schedule your appointment at this time. Please call
          the office at{" "}
          <a href={toTel(office.formattedPhone)}>{office.formattedPhone}</a> to
          schedule your appointment.
        </p>
      }
      cta={cta}
    />
  );
};

export const AppointmentFailure: React.FC = () => (
  <NeedsSelectedAppointment>
    {() => (
      <WithOffice>
        {({ office }) => <UnconnectedAppointmentFailure office={office} />}
      </WithOffice>
    )}
  </NeedsSelectedAppointment>
);
