import React from "react";
import { Card } from "./Card";
import styles from "./WhatToExpectCard.module.scss";
import { OfficeInfo } from "./OfficeInfo";

type WhatToExpectCardProps = {
  officeId: string;
};

export const WhatToExpectCard: React.FC<WhatToExpectCardProps> = ({
  officeId,
}) => {
  return (
    <Card
      title="Not sure what to expect?"
      caption={
        <div className={styles.caption}>
          We are proud that you chose us for your Eye Health needs.
        </div>
      }
    >
      <p className={styles.paragraph}>
        For more info on MyEyeDr’s comprehensive eye health exam, please view
        our{" "}
        <a
          href="https://www.myeyedr.com/frequently-asked-questions#about-us"
          target="_blank"
          rel="noreferrer"
        >
          FAQ page
        </a>{" "}
        or call your local office:
      </p>
      <OfficeInfo className={styles.office} officeId={officeId} />
    </Card>
  );
};
