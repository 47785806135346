import {
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { reduce } from "lodash";
import {
  AvailabilitySummaryState,
  DoctorAvailability,
} from "./availabilitySummarySlice";
import { API_DATE_FORMAT } from "api/constants";
import { getAvailabilitySummaryRequest } from "api/getAvailabilitySummaryRequest";
import { RootState } from "app/store";
import { AvailabilitySummaryWithDoctor } from "types/availabilitySummary";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";

const SLICE_NAME = "firstAvailability";

const initialState: AvailabilitySummaryState = {};

interface FetchFirstAvailabilityArgs {
  officeId: string;
  examType: ExamType;
  doctorIds: string[];
  patientReturnStatus: PatientReturnStatus;
}

const fetchFirstAvailabilityAction: AsyncThunkPayloadCreator<
  AvailabilitySummaryWithDoctor[],
  FetchFirstAvailabilityArgs
> = async ({
  officeId,
  examType,
  doctorIds,
  patientReturnStatus,
}: FetchFirstAvailabilityArgs) => {
  return getAvailabilitySummaryRequest({
    officeId,
    examType,
    patientReturnStatus,
    fromDate: dayjs().format(API_DATE_FORMAT),
    doctorIds: doctorIds,
  });
};

interface AvailabilitySummary {
  [doctorId: string]: DoctorAvailability;
}

export const fetchFirstAvailability = createAsyncThunk(
  `${SLICE_NAME}/fetchFirstAvailability`,
  fetchFirstAvailabilityAction
);

export const firstAvailabilitySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFirstAvailability.fulfilled, (state, action) => {
        const { officeId } = action.meta.arg;
        const map = reduce(
          action.payload,
          (accum: AvailabilitySummary, x) => {
            accum[x.doctorId] = x.predictedAvailableDate;
            return accum;
          },
          {}
        );
        return {
          ...state,
          [officeId]: {
            loaded: true,
            doctors: map,
          },
        };
      })
      .addCase(fetchFirstAvailability.pending, (state, action) => {
        const { officeId } = action.meta.arg;
        return {
          ...state,
          [officeId]: {
            loaded: false,
            doctors: {},
          },
        };
      })
      .addCase(fetchFirstAvailability.rejected, (state, action) => {
        const { officeId } = action.meta.arg;
        return {
          ...state,
          [officeId]: {
            loaded: true,
            doctors: {},
          },
        };
      });
  },
});

export const reducer = firstAvailabilitySlice.reducer;

export const selectFirstAvailableDatesForAllDoctorsInOffice = (
  state: RootState,
  officeId: string
): AvailabilitySummary => {
  return state[SLICE_NAME][officeId]?.doctors;
};

export const selectFirstAvailablityLoadedForOffice = (
  state: RootState,
  officeId: string
): boolean => {
  return state[SLICE_NAME][officeId]?.loaded ?? false;
};
