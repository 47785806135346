import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type State = {
  appointmentData: {
    status: boolean;
    office: any;
    doctor: any;
    timeSlot: any;
    patientType: any;
    eyeExam: any;
    patientDetails: any;
    lastRequestUrl: any;
  };
  isEditForm: boolean;
  editSection: undefined | string;
  version: number;
};

const SLICE_NAME = "appointmentData";

export const initialState: State = {
  appointmentData: {
    status: false,
    office: {},
    doctor: undefined,
    timeSlot: undefined,
    patientType: {},
    eyeExam: {},
    patientDetails: {},
    lastRequestUrl: {},
  },
  isEditForm: false,
  editSection: undefined,
  version: 1,
};

export const appointmentDataSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAppointmentData: (state, action: PayloadAction<any>) => ({
      ...state,
      appointmentData: action.payload,
    }),
    setIsEditForm: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isEditForm: action.payload,
    }),
    setEditSection: (state, action: PayloadAction<undefined | string>) => ({
      ...state,
      editSection: action.payload,
    }),
    setVersion: (state, action: PayloadAction<number>) => ({
      ...state,
      version: action.payload,
    }),
  },
});

export const reducer = appointmentDataSlice.reducer;
export const { setAppointmentData, setIsEditForm, setEditSection, setVersion } =
  appointmentDataSlice.actions;

export const selectAppointmentData = (state: RootState): any => {
  if (state.appointmentData) {
    return state.appointmentData.appointmentData;
  }

  return [];
};

export const selectIsEditForm = (state: RootState): any => {
  if (state.appointmentData.isEditForm) {
    return state.appointmentData.isEditForm;
  }

  return false;
};

export const selectEditSection = (state: RootState): any => {
  if (state.appointmentData.editSection) {
    return state.appointmentData.editSection;
  }

  return undefined;
};

export const selectVersion = (state: RootState): any => {
  if (state.appointmentData.version) {
    return state.appointmentData.version;
  }

  return 1;
};

export const appointmentDataReducer = appointmentDataSlice.reducer;
