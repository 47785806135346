import axios from "axios";
import { TokenStatus } from "types/tokenStatus";

export type VerifyTokenResponse = {
  demographics_required: boolean;
};

export const verifyTokenRequest = async (
  token: string
): Promise<TokenStatus> => {
  const response = await axios.get<VerifyTokenResponse>(`/api/tokens/${token}`);

  return {
    isDemographicsRequired: response.data.demographics_required,
  };
};
