import React from "react";
import cx from "classnames";
import styles from "./TaskList.module.scss";
import { AllTasksCompletedFanfare } from "./AllTasksCompletedFanfare";
import { ProgressBar } from "./ProgressBar";
import checkmarkIcon from "images/checkmark.svg";
import { Card } from "components/Card";

export type Task = {
  title: string;
  description?: string;
  isCompleted: true | false | "partially";
};

type TaskListProps = {
  title: string;
  tasks: Task[];
  completionMessage?: JSX.Element;
};

export const TaskList: React.FC<TaskListProps> = ({
  title,
  tasks,
  completionMessage,
}) => {
  const numCompleted = tasks.filter((task) => task.isCompleted === true).length;
  const isPartiallyCompleted = tasks.some(
    (task) => task.isCompleted === "partially"
  );

  return (
    <Card className={cx(styles.card)}>
      <div className={styles.headerContainer}>
        <header className={styles.header}>
          <span>{title}</span>
          <span className={styles.ratio}>
            {numCompleted}/{tasks.length}
          </span>
        </header>
        {completionMessage && numCompleted === tasks.length && (
          <AllTasksCompletedFanfare className={styles.headerBubble}>
            {completionMessage}
          </AllTasksCompletedFanfare>
        )}
      </div>

      <ProgressBar
        className={styles.progressBar}
        completedNodes={
          isPartiallyCompleted ? numCompleted + 0.5 : numCompleted
        }
        totalNodes={tasks.length}
      />

      <section className={styles.section}>
        <ul className={styles.list}>
          {tasks.map(({ isCompleted, title, description }) => (
            <li key={title} className={styles.task}>
              <div
                className={cx(styles.taskTitle, {
                  [styles.taskTitleCompleted]: isCompleted === true,
                })}
              >
                <img src={checkmarkIcon} alt="Completed: " />
                <span>{title}</span>
              </div>
              {description && (
                <div className={styles.taskDescription}>{description}</div>
              )}
            </li>
          ))}
        </ul>
      </section>
    </Card>
  );
};
