import React from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { SlotButtons } from "./SlotButtons";
import styles from "./DoctorSlots.module.scss";
import { availabilityInfo } from "./availabilityInfo";
import {
  ButtonLinkExternal,
  ButtonLinkInternal,
  ButtonRole,
} from "components/Button";
import { DoctorAvailability } from "features/scheduling/availabilitySummarySlice";
import { DateToSlotsMap } from "features/scheduling/timeSlotsSlice";
import { ConnectedDoctorSlotsProps } from "features/scheduling/components/ConnectedDoctorSlots";
import * as paths from "features/routing/paths";
import { ExamType } from "types/examType";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Slot } from "types/slot";
import { AvatarSkeleton } from "components/Avatar/AvatarSkeleton";
import { ButtonSkeleton } from "components/Button/ButtonSkeleton";
import { toTel } from "features/scheduling/utils/toTel";
import { DoctorInfoSkeleton } from "features/scheduling/components/DoctorInfoSkeleton";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullName } from "utils/doctorFullName";
import { API_DATE_FORMAT } from "api/constants";

export type SelectSlotHandler = (x: Slot) => void;

interface ActionButtonProps {
  availability: DoctorAvailability;
  forDate: string;
  officeId: string;
  doctorId: string;
  telephone?: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

const buttonText = (availability: string, forDate: string) =>
  dayjs(availability).isSame(dayjs(forDate), "day")
    ? "View by week"
    : "View next available";

const ActionButton: React.FC<ActionButtonProps> = ({
  availability,
  forDate,
  officeId,
  doctorId,
  telephone,
  examType,
  patientReturnStatus,
}) => {
  if (!availability) {
    return (
      <div className={styles.action}>
        <ButtonLinkExternal
          href={toTel(telephone)}
          text="Call The Office"
          role={ButtonRole.Ghost}
        />
      </div>
    );
  }

  return (
    <div className={styles.action}>
      <ButtonLinkInternal
        to={`${paths.appointmentsAvailableAppointmentsDoctor({
          officeId,
          examType,
          patientReturnStatus,
          doctorId: doctorId,
          query: {
            date: availability,
          },
        })}`}
        text={buttonText(availability, forDate)}
        role={ButtonRole.Standard}
      />
    </div>
  );
};

export interface DoctorSlotsProps extends ConnectedDoctorSlotsProps {
  slots: DateToSlotsMap;
  availability: DoctorAvailability;
  office: Office;
}

export const DoctorSlots: React.FC<DoctorSlotsProps> = ({
  forDate,
  doctor,
  onSelectSlot,
  availability,
  office,
  slots,
  examType,
  patientReturnStatus,
}) => {
  if (availability === "loading") {
    return (
      <div>
        <div className={styles.doctor}>
          <div className={styles.imageContainer}>
            <AvatarSkeleton />
          </div>
          <div className={styles.doctorDetails}>
            <div>
              <DoctorInfoSkeleton />
            </div>
          </div>
        </div>

        <div className={styles.slots}>
          <ButtonSkeleton />
        </div>
      </div>
    );
  }

  const slotsForDate = slots[forDate];
  const slotsForTomorrow = slots[dayjs().add(1, "day").format(API_DATE_FORMAT)];
  const shouldShowTomorrow =
    dayjs(forDate).isToday() && slotsForTomorrow?.length > 0;
  const isAvailableStartingTomorrow =
    availability !== forDate && shouldShowTomorrow;

  return (
    <div>
      <div className={styles.doctor}>
        <div className={styles.imageContainer}>
          <DoctorAvatar doctor={doctor} selectedOffice={office} />
        </div>
        <div className={styles.doctorDetails}>
          {doctorFullName(doctor, office.namechangeVEH)}{" "}
        </div>
      </div>

      <div
        className={cx(styles.availability, {
          [styles.noAvailability]: isAvailableStartingTomorrow,
        })}
      >
        <span>
          {isAvailableStartingTomorrow
            ? "No Availability Today"
            : availabilityInfo(availability)}
          {doctor.offersVEH && (
            <>
              <br />
            </>
          )}
        </span>
      </div>

      {slotsForDate?.length > 0 && (
        <div className={styles.slots}>
          <SlotButtons slots={slotsForDate} onSelectSlot={onSelectSlot} />
        </div>
      )}

      {shouldShowTomorrow && (
        <>
          <div className={styles.availability}>Available Tomorrow</div>
          <div className={styles.slots}>
            <SlotButtons slots={slotsForTomorrow} onSelectSlot={onSelectSlot} />
          </div>
        </>
      )}

      <ActionButton
        availability={availability}
        forDate={forDate}
        officeId={office.id}
        doctorId={doctor.id}
        telephone={office.formattedPhone}
        examType={examType}
        patientReturnStatus={patientReturnStatus}
      />
    </div>
  );
};
