import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { RootState } from "app/store";
import { getOfficeRequest, getOfficeV1Request, getPilotOfficeRequest } from "api/getOfficeRequest";
import { getDoctorsForOfficeRequest } from "api/getDoctorsForOfficeRequest";
import { Office } from "types/office";
import { Doctor } from "types/doctor";

export type OptionalOffice = Office | "not-found";

type OfficeMap = {
  [officeId: string]: OptionalOffice;
};

const SLICE_NAME = "offices";

export type OfficesState = {
  pilotOffices: any;
  offices: OfficeMap;
  ids: { [requestedId: string]: string };
  doctorsByOffice: {
    [officeId: string]: Doctor[];
  };
  sortedDoctorsByOffice: {
    [officeId: string]: Doctor[];
  };
  serverError: boolean;
};

export const initialState: OfficesState = {
  pilotOffices: {},
  offices: {},
  ids: {},
  doctorsByOffice: {},
  sortedDoctorsByOffice: {},
  serverError: false,
};

export const fetchPilotOfficeInfo = createAsyncThunk(
  `${SLICE_NAME}/fetchPilotOfficeInfo`,
  getPilotOfficeRequest
);

export const fetchOfficeInfo = createAsyncThunk(
  `${SLICE_NAME}/fetchOfficeInfo`,
  getOfficeRequest
);

export const fetchOfficeV1Info = createAsyncThunk(
  `${SLICE_NAME}/fetchOfficeInfo`,
  getOfficeV1Request
);

export const fetchDoctorsForOffice = createAsyncThunk(
  `${SLICE_NAME}/fetchDoctorsForOffice`,
  getDoctorsForOfficeRequest
);

export const officesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSortedDoctorsByOffice: (state, action) => ({
      ...state,
      sortedDoctorsByOffice: {
        ...state.sortedDoctorsByOffice,
        [action.payload.officeId]: action.payload.newSortedDoctors,
      },    
    }),
    setDoctorsByOffice: (state, action) => ({
      ...state,
      doctorsByOffice: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorsForOffice.fulfilled, (state, action) => ({
        ...state,
        doctorsByOffice: {
          ...state.doctorsByOffice,
          [action.meta.arg]: action.payload,
        },
      }))
      .addCase(fetchDoctorsForOffice.rejected, (state) => ({
        ...state,
        serverError: true,
      }))
      .addCase(fetchOfficeInfo.fulfilled, (state, action) => ({
        ...state,
        offices: {
          ...state.offices,
          [action.payload.id]: action.payload,
        },
        ids: {
          ...state.ids,
          [action.meta.arg]: action.payload.id,
          [action.payload.id]: action.payload.id,
        },
      }))
      .addCase(fetchOfficeInfo.rejected, (state, action) => {
        const officeId = action.meta.arg;
        return action.error.code === "404"
          ? merge({}, state, {
              serverError: true,
              offices: { [officeId]: "not-found" as OptionalOffice },
              ids: { [officeId]: officeId },
            })
          : {
              ...state,
              serverError: true,
            };
      })
      .addCase(fetchPilotOfficeInfo.fulfilled, (state, action) => ({
        ...state,
        pilotOffices: {
          ...state.pilotOffices,
          pilotOffice: action.payload,
        }
      }));
  },
});

export const reducer = officesSlice.reducer;
export const { setSortedDoctorsByOffice, setDoctorsByOffice } = officesSlice.actions;

export const selectDoctorsForOffice = (
  state: RootState,
  officeId: string
): Doctor[] | undefined => {
  if (state.offices.doctorsByOffice) {
    return state.offices.doctorsByOffice[officeId];
  }

  return undefined;
};

export const selectSortedDoctorsForOffice = (
  state: RootState,
  officeId: string
): Doctor[] | undefined => {
  if (state.offices.sortedDoctorsByOffice) {
    return state.offices.sortedDoctorsByOffice[officeId];
  }
  return undefined;
};

export const selectServerError = (state: RootState): boolean =>
  state.offices.serverError;

export const selectOffices = (state: RootState): OfficeMap =>
  state.offices.offices;

export const selectPilotOffices = (state: RootState): any =>
  state.offices.pilotOffices;

export const selectOffice = (
  state: RootState,
  officeId: string
): OptionalOffice | undefined => {
  const resolvedOfficeId = state.offices.ids[officeId];
  return state.offices.offices[resolvedOfficeId];
};
