import React from "react";
import { useFormContext } from "react-hook-form";
import styles from "./DocumentAcknowledgmentModal.module.scss";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { acknowledgeDocument } from "api/acknowledgeDocument";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { DocumentType } from "features/checkIn/types";
import { DocumentPrintButton } from "features/checkIn/components/DocumentPrintButton";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";

interface DocumentAcknowledgmentModalProps {
  document: React.FC;
  documentType: DocumentType;
  documentTitle: string;
  documentVersion: string;
  open: boolean;
  onDismiss: () => void;
}

export const DocumentAcknowledgmentModal: React.FC<
  DocumentAcknowledgmentModalProps
> = ({
  document,
  documentTitle,
  documentType,
  documentVersion,
  open,
  onDismiss,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { fetchCurrentAppointment } = useCurrentAppointment();
  const { trigger } = useFormContext();
  const Document = document;
  const { showErrorToast } = useErrorToast();

  const submitAcknowledgment = async () => {
    setLoading(true);
    try {
      await acknowledgeDocument({
        documentType,
        documentVersion,
      });
      await fetchCurrentAppointment();
    } catch (err) {
      showErrorToast();
    }
    setLoading(false);
    onDismiss();
    trigger();
  };

  return (
    <Modal isOpen={open} onRequestClose={onDismiss} className={styles.modal}>
      <div className={styles.header}>
        <h1 className={styles.modalTitle}>{documentTitle}</h1>
        <div className={styles.printButtonContainer}>
          <DocumentPrintButton
            document={<Document />}
            filename={documentVersion}
          />
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={styles.modalEmbeddedDocument} tabIndex={0}>
        <Document />
      </div>
      <p className={styles.modalCaption}>
        By tapping &quot;Authorize Signature&quot; I acknowledge that I have
        read, and agreed and authorize my signature to the {documentTitle}.
      </p>
      <div className={styles.modalButtons}>
        <Button
          text="AUTHORIZE SIGNATURE"
          onClick={submitAcknowledgment}
          loading={loading}
          disabled={loading}
          size="large"
          autoFocus
        />
      </div>
    </Modal>
  );
};
