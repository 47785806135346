import React from "react";
import styles from "./MobileAccordionSteps.module.scss";
import * as paths from "features/routing/paths";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import {
  getPosition,
  SchedulingStep,
} from "features/scheduling/components/nav/SchedulingStep";

interface MobileAccordionStepsProps {
  step: number;
  officeId: string;
  examType?: ExamType;
  patientReturnStatus?: PatientReturnStatus;
}

export const MobileAccordionSteps: React.FC<MobileAccordionStepsProps> = ({
  step,
  examType,
  officeId,
  patientReturnStatus,
}) => {
  return (
    <>
      <SchedulingStep
        className={styles.mobileHeader}
        label="Eye Health Exam"
        position={getPosition(step, 1)}
        editLinkTo={paths.appointmentsSelectType({ officeId })}
      />
      <SchedulingStep
        label="Patient Details"
        className={styles.mobileHeader}
        position={getPosition(step, 2)}
        editLinkTo={
          examType &&
          paths.appointmentsSelectReturnStatus({
            officeId,
            examType,
          })
        }
      />
      <SchedulingStep
        label="Schedule with a Doctor"
        className={styles.mobileHeader}
        position={getPosition(step, 3)}
        editLinkTo={
          examType &&
          patientReturnStatus &&
          paths.appointmentsAvailableAppointments({
            officeId,
            examType,
            patientReturnStatus,
          })
        }
      />
    </>
  );
};
