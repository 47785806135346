import React from "react";
import styles from "./InfoCard.module.scss";
import { LegacyCard } from "components/LegacyCard";

interface InfoCardProps {
  details: React.ReactNode;
  children?: React.ReactNode;
  image: React.ReactNode;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  details,
  children,
  image,
}) => {
  return (
    <LegacyCard>
      <div>
        <div className={styles.infoContainer}>
          <div className={styles.imageContainer}>{image}</div>
          <div className={styles.details}>{details}</div>
        </div>
      </div>
      {children}
    </LegacyCard>
  );
};
