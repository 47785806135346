import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./AppointmentExamDetails.module.scss";
import { WearOption } from "./components/WearOption";
import {
  DesktopBreadcrumb,
  MobileAccordionContainer,
  MobileAccordionSteps,
} from "./components/nav";
import { today } from "features/scheduling/utils/today";
import * as paths from "features/routing/paths";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import glassesIcon from "images/glasses-icon.svg";
import contactsIcon from "images/contacts-icon.svg";
import glassesContactsIcon from "images/glasses-contacts-icon.svg";
import eyeIcon from "images/eye-icon.svg";
import { Button } from "components/Button";
import { Layout } from "features/layout";
import { Navbar } from "components/Navbar";
import { ExamType } from "types/examType";
import { Modal } from "components/Modal";
import { WithOffice } from "components/WithOffice";
import { Office } from "types/office";

const aria = {
  labelledby: "appointment-exam-details-modal-label",
  describedby: "appointment-exam-details-modal-description",
};

interface UnconnectedAppointmentExamDetailsProps {
  office: Office;
}

const UnconnectedAppointmentExamDetails: React.FC<
  UnconnectedAppointmentExamDetailsProps
> = ({ office }) => {
  const history = useHistory();

  const chooseExamType = (examType: ExamType) => {
    history.push(
      paths.appointmentsSelectReturnStatus({ officeId: office.id, examType })
    );
  };

  const [patientOnlyWearsGlasses, setPatientOnlyWearsGlasses] =
    React.useState<boolean>(false);

  usePageViewTracking("step-2-scheduler-start");

  return (
    <Layout
      navbar={
        <Navbar office={office}>
          <DesktopBreadcrumb step={1} officeId={office.id} />
        </Navbar>
      }
    >
      <MobileAccordionContainer>
        <MobileAccordionSteps step={1} officeId={office.id} />

        <Modal
          aria={aria}
          isOpen={patientOnlyWearsGlasses}
          onRequestClose={() => setPatientOnlyWearsGlasses(false)}
        >
          <p id={aria.labelledby} className={styles.interrogative}>
            Are you interested in a contacts fitting?
          </p>
          <p id={aria.describedby} className={styles.qualifier}>
            Your comfort is important to us. We set aside extra time for
            first-time wearers.
          </p>
          <div className={styles.buttons}>
            <Button
              text="Yes"
              size="large"
              onClick={() => chooseExamType(ExamType.GlassesTryContacts)}
              className={styles.button}
            />
            <Button
              text="No"
              size="large"
              onClick={() => chooseExamType(ExamType.GlassesNoTryContacts)}
              className={styles.button}
            />
          </div>
        </Modal>

        <div className={styles.layout}>
          <div className={styles.prompt}>
            <h1 className={styles.headline}>Tell us about yourself!</h1>
            <hr className={styles.divider} />
            <div className={styles.yourAnswer}>
              We&apos;d like to know what you <strong>currently use</strong>.
              Your answers will help us prepare for your visit.
            </div>
          </div>

          <div className={styles.iWearGrid}>
            <WearOption
              label="I only wear glasses"
              onClick={() => {
                setPatientOnlyWearsGlasses(true);
              }}
              icon={glassesIcon}
            />
            <WearOption
              label="I only wear contacts"
              onClick={() => chooseExamType(ExamType.Contacts)}
              icon={contactsIcon}
            />
            <WearOption
              label="I wear glasses and contacts"
              onClick={() => chooseExamType(ExamType.GlassesAndContacts)}
              icon={glassesContactsIcon}
            />
            <WearOption
              label="I do not wear glasses or contacts"
              onClick={() => chooseExamType(ExamType.NoGlassesOrContacts)}
              icon={eyeIcon}
            />
          </div>
          <div className={styles.bg} />
        </div>
      </MobileAccordionContainer>
    </Layout>
  );
};

declare let isEnabledV2: string;
export const AppointmentExamDetails: React.FC = () => {
  const { officeId } =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const history = useHistory();
  if (isEnabledV2) {
    history.push(paths.v2appointmentStart(officeId, today));
    return null;
  }

  return (
    <WithOffice officeId={officeId} version={1}>
      {({ office }) => <UnconnectedAppointmentExamDetails office={office} />}
    </WithOffice>
  );
};
