import React from "react";
import { Link } from "react-router-dom";
import { DeveloperNav } from "./DeveloperNav";
import { Layout } from "features/layout";
import * as paths from "features/routing/paths";

export const Root: React.FC = () => {
  return (
    <Layout>
      <div>
        <h1>Welcome to MyEyeDr.</h1>
      </div>
      <ul>
        <li>
          <a href="/pxp-admin">PxP Administration</a>
        </li>
        <li>
          <Link to={paths.offices()}>Begin Scheduling Process</Link>
        </li>
        <li>
          <Link to={paths.portalLogin()}>Portal Login</Link>
        </li>
        <DeveloperNav />
      </ul>
    </Layout>
  );
};
