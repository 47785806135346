import React from "react";
import styles from "features/componentCatalog/ComponentCatalog.module.scss";
import { Modal } from "components/Modal";
import { ConfirmNavigation } from "components/ConfirmNavigation";

export const ModalDemo: React.FC = () => {
  const [isShortModalOpen, setShortModal] = React.useState(false);
  const [isLongModalOpen, setLongModal] = React.useState(false);
  const [confirmNavigation, setConfirmNavigation] = React.useState(false);

  return (
    <div className={styles.section}>
      <h1>Modal</h1>

      <button onClick={() => setShortModal(true)}>Short Modal</button>
      <Modal
        isOpen={isShortModalOpen}
        onRequestClose={() => setShortModal(false)}
        closeOnOverlayClick={true}
      >
        <h1>This is a modal</h1>
      </Modal>

      <button onClick={() => setLongModal(true)}>Long Modal</button>
      <Modal
        isOpen={isLongModalOpen}
        onRequestClose={() => setLongModal(false)}
        closeOnOverlayClick={true}
      >
        <ul>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            obcaecati velit, quam facilis magnam, laudantium illum quis rem est
            corrupti reiciendis eligendi. Sit voluptatum error accusantium ipsam
            consectetur odio eum!
          </li>
        </ul>
      </Modal>

      <button onClick={() => setConfirmNavigation(!confirmNavigation)}>
        Confirm Navigation Modal: {confirmNavigation.toString()}
      </button>
      <ConfirmNavigation enableOnLocationChange={() => confirmNavigation} />
    </div>
  );
};
