import dayjs from "dayjs";
import { FixedArray } from "utils/FixedArray";

export type DayjsWeek = FixedArray<dayjs.Dayjs, 7>;

export const calendarWeek = (searchDate: string): DayjsWeek => {
  const week = [];
  const searchDay = dayjs(searchDate);
  const sundayPrior = searchDay.startOf("week");
  let start = sundayPrior;
  const saturdayAfter = searchDay.endOf("week");
  while (start.isBefore(saturdayAfter)) {
    week.push(start);
    start = start.add(1, "day");
  }
  return week as DayjsWeek;
};

export const calendarWeekV2 = (searchDate: string): DayjsWeek => {
  const week: any[] = [];
  ["0", "1", "2", "3", "4", "5", "6"].map((i) =>
    week.push(dayjs(searchDate).add(parseInt(i), "day"))
  );
  return week as DayjsWeek;
};
