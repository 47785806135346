import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import styles from "./DocumentPrintButton.module.scss";
import printIcon from "images/print.svg";
import { TextButton } from "components/TextButton";

interface DocumentPrintButtonProps {
  className?: string;
  document: React.ReactElement;
  filename: string;
  linkText?: string;
}

export const DocumentPrintButton: React.FC<DocumentPrintButtonProps> = ({
  className,
  document,
  filename,
  linkText,
}) => {
  const createPrintPreview = () => {
    const newTab = window.open("", filename);

    if (!newTab) return;

    ReactDOM.render(document, newTab.document.body);
    newTab.document.title = filename;
    newTab.print();
  };

  if (linkText) {
    return (
      <TextButton className={styles.inlineLink} onClick={createPrintPreview}>
        {linkText}
      </TextButton>
    );
  }

  return (
    <button
      className={cx(styles.button, className)}
      onClick={createPrintPreview}
    >
      <img src={printIcon} alt="Print" />
    </button>
  );
};
