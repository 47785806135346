import React from "react";
import cx from "classnames";
import styles from "./Home.module.scss";
import { ButtonLinkInternal } from "components/Button";
import { checkInPrescriptions } from "features/routing/paths";

export const StartPrescriptions: React.FC = () => {
  return (
    <div className={styles.currentAction}>
      <header className={styles.currentActionHeader}>
        <h2 className={styles.preheading}>Almost done,</h2>
        <h1 className={styles.heading}>How Rx works at MyEyeDr.</h1>
        <aside className={cx(styles.aside, styles.asideClipboardClock)}>
          Simple 1-Step Process
        </aside>
      </header>

      <div className={styles.actionText}>
        We&apos;ll provide easy access to any future prescriptions online and at
        the office.
      </div>

      <div className={styles.action}>
        <ButtonLinkInternal
          text="Continue"
          size="large"
          to={checkInPrescriptions()}
        />
      </div>
    </div>
  );
};
