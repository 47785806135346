import React from "react";
import { DefaultToast, defaultToastIcons } from "components/Toast/DefaultToast";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";

interface SessionExpiredToastProps {
  visible: boolean;
  onDismiss: () => void;
}

export const UnconnectedSessionExpiredToast: React.FC<
  SessionExpiredToastProps
> = ({ visible, onDismiss }) => {
  return (
    <DefaultToast
      visible={visible}
      sideIcon={<defaultToastIcons.Clock />}
      onDismiss={onDismiss}
    >
      <p>
        The session has expired due to inactivity. Log in to manage your
        account.
      </p>
    </DefaultToast>
  );
};

export const SessionExpiredToast: React.FC = () => {
  const { sessionStatus } = useCurrentPatient();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setVisible(sessionStatus === "expired");
  }, [sessionStatus]);

  return (
    <UnconnectedSessionExpiredToast
      visible={visible}
      onDismiss={() => setVisible(false)}
    />
  );
};
