import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AppointmentSuccess.module.scss";
import {
  reinitialize,
  selectCalendarLinks,
  selectCreatedAppointment,
  selectPatient,
} from "features/scheduling/appointmentCreationSlice";
import { CalendarLinkModal } from "components/CalendarLinkModal";
import { parseStartTimeWithZone } from "features/appointmentTime";
import { Doctor } from "types/doctor";
import { DoctorInfoCard } from "components/DoctorInfoCard";
import { OfficeInfoCard } from "components/OfficeInfoCard";
import { Layout } from "features/layout";
import { Button, ButtonRole } from "components/Button";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Office } from "types/office";
import { SelectedAppointment } from "types/selectedAppointment";
import { Navbar } from "components/Navbar";
import { CheckInCard } from "features/scheduling/components/CheckInCard";
import confirmationIcon from "images/confirmation-icon.svg";
import { WithOffice } from "components/WithOffice/WithOffice";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";

interface AppointmentSuccessProps {
  office: Office;
  doctor: Doctor;
  selectedAppointment: SelectedAppointment;
}

export const UnconnectedAppointmentSuccess: React.FC<
  AppointmentSuccessProps
> = ({ office, doctor, selectedAppointment }) => {
  const parsedTime = parseStartTimeWithZone(selectedAppointment);
  const formattedDate = parsedTime.format("ddd. MMM D, YYYY");
  const formattedTime = parsedTime.format("h:mm A z");
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const createdAppointment = useSelector(selectCreatedAppointment);
  const patient = useSelector(selectPatient);
  usePageViewTracking("step-8-appointment-created", {
    doctorId: doctor.id,
    patientId: createdAppointment?.patientId,
    patientPhoneNumber: patient?.phoneNumber,
    patientSmsOptIn: patient?.smsOptInNotices,
    appointmentId: createdAppointment?.id,
  });
  const calendarLinks = useSelector(selectCalendarLinks);

  useEffect(() => {
    return () => {
      dispatch(reinitialize());
    };
  }, [dispatch]);

  return (
    <Layout navbar={<Navbar office={office} />}>
      <div data-testid="appElement" className={styles.container}>
        <p className={styles.youreScheduled}>You’re Scheduled!</p>
        <h2 className={styles.subheader}>
          {formattedDate} at {formattedTime}
        </h2>

        <div className={styles.twoColumns}>
          <div className={styles.column}>
            <div>
              <OfficeInfoCard office={office} />
              <DoctorInfoCard doctor={doctor} office={office} />
            </div>
            <Button
              className={styles.calendarButton}
              text="Add to Calendar"
              onClick={(ev) => {
                setModalOpen(true);
                ev.stopPropagation();
              }}
              role={ButtonRole.Ghost}
              size="large"
            />
          </div>

          <div className={styles.column}>
            <CheckInCard appointment={selectedAppointment} />
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.confirmIcon}>
            <img src={confirmationIcon} alt="confirmationIcon" />
          </div>
          <div className={styles.message}>
            A confirmation email has been sent to you with a link to your
            Patient Portal. This may take a few minutes to arrive.
            <div className={styles.caption}>
              In the Patient Portal, you can manage everything about your
              appointment, such as adding your insurance info and easily
              rescheduling if needed.
            </div>
          </div>
        </div>
      </div>
      <CalendarLinkModal
        onRequestClose={() => setModalOpen(false)}
        isModalOpen={isModalOpen}
        links={calendarLinks}
      />
    </Layout>
  );
};

export const AppointmentSuccess: React.FC = () => (
  <WithOffice>
    {({ office }) => (
      <NeedsSelectedAppointment>
        {({ selectedAppointment, doctor }) => (
          <UnconnectedAppointmentSuccess
            office={office}
            doctor={doctor}
            selectedAppointment={selectedAppointment}
          />
        )}
      </NeedsSelectedAppointment>
    )}
  </WithOffice>
);
