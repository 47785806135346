import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./MagicLinkLanding.module.scss";
import * as paths from "features/routing/paths";
import { ErrorToast } from "features/patientDashboard/ErrorToast";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { SadLayout } from "features/layout/SadLayout";
import { ButtonLinkExternal } from "components/Button";
import { verifyTokenRequest } from "api/verifyTokenRequest";
import { useQueryParam } from "hooks/useQueryParam";
import { REDIRECT_KEY } from "features/routing/AuthorizedRouter";
import { useCreatePatientSession } from "features/login/hooks/useCreatePatientSession";

const ScheduleCta: React.FC<{ styles: { button: string } }> = ({ styles }) => (
  <ButtonLinkExternal
    href={paths.LOCATIONS_PAGE_SEARCH_URL}
    text="Schedule Appointment"
    size="large"
    className={styles.button}
  />
);

const Message: React.FC<{ styles: { message: string } }> = ({ styles }) => (
  <p className={styles.message}>
    Hmm, looks like your appointment time was rescheduled or has passed.
    Reschedule with us if you've reached this message in error.
  </p>
);

type AppointmentStatus = null | "valid" | "invalid";

export const MagicLinkLanding: React.FC = () => {
  const { token } = useParams<paths.TokenRouteParams>();
  const history = useHistory();
  const [appointmentStatus, setAppointmentStatus] =
    React.useState<AppointmentStatus>(null);
  const { showErrorToast } = useErrorToast();
  const [destination, _setDestination] = useQueryParam(REDIRECT_KEY, undefined);
  const { createPatientSession } = useCreatePatientSession();

  useEffect(() => {
    const onUnauthorizedCallback = () => {
      history.push(paths.appointmentAuthorizationFailed());
    };
    const validateToken = async () => {
      try {
        setAppointmentStatus(null);
        const { isDemographicsRequired } = await verifyTokenRequest(token);
        if (isDemographicsRequired) {
          history.push(
            paths.verifyDemographics({
              token,
              query: {
                redirectTo: destination,
              },
            })
          );
        } else {
          // We can skip demographics and log the patient right in
          createPatientSession({ token }, onUnauthorizedCallback);
        }
      } catch (e) {
        if (
          e.code === "404" ||
          e.message === "Request failed with status code 404"
        ) {
          setAppointmentStatus("invalid");
        } else {
          showErrorToast();
        }
      }
    };

    validateToken();
  }, [createPatientSession, showErrorToast, token, history, destination]);

  if (appointmentStatus === "invalid") {
    return (
      <SadLayout
        headline="Appointment not found"
        message={<Message styles={styles} />}
        cta={<ScheduleCta styles={styles} />}
        className={styles.container}
      />
    );
  }

  return <ErrorToast />;
};
