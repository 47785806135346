import React from "react";
import styles from "./DoctorInfoCard.module.scss";
import { InfoCard } from "components/InfoCard";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullName } from "utils/doctorFullName";

interface DoctorInfoCardProps {
  doctor: Doctor;
  office: Office;
}

export const DoctorInfoCard: React.FC<DoctorInfoCardProps> = ({
  doctor,
  office,
}) => (
  <InfoCard
    image={<DoctorAvatar doctor={doctor} selectedOffice={office} />}
    details={
      <ul className={styles.doctorInfo}>
        <li className={styles.doctorInfoItem}>
          {doctorFullName(doctor, office.namechangeVEH)}
        </li>
      </ul>
    }
  />
);
