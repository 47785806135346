import React from "react";
import cx from "classnames";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import styles from "./VerifyDemographics.module.scss";
import { Layout } from "features/layout";
import { LegacyCard } from "components/LegacyCard";
import { Button } from "components/Button";
import * as paths from "features/routing/paths";
import { TextInput } from "components/TextInput";
import {
  MaskedDateInput,
  MaskedPhoneInput,
} from "components/MaskedTextInput/MaskedTextInput";
import { useCreatePatientSession } from "features/login/hooks/useCreatePatientSession";
import shieldIcon from "images/shield-checkmark-icon.svg";
import { isValidDateOfBirth } from "utils/dateOfBirthValidation";
import { isValidPhoneNumber } from "utils/phoneNumberValidation";
import { presenceValidation } from "utils/presenceValidation";
import alert from "images/alert.svg";

interface SubmitParams {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
}

interface UseApppointmentAuthorizationReturn {
  onSubmit: (params: SubmitParams) => Promise<void>;
}

const useVerifyDemographics = (
  {
    token,
  }: {
    token: string;
  },
  onUnauthorizedCallback: () => void
): UseApppointmentAuthorizationReturn => {
  const { createPatientSession } = useCreatePatientSession();

  const onSubmit = async (demographics: SubmitParams) => {
    await createPatientSession({ token, demographics }, onUnauthorizedCallback);
  };

  return { onSubmit };
};

export const VerifyDemographics: React.FC = () => {
  const [error, setError] = React.useState(false);
  const onUnauthorizedCallback = () => {
    setError(true);
  };
  const { token } = useParams<paths.VerifyDemographicsRouteParams>();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
  });
  const firstName = register(
    "firstName",
    presenceValidation("Not a valid first name")
  );
  const lastName = register(
    "lastName",
    presenceValidation("Not a valid last name")
  );
  const phoneNumber = register("phoneNumber", { validate: isValidPhoneNumber });
  const dateOfBirth = register("dateOfBirth", { validate: isValidDateOfBirth });
  const { onSubmit } = useVerifyDemographics({ token }, onUnauthorizedCallback);

  return (
    <Layout>
      <div>
        <LegacyCard>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.cardContents}
          >
            <h1 className={styles.header}>Let’s verify your details</h1>
            <div className={styles.subhead}>
              <img src={shieldIcon} alt="" />
              <p>
                In order to keep your account secure, we need to verify it’s
                you.
              </p>
            </div>
            <p className={styles.body}>
              Please enter your information that you’ve used to schedule your
              appointment.
            </p>
            {error && (
              <div className={cx(styles.box, styles.wrapper)}>
                <div>
                  <img src={alert} alt="alert" />
                </div>
                <div>
                  <div className={styles.heading}>
                    This info doesn’t match our records
                  </div>
                  <div>
                    Try entering different information you’ve used to schedule
                    your appointment.
                  </div>
                  <div className={styles.wrapperJr}>
                    <div>
                      <hr className={styles.hr} />
                    </div>
                    <div className={styles.or}>or</div>
                    <div>
                      <hr className={styles.hr} />
                    </div>
                  </div>
                  <Link to={paths.portalLogin()}>Log in</Link> from another
                  address
                </div>
              </div>
            )}
            <section className={styles.fields}>
              <TextInput
                {...firstName}
                inputType="text"
                autoFocus
                placeholder="Enter your legal name"
                onChange={(e) => {
                  clearErrors();
                  firstName.onChange(e);
                }}
                errorClassName={cx({
                  [styles.emptyError]: !errors.firstName?.message,
                })}
                errorMessage={errors.firstName?.message}
              >
                First Name
              </TextInput>
              <TextInput
                {...lastName}
                inputType="text"
                onChange={(e) => {
                  clearErrors();
                  lastName.onChange(e);
                }}
                errorClassName={cx({
                  [styles.emptyError]: !errors.lastName?.message,
                })}
                errorMessage={errors.lastName?.message}
              >
                Last Name
              </TextInput>
              <MaskedPhoneInput
                {...phoneNumber}
                onChange={(e) => {
                  clearErrors();
                  phoneNumber.onChange(e);
                }}
                errorClassName={cx({
                  [styles.emptyError]: !errors.phoneNumber?.message,
                })}
                errorMessage={errors.phoneNumber?.message}
                setValue={setValue}
              >
                Phone Number
              </MaskedPhoneInput>
              <MaskedDateInput
                {...dateOfBirth}
                onChange={(e) => {
                  clearErrors();
                  dateOfBirth.onChange(e);
                }}
                errorClassName={cx({
                  [styles.emptyError]: !errors.dateOfBirth?.message,
                })}
                errorMessage={errors.dateOfBirth?.message}
                setValue={setValue}
              >
                Date of Birth
              </MaskedDateInput>
            </section>
            {errors.form?.message && (
              <div className={styles.error}>{errors.form?.message}</div>
            )}
            <div className={styles.buttonContainer}>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                size="large"
                text="Next"
                renderAsDisabled={!isValid}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </form>
        </LegacyCard>
        {error && (
          <div className={styles.bottomText}>
            If you’ve changed your email since booking, please call your local
            office to update your account details{" "}
            <a href={"https://locations.myeyedr.com/search"}>Click Here.</a>
          </div>
        )}
      </div>
    </Layout>
  );
};
