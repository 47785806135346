import React from "react";
import { getCurrentPatientInsuranceRequest } from "api/getCurrentPatientInsuranceRequest";
import { Insurance } from "types/insurance";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";

export const useCurrentPatientInsurance = (): {
  currentPatientInsurance: Insurance[] | [] | undefined;
  fetchCurrentPatientInsurance: () => Promise<void>;
} => {
  const [currentPatientInsurance, setCurrentPatientInsurance] = React.useState<
    Insurance[] | undefined
  >();
  const { showErrorToast } = useErrorToast();

  const fetchCurrentPatientInsurance = React.useCallback(async () => {
    try {
      const result = await getCurrentPatientInsuranceRequest();
      setCurrentPatientInsurance(result);
    } catch (e) {
      showErrorToast();
    }
  }, [showErrorToast]);

  React.useEffect(() => {
    if (currentPatientInsurance === undefined) {
      fetchCurrentPatientInsurance();
    }
  }, [currentPatientInsurance, fetchCurrentPatientInsurance]);

  return { currentPatientInsurance, fetchCurrentPatientInsurance };
};
