import React, { createContext, useReducer } from "react";
import { Message } from "./types";

type SnackbarState = {
  messages: Array<Message>;
};

type ContextType = {
  state: SnackbarState;
  dispatch: (action: SnackbarAction) => void;
};

export const SnackbarContext = createContext<ContextType | undefined>(
  undefined
);

type SnackbarAction =
  | {
      type: "add";
      message: Message;
    }
  | {
      type: "remove";
      id: string;
    };

const snackbarReducer = (
  state: SnackbarState,
  action: SnackbarAction
): SnackbarState => {
  switch (action.type) {
    case "add": {
      return { messages: [...state.messages, action.message] };
    }
    case "remove": {
      return { messages: state.messages.filter(({ id }) => id !== action.id) };
    }
    default: {
      return state;
    }
  }
};

export const SnackbarProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, { messages: [] });

  return (
    <SnackbarContext.Provider value={{ state, dispatch }}>
      {children}
    </SnackbarContext.Provider>
  );
};
