import React from "react";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import styles from "./DocumentAcknowledgment.module.scss";
import { DocumentAcknowledgmentModal } from "./DocumentAcknowledgmentModal";
import {
  InsuranceAssignmentPolicy,
  VERSION as INSURANCE_VERSION,
} from "features/checkIn/documents/InsuranceAssignmentPolicy";
import {
  HIPAANoticePrivacyPractices,
  VERSION as HIPAA_VERSION,
} from "features/checkIn/documents/HIPAANoticePrivacyPractices";
import { DocumentPrintButton } from "features/checkIn/components/DocumentPrintButton";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { Button } from "components/Button";
import checkmarkIcon from "images/checkmark-white.svg";
import { DocumentType } from "features/checkIn/types";

type DocumentForReturn = {
  Document: React.FC;
  documentTitle: string;
  version: string;
};

export const documentFor = (documentType: DocumentType): DocumentForReturn => {
  switch (documentType) {
    case DocumentType.HipaaNotice:
      return {
        Document: HIPAANoticePrivacyPractices,
        documentTitle: "HIPAA Notice of Privacy Practices",
        version: HIPAA_VERSION,
      };
    case DocumentType.InsuranceAssignmentPolicy:
      return {
        Document: InsuranceAssignmentPolicy,
        documentTitle: "Insurance Assignment Policy",
        version: INSURANCE_VERSION,
      };
    default: {
      throw Error("Unsupported document type");
    }
  }
};

type DocumentAcknowledgmentProps = {
  documentType: DocumentType;
};

export const DocumentAcknowledgment: React.FC<DocumentAcknowledgmentProps> = ({
  documentType,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { register, setValue, formState } = useFormContext();
  const { currentAppointment } = useCurrentAppointment();
  const { Document, documentTitle, version } = documentFor(documentType);

  register("documentAcknowledged", {
    required: true,
  });
  const isMissing = formState.errors.documentAcknowledged;
  const acknowledged = (
    currentAppointment?.checkin?.acknowledgedDocumentTypes || []
  ).includes(documentType);

  React.useEffect(() => {
    if (acknowledged) {
      setValue("documentAcknowledged", true, { shouldValidate: true });
    }
  }, [acknowledged, setValue]);

  return (
    <div>
      <p className={cx(styles.legend, { [styles.error]: isMissing })}>
        Please review &amp; sign.
      </p>
      <div
        className={cx(styles.buttonBox, {
          [styles.buttonBoxSigned]: acknowledged,
          [styles.buttonBoxError]: isMissing,
        })}
      >
        <DocumentPrintButton
          className={styles.printButton}
          document={<Document />}
          filename={version}
        />

        {acknowledged && (
          <div className={styles.pill}>
            <img src={checkmarkIcon} alt="" />
            SIGNED
          </div>
        )}
        {!acknowledged && (
          <Button
            className={styles.button}
            text="OPEN TO SIGN"
            onClick={() => setModalOpen(true)}
            size="large"
          />
        )}
      </div>
      <DocumentAcknowledgmentModal
        document={Document}
        documentTitle={documentTitle}
        documentType={documentType}
        documentVersion={version}
        open={modalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </div>
  );
};
