type RequiredRules = {
  required: string;
  pattern: {
    value: RegExp;
    message: string;
  };
};

export const presenceValidation = (errorMessage: string): RequiredRules => ({
  required: errorMessage,
  pattern: { value: /\S/, message: errorMessage },
});
