export enum ButtonRole {
  Standard,
  Ghost,
}

export type ButtonSize = "standard" | "large";

export type TimeButtonSize = "standard" | "small";

export interface CommonButtonProps {
  text: string;
  role?: ButtonRole;
  className?: string;
  size?: ButtonSize;
  loading?: boolean;
  onClick?: React.MouseEventHandler;
  renderAsDisabled?: boolean;
}
