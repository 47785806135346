import axios from "axios";

export const createPatientLoginRequest = async (params: {
  email: string;
  redirectTo?: string;
}): Promise<void> => {
  const { email, redirectTo } = params;
  return await axios.post("/api/patient_login_requests", {
    email,
    ...(redirectTo ? { redirect_to: redirectTo } : {}),
  });
};
