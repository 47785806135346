import axios from "axios";

export const createTwoFactorVerificationRequest = async (params: {
  code: string;
  phoneNumberId: string;
}): Promise<void> => {
  const { code, phoneNumberId } = params;
  await axios.post("/api/two_factor_verifications", {
    code,
    phone_number_id: phoneNumberId,
  });
};
