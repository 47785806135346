import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./UpcomingAppointmentSkeleton.module.scss";
import upcomingAppointmentStyles from "./UpcomingAppointment.module.scss";
import { Card } from "components/Card";
import { DoctorAvatar } from "components/DoctorAvatar";

export const UpcomingAppointmentSkeleton: React.FC = () => {
  return (
    <div
      className={upcomingAppointmentStyles.cardContainer}
      data-testid="upcoming-appointment-placeholder"
    >
      <Card className={upcomingAppointmentStyles.card}>
        <div className={styles.headerSkeleton}>
          <ContentLoader
            speed={2}
            width={258}
            height={12}
            viewBox="0 0 258 12"
            preserveAspectRatio="none"
            backgroundColor="var(--gray-02)"
            foregroundColor="var(--gray-04)"
          >
            <rect x="0" y="0" rx="4" ry="4" width="258" height="12" />
          </ContentLoader>
        </div>

        <div className={upcomingAppointmentStyles.avatarContainer}>
          <DoctorAvatar size="small" doctor={undefined} loaded={false} />
        </div>

        <div className={styles.instructionSkeleton}>
          <div className={styles.instructionSkeletonInner}>
            <ContentLoader
              speed={2}
              width={206}
              height={128}
              viewBox="0 0 206 128"
              preserveAspectRatio="none"
              backgroundColor="var(--gray-02)"
              foregroundColor="var(--gray-04)"
            >
              <rect x="0" y="0" rx="4" ry="4" width="206" height="12" />
              <rect x="0" y="27" rx="4" ry="4" width="206" height="12" />
              <rect x="0" y="72" rx="4" ry="4" width="206" height="12" />
              <rect x="0" y="94" rx="4" ry="4" width="206" height="12" />
              <rect x="0" y="116" rx="4" ry="4" width="206" height="12" />
            </ContentLoader>

            <div className={styles.addToCalendarButtonSkeleton}>
              <ContentLoader
                speed={2}
                width={122}
                height={12}
                viewBox="0 0 122 12"
                preserveAspectRatio="none"
                backgroundColor="var(--gray-02)"
                foregroundColor="var(--gray-04)"
              >
                <rect x="0" y="0" rx="4" ry="4" width="122" height="12" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
