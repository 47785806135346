import { createSelector } from "@reduxjs/toolkit";
import { selectServerError as selectOfficeError } from "features/officesSlice";
import { selectServerError as selectTimeSlotsError } from "features/scheduling/timeSlotsSlice";
import { selectServerError as selectBrazeValidationError } from "features/scheduling/brazePatientDetailSlice";

// this is a high level selector suitable for use with ErrorToast
export const selectError = createSelector(
  selectOfficeError,
  selectTimeSlotsError,
  selectBrazeValidationError,
  (officeError, timeSlotsError, brazeValidationError) =>
    officeError || timeSlotsError || brazeValidationError
);
