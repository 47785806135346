import React, { useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import styles from "./MyAccount.module.scss";
import { useCurrentPatient } from "./hooks/useCurrentPatient";
import { communicationPreferences, portalHome } from "features/routing/paths";
import chevronUpIcon from "images/chevron-up.svg";
import chevronDownIcon from "images/chevron-down.svg";
import myAccountIcon from "images/my-account-icon.svg";

export const MyAccount: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { currentPatient, logout } = useCurrentPatient();

  const toggle = () => {
    if (isOpen === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <button onClick={toggle} className={styles.myAccountButton}>
        <img src={myAccountIcon} alt="my account icon" /> My Account{" "}
        {isOpen ? (
          <img src={chevronUpIcon} alt="chevron up icon" />
        ) : (
          <img src={chevronDownIcon} alt="chevron down icon" />
        )}
      </button>
      {isOpen && (
        <div className={styles.subButtons}>
          <Link to={portalHome}>
            <button className={styles.subButton}>Patient Portal</button>
          </Link>
          <Link
            to={communicationPreferences({
              patientId: currentPatient?.id as string,
            })}
          >
            <button className={cx(styles.subButton, styles.middleButton)}>
              Notification Settings
            </button>
          </Link>
          <button
            id="logout"
            onClick={logout}
            className={cx(styles.subButton, styles.bottomButton)}
          >
            Log Out
          </button>
        </div>
      )}
    </>
  );
};
