import axios from "axios";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { DesiredDayOfWeek } from "types/desiredDayOfWeek";

export interface WaitListRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  acuity_office_id: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  desiredDayOfWeek: DesiredDayOfWeek;
  desiredTimeOfDay: string;
  officeName: string;
  officeAddress: string;
  smsOptInNotices: number;
  smsOptInPromos: number;
}

export const waitListRequest = async (
  params: WaitListRequest
): Promise<{ status: number }> => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    acuity_office_id,
    examType,
    patientReturnStatus,
    desiredDayOfWeek,
    desiredTimeOfDay,
    officeName,
    officeAddress,
    smsOptInNotices,
    smsOptInPromos,
  } = params;

  const res = await axios.post<WaitListRequest>(`/api/waitlist_requests`, {
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email,
    acuity_office_id,
    exam_type: examType,
    patient_return_status: patientReturnStatus,
    desired_day_of_week: desiredDayOfWeek,
    desired_times_of_day: desiredTimeOfDay,
    office_name: officeName,
    location: officeAddress,
    sms_opt_in_notices: smsOptInNotices ? 1 : 0,
    sms_opt_in_promos: smsOptInPromos ? 1 : 0,
  });

  return { status: res.status };
};
