import React from "react";
import cx from "classnames";
import styles from "./RadioButton.module.scss";

export interface RadioButtonProps
  extends React.ComponentPropsWithoutRef<"input"> {
  className?: string;
  hasError?: boolean;
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ className, hasError, children, ...nativeProps }, ref) => {
    const { name, value } = nativeProps;
    const id = `${name}-${value}`;

    return (
      <div className={cx(styles.radioButtonContainer, className)}>
        <input
          ref={ref}
          type="radio"
          className={styles.nativeRadioButton}
          id={id}
          {...nativeProps}
        />
        <div
          className={cx(styles.radioButton, {
            [styles.error]: hasError,
          })}
        />
        {children && (
          <label className={styles.label} htmlFor={id}>
            {children}
          </label>
        )}
      </div>
    );
  }
);
