import React from "react";
import { useForm } from "react-hook-form";
import { noop } from "lodash";
import styles from "./CommunicationPreferences.module.scss";
import { Button, ButtonRole } from "components/Button";
import { TextButton } from "components/TextButton";
import { LegacyCard } from "components/LegacyCard";
import { DescriptiveCheckbox } from "components/DescriptiveCheckbox";
import { CommunicationPreference } from "types/communicationPreference";
import { updateCommunicationPreferencesRequest } from "api/updateCommunicationPreferencesRequest";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";

interface FormProps {
  patientId: string;
  clinicalEmails: CommunicationPreference;
  promotionalEmails: CommunicationPreference;
  smsOptInNotices: CommunicationPreference;
  smsOptInPromos: CommunicationPreference;

  // todo: after releasing we can make this required again
  automatedPhoneCalls?: CommunicationPreference;
}

export const Form: React.FC<FormProps> = ({
  patientId,
  clinicalEmails,
  promotionalEmails,
  smsOptInNotices,
  smsOptInPromos,
  automatedPhoneCalls,
}) => {
  const { displayMessage } = useSnackbar();
  // todo: we can merge these after release
  const automatedPhoneCallValues = automatedPhoneCalls
    ? { [automatedPhoneCalls.name]: automatedPhoneCalls.isSubscribed }
    : {};
  const defaultValues = {
    [clinicalEmails.name]: clinicalEmails.isSubscribed,
    [promotionalEmails.name]: promotionalEmails.isSubscribed,
    [smsOptInNotices.name]: smsOptInNotices.isSubscribed,
    [smsOptInPromos.name]: smsOptInPromos.isSubscribed,
    ...automatedPhoneCallValues,
  };
  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });

  const clinicalEmailsInput = register(clinicalEmails.name);
  const promotionalEmailsInput = register(promotionalEmails.name);
  const smsOptInNoticesInput = register(smsOptInNotices.name);
  const smsOptInPromosInput = register(smsOptInPromos.name);

  const unsubscribeAll = () => {
    // Uncheck all checkboxes...
    const preferenceNames = Object.keys(getValues());
    preferenceNames.forEach((preferenceName) => {
      setValue(preferenceName, false, { shouldDirty: true });
    });
    // ...then submit immediately
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data: Record<string, boolean>) => {
    try {
      // Perform the API request with the form data
      await updateCommunicationPreferencesRequest(patientId, data);

      // Display a success message
      displayMessage({
        text: "Your settings have been updated.",
        icon: "checkmark",
      });

      // Reset the form with the data that was just submitted
      // This assumes the submission was successful and the data parameter
      // reflects the desired state of the form post-submission
      reset(data);
    } catch (error) {
      // Handle any errors from the API call
      displayMessage({
        text: "Failed to update communication preferences",
        icon: "checkmark",
      });
      console.error("Failed to update communication preferences:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LegacyCard>
        <div className={styles.content}>
          <section className={styles.section}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Email</p>
            </div>
            <div className={styles.preference}>
              <DescriptiveCheckbox
                label="Clinical Messages"
                description="Be reminded when you’re due to book your annual appointment as well as reminders for upcoming appointments."
                {...clinicalEmailsInput}
              />
            </div>
            <div className={styles.preference}>
              <DescriptiveCheckbox
                label="Marketing Messages"
                description="Stay up to date on our latest products and styles, new vision technology, and promotions and special offers from MyEyeDr."
                {...promotionalEmailsInput}
              />
            </div>
          </section>
          {automatedPhoneCalls && (
            <section className={styles.section}>
              <div className={styles.sectionHeader}>
                <p className={styles.sectionTitle}>
                  Automated Phone&nbsp;Calls
                </p>
              </div>
              <div className={styles.preference}>
                <DescriptiveCheckbox
                  label="Clinical Messages"
                  description="Be reminded when you’re due to book your annual appointment as well as reminders for upcoming appointments."
                  {...register(automatedPhoneCalls.name)}
                />
              </div>
            </section>
          )}
          <section className={styles.section}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Text Messages</p>
            </div>
            <div className={styles.preference}>
              <DescriptiveCheckbox
                label="SMS Notifications"
                description="Receive important reminders when you're due for  your annual eye exam, about upcoming appointments, about your prescription, and when your glasses or contacts are ready for pickup. (MyEyeDr. Notices)"
                {...smsOptInNoticesInput}
              />
            </div>
            <div className={styles.preference}>
              <DescriptiveCheckbox
                label="SMS Promotions"
                description="Receive promotional texts with eyewear and eyecare offers. (MyEyeDr. Promos)"
                {...smsOptInPromosInput}
              />
            </div>
          </section>
          <Button
            className={styles.cta}
            disabled={!isDirty || isSubmitting}
            loading={isSubmitting}
            type="submit"
            text="Save"
            size="large"
            role={ButtonRole.Ghost}
            onClick={noop}
          />
        </div>
      </LegacyCard>
      <div className={styles.footer}>
        <p className={styles.footerText}>
          MyEyeDr. will not be able to contact you when you&apos;re due for your
          annual eye exam, with appointment reminders, or with other important
          information about your eye care.
        </p>
        <TextButton className={styles.unsubscribe} onClick={unsubscribeAll}>
          Unsubscribe from all
        </TextButton>
      </div>
    </form>
  );
};
