import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { scrollToTop } from "utils/scrollToTop";

export const AppRoute: React.FC<RouteProps> = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    scrollToTop();
  }, [history.location.pathname]);

  return <Route {...props} />;
};
