import React, { createContext } from "react";
import { Patient } from "types/patient";

type CurrentPatientState = Partial<Patient> | undefined;

type ContextType = {
  currentPatient: CurrentPatientState;
  setCurrentPatient: (currentPatient: Partial<Patient>) => void;
};

export const CurrentPatientContext = createContext<ContextType | undefined>(
  undefined
);

export const CurrentPatientProvider: React.FC = ({ children }) => {
  const [currentPatient, setCurrentPatient] =
    React.useState<CurrentPatientState>(undefined);

  return (
    <CurrentPatientContext.Provider
      value={{ currentPatient, setCurrentPatient }}
    >
      {children}
    </CurrentPatientContext.Provider>
  );
};
