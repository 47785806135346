import React from "react";
import { Link } from "react-router-dom";

export const DeveloperNav: React.FC = () => {
  return (
    <li>
      <Link to="/component-catalog">Component Catalog</Link>
    </li>
  );
};
