import React from "react";
import ReactModal from "react-modal";
import cx from "classnames";
import styles from "./Modal.module.scss";
import closeIcon from "images/close.svg";
import closeNewIcon from "images/closeNew.svg";

interface ModalProps {
  closeOnOverlayClick?: boolean;
  isOpen: boolean;
  className?: string;
  overlayClassName?: string;
  onRequestClose?: () => void; // This callback is called when the user hits esc or clicks outside of the modal.
  aria?: ReactModal.Aria;
  customCloseIcon?: boolean;
  hideClose?: boolean;
}
export const Modal: React.FC<ModalProps> = ({
  closeOnOverlayClick,
  isOpen,
  children,
  className,
  overlayClassName,
  onRequestClose,
  aria,
  customCloseIcon,
  hideClose,
}) => {
  if (customCloseIcon) {
    return (
      <ReactModal
        aria={aria}
        isOpen={isOpen}
        className={cx(styles.modal, className)}
        overlayClassName={cx(styles.overlay, overlayClassName)}
        bodyOpenClassName={styles.bodyOpenClassName}
        onRequestClose={onRequestClose}
        contentLabel={"Modal dialog"}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
      >
        <div className={styles.closeContainer}>
          <button className={styles.dismissButton} onClick={onRequestClose}>
            Close
            <img src={closeNewIcon} alt="" className={styles.dismissIcon} />
          </button>
        </div>
        <div className={styles.modalContent}>{children}</div>
      </ReactModal>
    );
  } else {
    return (
      <ReactModal
        aria={aria}
        isOpen={isOpen}
        className={cx(styles.modal, className)}
        overlayClassName={cx(styles.overlay, overlayClassName)}
        bodyOpenClassName={styles.bodyOpenClassName}
        onRequestClose={onRequestClose}
        contentLabel={"Modal dialog"}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
      >
        <div>
          {!hideClose && (
            <button className={styles.dismissButton} onClick={onRequestClose}>
              Close
              <img src={closeIcon} alt="" className={styles.dismissIcon} />
            </button>
          )}
        </div>
        <div className={styles.modalContent}>{children}</div>
      </ReactModal>
    );
  }
};
