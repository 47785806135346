import React from "react";
import styles from "./LabeledField.module.scss";

interface LabeledFieldProps {
  children: React.ReactNode;
  className?: string;
  icon?: {
    src: string;
    alt: string;
  };
  label: string;
}

export const LabeledField: React.FC<LabeledFieldProps> = ({
  children,
  className,
  icon,
  label,
}) => (
  <div className={className}>
    <div className={styles.label}>{label}</div>
    <div className={styles.valueContainer}>
      {icon && <img src={icon.src} className={styles.icon} alt={icon.alt} />}
      <div className={styles.value}>{children}</div>
    </div>
  </div>
);
