import axios from "axios";
import { normalizeDate } from "./util/normalizeDate";

type VerifyInsuranceCoverageArgs = {
  insuranceCarrier: string;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberDateOfBirth: string;
};

export const verifyInsuranceRequest = async (
  params: VerifyInsuranceCoverageArgs
): Promise<void> => {
  await axios.post(`/api/checkins/insurance_verifications`, {
    insurance_carrier: params.insuranceCarrier,
    subscriber_first_name: params.subscriberFirstName,
    subscriber_last_name: params.subscriberLastName,
    subscriber_date_of_birth: normalizeDate(params.subscriberDateOfBirth),
  });
};
