import { useEffect } from "react";
import { useTrackEvent } from "./useTrackEvent";

export const usePageViewTracking = (
  eventName: string,
  eventProperties = {}
): void => {
  const { trackEvent } = useTrackEvent();
  return useEffect(
    () => {
      trackEvent(eventName, eventProperties);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      // Ensure this hook only runs once on page mount.
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );
};
