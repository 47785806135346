import React from "react";
import styles from "./OfficeInfo.module.scss";
import { OfficeAddress } from "components/OfficeAddress";
import { Office } from "types/office";

interface OfficeInfoProps {
  selectedOffice: Office;
}

export const OfficeInfo: React.FC<OfficeInfoProps> = ({ selectedOffice }) => {
  return (
    <div className={styles.officeInfo}>
      <div className={styles.center}>
        <div className={styles.caption}>Selected Office</div>
        <div className={styles.officeName}>{selectedOffice.displayName}</div>
        <OfficeAddress
          office={selectedOffice}
          className={styles.officeAddress}
        />
      </div>
    </div>
  );
};
