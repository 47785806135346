import React from "react";
import cx from "classnames";
import { FormProvider, useController, useForm } from "react-hook-form";
import { Fieldset } from "./components/Fieldset";
import styles from "./ConfirmAboutYou1.module.scss";
import { AboutYouFormData } from "./types";
import { StateOptions } from "./constants";

import { useProgressGuard } from "./hooks/useProgressGuard";
import { TextInput } from "components/TextInput";
import { Typeahead } from "components/Typeahead";
import { presenceValidation } from "utils/presenceValidation";
import { isValidZip } from "utils/zipValidation";

interface AboutYou1Props {
  aboutYou1Data: AboutYouFormData | null;
  getAddressValues: (aboutYou1Data: AboutYouFormData) => void;
  isSubmit: boolean;
  version?: number;
}
declare let env_react: string;
export const ConfirmAboutYou1: React.FC<AboutYou1Props> = ({
  getAddressValues,
  isSubmit,
  version,
}) => {
  useProgressGuard({ field: "aboutYou" });
  const formMethods = useForm<AboutYouFormData>({
    mode: "onBlur",
  });
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
  } = formMethods;

  const address1 = register(
    "address1",
    presenceValidation("Not a valid address")
  );
  const address2 = register("address2");
  const city = register("city", presenceValidation("Not a valid city"));
  const zipCode = register("zipCode", { validate: isValidZip });
  const { field: stateField } = useController({
    rules: { required: true },
    name: "state",
    control,
  });

  const envReact = env_react !== "undefined" ? env_react : "production";
  const apiKey =
    envReact === "development"
      ? "AIzaSyBZoc3kW6Ygemp-m3hsaX4v-H4BDMvHDPo"
      : "AIzaSyDxt1Xdg78h4jTetBtXEt98ZlBnQv5vbHI";
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const [isVisible, setVisible] = React.useState(false);
  const searchInput = React.useRef(null);
  stateField.onChange = (value: string) => {
    setValue("state", value);
    stateField.value = value;
    getAddressValues(getValues());
  };
  // load google map api js
  const extractAddress = (
    place: any,
    searchInput: { current: { value: string } }
  ) => {
    let address1 = "";
    place.address_components.forEach(
      (component: { types: string; long_name: string; short_name: string }) => {
        const types = component.types;
        const value = component.long_name;
        if (types.includes("street_number")) {
          address1 = address1 + value;
        }
        if (types.includes("route")) {
          address1 = address1 + " " + value;
        }
        if (types.includes("locality")) {
          setValue("city", value);
        }

        if (types.includes("administrative_area_level_1")) {
          stateField.value = component.short_name;
          setValue("state", component.short_name);
        }

        if (types.includes("postal_code")) {
          setValue("zipCode", value);
        }
      }
    );
    searchInput.current.value = address1;
    setValue("address1", address1);
    getAddressValues(getValues());
  };
  function loadAsyncScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeAddress = (
    autocomplete: google.maps.places.Autocomplete,
    searchInput: any
  ) => {
    const place = autocomplete.getPlace();
    extractAddress(place, searchInput);
  };
  // init gmap script
  const initMapScript = React.useCallback(() => {
    // if script already loaded
    if (window.google || !apiKey) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }, [apiKey]);

  React.useEffect(() => {
    if (isVisible) {
      initMapScript().then(() => {
        if (!searchInput.current || !apiKey) return;

        const options = {
          componentRestrictions: { country: "us" },
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          searchInput.current,
          options
        );
        autocomplete.setFields([
          "address_component",
          "geometry",
          "formatted_address",
        ]);
        autocomplete.addListener("place_changed", () =>
          onChangeAddress(autocomplete, searchInput)
        );
      });
    }
  }, [apiKey, initMapScript, onChangeAddress, isVisible]);

  const handleChangeaddress1 = (event: { target: { value: any } }) => {
    if (!isVisible) {
      if (event.target.value.length > 5) setVisible(true);
    }
    setValue("address1", event.target.value);
    getAddressValues(getValues());
  };

  const handleChangeCity = (event: { target: { value: string } }) => {
    setValue("city", event.target.value);
    getAddressValues(getValues());
  };

  const handleChangeZip = (event: { target: { value: string } }) => {
    setValue("zipCode", event.target.value);
    getAddressValues(getValues());
  };

  React.useEffect(() => {
    if (isSubmit) {
      trigger();
    }
  }, [isSubmit, trigger]);

  return (
    <FormProvider {...formMethods}>
      <div className={styles.introduction}>
        <h1 className={styles.header}>Apply your coverage</h1>
        <div>
          We are committed to helping you get the most out of your insurance.
        </div>
      </div>

      <div
        className={
          version && version === 2
            ? cx(styles.contentNew, styles.card, styles.contentBG)
            : cx(styles.contentNew, styles.card)
        }
      >
        <Fieldset legend="" fieldGroupClassName={styles.addressFieldGroup}>
          <TextInput
            {...address1}
            ref={searchInput}
            inputType="text"
            onChange={handleChangeaddress1}
            errorMessage={errors.address1?.message}
            className={cx(styles.field, styles.fieldSpan)}
            errorClassName={cx({
              [styles.emptyError]: !errors.address1?.message,
            })}
          >
            Address
          </TextInput>

          <TextInput
            {...address2}
            inputType="text"
            errorMessage={errors.address2?.message}
            className={cx(styles.field, styles.fieldSpan)}
            errorClassName={cx({
              [styles.emptyError]: !errors.address2?.message,
            })}
          >
            Apt./Suite # (optional)
          </TextInput>

          <Typeahead
            field={stateField}
            name="state"
            options={StateOptions}
            label="State"
            creatable={false}
            clearable={true}
            errorMessage={errors.state?.message}
            className={cx(styles.field, styles.fieldSpan)}
            errorClassName={cx({
              [styles.emptyError]: !errors.state?.message,
            })}
          />

          <TextInput
            {...city}
            inputType="text"
            errorMessage={errors.city?.message}
            className={styles.field}
            onChange={handleChangeCity}
            errorClassName={cx({
              [styles.emptyError]: !errors.city?.message,
            })}
          >
            City
          </TextInput>

          <TextInput
            {...zipCode}
            inputType="text"
            inputMode="numeric"
            maxLength={5}
            errorMessage={errors.zipCode?.message}
            className={styles.field}
            onChange={handleChangeZip}
            errorClassName={cx({
              [styles.emptyError]: !errors.zipCode?.message,
            })}
          >
            Zip
          </TextInput>
        </Fieldset>
      </div>
    </FormProvider>
  );
};
