import React from "react";
import cx from "classnames";
import styles from "./Toast.module.scss";

interface ToastProps {
  children: React.ReactNode;
  visible: boolean;
  toastClassName?: string;
}

export const Toast: React.FC<ToastProps> = ({
  visible,
  children,
  toastClassName,
}) => {
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.visible]: visible,
        },
        toastClassName
      )}
      style={{ opacity: visible ? 1 : 0 }}
      role="alert"
    >
      <div className={styles.toast}>{children}</div>
    </div>
  );
};
