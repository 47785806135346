import React from "react";
import styles from "./DesktopBreadcrumb.module.scss";
import * as paths from "features/routing/paths";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import {
  getPosition,
  SchedulingStep,
} from "features/scheduling/components/nav/SchedulingStep";

interface DesktopBreadcrumbProps {
  step: number;
  officeId: string;
  examType?: ExamType;
  patientReturnStatus?: PatientReturnStatus;
}

export const DesktopBreadcrumb: React.FC<DesktopBreadcrumbProps> = ({
  step,
  examType,
  officeId,
  patientReturnStatus,
}) => {
  return (
    <div className={styles.breadcrumbs}>
      <SchedulingStep
        className={styles.tab}
        label="1. Eye Health Exam"
        position={getPosition(step, 1)}
        editLinkTo={paths.appointmentsSelectType({ officeId })}
      />
      <SchedulingStep
        className={styles.tab}
        label="2. Patient Details"
        position={getPosition(step, 2)}
        editLinkTo={
          examType &&
          paths.appointmentsSelectReturnStatus({
            officeId,
            examType,
          })
        }
      />
      <SchedulingStep
        className={styles.tab}
        label="3. Schedule with a Doctor"
        position={getPosition(step, 3)}
        editLinkTo={
          examType &&
          patientReturnStatus &&
          paths.appointmentsAvailableAppointments({
            officeId,
            examType,
            patientReturnStatus,
          })
        }
      />
    </div>
  );
};
