import React from "react";
import cx from "classnames";
import { times } from "lodash";
import styles from "./ProgressHeader.module.scss";

export interface ProgressHeaderProps {
  title: string;
  currentStep?: number;
  totalSteps?: number;
  visibility?: "desktopLarge" | "mobile";
}

export const ProgressHeader: React.FC<ProgressHeaderProps> = ({
  title,
  currentStep,
  totalSteps = 1,
  visibility,
}) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.desktopOnly]: visibility === "desktopLarge",
        [styles.mobileOnly]: visibility === "mobile",
      })}
    >
      <div className={cx(styles.row, styles.textRow)}>
        <span className={styles.text}>{title}</span>
        {currentStep && (
          <span className={styles.text}>
            {currentStep}/{totalSteps}
          </span>
        )}
      </div>
      <div className={styles.row}>
        {times(totalSteps).map((n) => (
          <span
            key={n}
            className={cx({
              [styles.completeBar]: n < (currentStep || 1),
              [styles.incompleteBar]: n >= (currentStep || 1),
            })}
          />
        ))}
      </div>
    </div>
  );
};
