import React from "react";
import { NearbyOfficeCard } from "./NearbyOfficeCard";
import styles from "./NearbyOfficeCards.module.scss";
import { SectionDivider } from "components/SectionDivider";
import { Office } from "types/office";
import { Spinner } from "components/Button/Spinner";
import locationpin from "images/locationpin.svg";

interface NearbyOfficeCardsProps {
  offices: Office[];
  loading: boolean;
  selectedDoctorId?: string;
  version?: number;
}

export const NearbyOfficeCards: React.FC<NearbyOfficeCardsProps> = ({
  offices,
  loading,
  selectedDoctorId,
  version,
}) => {
  const [visible, setVisible] = React.useState(false);
  const onClickVisible = () => {
    setVisible(!visible);
  };
  if (loading) {
    const nearbyLoadingHeading = <span>Fetching Nearby Offices</span>;
    return (
      <div className={styles.container}>
        <span
          className={`${styles.desktopHeading} ${styles.loaderWithOfficeHeading}`}
        >
          <Spinner className={styles.spinner} />
          {nearbyLoadingHeading}
        </span>
        <SectionDivider className={styles.mobileHeading}>
          <Spinner className={styles.spinner} />
          {nearbyLoadingHeading}
        </SectionDivider>
      </div>
    );
  }
  if (offices.length === 0) {
    const noNearbyHeading = <span>No Availability Nearby</span>;
    return (
      <div className={styles.noNearByOfficeContainer}>
        <span className={styles.desktopHeading}>{noNearbyHeading}</span>
        <SectionDivider
          className={styles.mobileHeading}
          version={version}
          visible={visible}
          onClickVisible={onClickVisible}
        >
          {noNearbyHeading}
        </SectionDivider>
        {visible && (
          <>
            <div className={styles.locationImg}>
              <img src={locationpin} alt="locationpin" />
            </div>
            <span className={styles.locationText}>
              We were unable to find availability at a nearby location. To view
              other offices go back to our{" "}
              <a href="https://locations.myeyedr.com/">Locations</a> page.
            </span>
          </>
        )}
      </div>
    );
  }
  const headingText =
    offices.length > 1 ? "Other Nearby Offices" : "Other Nearby Office";
  return (
    <div className={styles.container}>
      <h1 className={styles.desktopHeading}>{headingText}</h1>
      <SectionDivider
        className={styles.mobileHeading}
        version={version}
        visible={visible}
        onClickVisible={onClickVisible}
      >
        <span>{headingText}</span>
      </SectionDivider>
      {!visible && (
        <div className={styles.cards}>
          {offices.map((office) => (
            <NearbyOfficeCard
              key={office.id}
              office={office}
              selectedDoctorId={selectedDoctorId}
            />
          ))}
        </div>
      )}
    </div>
  );
};
