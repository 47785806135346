import React from "react";
import styles from "./Checklist.module.scss";
import { WhatToExpectCard } from "./WhatToExpectCard";
import { WhenYouArriveCard } from "./WhenYouArriveCard";
import { ButtonLinkInternal } from "components/Button/ButtonLinkInternal";
import { EmptyLayout } from "features/checkIn/components/EmptyLayout";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { portalHome } from "features/routing/paths";
import circleCheckImage from "images/circle-check.svg";
import { usePageViewTracking } from "hooks/usePageViewTracking";

export const Checklist: React.FC = () => {
  usePageViewTracking("digital-check-in-complete");
  const { currentAppointment } = useCurrentAppointment();
  // This shouldn't happen, because current appointment will be loaded by the time we get to this page.
  if (!currentAppointment) return null;

  return (
    <EmptyLayout
      allowBack={false}
      primaryCta={
        <ButtonLinkInternal
          className={styles.cta}
          to={portalHome()}
          text="Go to my account"
        />
      }
      emptyHeight={true}
    >
      <div className={styles.stack}>
        <div className={styles.header}>
          <img className={styles.headerIcon} src={circleCheckImage} alt="" />
          <div>
            <h1 className={styles.headline}>We are ready for your visit</h1>
            <p className={styles.subhead}>
              Thank you for choosing us for your vision healthcare needs.
            </p>
          </div>
        </div>
        <WhenYouArriveCard />
        <WhatToExpectCard officeId={currentAppointment.officeId} />
      </div>
    </EmptyLayout>
  );
};
