import React from "react";

interface UseCarouselReturnType<T> {
  carouselRef: React.RefObject<T>;
  scroll: (left: number) => void;
  previousEnabled: boolean;
  nextEnabled: boolean;
}

export function useCarousel<T extends HTMLElement>(): UseCarouselReturnType<T> {
  const carouselRef = React.useRef<T>(null);
  const [previousEnabled, setPreviousEnabled] = React.useState(false);
  const [nextEnabled, setNextEnabled] = React.useState(true);
  const onCarouselScroll = React.useCallback((event: Event) => {
    const target = event.target as T;

    if (target.scrollLeft === 0) {
      setPreviousEnabled(false);
    } else {
      setPreviousEnabled(true);
    }

    if (target.scrollLeft + target.offsetWidth >= target.scrollWidth) {
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
  }, []);

  const scroll = React.useCallback(
    (left: number) => {
      if (!carouselRef.current) {
        return;
      }

      carouselRef.current.scrollBy({
        left,
        behavior: "smooth",
      });
    },
    [carouselRef]
  );

  React.useEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    const currentRef = carouselRef.current;
    currentRef.addEventListener("scroll", onCarouselScroll);

    if (
      currentRef.scrollLeft + currentRef.offsetWidth >=
      currentRef.scrollWidth
    ) {
      setNextEnabled(false);
    }

    return () => {
      currentRef?.removeEventListener("scroll", onCarouselScroll);
    };
  }, [carouselRef, onCarouselScroll]);

  return { carouselRef, scroll, previousEnabled, nextEnabled };
}
