import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { AppRouter } from "features/routing/AppRouter";
import { store } from "app/store";
import { AppProvider } from "app/AppProvider";
import { getDocumentMeta } from "utils/getDocumentMeta";
import { initializeDayjs } from "utils/initializeDayjs";
import { ErrorToast } from "components/Toast/ErrorToast";
import { Snackbar } from "components/Snackbar";

initializeDayjs();

document.addEventListener("DOMContentLoaded", () => {
  Sentry.init({
    dsn: getDocumentMeta("sentry_dsn") || undefined,
    environment: getDocumentMeta("sentry_current_env") || "development",
    release: getDocumentMeta("release") || undefined,
    denyUrls: [
      // Pinterest tracking
      /s\.pinimg\.com/i,
    ],
    allowUrls: [
      /^https?:\/\/localhost/,
      /^https?:\/\/([^.]+\.)?myeyedr\.com/,
      /^https?:\/\/[^.]+\.herokuapp\.com/,
    ],
  });

  ReactDOM.render(
    <React.StrictMode>
      <AppProvider store={store}>
        <ErrorToast />
        <AppRouter />
        <Snackbar />
      </AppProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
