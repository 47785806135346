export const StateOptions = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UM",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
].map((s) => ({ label: s, value: s }));

export const GenderOptions = [
  "Prefer not to say",
  "Female",
  "Male",
  "Non Binary",
].map((option) => ({
  label: option,
  value: option,
}));

export const PreferredLanguageOptions = [
  "English",
  "Spanish; Castilian",
  "French",
  "Other",
].map((option) => ({
  label: option,
  value: option,
}));

export const RaceOptions = [
  "Prefer not to say",
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White",
].map((option) => ({
  label: option,
  value: option,
}));

export const EthnicityOptions = [
  "Prefer not to say",
  "Hispanic or Latino",
  "Not Hispanic or Latino",
].map((option) => ({
  label: option,
  value: option,
}));
