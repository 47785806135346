import { getDocumentMeta } from "utils/getDocumentMeta";

export const logoutTimerMillis = (): number => {
  const defaultMillis = 2 * 60 * 1000;
  const value = getDocumentMeta("frontend_logout_timer_minutes_override");
  if (!value) {
    return defaultMillis;
  }

  const minuteOverride = parseInt(value);
  if (isNaN(minuteOverride)) {
    return defaultMillis;
  }

  return minuteOverride * 60 * 1000;
};
