import React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";
import styles from "./DoctorInfoSkeleton.module.scss";

export const DoctorInfoSkeleton: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 146 40"
    backgroundColor="var(--gray-02)"
    foregroundColor="var(--gray-04)"
    className={styles.contentLoader}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="146" height="16" />
    <rect x="0" y="24" rx="4" ry="4" width="146" height="16" />
  </ContentLoader>
);
