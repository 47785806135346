import React from "react";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { Order } from "types/order";
import { getCurrentPatientEcomOrdersRequest } from "api/getCurrentPatientEcomOrdersRequest";

export const useOrders = (
  patientId: string
): {
  orders: Order[] | undefined;
} => {
  const [orders, setOrders] = React.useState<undefined | Order[]>(undefined);
  const { showErrorToast } = useErrorToast();

  React.useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getCurrentPatientEcomOrdersRequest(patientId);
        //const data = await getCurrentPatientOrdersRequest();
        setOrders(data);
      } catch (e) {
        showErrorToast();
      }
    };

    fetchOrders();
  }, [showErrorToast, setOrders, patientId]);

  return { orders };
};
