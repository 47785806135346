import React from "react";
import styles from "./OfficeInfoCard.module.scss";
import { toTel } from "features/scheduling/utils/toTel";
import { InfoCard } from "components/InfoCard";
import { OfficeAddress } from "components/OfficeAddress";
import officePlaceholder from "images/office-placeholder.svg";
import { Office } from "types/office";

interface OfficeInfoCardProps {
  office: Office;
  children?: React.ReactNode;
}

export const OfficeInfoCard: React.FC<OfficeInfoCardProps> = ({
  office,
  children,
}) => {
  const { formattedPhone } = office;
  const infoImage = <img src={officePlaceholder} alt="Office" />;

  return (
    <InfoCard
      image={infoImage}
      details={
        <ul className={styles.container}>
          <li className={styles.item}>
            <OfficeAddress office={office} />
          </li>
          <li className={styles.item}>
            <a href={toTel(formattedPhone)}>{formattedPhone}</a>
          </li>
        </ul>
      }
    >
      {children}
    </InfoCard>
  );
};
