import React from "react";
import { useHistory } from "react-router";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import * as paths from "features/routing/paths";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { Checkin } from "types/checkin";

interface UseProgressGuardProps {
  field: keyof Checkin;
  isSubmitting?: boolean;
}

// checks the current appointment and redirects to home base if the user has already
// completed the check in section
export const useProgressGuard = ({
  field,
  isSubmitting,
}: UseProgressGuardProps): void => {
  const { currentAppointment } = useCurrentAppointment();
  const history = useHistory();
  const { displayMessage } = useSnackbar();

  React.useEffect(() => {
    if (currentAppointment) {
      if (
        !isSubmitting &&
        !(
          currentAppointment.checkin[field] === "unstarted" ||
          currentAppointment.checkin[field] === "needs_verification" ||
          currentAppointment.checkin[field] === "new_verification" ||
          currentAppointment.checkin[field] === "new_verification_skipped"
        )
      ) {
        displayMessage({ icon: "checkmark", text: "Already submitted." });
        history.push(paths.checkInHome());
      }
    }
  }, [currentAppointment, field, displayMessage, history, isSubmitting]);
};
