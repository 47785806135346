import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEmpty } from "lodash";
import styles from "./InsuranceSummaryCard.module.scss";
import { FieldReview } from "features/checkIn/components/FieldReview";
import { InsuranceFormData } from "features/checkIn/types";
import { checkInInsurance } from "features/routing/paths";
import {
  setInsuranceData,
  setCurrentInsurance,
  setIsEditForm,
  selectInsuranceData,
} from "features/insuranceDataSlice";

const subscriber = (insurance: InsuranceFormData) =>
  `${insurance.subscriberFirstName} ${insurance.subscriberLastName} (${insurance.relationToPatient})`;

export const InsuranceSummaryCard: React.FC<{
  insurance: InsuranceFormData;
  currentIndex: number;
}> = ({ insurance, currentIndex }) => {
  const subscriberIdMaskString = "●".repeat(insurance.subscriberId.length);
  const dispatch = useDispatch();

  const refStr = "#";
  const insuranceFormDataArr = useSelector(selectInsuranceData);
  const [showRemove, setShowRemove] = React.useState(true);
  React.useEffect(() => {
    if (isEmpty(insuranceFormDataArr[0]) || isEmpty(insuranceFormDataArr[1]))
      setShowRemove(false);
  }, [insuranceFormDataArr]);
  return (
    <div className={styles.container}>
      <div
        id={`chk-insuranceDetails-${currentIndex}`}
        className={cx(styles.card, "chk-insuranceDetails")}
      >
        <div className={showRemove ? styles.edit : styles.editSingle}>
          <Link
            to={checkInInsurance()}
            onClick={() => {
              dispatch(setCurrentInsurance(currentIndex));
              dispatch(setIsEditForm(true));
              //e.preventDefault();
            }} // don't change doctors when users click on phone number.
            href={refStr}
          >
            Edit
          </Link>
        </div>
        {showRemove && (
          <div className={styles.remove}>
            <a
              href={refStr}
              onClick={(e) => {
                const insuranceArrTemp = cloneDeep(insuranceFormDataArr);
                if (currentIndex === 0 && !isEmpty(insuranceArrTemp[1])) {
                  insuranceArrTemp[0] = insuranceArrTemp[1];
                  insuranceArrTemp[1] = {};
                } else {
                  insuranceArrTemp[currentIndex] = {};
                }
                dispatch(setInsuranceData(insuranceArrTemp));
                if (!isEmpty(insuranceArrTemp[0]))
                  dispatch(setCurrentInsurance(0));
                if (!isEmpty(insuranceArrTemp[1]))
                  dispatch(setCurrentInsurance(1));
                e.preventDefault();
              }}
            >
              Remove
            </a>
          </div>
        )}
        <FieldReview
          label="Insurance Carrier"
          value={insurance.insuranceCarrier}
        />
        <FieldReview label="Subscriber" value={subscriber(insurance)} />
        <FieldReview
          maskable
          maskString="●●●●"
          label="Last 4 Digits of SSN"
          value={insurance.subscriberSsn}
          style={{ width: insurance.subscriberSsn.length + 1 + "ch" }}
        />
        <FieldReview
          maskable
          maskString={subscriberIdMaskString}
          label="Subscriber ID"
          value={insurance.subscriberId}
          style={{ width: insurance.subscriberId.length + 1 + "ch" }}
        />
        <FieldReview
          label="Date of Birth"
          value={insurance.subscriberDateOfBirth}
        />
      </div>
    </div>
  );
};
