import React from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import styles from "./InsuranceNotFound.module.scss";
import { useOffice } from "features/patientDashboard/hooks/useOffice";
import officePlaceholder from "images/office-info.svg";
import { toTel } from "features/scheduling/utils/toTel";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { checkInInsurance } from "features/routing/paths";

export const InsuranceNotFound: React.FC = () => {
  const { currentAppointment } = useCurrentAppointment();
  const { office } = useOffice(currentAppointment?.officeId as string);

  if (office === undefined) {
    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <ContentLoader viewBox="0 0 760 254" style={{ width: "100%" }}>
            <rect x="6" y="1" rx="3" ry="3" width="216" height="15" />
            <rect x="6" y="66" rx="3" ry="3" width="137" height="16" />
            <rect x="6" y="42" rx="3" ry="3" width="137" height="16" />
            <rect x="6" y="111" rx="3" ry="3" width="137" height="16" />
            <rect x="6" y="135" rx="3" ry="3" width="137" height="16" />
            <rect x="6" y="176" rx="3" ry="3" width="137" height="16" />
            <rect x="6" y="200" rx="3" ry="3" width="137" height="16" />
            <rect x="600" y="42" rx="3" ry="3" width="137" height="16" />
          </ContentLoader>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div>
          <div className={styles.semibold}>
            If you recently submitted insurance, your office may be verifying
            your coverage.
          </div>
          You can still{" "}
          <Link to={checkInInsurance()}>add new insurance coverage.</Link>
        </div>
        <hr className={styles.rule} />
        <div>
          You can also call your local office to confirm your insurance
          information is up to date.
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.imageContainer}>
            <img src={officePlaceholder} alt="Office" />
          </div>
          <div className={styles.details}>
            {office.address1} {office.address2 ? `, ${office.address2}` : ""}
            <br />
            {office.city}
            {", "}
            {office.stateAbbr} {office.formattedZipCode}
            <br />
            <a href={toTel(office.formattedPhone)}>{office.formattedPhone}</a>
          </div>
        </div>
      </div>
    </div>
  );
};
