import React from "react";
import styles from "features/componentCatalog/ComponentCatalog.module.scss";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { LegacyCard } from "components/LegacyCard";
import { Message } from "components/Snackbar/Message";

export const SnackbarDemo: React.FC = () => {
  const { displayMessage } = useSnackbar();

  return (
    <div className={styles.section}>
      <h1>Snackbar</h1>
      <LegacyCard className={styles.snackbarCard}>
        <button
          onClick={() =>
            displayMessage({ text: "I am a snack", icon: "checkmark" })
          }
        >
          Hungry?
        </button>
        <button
          onClick={() =>
            displayMessage({ text: "I am a drink", icon: "checkmark" })
          }
        >
          Thirsty?
        </button>
        <div>
          <Message
            message={{
              id: "123",
              icon: "checkmark",
              text: "This is what a snackbar message looks like",
            }}
          />
        </div>
      </LegacyCard>
    </div>
  );
};
