import React from "react";
import cx from "classnames";
import styles from "./InfoTooltip.module.scss";
import infoIcon from "images/info.svg";
import closeIcon from "images/close.svg";

interface InfoTooltipProps {
  children: React.ReactNode;
  className?: string;
  iconClassName?: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  className,
  iconClassName,
  children,
}) => {
  const [isVisible, setVisible] = React.useState(false);
  const toggleVisibility = (e: React.MouseEvent) => {
    setVisible(!isVisible);
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <div
        className={cx(
          [styles.tooltipContainer, styles.tooltipPositionTop, className],
          {
            [styles.tooltipVisible]: isVisible,
          }
        )}
      >
        <div className={styles.contentContainer}>
          <div className={styles.content}>{children}</div>
          <div>
            <button
              className={cx(styles.buttonIcon, iconClassName)}
              onClick={toggleVisibility}
              type="button"
            >
              <img src={closeIcon} alt="Close tooltip" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={toggleVisibility}
          className={styles.buttonIcon}
          type="button"
        >
          <img src={infoIcon} alt="More info" />
        </button>
      </div>
    </div>
  );
};
