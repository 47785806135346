import React from "react";
import cx from "classnames";
import { PleaseBringItem } from "./PleaseBringItem";
import { useItems } from "./useItems";
import styles from "./WhenYouArriveCard.module.scss";
import { Card } from "./Card";

export const WhenYouArriveCard: React.FC = () => {
  const items = useItems();

  return (
    <Card
      title="Please bring the available items below:"
      caption={
        <>
          <h3 className={styles.captionHeading}>
            Are you a legal guardian or counselor?
          </h3>
          <p className={styles.caption}>
            If you are a legal guardian of a patient who is a minor or an
            incapacitated adult, please bring their insurance card(s). If you
            are a counselor escorting a patient, please bring your badge.
          </p>
        </>
      }
    >
      <div className={cx(styles.subheading, styles.caption)}>
        While not required, these help us provide a comprehensive health and
        vision exam.
      </div>
      <div className={styles.columns}>
        {items.map((columnItems, i) => {
          return (
            <ul key={i} className={styles.list}>
              {columnItems.map((item) => {
                return (
                  <PleaseBringItem
                    key={item.item}
                    className={styles.item}
                    {...item}
                  />
                );
              })}
            </ul>
          );
        })}
      </div>
    </Card>
  );
};
