import React from "react";
import styles from "./RevealButton.module.scss";
import { ReactComponent as EyeOpenIcon } from "images/eye-open.svg";
import { ReactComponent as EyeClosedIcon } from "images/eye-closed.svg";

export const RevealButton: React.FC<{
  label: string;
  onClick: () => void;
  revealed: boolean;
}> = ({ label, onClick, revealed }) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
      aria-label={`${revealed ? "hide" : "show"} ${label}`}
    >
      {revealed ? (
        <EyeOpenIcon className={styles.icon} />
      ) : (
        <EyeClosedIcon className={styles.icon} />
      )}
    </button>
  );
};
