import React from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import { toTel } from "../utils/toTel";
// eslint-disable-next-line no-restricted-imports
import { NearbyDoctorAvailabilitySection } from "../components/NearbyDoctorAvailabilitySection";
import styles from "./NearbyOfficeCard.module.scss";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { ExamType } from "types/examType";
import {
  selectDoctorsAvailableLaterForOffice,
  selectDoctorsAvailableTodayInOffice,
  selectNextAvailableDateForOffice,
} from "features/scheduling/nearbyOfficesSlice";
import phoneIcon from "images/callIcon.svg";
import locationIcon from "images/location.svg";
import { externalRedirect } from "utils/externalRedirect";
import * as paths from "features/routing/paths";
import { getOfficeHours } from "utils/getOfficeHours";

interface NearbyOfficeCardProps {
  nearby: Office;
  patientReturnStatus: PatientReturnStatus;
  examType: ExamType;
}

export const NearbyOfficeCard: React.FC<NearbyOfficeCardProps> = ({
  nearby,
  patientReturnStatus,
  examType,
}) => {
  const doctorsAvailableToday = useSelector((state: RootState) =>
    selectDoctorsAvailableTodayInOffice(state, nearby.id)
  );
  const doctorsAvailableLater = useSelector((state: RootState) =>
    selectDoctorsAvailableLaterForOffice(state, nearby.id)
  );
  const nextAvailableDate = useSelector((state: RootState) =>
    selectNextAvailableDateForOffice(state, nearby.id)
  );
  const onClickOffice = (officeid: string) => {
    externalRedirect(
      window.location.origin +
        paths.appointmentBookingStartV2({ officeId: officeid })
    );
  };

  const displayDistance = (distanceMiles: any) => {
    return distanceMiles
      ? parseFloat(Number(distanceMiles).toFixed(1)) + " miles"
      : "";
  };

  return (
    <>
      <div
        className={styles.nearbyContent}
        onClick={() => onClickOffice(nearby.logicalId)}
        aria-hidden="true"
      >
        <div className={styles.contentHeader}>
          <div className={styles.miles}>
            <img src={locationIcon} alt="Location" />
            {displayDistance(nearby.distanceMiles)}
          </div>
          <div className={styles.examDetails}>
            {doctorsAvailableToday.length > 0 && (
              <NearbyDoctorAvailabilitySection
                doctors={doctorsAvailableToday}
                office={nearby}
                examType={examType}
                patientReturnStatus={patientReturnStatus}
                version={2}
              />
            )}
            {doctorsAvailableToday.length === 0 && (
              <NearbyDoctorAvailabilitySection
                date={nextAvailableDate}
                doctors={doctorsAvailableLater}
                office={nearby}
                examType={examType}
                patientReturnStatus={patientReturnStatus}
                version={2}
              />
            )}
          </div>
        </div>
        <div className={styles.contentLeft}>
          <div className={styles.officeName}>{nearby.displayName}</div>
          <div className={styles.officeAddress}>
            {nearby.address1} <br />
            {nearby.city}, {nearby.stateAbbr}&nbsp;{nearby.formattedZipCode}
          </div>
        </div>
        <div className={styles.contentRight}>
          <div className={styles.timings}>{getOfficeHours(nearby)}</div>
          <div className={styles.officePhone}>
            <a
              href={toTel(nearby.formattedPhone)}
              className={styles.footerPhoneLink}
            >
              <img
                src={phoneIcon}
                alt="Contact Phone"
                className={styles.icon}
              />
              {nearby.formattedPhone}
            </a>
          </div>
          {/*nearby.supportsMedicaid && (
            <div className={styles.medicaid}>
              <img src={handbagIcon} alt="" className={styles.icon} />
              Medicaid accepted{" "}
            </div>
          )*/}
          {/*!nearby.supportsMedicaid && (
            <div className={styles.medicaid}>
              <img src={handbagIcon} alt="" className={styles.icon} />
              Medicaid not accepted{" "}
            </div>
          )*/}
        </div>
      </div>
    </>
  );
};
