import dayjs from "dayjs";

export const isBackAvailable = (date: string): boolean =>
  dayjs(date).isAfter(dayjs(), "week");

export const isNextAvailable = (date: string, timeSlotWeeks: number): boolean =>
  dayjs(date).isBefore(dayjs().add(timeSlotWeeks, "weeks"), "week");

export const isRestrictDate = (timeSlotWeeks: number): string =>
  dayjs().add(timeSlotWeeks, "weeks").day(6).format("YYYY-MM-DD");

export const isNextAvailableToday = (
  date: string,
  timeSlotWeeks: number
): boolean => dayjs(date).isBefore(dayjs().add(timeSlotWeeks, "weeks").day(6));
