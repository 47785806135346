import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type State = {
  insuranceData: [any?, any?];
  currentInsurance: number;
  isEditForm: boolean;
  showAddFooter: boolean;
};

const SLICE_NAME = "insuranceData";

export const initialState: State = {
  insuranceData: [{}, {}],
  currentInsurance: 0,
  isEditForm: false,
  showAddFooter: false,
};

export const insuranceDataSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setInsuranceData: (state, action: PayloadAction<any>) => ({
      ...state,
      insuranceData: action.payload,
    }),
    setCurrentInsurance: (state, action: PayloadAction<number>) => ({
      ...state,
      currentInsurance: action.payload,
    }),
    setIsEditForm: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isEditForm: action.payload,
    }),
    setShowAddFooter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showAddFooter: action.payload,
    }),
  },
});

export const reducer = insuranceDataSlice.reducer;
export const {
  setInsuranceData,
  setCurrentInsurance,
  setIsEditForm,
  setShowAddFooter,
} = insuranceDataSlice.actions;

export const selectInsuranceData = (state: RootState): any => {
  if (state.insuranceData.insuranceData) {
    return state.insuranceData.insuranceData;
  }

  return [];
};
export const selectCurrentInsurance = (state: RootState): number => {
  if (state.insuranceData.currentInsurance > -1) {
    return state.insuranceData.currentInsurance;
  }

  return 0;
};

export const selectIsEditForm = (state: RootState): boolean => {
  if (state.insuranceData.isEditForm) {
    return state.insuranceData.isEditForm;
  }

  return false;
};

export const selectShowAddFooter = (state: RootState): boolean => {
  if (state.insuranceData.showAddFooter) {
    return state.insuranceData.showAddFooter;
  }

  return false;
};

export const insuranceDataReducer = insuranceDataSlice.reducer;
