import axios from "axios";
import { Order } from "types/order";

export interface GetOrderStatusResponse {
  OrderNum: number;
  OrderDate: string;
  ExpectDate: string;
  StatusCode: string;
  OrderType: "E" | "S";
}

export const getOrderStatusRequest = async (
  orderUID: string
): Promise<Order[]> => {
  try {
    const { data, status } = await axios.get<GetOrderStatusResponse[]>(
      `/api/order_status/${orderUID}`
    );
    return data.order_tracking_info.map((pref) => ({
      orderNum: pref.order_num,
      orderDate: pref.order_date,
      orderTypeName: pref.order_type_name,
      expectDate: pref.expect_date,
      fulfillmentType: pref.fulfillment_type,
      fulfillmentDestination: pref.fulfillment_destination,
      displayByline: pref.display_byline,
      displayStatus: pref.display_status,
    }));
  } catch (err) {
    return [];
  }
};
