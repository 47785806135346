import React from "react";
import ContentLoader from "react-content-loader";

export const TimetableColumnSkeleton = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={113}
    height={170}
    viewBox="0 0 113 170"
    backgroundColor="var(--gray-02)"
    foregroundColor="var(--gray-04)"
    style={{ marginTop: -12 }}
    title="Loading time slots"
  >
    <path
      d="M12.5703 16C12.5703 13.7909 14.3612 12 16.5703 12H96.5703C98.7794 12 100.57 13.7909 100.57 16V44C100.57 46.2091 98.7794 48 96.5703 48H16.5703C14.3612 48 12.5703 46.2091 12.5703 44V16Z"
      fill="#ADADAD"
    />
    <path
      d="M12.5703 72C12.5703 69.7909 14.3612 68 16.5703 68H96.5703C98.7794 68 100.57 69.7909 100.57 72V100C100.57 102.209 98.7794 104 96.5703 104H16.5703C14.3612 104 12.5703 102.209 12.5703 100V72Z"
      fill="#ADADAD"
    />
    <path
      d="M12.5703 128C12.5703 125.791 14.3612 124 16.5703 124H96.5703C98.7794 124 100.57 125.791 100.57 128V156C100.57 158.209 98.7794 160 96.5703 160H16.5703C14.3612 160 12.5703 158.209 12.5703 156V128Z"
      fill="#ADADAD"
    />
  </ContentLoader>
);
