import { unwrapResult, AsyncThunkPayloadCreator } from "@reduxjs/toolkit";
import { difference, uniq } from "lodash";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import {
  getSlots,
  selectHasFreshSlots,
  selectTimeslots,
} from "./timeSlotsSlice";
import { fetchAvailabilitySummary } from "./availabilitySummarySlice";
import { calendarWeek, calendarWeekV2 } from "./utils/calendarWeek";
import { parseStartTimeWithZone } from "features/appointmentTime";
import { RootState } from "app/store";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Slot } from "types/slot";
import { API_DATE_FORMAT } from "api/constants";

const filteredSlots = (slotsToFilter: Slot[], date: string) => {
  const now = dayjs();

  if (dayjs(date).isSame(now, "day")) {
    return slotsToFilter.filter((slot) =>
      now.isBefore(parseStartTimeWithZone(slot))
    );
  }

  return slotsToFilter.filter((slot) =>
    dayjs(date).isSameOrBefore(dayjs(slot.date))
  );
};

interface FetchOfficeAvailabilityArgs {
  officeId: string;
  examType?: ExamType;
  searchDate: string;
  doctorIds: string[];
  patientReturnStatus: PatientReturnStatus;
  version?: number;
}

export const fetchOfficeAvailabilityAction: AsyncThunkPayloadCreator<
  void,
  FetchOfficeAvailabilityArgs
> = async (
  {
    officeId,
    searchDate,
    examType,
    doctorIds,
    patientReturnStatus,
    version,
  }: FetchOfficeAvailabilityArgs,
  thunkApi
) => {
  const week =
    version === 2 ? calendarWeekV2(searchDate) : calendarWeek(searchDate);
  const startDate = week[0].format(API_DATE_FORMAT);
  const endDate = isMobile
    ? week[4].format(API_DATE_FORMAT)
    : week[6].format(API_DATE_FORMAT);

  const currentState = thunkApi.getState() as RootState;

  const hasFreshSlots = selectHasFreshSlots(
    currentState,
    startDate,
    endDate,
    doctorIds
  );
  const slotsForThisWeek = hasFreshSlots
    ? selectTimeslots(currentState)
    : unwrapResult(
        // This will throw if the underlying XHR promise was rejected
        await thunkApi.dispatch(
          getSlots({
            officeId,
            startDate,
            endDate,
            doctorIds,
            examType,
            patientReturnStatus,
          })
        )
      );

  const doctorsIdsWhoHaveSlots: string[] = uniq(
    filteredSlots(slotsForThisWeek, searchDate).map(
      (timeSlot) => timeSlot.doctorId
    )
  );

  const doctorIdsWhoDoNotHaveSlots = difference(
    doctorIds,
    doctorsIdsWhoHaveSlots
  );

  if (version === 2) {
    if (doctorIds.length > 0) {
      await thunkApi.dispatch(
        fetchAvailabilitySummary({
          officeId,
          examType,
          patientReturnStatus,
          fromDate: dayjs(endDate).add(1, "day").format(API_DATE_FORMAT),
          doctorIds: doctorIds,
        })
      );
    }
  } else {
    if (doctorIdsWhoDoNotHaveSlots.length > 0) {
      await thunkApi.dispatch(
        fetchAvailabilitySummary({
          officeId,
          examType,
          patientReturnStatus,
          fromDate: dayjs(endDate).add(1, "day").format(API_DATE_FORMAT),
          doctorIds: doctorIdsWhoDoNotHaveSlots,
        })
      );
    }
  }
};
