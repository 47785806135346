const MASK = "__________";
export const maskPhone = (number: string): string => {
  if (number.length === 0) return number;

  const digits = number.replace(/[^\d]/g, "").substring(0, MASK.length);
  const masked = `${digits}${MASK.substring(digits.length, MASK.length)}`;

  return `(${masked.substring(0, 3)}) ${masked.substring(
    3,
    6
  )}-${masked.substring(6)}`;
};
