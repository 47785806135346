import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { isEmpty } from "lodash";
import { Navbar } from "./components/Navbar";
import { Layout } from "./components/Layout";
import { InsuranceSummaryCard } from "./components/InsuranceSummaryCard";
import { DocumentType, InsuranceFormData } from "./types";
import { Introduction } from "./components/Introduction";
import { Fieldset } from "./components/Fieldset";
import { DocumentAcknowledgment } from "./components/DocumentAcknowledgment";
import styles from "./InsuranceReview.module.scss";
import { useProgressGuard } from "./hooks/useProgressGuard";
import * as paths from "features/routing/paths";
import { Button } from "components/Button";
import { createInsuranceCoverageRequest } from "api/createInsuranceCoverageRequest";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { selectInsuranceData } from "features/insuranceDataSlice";

export const InsuranceReview: React.FC<{
  insuranceData: InsuranceFormData | null;
}> = () => {
  const history = useHistory();
  const { handleUnauthorized } = useCurrentPatient();
  const insuranceReviewFormMethods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = insuranceReviewFormMethods;
  const { displayMessage } = useSnackbar();
  //useProgressGuard({ field: "insurance" });
  const insuranceDataArr = useSelector(selectInsuranceData);
  if (isEmpty(insuranceDataArr[0]) && isEmpty(insuranceDataArr[1]))
    return <Redirect to={paths.checkInHome()} />;
  const onSubmit = async () => {
    await handleUnauthorized(async () => {
      try {
        await createInsuranceCoverageRequest(insuranceDataArr);
        history.push(paths.checkInHome());
      } catch (e) {
        if (e.code === "422") {
          displayMessage({ icon: "checkmark", text: "Already submitted." });
          return;
        }
        throw e;
      }
    });
  };

  return (
    <FormProvider {...insuranceReviewFormMethods}>
      <Layout
        navbar={<Navbar currentStep={2} totalSteps={2} title={"Insurance"} />}
        primaryCta={
          <Button
            onClick={handleSubmit(onSubmit)}
            renderAsDisabled={!isValid}
            loading={isSubmitting}
            disabled={isSubmitting}
            text="Save & Continue"
          />
        }
        emptyHeight={true}
      >
        <Introduction header="Review your coverage">
          We use this information to verify your coverage, please ensure details
          are accurate.
        </Introduction>
        {insuranceDataArr.map(
          // eslint-disable-next-line array-callback-return
          (insuranceData: InsuranceFormData, index: number) =>
            !isEmpty(insuranceData) && (
              <InsuranceSummaryCard
                insurance={insuranceData}
                currentIndex={index}
              />
            )
        )}
        <hr className={styles.separator} />
        <div className={styles.agreement}>
          <Fieldset
            legend="Insurance Assignment Policy & Agreement"
            legendClassName={styles.alwaysVisible}
          >
            <DocumentAcknowledgment
              documentType={DocumentType.InsuranceAssignmentPolicy}
            />
          </Fieldset>
        </div>
      </Layout>
    </FormProvider>
  );
};
