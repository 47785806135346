import React from "react";
import styles from "./SlotButtons.module.scss";
import { parseStartTime } from "features/appointmentTime";
import { Slot } from "types/slot";
import { TimeButtonSize } from "components/Button/types";
import { TimeButton } from "components/Button/TimeButton";

type SelectSlotHandler = (x: Slot) => void;

interface SlotButtonProps {
  slot: Slot;
  onSelectSlot: SelectSlotHandler;
  size?: TimeButtonSize;
  version?: number;
  onSelection?: (type: string, value: Slot) => void;
  selectedSlot?: undefined | Slot;
}

interface SlotButtonsProps {
  slots: Slot[];
  onSelectSlot: SelectSlotHandler;
  version?: number;
  onSelection?: (type: string, value: Slot) => void;
  selectedSlot?: undefined | Slot;
}

export const SlotButton: React.FC<SlotButtonProps> = ({
  slot,
  onSelectSlot,
  size,
  version,
  onSelection,
  selectedSlot,
}) => {
  // Ignore time zone to show slot time relative to the office's zone
  const formattedTime = parseStartTime(slot).format("h:mm A");
  const selectFormattedTime = selectedSlot
    ? parseStartTime(selectedSlot).format("h:mm A")
    : false;
  const isSelected =
    selectedSlot?.doctorId === slot?.doctorId &&
    selectedSlot?.date === slot?.date &&
    formattedTime === selectFormattedTime;
  if (version === 2 && onSelection) {
    return (
      <TimeButton
        className={styles.button}
        text={formattedTime}
        onClick={() => {
          onSelection("timeslot", slot);
        }}
        size={size}
        version={version}
        selected={isSelected}
      />
    );
  }
  return (
    <TimeButton
      className={styles.button}
      text={formattedTime}
      onClick={() => {
        onSelectSlot(slot);
      }}
      size={size}
      version={version}
    />
  );
};

export const SlotButtons: React.FC<SlotButtonsProps> = ({
  slots,
  onSelectSlot,
  version,
  onSelection,
}) => (
  <div className={styles.grid}>
    {slots.map((slot) => (
      <SlotButton
        key={`${slot.date}-${slot.startTime}`}
        slot={slot}
        onSelectSlot={onSelectSlot}
        version={version}
        onSelection={onSelection}
      />
    ))}
  </div>
);
