import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./Home.module.scss";
import { StartInsurance } from "./StartInsurance";
import { StartCheckIn } from "./StartCheckIn";
import { StartPrescriptions } from "./StartPrescriptions";
import { StepStatus } from "./StepStatus";
import { Checklist } from "features/checkIn/Checklist";
import {
  aboutYouNeedsVerification,
  aboutYouNewVerification,
  aboutYouSubmitted,
  checkinCompleted,
  insuranceNeedsVerification,
  insuranceNewVerification,
  insuranceSkippedNewVerification,
  insuranceSkipped,
  insuranceSubmitted,
  prescriptionsSubmitted,
} from "features/checkIn/helpers";
import { Layout } from "features/layout";
import { portalHome } from "features/routing/paths";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";

export const Home: React.FC = () => {
  const { currentAppointment, fetchCurrentAppointment } =
    useCurrentAppointment();
  const { currentPatient, fetchCurrentPatient } = useCurrentPatient();
  const checkin = currentAppointment?.checkin;

  useEffect(() => {
    fetchCurrentAppointment();
  }, [fetchCurrentAppointment]);

  useEffect(() => {
    fetchCurrentPatient();
  }, [fetchCurrentPatient]);

  // Delay rendering until appointment has been fetched
  if (currentAppointment === undefined || currentPatient === undefined) {
    return null;
  }

  if (currentAppointment === null) {
    return <Redirect to={portalHome()} />;
  }

  if (checkinCompleted(checkin)) {
    return <Checklist />;
  }

  return (
    <Layout>
      <div className={styles.pageContainer}>
        {!aboutYouSubmitted(checkin) && (
          <StartCheckIn
            returningPatient={
              (aboutYouNeedsVerification(checkin) ||
                aboutYouNewVerification(checkin)) &&
              !!currentPatient?.primaryAddress
            }
          />
        )}
        {aboutYouSubmitted(checkin) && !insuranceSubmitted(checkin) && (
          <StartInsurance
            returningPatient={
              insuranceNeedsVerification(checkin) ||
              insuranceNewVerification(checkin)
            }
          />
        )}
        {aboutYouSubmitted(checkin) && insuranceSubmitted(checkin) && (
          <StartPrescriptions />
        )}
        <div className={styles.steps}>
          <StepStatus
            number={1}
            isSubmitted={aboutYouSubmitted(checkin)}
            name="About You"
          />
          <StepStatus
            number={2}
            isSubmitted={insuranceSubmitted(checkin)}
            name="Insurance"
            note={insuranceSkipped(checkin) ? "Not being used" : undefined}
          />
          <StepStatus
            number={3}
            isSubmitted={prescriptionsSubmitted(checkin)}
            name="Prescriptions"
          />
        </div>
      </div>
    </Layout>
  );
};
