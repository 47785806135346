import React from "react";
import styles from "./EmptyLayout.module.scss";
import { Footer } from "features/checkIn/components/Footer";
import { Layout as BaseLayout } from "features/layout";

interface EmptyLayoutProps {
  navbar?: React.ReactNode;
  primaryCta: React.ReactNode;
  allowBack?: boolean;
  emptyHeight?: boolean;
  footerLink?: React.ReactElement;
  calledFrom?: string;
}

export const EmptyLayout: React.FC<EmptyLayoutProps> = ({
  children,
  navbar,
  primaryCta,
  allowBack,
  emptyHeight,
  calledFrom,
}) => {
  return (
    <BaseLayout
      navbar={navbar}
      emptyHeight={emptyHeight}
      calledFrom={calledFrom}
    >
      <div className={styles.main}>{children}</div>
      <Footer primaryButton={primaryCta} allowBack={allowBack} />
    </BaseLayout>
  );
};
