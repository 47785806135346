import React from "react";
import dayjs from "dayjs";
import styles from "./Order.module.scss";
import contactLensIcon from "images/contact-lens.svg";
import eyeglassesIcon from "images/eyeglasses.svg";
import onlineOrderIcon from "images/online-order.svg";
import truck from "images/truck.svg";
import smallLocationPin from "images/small-location-pin.svg";
import { Order as OrderType } from "types/order";

export const Order: React.FC<{ order: OrderType }> = ({ order }) => {
  const formattedDate = (date: string) => dayjs(date).format("MM/DD/YY");
  let orderIcon = "";
  let orderTypeName = "";
  if (order.orderTypeName === "soft_contacts" || order.orderTypeName === "S") {
    orderIcon = contactLensIcon;
    orderTypeName = "Contact Lenses";
  } else if (
    order.orderTypeName === "eyeglasses" ||
    order.orderTypeName === "E"
  ) {
    orderIcon = eyeglassesIcon;
    orderTypeName = "Eyeglasses";
  } else {
    orderIcon = onlineOrderIcon;
    orderTypeName = "Dry Eye";
  }
  orderIcon = order.onlineOrder ? onlineOrderIcon : orderIcon;
  orderTypeName = order.onlineOrder ? "Online Order" : orderTypeName;

  return (
    <div className={styles.order}>
      <div className={styles.thumbnail}>
        <img
          className={styles.thumbnailIcon}
          src={orderIcon}
          alt="" /* NB: alt left blank, redundant with below copy */
        />
        <div className={styles.thumbnailDescription}>{orderTypeName}</div>
      </div>
      <div className={styles.data}>
        <div className={styles.item}>
          <div className={styles.prop}>Order Date</div>
          <div className={styles.value}>{formattedDate(order.orderDate)}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.prop}>Order No.</div>
          <div className={styles.value}>{`#${order.orderNum}`}</div>
        </div>
        <div className={styles.largeItem}>
          <div className={styles.prop}>Status</div>
          <div className={styles.value}>
            {order.displayStatus}
            <div className={styles.byline}>{order.displayByline}</div>
            {order.expectDate && order.displayStatus === "In the works" && (
              <div className={styles.promisedDate}>
                Est. Arrival: {formattedDate(order.expectDate)}
              </div>
            )}
          </div>
        </div>
        {order.fulfillmentDestination &&
          order.fulfillmentType === "shipping" && (
            <div className={styles.destinationBar}>
              <img src={truck} className={styles.logo} alt="truck" />
              Shipped to{" "}
              <span className={styles.destination}>
                {order.fulfillmentDestination}
              </span>
            </div>
          )}
        {order.fulfillmentDestination && order.fulfillmentType === "pickup" && (
          <div className={styles.destinationBar}>
            <img
              src={smallLocationPin}
              className={styles.logo}
              alt="small location pin"
            />
            Pickup at{" "}
            <span className={styles.destination}>
              <a href={order.fulfillmentDestinationMapUrl}>
                {order.fulfillmentDestination}
              </a>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
