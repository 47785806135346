import React from "react";
import { useDispatch } from "react-redux";
import { setServerError } from "features/patientDashboard/patientDashboardSlice";

interface UseErrorReturn {
  showErrorToast: () => void;
}

export const useErrorToast = (): UseErrorReturn => {
  const dispatch = useDispatch();

  return {
    showErrorToast: React.useCallback(() => {
      dispatch(setServerError(true));
    }, [dispatch]),
  };
};
