import axios, { CancelToken } from "axios";

export const getInsuranceCarriersRequest = async ({
  officeId,
  cancelToken,
}: {
  officeId: string;
  cancelToken?: CancelToken;
}): Promise<Array<string>> => {
  const { data } = await axios.get<Array<string>>(
    `/api/offices/${officeId}/insurance_carriers`,
    {
      cancelToken,
    }
  );

  return data;
};
