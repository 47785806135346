import React from "react";
import { Provider } from "react-redux";
import { EnhancedStore } from "@reduxjs/toolkit";
import { SnackbarProvider } from "features/patientDashboard/hooks/useSnackbar/SnackbarContext";
import { CurrentPatientProvider } from "features/patientDashboard/hooks/useCurrentPatient/CurrentPatientContext";
import { CurrentAppointmentProvider } from "features/patientDashboard/hooks/useCurrentAppointment/CurrentAppointmentContext";

export const AppProvider: React.FC<{
  store: EnhancedStore;
  children: React.ReactNode;
}> = ({ children, store }) => (
  <Provider store={store}>
    <SnackbarProvider>
      <CurrentPatientProvider>
        <CurrentAppointmentProvider>{children}</CurrentAppointmentProvider>
      </CurrentPatientProvider>
    </SnackbarProvider>
  </Provider>
);
