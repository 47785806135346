import React from "react";
import { Prompt, useHistory } from "react-router";
import { Location, Action } from "history";
import styles from "./ConfirmNavigation.module.scss";
import { Button, ButtonRole } from "components/Button";
import { Modal } from "components/Modal";

type NavigationEvent = {
  location: Location;
  action: Action;
};

interface ConfirmNavigationProps {
  enableOnLocationChange?: (location: Location) => boolean;
  enableOnExit?: boolean;
}

type ModalStatus = "hidden" | "visible" | "exiting";

export const ConfirmNavigation: React.FC<ConfirmNavigationProps> = ({
  enableOnLocationChange = () => false,
  enableOnExit = false,
}) => {
  const [status, setStatus] = React.useState<ModalStatus>("hidden");
  const [navigationEvent, setNavigationEvent] =
    React.useState<NavigationEvent | null>(null);
  const history = useHistory();
  const interceptNavigation = (location: Location, action: Action) => {
    if (!enableOnLocationChange(location)) {
      return true;
    }

    if (status === "exiting") {
      // this was previously set from the modal, so we can safely let this event through
      setNavigationEvent(null);
      setStatus("hidden");
      return true;
    }

    // otherwise, block the navigation and show the modal
    setStatus("visible");

    // we need to stash the parameters in case the user wants to exit the screen
    setNavigationEvent({
      location,
      action,
    });
    return false;
  };
  const stayOnPage = () => {
    setNavigationEvent(null);
    setStatus("hidden");
  };
  const leavePage = () => {
    if (!navigationEvent) {
      setStatus("hidden");
      return;
    }

    setStatus("exiting");
  };
  const confirmExit = React.useCallback(
    (event: BeforeUnloadEvent) => {
      if (!enableOnExit) {
        return;
      }

      event.preventDefault();
      event.returnValue = null;
    },
    [enableOnExit]
  );
  React.useEffect(() => {
    if (status !== "exiting" || navigationEvent === null) {
      return;
    }

    if (navigationEvent.action === "PUSH") {
      history.push(navigationEvent.location);
    }

    if (navigationEvent.action === "POP") {
      history.goBack();
    }

    if (navigationEvent.action === "REPLACE") {
      history.replace(navigationEvent.location);
    }
  }, [status, history, navigationEvent]);

  React.useEffect(() => {
    window.addEventListener("beforeunload", confirmExit);
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, [confirmExit]);

  return (
    <>
      <Prompt message={interceptNavigation} />
      <Modal
        isOpen={status === "visible"}
        onRequestClose={stayOnPage}
        aria={{
          labelledby: "confirm-exit-modal-label",
          describedby: "confirm-exit-modal-description",
        }}
      >
        <h1 className={styles.header}>Are you sure you want to leave?</h1>
        <p className={styles.body}>
          You may have unsaved information. Any information you enter that is
          not saved may be lost.
        </p>
        <div className={styles.buttons}>
          <Button
            size="large"
            role={ButtonRole.Ghost}
            text="No, Keep Info"
            onClick={stayOnPage}
          />
          <Button size="large" text="Yes, Leave" onClick={leavePage} />
        </div>
      </Modal>
    </>
  );
};
