const LETTER_MASK = "mmddyyyy";

export const maskDate = (date: string): string => {
  const digits = date.replace(/[^\d]/g, "").substring(0, LETTER_MASK.length);

  const maskedWithoutSlashes = String(
    `${digits}${LETTER_MASK.substring(digits.length, LETTER_MASK.length)}`
  ).split("");

  maskedWithoutSlashes.splice(4, 0, "/");
  maskedWithoutSlashes.splice(2, 0, "/");

  return maskedWithoutSlashes.join("");
};
