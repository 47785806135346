import React, { ReactNode, SyntheticEvent } from "react";
import cx from "classnames";
import styles from "./TextInput.module.scss";
import errorIcon from "images/error.svg";
import { GuidedTooltip } from "components/GuidedTooltip";

type InputMode = "numeric";
type AutoComplete =
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code";
type InputType = "text" | "tel" | "email" | "password" | "date";

export interface TextInputProps {
  inputType: InputType;
  autoComplete?: AutoComplete;
  name: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: SyntheticEvent) => void;
  placeholder?: string;
  errorMessage?: string;
  children?: ReactNode;
  defaultValue?: string;
  inputMode?: InputMode;
  autoFocus?: boolean;
  className?: string;
  errorClassName?: string;
  readOnly?: boolean;
  afterInput?: React.ReactNode;
  maxLength?: number;
  ariaLabel?: string;
  guidedTooltip?: React.ReactNode;
  guidedTooltipClassName?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      inputType,
      autoComplete,
      autoFocus,
      name,
      onBlur,
      onChange,
      onClick,
      placeholder,
      errorMessage,
      children,
      defaultValue,
      inputMode,
      className,
      errorClassName,
      readOnly,
      afterInput,
      maxLength,
      ariaLabel,
      guidedTooltip,
      guidedTooltipClassName,
    },
    ref
  ) => {
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const errorToDisplay = errorMessage ? (
      `Error: ${errorMessage}`
    ) : (
      <span>&nbsp;</span>
    );

    return (
      <div className={cx(styles.container, className)}>
        <label className={styles.label} htmlFor={name}>
          {children}
        </label>
        <div className={styles.inputContainer}>
          <input
            pattern="\S+"
            required
            autoFocus={autoFocus}
            type={inputType}
            inputMode={inputMode}
            className={cx(styles.input, {
              [styles.error]: errorMessage !== undefined,
            })}
            name={name}
            autoComplete={autoComplete}
            id={name}
            onFocus={() => setTooltipVisible(true)}
            onBlur={(e) => {
              setTooltipVisible(false);
              return onBlur && onBlur(e);
            }}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder}
            ref={ref}
            defaultValue={defaultValue}
            aria-invalid={errorMessage !== undefined && errorMessage !== null}
            readOnly={readOnly}
            maxLength={maxLength}
            aria-label={ariaLabel}
          />
          {afterInput}
          {errorMessage && (
            <img className={styles.errorIcon} alt="" src={errorIcon} />
          )}
        </div>
        {guidedTooltip && tooltipVisible && (
          <div
            className={cx(styles.tooltip, guidedTooltipClassName, {
              [styles.tooltipAfterError]: !!errorMessage,
            })}
          >
            <GuidedTooltip>{guidedTooltip}</GuidedTooltip>
          </div>
        )}
        <p className={cx(styles.errorMessage, errorClassName)}>
          {errorToDisplay}
        </p>
      </div>
    );
  }
);

export const ApptTextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      inputType,
      autoComplete,
      autoFocus,
      name,
      onBlur,
      onChange,
      onClick,
      placeholder,
      errorMessage,
      children,
      defaultValue,
      inputMode,
      className,
      errorClassName,
      readOnly,
      afterInput,
      maxLength,
      ariaLabel,
      guidedTooltip,
      guidedTooltipClassName,
    },
    ref
  ) => {
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const errorToDisplay = errorMessage ? (
      `Error: ${errorMessage}`
    ) : (
      <span>&nbsp;</span>
    );

    return (
      <div className={cx(styles.apptContainer, className)}>
        <label className={styles.label} htmlFor={name}>
          {children}
        </label>
        <div className={styles.inputContainer}>
          <input
            pattern="\S+"
            required
            autoFocus={autoFocus}
            type={inputType}
            inputMode={inputMode}
            className={cx(styles.input, {
              [styles.error]: errorMessage !== undefined,
            })}
            name={name}
            autoComplete={autoComplete}
            id={name}
            onFocus={() => setTooltipVisible(true)}
            onBlur={(e) => {
              setTooltipVisible(false);
              return onBlur && onBlur(e);
            }}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder}
            ref={ref}
            defaultValue={defaultValue}
            aria-invalid={errorMessage !== undefined && errorMessage !== null}
            readOnly={readOnly}
            maxLength={maxLength}
            aria-label={ariaLabel}
          />
          {afterInput}
          {errorMessage && (
            <img className={styles.errorIcon} alt="" src={errorIcon} />
          )}
        </div>
        {guidedTooltip && tooltipVisible && (
          <div
            className={cx(styles.tooltip, guidedTooltipClassName, {
              [styles.tooltipAfterError]: !!errorMessage,
            })}
          >
            <GuidedTooltip>{guidedTooltip}</GuidedTooltip>
          </div>
        )}
        <p className={cx(styles.errorMessage, errorClassName)}>
          {errorToDisplay}
        </p>
      </div>
    );
  }
);
