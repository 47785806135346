import axios from "axios";
import { Insurance } from "types/insurance";

export interface GetCurrentPatientInsuranceResponse {
  other_insurance_name: string;
  insurance_carrier: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  relationship: string;
}

export const getCurrentPatientInsuranceRequest = async (): Promise<
  Insurance[]
> => {
  const { data } = await axios.get<GetCurrentPatientInsuranceResponse[]>(
    `/api/checkins/patient_insurances`
  );

  return data.map((patientInsurance) => ({
    insuranceCarrier: patientInsurance.insurance_carrier,
    otherInsuranceName: patientInsurance.other_insurance_name,
    subscriberFirstName: patientInsurance.first_name,
    subscriberLastName: patientInsurance.last_name,
    subscriberDateOfBirth: patientInsurance.date_of_birth,
    subscriberRelationship: patientInsurance.relationship,
  }));
};
