import React from "react";
import {
  CurrentAppointmentContext,
  CurrentAppointmentState,
} from "./CurrentAppointmentContext";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { getCurrentAppointmentRequest } from "api/getCurrentAppointmentRequest";

export const useCurrentAppointment = (): {
  currentAppointment: CurrentAppointmentState;
  fetchCurrentAppointment: () => Promise<void>;
} => {
  const context = React.useContext(CurrentAppointmentContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentAppointment must be used within a CurrentAppointmentProvider"
    );
  }

  const { currentAppointment, setCurrentAppointment, isFetching } = context;
  const { handleUnauthorized } = useCurrentPatient();

  const fetchCurrentAppointment = React.useCallback(async () => {
    if (isFetching.current) {
      return;
    }
    await handleUnauthorized(async () => {
      isFetching.current = true;
      const result = await getCurrentAppointmentRequest();
      setCurrentAppointment(result);
    });
    isFetching.current = false;
  }, [setCurrentAppointment, handleUnauthorized, isFetching]);

  React.useEffect(() => {
    if (currentAppointment === undefined) {
      fetchCurrentAppointment();
    }
  }, [currentAppointment, fetchCurrentAppointment]);

  return { currentAppointment, fetchCurrentAppointment };
};
