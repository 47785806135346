import React from "react";
import cx from "classnames";
import styles from "./Layout.module.scss";
import { Card } from "components/Card";
import { EmptyLayout } from "features/checkIn/components/EmptyLayout";
import { ErrorToast } from "features/patientDashboard/ErrorToast";

interface LayoutProps {
  navbar?: React.ReactNode;
  primaryCta: React.ReactNode;
  className?: string;
  emptyHeight?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  navbar,
  primaryCta,
  emptyHeight = false,
}) => {
  return (
    <EmptyLayout
      navbar={navbar}
      primaryCta={primaryCta}
      emptyHeight={emptyHeight}
    >
      <ErrorToast />
      <Card className={cx(styles.card, className)}>{children}</Card>
    </EmptyLayout>
  );
};
