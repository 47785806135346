import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import styles from "./PrescriptionsReview.module.scss";
import { Navbar } from "./components/Navbar";
import { EmptyLayout } from "./components/EmptyLayout";
import { DocumentPrintButton } from "./components/DocumentPrintButton";
import * as paths from "features/routing/paths";
import { Button } from "components/Button";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { acknowledgeDocument } from "api/acknowledgeDocument";
import { createPrescriptionsAcknowledgmentRequest } from "api/createPrescriptionsAcknowledgment";
import { skipPrescriptionsAcknowledgmentRequest } from "api/skipPrescriptionsAcknowledgment";
import circlePin from "images/circle-pin.svg";
import circleMagnifyingGlass from "images/circle-magnifying-glass.svg";
import { TextButton } from "components/TextButton";
import { DocumentType } from "features/checkIn/types";
import {
  PrescriptionAccessNoticePolicy,
  VERSION as PRESCRIPTION_VERSION,
} from "features/checkIn/documents/PrescriptionAccessNoticePolicy";

declare let getCLXUrl: string;
export const PrescriptionsReview: React.FC = () => {
  const history = useHistory();
  const { fetchCurrentAppointment } = useCurrentAppointment();
  const { handleUnauthorized } = useCurrentPatient();
  const prescriptionsReviewFormMethods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = prescriptionsReviewFormMethods;

  const onSubmit = async () => {
    await handleUnauthorized(async () => {
      await acknowledgeDocument({
        documentType: DocumentType.PrescriptionAccessNotice,
        documentVersion: PRESCRIPTION_VERSION,
      });
      await createPrescriptionsAcknowledgmentRequest();
      await fetchCurrentAppointment();
      history.push(paths.checkInHome());
    });
  };

  const onSkip = async () => {
    await handleUnauthorized(async () => {
      await skipPrescriptionsAcknowledgmentRequest();
      history.push(paths.checkInHome());
    });
  };
  // Note: Do NOT modify the copy for this page without consulting legal.
  return (
    <FormProvider {...prescriptionsReviewFormMethods}>
      <EmptyLayout
        allowBack={false}
        navbar={<Navbar title={"Prescriptions"} />}
        primaryCta={
          <div className={styles.footerButtons}>
            <Button
              onClick={handleSubmit(onSubmit)}
              renderAsDisabled={!isValid}
              disabled={isSubmitting}
              loading={isSubmitting}
              text="Yes, I understand"
            />
            <div className={styles.center}>
              <TextButton
                onClick={handleSubmit(onSkip)}
                disabled={isSubmitting}
                className={styles.skip}
              >
                Get More Details During Visit
              </TextButton>
            </div>
          </div>
        }
        emptyHeight={true}
        calledFrom="perscription"
      >
        <div className={styles.container}>
          <div className={cx(styles.top, styles.heading2)}>
            We&rsquo;ll provide a copy of prescriptions.
          </div>
          <div className={styles.columns}>
            <div>
              <img
                className={styles.img}
                src={circleMagnifyingGlass}
                alt="magnifying glass in circle"
              />
              <br />
              <div className={styles.bodyTextLarge}>
                Access prescriptions online
              </div>
              <div className={styles.bodyText}>
                MyEyeDr. provides convenient prescription access with all
                current eyeglass and contact lens prescriptions available
                digitally at{" "}
                <a
                  href={getCLXUrl}
                  className={styles.inlineLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  getrx.myeyedr.com
                </a>
              </div>
            </div>
            <div className={cx(styles.or, styles.centerColumn)}>or</div>
            <div>
              <img
                className={styles.img}
                src={circlePin}
                alt="location pin in circle"
              />
              <br />
              <div className={styles.bodyTextLarge}>
                Get prescriptions at our location
              </div>
              <div className={styles.bodyText}>
                Physical copies of eyeglass and contact lens prescriptions are
                also available at the completion of your visit or any time
                after.
              </div>
            </div>
          </div>
          <div className={styles.caption}>
            By tapping “Yes, I understand” I acknowledge the policy and note I
            can (i) access my eyeglass and contact lens prescriptions digitally
            at{" "}
            <a
              href={getCLXUrl}
              className={styles.captionLink}
              target="_blank"
              rel="noreferrer"
            >
              getrx.myeyedr.com
            </a>{" "}
            or (ii) obtain a paper copy at any time as well.{" "}
            <DocumentPrintButton
              filename={PRESCRIPTION_VERSION}
              document={<PrescriptionAccessNoticePolicy />}
              linkText={"Print this Document."}
            />
          </div>
        </div>
      </EmptyLayout>
    </FormProvider>
  );
};
