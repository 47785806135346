import React from "react";

export const VERSION = "Insurance_Assignment_Policy_and_Agreement_10_2021";

export const InsuranceAssignmentPolicy = (): React.ReactElement => (
  <>
    <div>
      <h2>INSURANCE ASSIGNMENT POLICY & AGREEMENT</h2>
    </div>
    <h4>INSURANCE ASSIGNMENT POLICY AND AGREEMENT</h4>
    <p>
      We will do our best to accurately verify and file your insurance for
      services and/or materials, however, benefits quoted by your insurance
      carrier are not a guarantee of payment. All current insurance information
      must be provided at the time of service, including any information
      relating to any open Worker’s Compensation or Personal Injury cases. You
      are also responsible for any and all co-insurance, deductible and
      non-covered services on the day services are rendered.
    </p>
    <h4>Non-Covered Services</h4>
    <p>
      Should your insurance deny any service or materials for any reason, you
      will be responsible for full payment to us. You may pursue any
      reimbursement you deem payable directly from your insurance company.
    </p>
    <p>
      Insurance coverage varies depending on individual plans and contracts, but
      we have found that some plans do not cover the following:
    </p>
    <ul>
      <li>
        Additional fees for special diagnostic testing (Routine Retinal Photos)
      </li>
      <li>Eyeglass lens and frame options (UV, AR, Scratch)</li>
      <li>Contact lens evaluation, fitting, follow up care and solutions</li>
      <li>Premium, thinner, oversize or specialty lenses</li>
    </ul>

    <h4>Referrals/Pre-authorizations</h4>
    <p>
      We will be happy to maintain any referrals and file any claims directly to
      your insurance carrier, but we are unable to assume responsibility for any
      unauthorized treatment. You are responsible for obtaining any referral or
      pre-authorization required by your insurance company.
    </p>
    <h4>Unpaid Balances</h4>
    <p>
      We will make every effort to work with you to resolve any outstanding
      balance. Statements are sent to patients with unpaid balances. Any amounts
      remaining open after the initial billing cycle may be submitted to a
      collections agency and are subject to additional fees. In signing below,
      you consent and agree to being contacted by MyEyeDr. or any organization
      to which MyEyeDr. assigns your account(s), such as debt collection
      agencies, and any affiliated or business associated service providers or
      vendors of any of these organizations (collectively, "We") about any
      matter related to your account(s), or to collect amounts you may owe,
      using contact information or any new information that you provide. We may
      contact you by U.S. mail, email, telephone, including cell phone; and may
      use technology including SMS messaging, auto-dialing and/or pre-recorded
      or artificial voice, or voice messages, to contact you, regardless of
      whether you incur charges as a result.
    </p>
  </>
);
