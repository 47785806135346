import React from "react";
import styles from "./RescheduleAppointmentModal.module.scss";
import { CreateAppointmentArgs } from "api/createAppointmentRequest";
import { Button } from "components/Button";

interface RescheduleAppointmentModalProps {
  setShowRescheduleModal: (val: boolean) => void;
  submitLocked: boolean;
  scheduleAppointment: (overrides?: Partial<CreateAppointmentArgs>) => void;
}
export const rescheduleAppointmentModalAria = {
  labelledby: "reschedule-appointment-modal-label",
  describedby: "reschedule-appointment-modal-description",
};
export const RescheduleAppointmentModal: React.FC<
  RescheduleAppointmentModalProps
> = ({ submitLocked, scheduleAppointment }) => {
  return (
    <div className={styles.container}>
      <h1
        className={styles.header}
        id={rescheduleAppointmentModalAria.labelledby}
      >
        Previously Booked
      </h1>
      <div id={rescheduleAppointmentModalAria.describedby}>
        <p>
          Looks like you have another appointment. We&apos;ll replace your
          previous appointment with this one if you click
          &apos;Reschedule&apos;.
        </p>
        <p>
          <strong>
            If this appointment is for another family member, please enter their
            name and date of birth to create a new appointment.
          </strong>
        </p>
      </div>
      <div className={styles.ctas}>
        <Button
          text="Reschedule"
          disabled={submitLocked}
          loading={submitLocked}
          onClick={() => scheduleAppointment({ allowReschedule: true })}
          className={styles.button}
          size="large"
          autoFocus
        />
      </div>
    </div>
  );
};
