import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { TimetableColumnSkeleton } from "./TimetableColumnSkeleton";
import { OnSelectSlotHandler } from "./types";
import { LimitedAvailability } from "./LimitedAvailability";
import styles from "./TimetableSlots.module.scss";
import { FutureAvailability } from "./FutureAvailability";
import { SlotButton } from "features/scheduling/components/SlotButtons";
import {
  selectHasRequested,
  selectUpcomingTimeslotsForDoctorByDate,
} from "features/scheduling/timeSlotsSlice";
import { RootState } from "app/store";
import { API_DATE_FORMAT } from "api/constants";
import { selectNextAvailableDateForDoctor } from "features/scheduling/availabilitySummarySlice";
import { DateString } from "types/dateString";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Doctor } from "types/doctor";
import { Office } from "types/office";

interface TimetableSlotsProps {
  days: dayjs.Dayjs[];
  doctor: Doctor | null;
  officeId: string;
  onSelectSlot: OnSelectSlotHandler;
  fromDate: DateString;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  selectedOffice: Office;
}

interface TimetableWithDoctorProps extends TimetableSlotsProps {
  doctor: Doctor;
}

const TimetableSkeleton: React.FC<{ days: dayjs.Dayjs[] }> = ({ days }) => {
  return (
    <div className={styles.slotArea}>
      {days.map((day) => (
        <TimetableColumnSkeleton key={day.format(API_DATE_FORMAT)} />
      ))}
    </div>
  );
};

const TimetableWithDoctor: React.FC<TimetableWithDoctorProps> = ({
  days,
  doctor,
  officeId,
  onSelectSlot,
  fromDate,
  examType,
  patientReturnStatus,
  selectedOffice,
}) => {
  const timeSlotsForDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForDoctorByDate(state, doctor.id)
  );
  const availability = useSelector((state: RootState) =>
    selectNextAvailableDateForDoctor(
      state,
      officeId,
      doctor.id,
      fromDate,
      selectedOffice.timeSlotWeeks
    )
  );

  if (availability === "loading") {
    return <TimetableSkeleton days={days} />;
  }

  if (availability && Object.keys(timeSlotsForDoctor).length === 0) {
    return (
      <FutureAvailability
        doctor={doctor}
        officeId={officeId}
        availability={availability}
        examType={examType}
        patientReturnStatus={patientReturnStatus}
        selectedOffice={selectedOffice}
      />
    );
  }

  if (Object.keys(timeSlotsForDoctor).length === 0) {
    return <LimitedAvailability doctor={doctor} />;
  }

  return (
    <div className={styles.slotArea}>
      {days.map((day) => (
        <div
          key={day.format(API_DATE_FORMAT)}
          className={styles.columnBackground}
        >
          <div className={styles.column}>
            {timeSlotsForDoctor[day.format(API_DATE_FORMAT)]?.map((slot) => (
              <SlotButton
                key={`${slot.date} ${slot.startTime}`}
                slot={slot}
                size="small"
                onSelectSlot={onSelectSlot}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const TimetableSlots: React.FC<TimetableSlotsProps> = ({
  days,
  doctor,
  officeId,
  onSelectSlot,
  fromDate,
  examType,
  patientReturnStatus,
  selectedOffice,
}) => {
  const hasRequested = useSelector(selectHasRequested);

  if (!hasRequested || !doctor) {
    return <TimetableSkeleton days={days} />;
  }

  return (
    <TimetableWithDoctor
      days={days}
      doctor={doctor}
      officeId={officeId}
      onSelectSlot={onSelectSlot}
      fromDate={fromDate}
      examType={examType}
      patientReturnStatus={patientReturnStatus}
      selectedOffice={selectedOffice}
    />
  );
};
