import React from "react";
import { useHistory } from "react-router";
import styles from "./Footer.module.scss";
import { Button, ButtonRole } from "components/Button";

export interface FooterProps {
  primaryButton: React.ReactNode;
  allowBack?: boolean;
}

export const Footer: React.FC<FooterProps> = ({
  primaryButton,
  allowBack = true,
}) => {
  const history = useHistory();

  return (
    <footer className={styles.footer}>
      <div className={allowBack ? styles.buttons : styles.button}>
        {allowBack && (
          <Button
            onClick={history.goBack}
            role={ButtonRole.Ghost}
            text="Back"
          />
        )}
        {primaryButton}
      </div>
    </footer>
  );
};
