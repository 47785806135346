import React from "react";
import cx from "classnames";
import styles from "./WearOption.module.scss";
import { RadioButton } from "components/RadioButton";
import { ExamType } from "types/examType";

interface WearOptionProps {
  label: string;
  value: ExamType | undefined;
  examType: ExamType;
  onClick: (e: React.MouseEvent) => void;
  icon: string;
  isMobileView?: boolean;
}

export const WearOption: React.FC<WearOptionProps> = ({
  label,
  value,
  examType,
  onClick,
  icon,
  isMobileView,
}) => {
  if (isMobileView) {
    return (
      <button
        onClick={onClick}
        className={
          (
            examType === ExamType.GlassesTryContacts
              ? ExamType.GlassesTryContacts === value ||
                ExamType.GlassesNoTryContacts === value
              : examType === value
          )
            ? cx(styles.wearOptionMobile, styles.selected)
            : styles.wearOptionMobile
        }
      >
        <img
          src={icon}
          className={
            label === "I do not wear glasses or contacts"
              ? cx(styles.icon, styles.addMarginIcon)
              : styles.icon
          }
          alt=""
        />
        <div
          className={
            label === "I do not wear glasses or contacts"
              ? cx(styles.wearOptionNameContainer, styles.addMargin)
              : styles.wearOptionNameContainer
          }
        >
          <div className={styles.wearOptionName}>{label}</div>
        </div>
      </button>
    );
  }

  return (
    <button onClick={onClick} className={styles.wearOption}>
      <RadioButton
        name="eyeExam"
        className={styles.radio}
        checked={
          examType === ExamType.GlassesTryContacts
            ? ExamType.GlassesTryContacts === value ||
              ExamType.GlassesNoTryContacts === value
            : examType === value
        }
      ></RadioButton>
      <img src={icon} className={styles.icon} alt="" />
      <div
        className={
          label === "I do not wear glasses or contacts"
            ? cx(styles.wearOptionNameContainer, styles.addMargin)
            : styles.wearOptionNameContainer
        }
      >
        <div className={styles.wearOptionName}>{label}</div>
      </div>
    </button>
  );
};
