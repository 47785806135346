import React from "react";
import { useHistory } from "react-router-dom";
import * as paths from "features/routing/paths";
import {
  CreatePatientSessionArgs,
  createPatientSessionRequest,
} from "api/createPatientSession";
import { useQueryParam } from "hooks/useQueryParam";
import { REDIRECT_KEY } from "features/routing/AuthorizedRouter";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";

type UseCreatePatientSessionReturn = {
  createPatientSession: (
    params: CreatePatientSessionArgs,
    onUnauthorizedCallback: () => void
  ) => Promise<void>;
};

export const safeDestination = (destination: string): string => {
  return destination[0] === "/" ? destination : paths.portalHome();
};

export const useCreatePatientSession = (): UseCreatePatientSessionReturn => {
  const { handleUnauthorized } = useCurrentPatient();
  const history = useHistory();
  const [destination, _setDestination] = useQueryParam(
    REDIRECT_KEY,
    paths.portalHome()
  );

  const createPatientSession = React.useCallback(
    async (
      params: CreatePatientSessionArgs,
      onUnauthorizedCallback: () => void
    ): Promise<void> => {
      await handleUnauthorized(
        async () => {
          await createPatientSessionRequest(params);
          history.push(
            paths.chooseTwoFactorMethod({
              query: { redirectTo: destination },
            })
          );
        },
        {
          onUnauthorized: onUnauthorizedCallback,
        }
      );
    },
    [destination, handleUnauthorized, history]
  );

  return { createPatientSession };
};
