import React from "react";
import dayjs from "dayjs";
import { Redirect, useParams } from "react-router-dom";
import {
  AppointmentSelectDoctorSlotRouteProps,
  Week,
  searchDateParam,
  today,
} from "./shared";
import { useQueryParam } from "hooks/useQueryParam";
import * as paths from "features/routing/paths";
import { calendarWeek } from "features/scheduling/utils/calendarWeek";

interface WithUpcomingWeekProps {
  children: (props: { week: Week }) => JSX.Element;
}

export const WithUpcomingWeek: React.FC<WithUpcomingWeekProps> = (props) => {
  const { officeId, examType, doctorId, patientReturnStatus } =
    useParams<AppointmentSelectDoctorSlotRouteProps>();
  const [searchDate] = useQueryParam(searchDateParam, today());

  const week = calendarWeek(searchDate).filter((day) =>
    day.isSameOrAfter(dayjs(), "day")
  );

  if (week.length === 0) {
    return (
      <Redirect
        to={paths.appointmentsAvailableAppointmentsDoctor({
          officeId,
          examType,
          patientReturnStatus,
          doctorId,
          query: { date: today() },
        })}
      />
    );
  } else {
    return props.children({ week });
  }
};
