import { Checkin } from "types/checkin";

export const checkinCompleted = (checkin?: Checkin): boolean =>
  insuranceSubmitted(checkin) &&
  aboutYouSubmitted(checkin) &&
  prescriptionsSubmitted(checkin);

export const checkinPartiallyCompleted = (checkin?: Checkin): boolean =>
  aboutYouSubmitted(checkin) && !insuranceSubmitted(checkin);

export const insuranceSubmitted = (checkin?: Checkin): boolean =>
  insuranceFinished(checkin) || insuranceSkipped(checkin);

export const prescriptionsSubmitted = (checkin?: Checkin): boolean =>
  prescriptionsFinished(checkin) || prescriptionsSkipped(checkin);

export const insuranceSkipped = (checkin?: Checkin): boolean =>
  checkin?.insurance === "skipped";

export const insuranceFinished = (checkin?: Checkin): boolean =>
  checkin?.insurance === "finished";

export const aboutYouSubmitted = (checkin?: Checkin): boolean =>
  checkin?.aboutYou === "finished";

export const prescriptionsFinished = (checkin?: Checkin): boolean =>
  checkin?.prescriptions === "finished";

export const prescriptionsSkipped = (checkin?: Checkin): boolean =>
  checkin?.prescriptions === "skipped";

export const aboutYouNeedsVerification = (checkin?: Checkin): boolean =>
  checkin?.aboutYou === "needs_verification";

export const insuranceNeedsVerification = (checkin?: Checkin): boolean =>
  checkin?.insurance === "needs_verification";

export const aboutYouNewVerification = (checkin?: Checkin): boolean =>
  checkin?.aboutYou === "new_verification";

export const insuranceNewVerification = (checkin?: Checkin): boolean =>
  checkin?.insurance === "new_verification";

export const insuranceSkippedNewVerification = (checkin?: Checkin): boolean =>
  checkin?.insurance === "new_verification_skipped";
