import React from "react";
import cx from "classnames";
import styles from "./SchedulingStep.module.scss";
import circleCheck from "images/circle-check.svg";
import { TextLink } from "components/TextLink";

export enum Position {
  Visited,
  Current,
  Future,
}

export const getPosition = (step: number, position: number): Position => {
  if (position < step) {
    return Position.Visited;
  }

  if (position === step) {
    return Position.Current;
  }

  return Position.Future;
};

interface SchedulingStepProps {
  label: string;
  position?: Position;
  editLinkTo?: string;
  className?: string;
}

export const SchedulingStep: React.FC<SchedulingStepProps> = ({
  label,
  position,
  editLinkTo,
  className,
}) => {
  if (!label) {
    return <></>;
  }
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.containerFuture]: position === Position.Future,
          [styles.containerVisited]: position === Position.Visited,
        },
        className
      )}
    >
      {position === Position.Visited && (
        <img className={styles.icon} src={circleCheck} alt="Check" />
      )}
      <h1
        className={cx(styles.label, {
          [styles.future]: position === Position.Future,
          [styles.visited]: position === Position.Visited,
        })}
      >
        {label}
      </h1>

      {position === Position.Visited && editLinkTo && (
        <TextLink to={editLinkTo} className={styles.editLink}>
          Change
        </TextLink>
      )}
    </div>
  );
};

SchedulingStep.defaultProps = {
  position: Position.Current,
};
