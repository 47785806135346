import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import cx from "classnames";
import styles from "./AppointmentBookingStart.module.scss";
import { MedicaidToast } from "./components/MedicaidToast";
import { toTel } from "./utils/toTel";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import appointmentLogo from "images/appointment-logo.svg";
import * as paths from "features/routing/paths";
import { LegacyCard } from "components/LegacyCard";
import { Button, ButtonRole, ButtonLinkExternal } from "components/Button";
import { today } from "features/scheduling/utils/today";
import { InfoTooltip } from "components/InfoTooltip";
import { Layout } from "features/layout";
import { Navbar } from "components/Navbar";
import { Office } from "types/office";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { WithOffice } from "components/WithOffice";
import { Modal } from "components/Modal/Modal";
import checkmark from "images/checkmark.svg";
import { OfficeAddress } from "components/OfficeAddress";
import { useGetNearbyOfficesV1WithoutAvailabilityQuery } from "app/services/api";
import { validatePatient } from "api/validatePatient";
import {
  setBrazePatientDetail,
  setServerError,
} from "features/scheduling/brazePatientDetailSlice";

const aria = { labelledby: "medicaid-modal-label" };
interface UnconnectedAppointmentBookingStartProps {
  officeForThisAppointment: Office;
  officeId: string;
}

declare let orderContactUrl: string;
const UnconnectedAppointmentBookingStart: React.FC<
  UnconnectedAppointmentBookingStartProps
> = ({ officeForThisAppointment, officeId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formattedPhone } = officeForThisAppointment;
  const [showMedicaidToast, setShowMedicaidToast] = React.useState(
    !officeForThisAppointment.supportsMedicaid
  );
  const [showModal, setShowModal] = React.useState(false);
  //const [callOnce, setCallOnce] = React.useState(false);
  const [selectedOffice, setSelectedOffice] = React.useState("");
  const search = useLocation().search;
  const patientId = new URLSearchParams(search).get("eid");
  const goToScheduler = () => {
    const selectedOfficeId = !selectedOffice ? officeId : selectedOffice;

    setShowModal(false);
    if (selectedOfficeId) {
      history.push(
        paths.appointmentsSelectType({ officeId: selectedOfficeId })
      );
    }
  };

  usePageViewTracking("step-1-scheduler-enter");

  const { isLoading } = useGetNearbyOfficesV1WithoutAvailabilityQuery(officeId);

  const { nearbyOffices } = useGetNearbyOfficesV1WithoutAvailabilityQuery(
    officeId,
    {
      selectFromResult: (data) => ({
        nearbyOffices: data?.data?.filter((r) => r.supportsMedicaid),
      }),
    }
  );
  React.useEffect(() => {
    if (officeForThisAppointment.restrictVEH && patientId) {
      (async () => {
        const patientDetails = {
          patientId: patientId,
          firstName: null,
          lastName: null,
          phoneNumber: null,
          dateOfBirth: null,
          email: null,
        };
        try {
          const vaildPatient = await validatePatient(patientDetails);
          if (vaildPatient.patient !== "Not found") {
            dispatch(setBrazePatientDetail({ patientId: patientId }));
          }
        } catch (e) {
          if (e.code === "503") {
            dispatch(setServerError({ serverError: true }));
            return;
          }
          throw e;
        }
      })();
    }
  }, [dispatch, patientId, officeForThisAppointment]);

  return (
    <Layout
      navbar={
        <>
          <Navbar office={officeForThisAppointment}>
            <ButtonLinkExternal
              href={MYEYEDR_WEBSITE_URL}
              text="Back to Home"
              className={styles.backToHome}
              role={ButtonRole.Ghost}
              size="large"
            />
          </Navbar>
          {showModal && (
            <Modal
              aria={aria}
              isOpen={showModal}
              onRequestClose={() => {
                setShowModal(false);
                setSelectedOffice("");
              }}
              className={styles.modal}
              overlayClassName={styles.modalOverlayCustom}
              closeOnOverlayClick={false}
            >
              <div className={styles.prompt} id={aria.labelledby}>
                Select a nearby MyEyeDr. location that accepts Medicaid.
              </div>
              <div className={styles.contentWrap}>
                {nearbyOffices &&
                  !!nearbyOffices.length &&
                  nearbyOffices.map((r) => (
                    <div
                      className={styles.nearByModalOfficeBox}
                      onClick={() => setSelectedOffice(r.id)}
                      key={r.id}
                      role="button"
                      tabIndex={0}
                      aria-hidden="true"
                    >
                      <div
                        className={cx({
                          [styles.selectedNearByModalOfficeBox]:
                            selectedOffice === r.id,
                          [styles.nonSelectedNearByModalOfficeBox]:
                            selectedOffice !== r.id,
                        })}
                      >
                        <div className={styles.correctSign}>
                          <div className={styles.correctSignSpace}>
                            {selectedOffice === r.id && (
                              <img src={checkmark} alt="Checkmark" />
                            )}
                          </div>
                          <div className={styles.checkmarkUnderline}></div>
                        </div>

                        <div className={styles.officeContent}>
                          <div className={styles.officeTitle}>
                            {r.displayName}
                          </div>
                          <OfficeAddress office={r} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <Button
                text={"Continue"}
                role={ButtonRole.Standard}
                size="large"
                onClick={goToScheduler}
                className={styles.continueBtn}
                disabled={!selectedOffice}
              />
            </Modal>
          )}
        </>
      }
    >
      <MedicaidToast
        visible={showMedicaidToast}
        onDismiss={() => setShowMedicaidToast(false)}
        onShowModal={() => {
          setShowModal(true);
          setShowMedicaidToast(false);
        }}
        isLoading={isLoading}
        showMoreLocation={!!nearbyOffices?.length}
      />
      <div className={styles.container}>
        <div className={styles.preamble}>
          <div className={styles.howCanWeHelpYou}>
            How can we help you&nbsp;today?
          </div>
          <div className={styles.introduction}>
            You can book your eye health exam now, or shop glasses and contacts
            on our website.
          </div>
        </div>
        <LegacyCard className={styles.card}>
          <div className={styles.scheduleContainer}>
            <div className={styles.logoContainer}>
              <img src={appointmentLogo} alt="Schedule My Exam" />
            </div>
            <div className={styles.scheduleMyExam}>
              Schedule my eye&nbsp;health&nbsp;exam
            </div>
            <Button
              text={"Get Started"}
              role={ButtonRole.Standard}
              size="large"
              onClick={goToScheduler}
              className={styles.getStarted}
            />
          </div>
        </LegacyCard>
        <div className={styles.footer}>
          <section className={styles.footerSection}>
            <div className={styles.footerHeadingWithTooltip}>
              <h3 className={styles.footerHeading}>Other eyecare needs?</h3>
              <InfoTooltip>
                <p>
                  Please call our office to schedule appointments for follow-ups
                  or urgent concerns.
                </p>
                <p>
                  Urgent concerns may include any kind of eye infection or
                  injury that impairs vision. Some examples are seeing floaters
                  or flashing lights.
                </p>
              </InfoTooltip>
            </div>
            <p className={styles.footerParagraph}>
              If you are experiencing any eyecare needs outside of your routine
              exam, please call the office at{" "}
              <a
                href={toTel(formattedPhone)}
                className={styles.footerPhoneLink}
              >
                {formattedPhone}
              </a>{" "}
              to schedule an&nbsp;appointment.
            </p>
          </section>
          <hr className={styles.footerDivider} />
          <section className={styles.footerSection}>
            <h3 className={styles.footerHeading}>
              Need new glasses or contacts?
            </h3>
            <p className={styles.footerLinkContainer}>
              <a
                href={`${MYEYEDR_WEBSITE_URL}/eye-wear/shop-glasses`}
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Browse Glasses
              </a>
            </p>
            <p className={styles.footerLinkContainer}>
              <a
                href={orderContactUrl}
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Order Contacts
              </a>
            </p>
          </section>
        </div>
      </div>
    </Layout>
  );
};
declare let isEnabledV2: string;
export const AppointmentBookingStart: React.FC = () => {
  const history = useHistory();
  const routeParams =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const officeId = routeParams.officeId;
  if (isEnabledV2) {
    history.push(paths.v2appointmentStart(officeId, today));
    return null;
  }
  return (
    <WithOffice version={1}>
      {({ office, officeId }) => (
        <UnconnectedAppointmentBookingStart
          officeForThisAppointment={office}
          officeId={officeId}
        />
      )}
    </WithOffice>
  );
};
