import React from "react";
import styles from "./SmsCheckbox.module.scss";
import { Checkbox, CheckboxProps } from "components/Checkbox";

interface SmsCheckboxProps extends CheckboxProps {
  label: string;
  labelType?: string;
}

export const SmsCheckbox = React.forwardRef<HTMLInputElement, SmsCheckboxProps>(
  ({ label, ...inputProps }, ref) => {
    return (
      <label className={styles.label}>
        <span className={styles.checkboxContainer}>
          <Checkbox ref={ref} theme="light" {...inputProps} />
        </span>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: label }}
        ></div>
      </label>
    );
  }
);
