import React from "react";
import styles from "./GuidedTooltip.module.scss";

export const GuidedTooltip: React.FC = ({ children }) => {
  return (
    <div className={styles.tooltip} role="tooltip">
      {children}
    </div>
  );
};
