import axios from "axios";
import { buildOffice, OfficeApiData } from "./util/buildOffice";
import { Patient } from "types/patient";

export interface GetCurrentPatientResponse {
  id: string;
  first_name: string;
  last_name: string;
  home_office?: OfficeApiData;
  date_of_birth: string;
  primary_address?: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip_code: string;
  };
  gender?: string;
  preferred_language?: string;
}

export const getCurrentPatientRequest = async (): Promise<Partial<Patient>> => {
  const { data } = await axios.get<GetCurrentPatientResponse>(
    `/api/patients/current`
  );

  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    homeOffice: data.home_office && buildOffice(data.home_office),
    dateOfBirth: data.date_of_birth,
    primaryAddress: data.primary_address && {
      address1: data.primary_address.address1,
      address2: data.primary_address.address2,
      city: data.primary_address.city,
      state: data.primary_address.state,
      zipCode: data.primary_address.zip_code,
    },
    gender: data?.gender,
    preferredLanguage: data?.preferred_language,
  };
};
