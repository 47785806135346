import axios from "axios";

export const updateCommunicationPreferencesRequest = async (
  patientId: string,
  data: Record<string, boolean>
): Promise<void> => {
  const payload = Object.keys(data).map((key) => ({
    name: key,
    subscribed: data[key],
  }));

  await axios.put<void>(`/api/communication_preferences/${patientId}`, {
    communication_preferences: payload,
  });
};
