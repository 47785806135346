import React from "react";
import { Form } from "./Form";
import styles from "./EnterTwoFactorCode.module.scss";
import { Card } from "components/Card";
import { Layout } from "features/layout";
import { ErrorToast } from "features/patientDashboard/ErrorToast";

export const EnterTwoFactorCode: React.FC = () => {
  return (
    <Layout>
      <ErrorToast />
      <div className={styles.container}>
        <Card className={styles.verificationCard}>
          <Form />
        </Card>
      </div>
    </Layout>
  );
};
