import React from "react";
import cx from "classnames";
import styles from "./AllTasksCompletedFanfare.module.scss";
import celebrateIcon from "images/celebrate-icon.svg";

type AllTasksCompletedFanfareProps = {
  className?: string;
};

export const AllTasksCompletedFanfare: React.FC<
  AllTasksCompletedFanfareProps
> = ({ children, className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <img src={celebrateIcon} alt="" className={styles.icon} />
      <p className={styles.message}>{children}</p>
    </div>
  );
};
