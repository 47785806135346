import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./NearbyOfficeCard.module.scss";
import { NearbyDoctorAvailabilitySection } from "./NearbyDoctorAvailabilitySection";
import { RootState } from "app/store";
import * as paths from "features/routing/paths";
import { ButtonLinkInternal } from "components/Button";
import { OfficeAddress } from "components/OfficeAddress";
import { ExamType } from "types/examType";
import { DateString } from "types/dateString";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import {
  selectDoctorsAvailableLaterForOffice,
  selectDoctorsAvailableTodayInOffice,
  selectNextAvailableDateForOffice,
} from "features/scheduling/nearbyOfficesSlice";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";
import { Card } from "components/Card";

interface NearbyOfficeCardProps {
  office: Office;
  selectedDoctorId?: string;
}

export const NearbyOfficeCard: React.FC<NearbyOfficeCardProps> = ({
  office,
  selectedDoctorId,
}) => {
  const { examType, patientReturnStatus } = useParams<{
    examType: ExamType;
    patientReturnStatus: PatientReturnStatus;
  }>();

  const doctorsAvailableToday = useSelector((state: RootState) =>
    selectDoctorsAvailableTodayInOffice(state, office.id)
  );
  const doctorsAvailableLater = useSelector((state: RootState) =>
    selectDoctorsAvailableLaterForOffice(state, office.id)
  );
  const nextAvailableDate = useSelector((state: RootState) =>
    selectNextAvailableDateForOffice(state, office.id)
  );

  return (
    <UnconnectedNearbyOfficeCard
      office={office}
      examType={examType}
      patientReturnStatus={patientReturnStatus}
      doctorsAvailableToday={doctorsAvailableToday}
      doctorsAvailableLater={doctorsAvailableLater}
      nextAvailableDate={nextAvailableDate}
      selectedDoctorId={selectedDoctorId}
    />
  );
};

interface UnconnectedNearbyOfficeCardProps extends NearbyOfficeCardProps {
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  doctorsAvailableToday: Doctor[];
  doctorsAvailableLater: Doctor[];
  nextAvailableDate?: DateString;
}

export const UnconnectedNearbyOfficeCard: React.FC<
  UnconnectedNearbyOfficeCardProps
> = ({
  office,
  examType,
  patientReturnStatus,
  doctorsAvailableToday,
  doctorsAvailableLater,
  nextAvailableDate,
  selectedDoctorId,
}) => {
  const officePath = paths.appointmentsAvailableAppointments({
    officeId: office.id,
    examType,
    patientReturnStatus,
  });
  const { trackSchedulerEvent } = useSchedulerTracking(selectedDoctorId);

  return (
    <Card className={styles.container}>
      <div className={styles.description}>
        <b>{office.displayName}</b>
        <OfficeAddress office={office} />
      </div>
      <hr className={styles.rule} />
      <div className={styles.doctors}>
        <NearbyDoctorAvailabilitySection
          doctors={doctorsAvailableToday}
          office={office}
          examType={examType}
          patientReturnStatus={patientReturnStatus}
          selectedDoctorId={selectedDoctorId}
        />
        <NearbyDoctorAvailabilitySection
          date={nextAvailableDate}
          doctors={doctorsAvailableLater}
          office={office}
          examType={examType}
          patientReturnStatus={patientReturnStatus}
          selectedDoctorId={selectedDoctorId}
        />
      </div>
      <ButtonLinkInternal
        text="View Availability"
        to={officePath}
        className={styles.button}
        onClick={() => trackSchedulerEvent("browse-nearby-offices")}
      />
    </Card>
  );
};
