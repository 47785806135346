// Polyfill modern JS features, like promises, async functions, etc.
import "core-js/stable";
import "regenerator-runtime/runtime";
import smoothscroll from "smoothscroll-polyfill";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import { configure as configureAxios } from "app/axios";
import "index";

smoothscroll.polyfill();

require.context("../images", true);

Rails.start();
configureAxios();
