export interface AboutYou1FormData {
  address1: string;
  address2?: string;
  state: string;
  city: string;
  zipCode: string;
  raceEthnicityToggle?: string;
  race?: string;
  ethnicity?: string;
  hipaaNoticeAcknowledged?: boolean;
}

export interface AboutYou2FormData {
  preferredName?: string;
  gender?: string;
  preferredPronoun?: string;
  preferredLanguage?: string;
  accommodations?: string;
  accommodationsToggle?: string;
}

export interface AboutYouFormData {
  address1: string;
  address2?: string;
  state: string;
  city: string;
  zipCode: string;
  raceEthnicityToggle?: string;
  race?: string;
  ethnicity?: string;
  preferredName?: string;
  gender?: string;
  preferredPronoun?: string;
  preferredLanguage?: string;
  accommodations?: string;
  accommodationsToggle?: string;
}

export interface InsuranceFormData {
  insuranceCarrier: string;
  otherInsuranceName?: string;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberId: string;
  subscriberDateOfBirth: string;
  subscriberSsn: string;
  relationToPatient: RelationToPatient;
  isPrimaryPolicyHolder?: string;
  insuranceAssignmentPolicyAcknowledged?: boolean;
}

export enum RelationToPatient {
  // Ensure these correspond to relationships defined in app/models/acuity_logic/patient_insurance.rb
  Self = "Self",
  Spouse = "Spouse",
  ChildDependent = "Child / Dependent",
  ParentGuardian = "Parent / Guardian", // Equivalent to Child/Dependent
  DomesticPartner = "Domestic Partner",
  Student = "Student",
}

export enum DocumentType {
  HipaaNotice = "hipaa_notice",
  InsuranceAssignmentPolicy = "insurance_assignment_policy",
  PrescriptionAccessNotice = "prescription_access_notice",
}
