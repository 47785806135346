import React from "react";
import { compact } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import styles from "./MobileView.module.scss";
import { DoctorAccordion } from "./DoctorAccordion";
import { OnSelectSlotHandler } from "./types";
import { Waitlist } from "features/scheduling/components/Waitlist";
import {
  DoctorAvailability,
  selectDoctorIdsOrderedByAvailability,
  selectNextAvailableDatesForOffice,
} from "features/scheduling/availabilitySummarySlice";
import { isRestrictDate } from "features/scheduling/utils/availabilitySearchWindow";
import { NearbyOfficeCards } from "features/scheduling/components/NearbyOfficeCards";
import { ConnectedDoctorSlots } from "features/scheduling/components/ConnectedDoctorSlots";
import { OfficeContent } from "features/scheduling/components/OfficeContent";
import { DateInput, Hook } from "components/DateInput";
import { SectionDivider } from "components/SectionDivider";
import { Doctor } from "types/doctor";
import { ExamType } from "types/examType";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { RootState } from "app/store";
import { selectNearbyOfficeLoadingState } from "features/scheduling/nearbyOfficesSlice";
import { Slot } from "types/slot";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";

interface MobileViewProps {
  doctors: Doctor[];
  officeId: string;
  onSelectSlot: OnSelectSlotHandler;
  examType: ExamType;
  nearbyOffices: Office[];
  patientReturnStatus: PatientReturnStatus;
  selectedOffice: Office;
  searchDate: string;
  setSearchDate: (newVal: string) => void;
}

export const MobileView: React.FC<MobileViewProps> = ({
  doctors,
  officeId,
  onSelectSlot,
  examType,
  nearbyOffices,
  patientReturnStatus,
  selectedOffice,
  searchDate,
  setSearchDate,
}) => {
  const doctorIdsByAvailability = useSelector(
    (state: RootState) =>
      selectDoctorIdsOrderedByAvailability(
        state,
        officeId,
        searchDate,
        selectedOffice.timeSlotWeeks
      ),
    shallowEqual
  );
  const doctorAvailbilities = useSelector(
    (state: RootState) =>
      selectNextAvailableDatesForOffice(
        state,
        officeId,
        searchDate,
        selectedOffice.timeSlotWeeks
      ),
    shallowEqual
  );
  const { trackSchedulerEvent } = useSchedulerTracking();
  const onDateChange: Hook = (_selectedDates, dateStr) => {
    trackSchedulerEvent("browse-dates");
    setSearchDate(dateStr);
  };
  const onSelectSlotWithAnalytics = (slot: Slot) => {
    trackSchedulerEvent("select-date", {
      dateTimeSelected: `${slot.date} ${slot.startTime}`,
    });
    onSelectSlot(slot);
  };

  const filteredDoctorSlots = (
    condition: (availability: DoctorAvailability) => boolean
  ) => {
    return compact(
      doctorIdsByAvailability.map((doctorId) => {
        const availability: any = doctorAvailbilities[doctorId];
        const doctor = doctors.find((doctor) => doctor.id === doctorId);
        return (
          doctor &&
          condition(availability) && (
            <ConnectedDoctorSlots
              key={`doctor-cards-${doctor.id}`}
              officeId={officeId}
              doctor={doctor}
              onSelectSlot={onSelectSlotWithAnalytics}
              forDate={searchDate}
              examType={examType}
              patientReturnStatus={patientReturnStatus}
              office={selectedOffice}
            />
          )
        );
      })
    );
  };

  const isNearByOfficesLoading = useSelector((state: RootState) =>
    selectNearbyOfficeLoadingState(state)
  );

  const doctorsWithAvailability = filteredDoctorSlots(
    (availability) => availability !== null
  );
  const doctorsWithoutAvailability = filteredDoctorSlots(
    (availability) => availability === null
  );

  return (
    <div className={styles.mobileView}>
      <div className={styles.dateContainer}>
        <DateInput
          name="start_date"
          onChange={onDateChange}
          value={searchDate}
          label="Start date"
          maxDate={isRestrictDate(selectedOffice.timeSlotWeeks)}
        />
      </div>

      <SectionDivider>Selected Office</SectionDivider>
      <div className={styles.officeSection}>
        <OfficeContent office={selectedOffice}>
          {doctors.length > 0 ? (
            doctorsWithAvailability
          ) : (
            <div className={styles.noAvailableDoctors}>
              Sorry there are no available doctors at this office
            </div>
          )}
          <Waitlist office={selectedOffice} />
          {doctorsWithoutAvailability.length > 0 && (
            <DoctorAccordion title="Doctors with limited availability">
              {doctorsWithoutAvailability}
            </DoctorAccordion>
          )}
        </OfficeContent>
      </div>
      <NearbyOfficeCards
        offices={nearbyOffices}
        loading={isNearByOfficesLoading}
      />
    </div>
  );
};
