import dayjs from "dayjs";
const dayList = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const getOfficeOpenSlots = (office: any) => {
  const startDate = office.startDate ? office.startDate : null; //"2022-12-20";
  const startTime = office.startTime ? office.startTime : null; //"14:00:00";
  const totalSlots = office.totalSlots ? office.totalSlots : null; //28;
  if (totalSlots && startTime && startDate) {
    // const parseStartTime = dayjs(`${startDate} ${startTime}`).format("hA");
    const parseStartDate = dayjs(startDate).format("ddd, MMM D");

    return `${totalSlots} Eye Exam Starting ${startTime} ${parseStartDate}`;
  } else {
    return "";
  }
};
export const getOfficeHours = (office: any) => {
  if (!office.hours) {
    return null;
  } else {
    const test = JSON.parse(office.hours.replaceAll("=>", ":"));
    const todayString = dayList[dayjs().get("day")];
    const todayhours = test[todayString];

    if (todayhours["isClosed"]) {
      return "Closed Today";
    }

    if (todayhours["openIntervals"] && todayhours["openIntervals"].length > 0) {
      const today = dayjs().format("MM/DD/YYYY");
      const startTime = dayjs(
        `${today} ${todayhours["openIntervals"][0]["start"]}`
      ).format("hA");
      const endTime = dayjs(
        `${today} ${todayhours["openIntervals"][0]["end"]}`
      ).format("hA");
      return `Open Today ${startTime} - ${endTime}`;
    }
    return "NaN";
  }
};
