import axios from "axios";
import dayjs from "dayjs";
import { API_DATE_FORMAT } from "./constants";

export type CreatePatientSessionArgs = {
  token: string;
  demographics?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    dateOfBirth: string;
  };
};

export const createPatientSessionRequest = async (
  params: CreatePatientSessionArgs
): Promise<void> => {
  const { token, demographics } = params;

  await axios.post("/api/patient_sessions", {
    token,
    first_name: demographics?.firstName,
    last_name: demographics?.lastName,
    phone_number: demographics?.phoneNumber.replace(/\D/g, ""),
    date_of_birth:
      demographics && dayjs(demographics.dateOfBirth).format(API_DATE_FORMAT),
  });
};
