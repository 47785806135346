import React from "react";
import cx from "classnames";
import styles from "./Fieldset.module.scss";

interface FieldsetProps {
  alwaysVertical?: boolean;
  legend: string;
  aside?: string;
  legendClassName?: string;
  fieldGroupClassName?: string;
}

export const Fieldset: React.FC<FieldsetProps> = ({
  alwaysVertical,
  legend,
  aside,
  legendClassName,
  fieldGroupClassName,
  children,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend
        className={cx(styles.legend, legendClassName, {
          [styles.alwaysVertical]: alwaysVertical,
        })}
      >
        {legend}
        {aside && (
          <>
            <br />
            <span className={cx(styles.aside)}>{aside}</span>
          </>
        )}
      </legend>

      <div className={cx(styles.fieldGroup, fieldGroupClassName)}>
        {children}
      </div>
    </fieldset>
  );
};
