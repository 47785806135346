import React from "react";
import styles from "./Message.module.scss";
import {
  Message as SnackbarMessage,
  Icon as SnackbarIcon,
} from "features/patientDashboard/hooks/useSnackbar";
import checkmark from "images/circle-check-white.svg";

const icons: Record<SnackbarIcon, string> = {
  checkmark,
};

export const Message: React.FC<{
  message: SnackbarMessage;
}> = ({ message }) => {
  return (
    <div role="alert" className={styles.message}>
      <img src={icons[message.icon]} alt="" />
      {message.text}
    </div>
  );
};
