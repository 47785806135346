import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./NavBackButton.module.scss";
import { AppointmentSelectDoctorSlotRouteProps } from "./shared";
import * as paths from "features/routing/paths";
import leftArrow from "images/left-arrow.svg";

export const NavBackButton: React.FC = ({ children }) => {
  const { officeId, examType, patientReturnStatus } =
    useParams<AppointmentSelectDoctorSlotRouteProps>();
  const history = useHistory();

  return (
    <button
      className={styles.backButton}
      type="button"
      onClick={() =>
        history.push(
          paths.appointmentsAvailableAppointments({
            officeId,
            examType,
            patientReturnStatus,
          })
        )
      }
    >
      <img className={styles.backButtonIcon} src={leftArrow} alt="back" />
      {children}
    </button>
  );
};
