import React from "react";
import { Link, LinkProps } from "react-router-dom";
import cx from "classnames";
import styles from "./TextLink.module.scss";

export const TextLink: React.FC<LinkProps> = ({
  children,
  to,
  className,
  onClick,
}) => {
  return (
    <Link className={cx(styles.link, className)} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};
