import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import cx from "classnames";
// eslint-disable-next-line no-restricted-imports
// import { MedicaidToast } from "../components/MedicaidToast";
import { isMobile } from "react-device-detect";
// eslint-disable-next-line no-restricted-imports
import { Footer } from "../components/Footer/FooterV2";
import styles from "./AppointmentBookingStartV2.module.scss";
import { WeekTimeSlots } from "./WeekTimeSlots";
import { OfficeCard } from "./OfficeCard";
import { DoctorCard } from "./DoctorCard";
import { NearbyOffice } from "./NearbyOffice";
import { MobileView } from "./MobileView";
import { AppointmentExamDetails } from "./AppointmentExamDetails";
import { AppointmentSelectReturnStatus } from "./AppointmentSelectReturnStatus";
import { AppointmentAccountSetup } from "./AppointmentAccountSetup";
import { useTrackEvent } from "hooks/useTrackEvent";
import { FooterPortal } from "components/Footer/Footer";
//import { RootState } from "app/store";
import { Modal } from "components/Modal";
import {
  setAppointmentData,
  selectAppointmentData,
  selectIsEditForm,
  selectEditSection,
} from "features/appointmentDataSlice";
import { Button, ButtonRole } from "components/Button";
import { LayoutV2 } from "features/layout";
import { API_DATE_FORMAT } from "api/constants";
import { Office } from "types/office";
import { ExamType } from "types/examType";
import { useQueryParam } from "hooks/useQueryParam";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { WithOffice } from "components/WithOffice";
import { Doctor } from "types/doctor";
import { WithOfficeDoctors } from "components/WithOfficeDoctors";
import * as paths from "features/routing/paths";
import {
  fetchNearbyOffices,
  selectNearbyOffices,
  selectNearbyOfficeLoadingState,
} from "features/scheduling/nearbyOfficesSlice";
import { fetchOfficeAvailability } from "features/scheduling/availabilitySummarySlice";
import { updateSelectedAppointment } from "features/scheduling/appointmentCreationSlice";
//import { fetchFirstAvailability } from "features/scheduling/firstAvailabilitySlice";
//import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";
import { Slot } from "types/slot";
import {
  isEmail,
  isValidTLDLength,
  acceptableCharacters,
} from "utils/emailValidation";
import { isValidDateOfBirth } from "utils/dateOfBirthValidation";
import { isValidDateOfBirthVEH } from "utils/dateOfBirthValidationVEH";
import { isValidDOBRestrictVEH } from "utils/isValidDOBRestrictVEH";
//import { isValidDateOfBirthVEH } from "utils/dateOfBirthValidationVEH";
import { isValidPhoneNumber } from "utils/phoneNumberValidation";
import successCircle from "images/success-circle-ticker.svg";
import errorCircle from "images/error-circle-cross.svg";

const aria = {
  labelledby: "appointment-exam-details-modal-label",
  describedby: "appointment-exam-details-modal-description",
};

interface UnconnectedAppointmentBookingStartProps {
  officeForThisAppointment: Office;
  officeId: string;
  doctors: Doctor[];
}

const UnconnectedAppointmentBookingStart: React.FC<
  UnconnectedAppointmentBookingStartProps
> = ({ officeForThisAppointment, officeId, doctors }) => {
  const { formattedPhone, officeUid } = officeForThisAppointment;
  const [eyeExam, setEyeExam] = React.useState<undefined | ExamType>(undefined);
  const [selectedDoctor, setSelectedDoctor] = React.useState<
    undefined | Doctor
  >(undefined);
  const [selectedSlot, setSelectedSlot] = React.useState<undefined | Slot>(
    undefined
  );
  const [vehSelectedSlot, setVehSelectedSlot] = React.useState<
    undefined | Slot
  >(undefined);
  const dummyPatientDetails = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    smsOptInNotices: false,
    smsOptInPromos: false,
  };
  const [patientDetails, setPatientDetails] =
    React.useState<any>(dummyPatientDetails);
  const [patientType, setPatientType] = React.useState<
    undefined | PatientReturnStatus
  >(undefined);
  const [vehDoctorTerms, setVehDoctorTerms] = React.useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const [searchDateOrNull, setSearchDate] = useQueryParam("date", null);
  const [executeOnce, setExecuteOnce] = React.useState(false);
  const [dataPush, setDataPush] = React.useState(false);
  const searchDate = searchDateOrNull || dayjs().format(API_DATE_FORMAT);
  const appoinmentData = useSelector(selectAppointmentData);
  const isEditForm = useSelector(selectIsEditForm);
  const editSection = useSelector(selectEditSection);
  const [nextButton, setNextButton] = React.useState(true);
  const examType = ExamType.Contacts;
  const patientReturnStatus = PatientReturnStatus.New;
  const WeekTimeSlotsRef = React.useRef<null | HTMLDivElement>(null);
  const examTypeRef = React.useRef<null | HTMLDivElement>(null);
  const patientTypeRef = React.useRef<null | HTMLDivElement>(null);
  const patientDetailsRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (doctors.length === 0) {
      return;
    }
    if (!executeOnce) {
      trackEvent("step-1-scheduler-enter", {
        location: officeId,
      });
      dispatch(
        fetchOfficeAvailability({
          officeId,
          searchDate,
          examType,
          doctorIds: doctors.map((doctor) => doctor.id),
          patientReturnStatus,
          version: 2,
        })
      );
    }
  }, [
    dispatch,
    searchDate,
    officeId,
    doctors,
    examType,
    patientReturnStatus,
    appoinmentData,
    executeOnce,
    isEditForm,
    trackEvent,
  ]);

  useEffect(() => {
    if (!executeOnce && isEditForm) {
      const appData = cloneDeep(appoinmentData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      officeForThisAppointment = appData["office"];
      setPatientDetails(appData["patientDetails"]);
      if (editSection && editSection === "patientDetails") {
        setTimeout(() => {
          patientDetailsRef?.current?.scrollIntoView();
        }, 1);
      }
      setEyeExam(appData["eyeExam"]);
      setPatientType(appData["patientType"]);
      if (editSection && editSection === "timeSlot") {
        setSelectedDoctor(undefined);
        setSelectedSlot(undefined);
      } else {
        setSelectedDoctor(appData["doctor"]);
        setSelectedSlot(appData["timeSlot"]);
      }
    }
    setExecuteOnce(true);
  }, [
    setSelectedDoctor,
    setSelectedSlot,
    setExecuteOnce,
    executeOnce,
    isEditForm,
  ]);
  /*
  useEffect(() => {
    if (doctors.length === 0) {
      return;
    }
    if (areFirstAvailableApptsByDefaultEnabled()) {
      dispatch(
        fetchFirstAvailability({
          officeId,
          examType,
          doctorIds: doctors.map((doctor) => doctor.id),
          patientReturnStatus,
        })
      );
    }
  }, [dispatch, officeId, examType, doctors, patientReturnStatus]);

  */

  // const { isLoading } = useGetNearbyOfficesWithoutAvailabilityQuery(officeId);

  const isLoading = useSelector((state: RootState) =>
    selectNearbyOfficeLoadingState(state)
  );
  const nearbyOffices = useSelector((state: RootState) =>
    selectNearbyOffices(state, officeId)
  );

  useEffect(() => {
    if (!isEditForm)
      dispatch(fetchNearbyOffices({ officeId, examType, patientReturnStatus }));
  }, [dispatch, officeId, examType, patientReturnStatus, isEditForm]);

  const onSelectionTimeSlot = (type: string, value: Slot) => {
    let doctorCache: Doctor | undefined;
    doctors.map((doctor) => {
      if (value.doctorId === doctor.id) {
        doctorCache = doctor;
        setSelectedDoctor(doctor);
      }
    });
    if (doctorCache?.offersVEH) {
      setVehDoctorTerms(true);
      setVehSelectedSlot(value);
    } else {
      setSelectedSlot(value);
      dispatch(
        updateSelectedAppointment({
          officeId,
          officeUid,
          ...value,
        })
      );
      setTimeout(() => {
        examTypeRef?.current?.scrollIntoView();
      }, 10);
      setDataPush(false);
    }
    //isFormValidation(patientDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: value,
      patientDetails: patientDetails,
    });
    trackEvent("step-2-scheduler-start", {
      location: officeId,
    });

    trackEvent("step-5-selected-appointment-time", {
      location: officeId,
      selected_type: eyeExam,
      patient_type: patientType,
      doctorId: doctorCache?.id,
    });
  };

  const onSelectionExamType = (type: string, value: ExamType) => {
    setEyeExam(value);
    /* some delay when popup close */
    setTimeout(() => {
      patientTypeRef?.current?.scrollIntoView();
      //isFormValidation(patientDetails);
      isFormValidation({
        eyeExam: value,
        patientType: patientType,
        selectedDoctor: selectedDoctor,
        selectedSlot: selectedSlot,
        patientDetails: patientDetails,
      });
      trackEvent("step-3-selected-eyewear-type", {
        location: officeId,
        selected_type: value,
        patient_type: patientType,
        doctorId: selectedDoctor?.id,
      });
    }, 10);
    setDataPush(false);
  };

  const onSelectionPatientType = (type: string, value: PatientReturnStatus) => {
    setPatientType(value);
    patientDetailsRef?.current?.scrollIntoView();
    setTimeout(() => {
      //isFormValidation(patientDetails);
      isFormValidation({
        eyeExam: eyeExam,
        patientType: value,
        selectedDoctor: selectedDoctor,
        selectedSlot: selectedSlot,
        patientDetails: patientDetails,
      });
      trackEvent("step-4-selected-patient-type", {
        location: officeId,
        selected_type: eyeExam,
        patient_type: value,
        doctorId: selectedDoctor?.id,
      });
    }, 10);
    setDataPush(false);
  };

  const onEditTimeSlot = () => {
    setSelectedDoctor(undefined);
    setSelectedSlot(undefined);
    setTimeout(() => {
      isFormValidation({
        eyeExam: eyeExam,
        patientType: patientType,
        selectedDoctor: selectedDoctor,
        selectedSlot: selectedSlot,
        patientDetails: patientDetails,
      });
      WeekTimeSlotsRef?.current?.scrollIntoView();
    }, 10);
    setDataPush(false);
  };

  const seeMoreScrollToTop = (visible: boolean) => {
    if (visible) WeekTimeSlotsRef?.current?.scrollIntoView();
  };

  const setTimeslotsExecution = () => {
    setExecuteOnce(false);
  };

  const setApptData = (data: any) => {
    if (isMobile) {
      setPatientType(data.patientType);
      setSelectedDoctor(data.doctor);
      setSelectedSlot(data.timeSlot);
      setEyeExam(data.eyeExam);
      setPatientDetails(data.patientDetails);
      dispatch(
        setAppointmentData({
          status: true,
          office: officeForThisAppointment,
          doctor: data.doctor,
          timeSlot: data.timeSlot,
          patientType: data.patientType,
          eyeExam: data.eyeExam,
          patientDetails: data.patientDetails,
          lastRequestUrl: searchDate,
        })
      );
    }
    /*
    office: officeForThisAppointment,
    doctor: selectedDoctor,
    timeSlot: selectedSlot,
    patientType: patientType,
    eyeExam: eyeExam,
    patientDetails: patientDetails,
    lastRequestUrl: searchDate,
    */
  };
  const getDateUSFormat = (brithDate: string) => {
    const dob = brithDate.split("-");
    return dob[1] + "/" + dob[2] + "/" + dob[0];
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const patientDetailsValid = (patientDetails: any) => {
    let isValid = false;
    if (
      patientDetails.firstName &&
      patientDetails.firstName.match(/^\s*[-a-zA-Z-]+\s*$/)
    ) {
      isValid = true;
    } else {
      return false;
    }
    if (
      patientDetails.lastName &&
      patientDetails.lastName.match(/^\s*[-a-zA-Z-]+\s*$/)
    ) {
      isValid = true;
    } else {
      return false;
    }
    if (
      patientDetails.email &&
      typeof isEmail(patientDetails.email) === "boolean" &&
      typeof isValidTLDLength(patientDetails.email) === "boolean" &&
      typeof acceptableCharacters(patientDetails.email) === "boolean"
    ) {
      isValid = true;
    } else {
      return false;
    }
    if (
      patientDetails.phoneNumber &&
      typeof isValidPhoneNumber(patientDetails.phoneNumber) === "boolean"
    ) {
      isValid = true;
    } else {
      return false;
    }
    if (patientDetails.dateOfBirth) {
      if (selectedDoctor?.offersVEH) {
        if (
          officeForThisAppointment.ageRestrictVEH &&
          typeof isValidDOBRestrictVEH(
            getDateUSFormat(patientDetails.dateOfBirth)
          ) === "boolean"
        ) {
          isValid = true;
        } else if (
          !officeForThisAppointment.ageRestrictVEH &&
          typeof isValidDateOfBirthVEH(
            getDateUSFormat(patientDetails.dateOfBirth)
          ) === "boolean"
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else if (
        typeof isValidDateOfBirth(
          getDateUSFormat(patientDetails.dateOfBirth)
        ) === "boolean"
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else {
      isValid = false;
    }
    return isValid;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFormValidation = (data: any) => {
    if (
      officeForThisAppointment &&
      data.patientType &&
      data.selectedSlot &&
      data.selectedDoctor &&
      data.eyeExam &&
      patientDetailsValid(data.patientDetails)
    ) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
    dispatch(
      setAppointmentData({
        status: true,
        office: officeForThisAppointment,
        doctor: data.selectedDoctor,
        timeSlot: data.selectedSlot,
        patientType: data.patientType,
        eyeExam: data.eyeExam,
        patientDetails: data.patientDetails,
        lastRequestUrl: searchDate,
      })
    );
  };

  const updatePatient = (fieldValue: any) => {
    const pDetails = cloneDeep(patientDetails);
    if (fieldValue?.field === "firstName") {
      pDetails.firstName = fieldValue?.value;
    }
    if (fieldValue?.field === "lastName") {
      pDetails.lastName = fieldValue?.value;
    }
    if (fieldValue?.field === "phoneNumber") {
      pDetails.phoneNumber = fieldValue?.value;
    }
    if (fieldValue?.field === "email") {
      pDetails.email = fieldValue?.value;
    }
    if (fieldValue?.field === "dateOfBirth") {
      pDetails.dateOfBirth = fieldValue?.value;
    }
    if (fieldValue?.field === "smsOptInNotices") {
      pDetails.smsOptInNotices = fieldValue?.value;
    }
    if (fieldValue?.field === "smsOptInPromos") {
      pDetails.smsOptInPromos = fieldValue?.value;
    }
    setPatientDetails(pDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: pDetails,
    });
  };

  React.useEffect(() => {
    if (!dataPush) {
      if (
        selectedSlot &&
        selectedDoctor &&
        eyeExam &&
        patientType &&
        patientDetailsValid(patientDetails)
      ) {
        setNextButton(false);
        setDataPush(true);
        dispatch(
          setAppointmentData({
            status: true,
            office: officeForThisAppointment,
            doctor: selectedDoctor,
            timeSlot: selectedSlot,
            patientType: patientType,
            eyeExam: eyeExam,
            patientDetails: patientDetails,
            lastRequestUrl: searchDate,
          })
        );
      } else {
        setNextButton(true);
        setDataPush(false);
      }
    }
  }, [
    eyeExam,
    officeForThisAppointment,
    patientType,
    selectedDoctor,
    selectedSlot,
    patientDetails,
    dispatch,
    searchDate,
    patientDetailsValid,
    dataPush,
    examType,
  ]);

  const moveToNext = () => {
    history.push(paths.v2appointmentReview(officeId));
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <LayoutV2 office={officeForThisAppointment}>
      <div className={styles.container}>
        <Modal
          className={styles.vehModal}
          aria={aria}
          isOpen={vehDoctorTerms}
          closeOnOverlayClick={false}
          hideClose={true}
        >
          <div>
            Because the doctor performing this exam will be remote, you are
            confirming that you meet the following qualifications by checking
            the box below:
            <ul>
              {selectedDoctor &&
                selectedDoctor.offersVEH &&
                !officeForThisAppointment.ageRestrictVEH && (
                  <li>I am at least 10 years old.</li>
                )}
              {selectedDoctor &&
                selectedDoctor.offersVEH &&
                officeForThisAppointment.ageRestrictVEH && (
                  <li>I am between the ages of 18-55.</li>
                )}
              <li>
                I understand that certain patients MAY require additional
                follow-up care for treatment of conditions identified within the
                visit, and that I will follow the doctor’s recommendation if
                needed{" "}
              </li>
              <li>
                This exam requires a retinal image to help assess the health of
                the eye. The additional fee for retinal imaging is $44 and is
                generally not covered by insurance.
              </li>
            </ul>
            <input
              type="checkbox"
              name="teleoptometry"
              id="teleoptometry"
              checked={checked}
              onChange={handleChange}
            />
            <label htmlFor="teleoptometry">
              I meet all the above requirements. I am ready to schedule my
              video-assisted eye health exam.
            </label>
          </div>
          <div className={styles.buttons}>
            <Button
              text="Cancel"
              size="large"
              role={ButtonRole.Ghost}
              onClick={() => {
                setVehDoctorTerms(false);
                setSelectedDoctor(undefined);
                setSelectedSlot(undefined);
                setVehSelectedSlot(undefined);
                setChecked(false);
              }}
              className={styles.button}
            />
            <Button
              text="Next"
              size="large"
              disabled={!checked}
              onClick={() => {
                setVehDoctorTerms(false);
                setSelectedSlot(vehSelectedSlot);
                setChecked(false);
                dispatch(
                  updateSelectedAppointment({
                    officeId,
                    officeUid,
                    ...vehSelectedSlot,
                  })
                );
                setTimeout(() => {
                  examTypeRef?.current?.scrollIntoView();
                }, 10);
              }}
              className={styles.button}
            />
          </div>
          <div className={styles.vehTerms}>
            *Clicking Cancel will discard your current choice and allow you to
            choose another doctor{" "}
          </div>
        </Modal>
        <div ref={WeekTimeSlotsRef} className={styles.firstContainer}>
          <WeekTimeSlots
            key={"weektime1"}
            office={officeForThisAppointment}
            doctors={doctors}
            searchDate={searchDate}
            onSelection={onSelectionTimeSlot}
            seeMoreScrollToTop={seeMoreScrollToTop}
            selectedSlot={selectedSlot}
            isEditForm={isEditForm}
            setTimeslotsExecution={setTimeslotsExecution}
          />
          <div className={styles.rightContainer}>
            <OfficeCard
              office={officeForThisAppointment}
              selectedSlot={selectedSlot}
              isEditForm={isEditForm}
            />
            {selectedDoctor && selectedSlot && (
              <DoctorCard
                doctor={selectedDoctor}
                selectedOffice={officeForThisAppointment}
                slot={selectedSlot}
                onClick={onEditTimeSlot}
                isEditForm={isEditForm}
              />
            )}
            {selectedSlot === undefined && (
              <NearbyOffice
                nearbyOffices={nearbyOffices}
                onClick={onEditTimeSlot}
                patientReturnStatus={patientReturnStatus}
                examType={examType}
                isEditForm={false}
                isMobile={false}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
        <div ref={examTypeRef}>
          <AppointmentExamDetails
            office={officeForThisAppointment}
            eyeExam={eyeExam}
            onSelection={onSelectionExamType}
          />
        </div>
        <div ref={patientTypeRef}>
          <AppointmentSelectReturnStatus
            patientType={patientType}
            onSelection={onSelectionPatientType}
          />
        </div>
        <div ref={patientDetailsRef}>
          <AppointmentAccountSetup
            office={officeForThisAppointment}
            doctor={selectedDoctor}
            patient={patientDetails}
            updatePatient={updatePatient}
            isMobileView={false}
          />
          <div className={styles.buttonWrapper}>
            <div className={styles.container}>
              <div
                className={cx(
                  [styles.tooltipContainer, styles.tooltipPositionTop],
                  {
                    [styles.tooltipVisible]: false,
                  }
                )}
              >
                <div className={styles.contentContainer}>
                  <div className={styles.content}>
                    {selectedDoctor && (
                      <div className={styles.classWhite}>
                        <img
                          className={styles.iconCircle}
                          src={successCircle}
                          alt="success"
                        />{" "}
                        Time Slot selected{" "}
                      </div>
                    )}
                    {!selectedDoctor && (
                      <div className={styles.classRed}>
                        <img
                          className={styles.iconCircle}
                          src={errorCircle}
                          alt="Error"
                        />{" "}
                        Time Slot not selected
                      </div>
                    )}
                    {eyeExam && (
                      <div className={styles.classWhite}>
                        <img
                          className={styles.iconCircle}
                          src={successCircle}
                          alt="success"
                        />{" "}
                        Eye Exam selected{" "}
                      </div>
                    )}
                    {!eyeExam && (
                      <div className={styles.classRed}>
                        <img
                          className={styles.iconCircle}
                          src={errorCircle}
                          alt="Error"
                        />{" "}
                        Eye Exam not selected
                      </div>
                    )}
                    {patientType && (
                      <div className={styles.classWhite}>
                        <img
                          className={styles.iconCircle}
                          src={successCircle}
                          alt="success"
                        />{" "}
                        Patient Type selected{" "}
                      </div>
                    )}
                    {!patientType && (
                      <div className={styles.classRed}>
                        <img
                          className={styles.iconCircle}
                          src={errorCircle}
                          alt="Error"
                        />{" "}
                        Patient Type not selected
                      </div>
                    )}
                    {patientDetailsValid(patientDetails) && (
                      <div className={styles.classWhite}>
                        <img
                          className={styles.iconCircle}
                          src={successCircle}
                          alt="success"
                        />{" "}
                        Patient Details updated
                      </div>
                    )}
                    {!patientDetailsValid(patientDetails) && (
                      <div className={styles.classRed}>
                        <img
                          className={styles.iconCircle}
                          src={errorCircle}
                          alt="Error"
                        />{" "}
                        Patient Details not updated
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <Button
                  className={styles.footerNextBtn}
                  text="REVIEW DETAILS"
                  onClick={moveToNext}
                  role={ButtonRole.Standard}
                  disabled={nextButton}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer formattedPhone={formattedPhone} />
        <FooterPortal />
      </div>
      <MobileView
        doctors={doctors}
        officeId={officeId}
        examType={examType}
        nearbyOffices={nearbyOffices}
        patientReturnStatus={patientReturnStatus}
        selectedOffice={officeForThisAppointment}
        searchDate={searchDate}
        setSearchDate={setSearchDate}
        setTimeslotsExecution={setTimeslotsExecution}
        setApptData={setApptData}
        patientDetailsValid={patientDetailsValid}
      />
    </LayoutV2>
  );
};

export const AppointmentBookingStartV2: React.FC = () => {
  return (
    <WithOffice version={1}>
      {({ office, officeId }) => (
        <WithOfficeDoctors officeId={officeId}>
          {({ doctors }) => (
            <UnconnectedAppointmentBookingStart
              officeForThisAppointment={office}
              officeId={officeId}
              doctors={doctors}
            />
          )}
        </WithOfficeDoctors>
      )}
    </WithOffice>
  );
};
