import React from "react";
import cx from "classnames";
import styles from "./Home.module.scss";
import { ButtonLinkInternal } from "components/Button";
import { checkInAboutYou1, reviewYourAddress } from "features/routing/paths";

interface StartCheckinProps {
  returningPatient: boolean;
}

export const StartCheckIn: React.FC<StartCheckinProps> = ({
  returningPatient,
}) => {
  return (
    <div className={styles.currentAction}>
      <header className={styles.currentActionHeader}>
        <h2 className={styles.preheading}>Save time in the office,</h2>
        <h1 className={styles.heading}>Finish your paperwork early</h1>
        <aside className={cx(styles.aside, styles.asideStopwatch)}>
          Takes less than 5 mins
        </aside>
      </header>

      <div className={styles.action}>
        <ButtonLinkInternal
          text="Start Check-in"
          size="large"
          to={returningPatient ? reviewYourAddress() : checkInAboutYou1()}
        />
      </div>
    </div>
  );
};
