import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import styles from "./AppointmentReview.module.scss";
import { LabeledField } from "./LabeledField";
import { LoadingToast } from "./LoadingToast";
import {
  RescheduleAppointmentModal,
  rescheduleAppointmentModalAria,
} from "./RescheduleAppointmentModal";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { parseStartTimeWithZone } from "features/appointmentTime";
import {
  createAppointment,
  selectPatient,
  selectAppointmentBookedStatus,
  selectCreatedAppointment,
  CreateAppointmentResult,
} from "features/scheduling/appointmentCreationSlice";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import * as paths from "features/routing/paths";
import { Button, ButtonRole } from "components/Button";
import { Layout } from "features/layout";
import { AppDispatch } from "app/store";
import calendarIcon from "images/calendar.svg";
import pinIcon from "images/pin.svg";
import { LegacyCard } from "components/LegacyCard";
import { OfficeAddress } from "components/OfficeAddress";
import { WithOffice } from "components/WithOffice";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Office } from "types/office";
import { Doctor } from "types/doctor";
import { SelectedAppointment } from "types/selectedAppointment";
import { doctorFullName } from "utils/doctorFullName";
import { CreateAppointmentArgs } from "api/createAppointmentRequest";
import { Modal } from "components/Modal";
import { Navbar } from "components/Navbar";
import { useTrackEvent } from "hooks/useTrackEvent";

interface AppointmentReviewPathParams {
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

interface AppointmentReviewProps {
  doctor: Doctor;
  office: Office;
  selectedAppointment: SelectedAppointment;
}

const UnconnectedAppointmentReview: React.FC<AppointmentReviewProps> = ({
  doctor,
  office,
  selectedAppointment,
}) => {
  const [submitLocked, setSubmitLocked] = React.useState(false);
  const patient = useSelector(selectPatient);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const routeParams = useParams<AppointmentReviewPathParams>();
  const { officeId, patientReturnStatus, examType } = routeParams;
  usePageViewTracking("step-7-account-created", { doctorId: doctor.id });
  const { trackEvent } = useTrackEvent();

  const time = parseStartTimeWithZone(selectedAppointment);
  const appointmentBookedStatus = useSelector(selectAppointmentBookedStatus);
  const { doctorId, date, startTime, endTime } = selectedAppointment;
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const createdAppointment = useSelector(selectCreatedAppointment);
  useEffect(() => {
    switch (appointmentBookedStatus) {
      case CreateAppointmentResult.Created:
        if (createdAppointment?.isNewPatient == 'yes') {
          history.push(paths.appointmentsSuccessNew(routeParams));
        } else {
          history.push(paths.appointmentsSuccess(routeParams));
        } 
        break;
      case CreateAppointmentResult.SlotTaken:
        history.push(paths.appointmentsSlotTaken(routeParams));
        break;
      case CreateAppointmentResult.Failed:
        history.push(paths.appointmentsFailure(routeParams));
        break;
      case CreateAppointmentResult.DuplicateAppointment:
        setSubmitLocked(false);
        setShowRescheduleModal(true);
        break;
    }
  }, [history, appointmentBookedStatus, routeParams, setShowRescheduleModal, createdAppointment]);

  if (!patient) {
    return (
      <Redirect
        to={paths.appointmentsAccountSetup({
          officeId,
          examType,
          patientReturnStatus,
        })}
      />
    );
  }

  const scheduleAppointment = (overrides?: Partial<CreateAppointmentArgs>) => {
    setSubmitLocked(true);

    dispatch(
      createAppointment({
        officeId,
        patient,
        doctorId,
        date,
        startTime,
        endTime,
        examType,
        patientReturnStatus: patientReturnStatus as PatientReturnStatus,
        ...overrides,
      })
    );
  };

  return (
    <Layout navbar={<Navbar office={office} />}>
      <LoadingToast isLoading={submitLocked} />
      <Modal
        aria={rescheduleAppointmentModalAria}
        isOpen={showRescheduleModal}
        onRequestClose={() => setShowRescheduleModal(false)}
        closeOnOverlayClick={false}
        className={styles.modal}
      >
        <RescheduleAppointmentModal
          setShowRescheduleModal={setShowRescheduleModal}
          submitLocked={submitLocked}
          scheduleAppointment={scheduleAppointment}
        />
      </Modal>
      <LegacyCard theme="desktopOnly" className={styles.card}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.header}>Review Appointment Details</h1>

            <LabeledField
              className={cx(styles.fieldsContainer, styles.hasDivider)}
              label="Date and Time"
              icon={{
                src: calendarIcon,
                alt: "Date and Time",
              }}
            >
              {time.format("h:mm a z, ddd, MMM D, YYYY")}
            </LabeledField>

            <LabeledField
              className={cx(styles.fieldsContainer, styles.hasDivider)}
              label="Location"
              icon={{
                src: pinIcon,
                alt: "Location",
              }}
            >
              <OfficeAddress
                office={office}
                className={styles.address}
                desktopInline
              />
              <div>with {doctorFullName(doctor)}</div>
            </LabeledField>

            <div
              id="apt-reviewPersonalDetails"
              className={cx(styles.personalDetails, styles.fieldsContainer)}
            >
              <LabeledField label="Name">
                {patient.firstName} {patient.lastName}
              </LabeledField>
              <LabeledField label="Phone Number">
                ({patient.phoneNumber?.slice(0, 3)}){" "}
                {patient.phoneNumber?.slice(3, 6)}
                {"-"}
                {patient.phoneNumber?.slice(6, 10)}
              </LabeledField>
              <LabeledField label="Date of Birth">
                {dayjs(patient.dateOfBirth).format("M/D/YYYY")}
              </LabeledField>
              <LabeledField label="Email Address">{patient.email}</LabeledField>
            </div>
            { !office.supportsMedicaid && 
              <div className={styles.medicaid}>
                This office does not accept Medicaid coverage. If you wish to use Medicaid <br /> 
                for your visit please <a href="https://locations.myeyedr.com/"> select another MyEyeDr. location </a>
              </div>
            }
          </div>

          <div className={styles.navOptions}>
            <Button
              text="Schedule"
              onClick={() => {
                trackEvent("step-8-patient-selects-schedule-appointment", {
                  patientReturnStatus: patientReturnStatus,
                });
                scheduleAppointment();
              }}
              disabled={submitLocked}
              loading={submitLocked}
              size="large"
              className={styles.button}
              autoFocus
            />

            <Button
              role={ButtonRole.Ghost}
              text="Back"
              className={styles.button}
              disabled={submitLocked}
              size="large"
              onClick={() => history.goBack()}
            />
          </div>
        </div>
      </LegacyCard>
    </Layout>
  );
};

export const AppointmentReview: React.FC = () => (
  <NeedsSelectedAppointment>
    {({ selectedAppointment, doctor }) => (
      <WithOffice>
        {({ office }) => (
          <UnconnectedAppointmentReview
            selectedAppointment={selectedAppointment}
            office={office}
            doctor={doctor}
          />
        )}
      </WithOffice>
    )}
  </NeedsSelectedAppointment>
);
