import { isEmpty } from "lodash";
import React from "react";
import cx from "classnames";
import { FieldValues } from "react-hook-form";
import Creatable from "react-select/creatable";
import { components } from "react-select";
import styles from "./Typeahead.module.scss";
import { ReactComponent as ChevronIcon } from "images/chevron-down.svg";
import { ReactComponent as CircleX } from "images/circle-x.svg";

type Option = { value: string; label: string };
export interface ComponentProps {
  options: Array<Option>;
  label: string;
  placeholder?: string;
  name: string;
  field: FieldValues;
  loading?: boolean;
  errorMessage?: string;
  creatable: boolean;
  clearable: boolean;
  className?: string;
  errorClassName?: string;
  isClear?: boolean;
}

const DropdownIndicator = (
  props: React.ComponentProps<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronIcon
        className={cx(
          styles.dropdownIndicator,
          props.selectProps.menuIsOpen && styles.dropdownIndicatorOpen
        )}
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (
  props: React.ComponentProps<typeof components.ClearIndicator>
) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref} aria-label={"clear"}>
      <CircleX className={styles.clearIndicator} />
    </div>
  );
};

export const Typeahead: React.FC<ComponentProps> = ({
  options,
  label,
  placeholder,
  name,
  field,
  loading,
  errorMessage,
  creatable,
  clearable,
  className,
  errorClassName,
  isClear,
}) => {
  // If the user has selected something, either find it in the current options, or create
  // an option with their inputted value
  const value = isEmpty(field.value)
    ? undefined
    : options.find((option) => option.value === field.value) || {
        label: field.value,
        value: field.value,
      };
  const selectRef = React.useRef<any>(null);

  if (isEmpty(field.value) && isClear && selectRef?.current) {
    selectRef?.current?.clearValue();
  }
  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Creatable
        {...field}
        ref={selectRef}
        className={cx(styles.reactSelect, {
          [styles.invalid]: errorMessage !== undefined,
        })}
        classNamePrefix="typeahead"
        onChange={(option: Option | null) =>
          field.onChange(option?.value || "")
        }
        value={value}
        placeholder={placeholder}
        options={options}
        name={name}
        aria-label={label}
        isLoading={loading}
        isClearable={clearable}
        formatCreateLabel={(option) => `Add "${option}"`}
        components={{ DropdownIndicator, ClearIndicator }}
        isValidNewOption={creatable ? undefined : () => false}
        noOptionsMessage={() => null}
        blurInputOnSelect={false} // allows onChange to be called before onBlur, for more expected validation behavior on mobile
      />
      <p className={cx(styles.errorMessage, errorClassName)}>
        {errorMessage ? `Error: ${errorMessage}` : <>&nbsp;</>}
      </p>
    </div>
  );
};
