import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { throttle } from "lodash";
import styles from "./DoctorSelectionPanel.module.scss";
import { OfficeInfo } from "./OfficeInfo";
import { DoctorButton } from "./DoctorButton";
import { AvailabilityForDoctor } from "./AvailabilityForDoctor";
import {
  selectFirstAvailableDatesForAllDoctorsInOffice,
  selectFirstAvailablityLoadedForOffice,
} from "features/scheduling/firstAvailabilitySlice";
import { isNextAvailableToday } from "features/scheduling/utils/availabilitySearchWindow";
import { useCarousel } from "features/scheduling/components/useCarousel";
import { selectNextAvailableDatesForOffice } from "features/scheduling/availabilitySummarySlice";
import { selectUpcomingTimeslotsForOfficeByDate } from "features/scheduling/timeSlotsSlice";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { RootState } from "app/store";
import chevronLeftIcon from "images/chevron-left.svg";
import chevronRightIcon from "images/chevron-right.svg";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";

interface DoctorSelectionPanelProps {
  selectedDoctorId: string;
  doctors: Doctor[];
  selectedOffice: Office;
  onSelect: (doctor: Doctor) => void;
  forDate: string;
}

// TODO: scrollAmount can be set to 1 and still successfully work on any browser _except_ Safari
const scrollAmount = 150;

const DoctorSlots: React.FC<DoctorSelectionPanelProps> = ({
  doctors,
  selectedDoctorId,
  selectedOffice,
  onSelect,
  forDate,
}) => {
  const slotsByDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForOfficeByDate(state, selectedOffice.id)
  );

  const availabilityByDoctor = useSelector((state: RootState) =>
    selectNextAvailableDatesForOffice(
      state,
      selectedOffice.id,
      forDate,
      selectedOffice.timeSlotWeeks
    )
  );

  const firstAvailability = useSelector((state: RootState) =>
    selectFirstAvailableDatesForAllDoctorsInOffice(state, selectedOffice.id)
  );
  const firstAvailabilityLoaded = useSelector((state: RootState) =>
    selectFirstAvailablityLoadedForOffice(state, selectedOffice.id)
  );

  return (
    <div className={styles.doctors}>
      {doctors.map((doctor, i) => {
        let availability;
        if (areFirstAvailableApptsByDefaultEnabled()) {
          availability = firstAvailabilityLoaded
            ? firstAvailability[doctor.id]
            : "loading";
        } else {
          availability = availabilityByDoctor[doctor.id];
          if (availability !== "loading" && availability !== null) {
            availability = isNextAvailableToday(
              availability,
              selectedOffice.timeSlotWeeks
            )
              ? availabilityByDoctor[doctor.id]
              : null;
          }
        }

        if (
          doctor.offersVEH &&
          doctor.firstName === "Open Network" &&
          !availability
        ) {
          return null;
        }

        return (
          <DoctorButton
            key={`doctor_${i}`}
            doctor={doctor}
            selectedOffice={selectedOffice}
            selected={selectedDoctorId === doctor.id}
            onClick={() => onSelect(doctor)}
            loaded={
              areFirstAvailableApptsByDefaultEnabled()
                ? firstAvailabilityLoaded
                : availability !== "loading"
            }
            availabilityInfo={
              <AvailabilityForDoctor
                doctor={doctor}
                office={selectedOffice}
                forDate={forDate}
                slots={slotsByDoctor[doctor.id]}
                availability={availability}
              />
            }
          />
        );
      })}
    </div>
  );
};

export const DoctorSelectionPanel: React.FC<DoctorSelectionPanelProps> = ({
  selectedDoctorId,
  doctors,
  onSelect,
  selectedOffice,
  forDate,
}) => {
  const { carouselRef, scroll, previousEnabled, nextEnabled } =
    useCarousel<HTMLDivElement>();
  const { trackSchedulerEvent } = useSchedulerTracking(selectedDoctorId);

  const scrollCallback = throttle(
    () => {
      if (!carouselRef.current) {
        return;
      }

      trackSchedulerEvent("browse-doctor");
    },
    500,
    { leading: true, trailing: false }
  );

  React.useEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    // we need to capture this so it persists even after the ref
    // is unmounted
    const carouselRefCurrent = carouselRef.current;
    carouselRefCurrent.addEventListener("scroll", scrollCallback);

    return () => {
      if (carouselRefCurrent) {
        carouselRefCurrent.removeEventListener("scroll", scrollCallback);
      }
    };
  }, [carouselRef, scrollCallback]);

  return (
    <div className={styles.doctorPanel}>
      <OfficeInfo selectedOffice={selectedOffice} />
      <button
        className={cx(styles.nav, { [styles.navDisabled]: !previousEnabled })}
        disabled={!previousEnabled}
        onClick={() => {
          scroll(-scrollAmount);
        }}
      >
        <div className={styles.button}>
          <img
            src={chevronLeftIcon}
            alt="View previous doctors"
            className={styles.buttonLeft}
          />
        </div>
      </button>
      <div
        className={styles.carousel}
        ref={carouselRef}
        data-testid="doctor-carousel"
      >
        {doctors.length > 0 ? (
          <DoctorSlots
            doctors={doctors}
            selectedDoctorId={selectedDoctorId}
            onSelect={onSelect}
            forDate={forDate}
            selectedOffice={selectedOffice}
          />
        ) : (
          <div className={styles.noAvailableDoctors}>
            <p>Sorry there are no available doctors at this office</p>
          </div>
        )}
      </div>
      <button
        className={cx(styles.nav, { [styles.navDisabled]: !nextEnabled })}
        disabled={!nextEnabled}
        onClick={() => {
          scroll(scrollAmount);
        }}
      >
        <div className={styles.button}>
          <img
            src={chevronRightIcon}
            className={styles.buttonRight}
            alt="View next doctors"
          />
        </div>
      </button>
    </div>
  );
};
