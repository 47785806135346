import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { api } from "./services/api";
import { reducer as appointmentCreationReducer } from "features/scheduling/appointmentCreationSlice";
import { reducer as availabilitySummaryReducer } from "features/scheduling/availabilitySummarySlice";
import { reducer as firstAvailabilityReducer } from "features/scheduling/firstAvailabilitySlice";
import { reducer as nearbyOfficesReducer } from "features/scheduling/nearbyOfficesSlice";
import { reducer as officesReducer } from "features/officesSlice";
import { reducer as patientDashboardReducer } from "features/patientDashboard/patientDashboardSlice";
import { reducer as timeSlotsReducer } from "features/scheduling/timeSlotsSlice";
import { reducer as waitListReducer } from "features/scheduling/waitListSlice";
import { reducer as insuranceDataReducer } from "features/insuranceDataSlice";
import { reducer as appointmentDataReducer } from "features/appointmentDataSlice";
import { reducer as brazePatientDetailReducer } from "features/scheduling/brazePatientDetailSlice";

export const reducer = {
  appointmentCreation: appointmentCreationReducer,
  availabilitySummary: availabilitySummaryReducer,
  firstAvailability: firstAvailabilityReducer,
  nearbyOffices: nearbyOfficesReducer,
  offices: officesReducer,
  patientDashboard: patientDashboardReducer,
  timeSlots: timeSlotsReducer,
  waitList: waitListReducer,
  insuranceData: insuranceDataReducer,
  appointmentData: appointmentDataReducer,
  brazePatientDetail: brazePatientDetailReducer,
  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (gDM) => gDM().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
