import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./TextArea.module.scss";

interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  children?: ReactNode;
  className?: string;
  afterInput?: React.ReactNode;
  hasError?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ children, className, afterInput, hasError, ...textAreaProps }, ref) => {
    return (
      <div className={cx(styles.container, className)}>
        <label className={styles.label}>
          {children}

          <div className={styles.textAreaContainer}>
            <textarea
              ref={ref}
              className={
                hasError ? cx(styles.textArea, styles.error) : styles.textArea
              }
              {...textAreaProps}
            />
            {afterInput}
          </div>
        </label>
        {hasError && (
          <p className={cx(styles.errorMessage)}>Error: Please enter text</p>
        )}
      </div>
    );
  }
);
