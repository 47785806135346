export function generateRouteParams<Param extends string>(
  ...params: Param[]
): Record<Param, string> {
  return params.reduce(
    (accum, param) => ({
      ...accum,
      [param]: `:${param}`,
    }),
    {} as Record<Param, string>
  );
}
