import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import styles from "./ButtonLink.module.scss";
import { ButtonRole } from "components/Button/index";
import { useButtonTemplate } from "components/Button/useButtonTemplate";
import { CommonButtonProps } from "components/Button/types";

interface ButtonLinkProps extends CommonButtonProps {
  to: string;
}

const ButtonLinkInternal: React.FC<ButtonLinkProps> = ({
  text,
  role = ButtonRole.Standard,
  to,
  className,
  onClick,
  size = "standard",
  loading = false,
  renderAsDisabled = false,
}) => {
  const [inner, classNames] = useButtonTemplate({
    role,
    size,
    loading,
    text,
    renderAsDisabled,
  });

  return (
    <Link
      to={to}
      className={cx(className, classNames, styles.link)}
      onClick={onClick}
    >
      {inner}
    </Link>
  );
};

export { ButtonLinkInternal };
