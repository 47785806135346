import axios from "axios";
import type { DocumentType } from "features/checkIn/types";

interface DocumentAcknowledgmentParams {
  documentType: DocumentType;
  documentVersion: string;
}

export const acknowledgeDocument = async (
  params: DocumentAcknowledgmentParams
): Promise<void> => {
  await axios.post(`/api/checkins/document_acknowledgments`, {
    document_type: params.documentType,
    document_version: params.documentVersion,
  });
};
