import React from "react";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import dayjs from "dayjs";
import { RadioButtonGroup, RadioButton } from "components/RadioButton";
import {
  MaskedDateInput,
  MaskedPhoneInput,
} from "components/MaskedTextInput/MaskedTextInput";
import { TextInput } from "components/TextInput";
import styles from "features/componentCatalog/ComponentCatalog.module.scss";
import { LegacyCard } from "components/LegacyCard";
import { Button } from "components/Button";
import { Typeahead } from "components/Typeahead";
import { TextArea } from "components/TextArea";
import { PasswordInput } from "components/PasswordInput";
import { presenceValidation } from "utils/presenceValidation";

export interface FormData {
  text: string;
  radio: string;
  date: string;
  phone: string;
  select: string;
  typeahead: string;
  createableTypeahead: string;
  textArea: string;
  readOnly: string;
  passwordUnique: string; // prevent autofill
}

export const FormDemo: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormData>({
    mode: "onBlur",
  });
  const onSubmit: SubmitHandler<FormData> = (data) =>
    console.log("Submitted: ", data);
  const onError: SubmitErrorHandler<FormData> = (errors) =>
    console.log("Errors: ", errors);

  const text = register(
    "text",
    presenceValidation("Required… enter some text!")
  );
  const date = register("date", {
    validate: (value) => {
      return (
        (dayjs().diff(value, "year") < 1 && dayjs().isAfter(value)) ||
        "Must be within one year ago and today"
      );
    },
  });
  const phone = register("phone", {
    validate: (value) => {
      const strippedNumber = value.replace(/[^\d]/g, "");
      return (
        (strippedNumber.length === 10 &&
          !strippedNumber.match(/(\d)\1{9}/) &&
          !strippedNumber.match(/^[01]/)) ||
        "Not a valid phone number"
      );
    },
  });
  const textArea = register("textArea", {
    required: "Required... enter some text!",
  });
  const readOnlyText = register("readOnly");
  const password = register("passwordUnique");

  return (
    <LegacyCard className={styles.paddedCard}>
      <h1>Demo Form</h1>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <TextInput
          {...text}
          inputType="text"
          onChange={(e) => {
            text.onChange(e);
          }}
          errorMessage={errors.text?.message}
          placeholder="Enter something"
        >
          Text field (required)
        </TextInput>

        <RadioButtonGroup errorMessage={errors.radio?.message}>
          <RadioButton
            {...register("radio", {
              required: "Required… pick one!",
            })}
            hasError={!!errors.radio}
            value="yes"
          >
            Yes
          </RadioButton>
          <RadioButton
            {...register("radio", {
              required: "Required… pick one!",
            })}
            hasError={!!errors.radio}
            value="no"
          >
            No
          </RadioButton>
        </RadioButtonGroup>

        <MaskedDateInput
          {...date}
          onChange={(e) => {
            date.onChange(e);
          }}
          setValue={setValue}
          name={"date"}
          errorMessage={errors.date?.message}
        >
          Date Field
        </MaskedDateInput>
        <MaskedPhoneInput
          {...phone}
          onChange={(e) => {
            date.onChange(e);
          }}
          name={"phone"}
          setValue={setValue}
          errorMessage={errors.phone?.message}
        >
          Phone Field
        </MaskedPhoneInput>
        <TextArea {...textArea}>Text Area</TextArea>
        <Controller
          rules={{ required: "Please select an option" }}
          render={({ field }) => (
            <Typeahead
              field={field}
              name="typeahead"
              label="Select field (Can't Create New Options)"
              placeholder="Select something"
              creatable={false}
              options={[
                { label: "Obtuse", value: "Obtuse" },
                { label: "Rubber Goose", value: "Rubber Goose" },
                { label: "Green Moose", value: "Green Moose" },
                { label: "Guava Juice", value: "Guava Juice" },
                { label: "Giant Snake", value: "Giant Snake" },
                { label: "Birthday Cake", value: "Birthday Cake" },
                { label: "Large Fries", value: "Large Fries" },
                { label: "Chocolate Shake", value: "Chocolate Shake" },
              ]}
              clearable={false}
              errorMessage={errors.typeahead?.message}
            />
          )}
          control={control}
          name="typeahead"
        />
        <Controller
          rules={{ required: "Please select an option" }}
          render={({ field }) => (
            <Typeahead
              field={field}
              name="createableTypeahead"
              label="Select field"
              placeholder="Select something"
              creatable={true}
              clearable={true}
              options={[
                { label: "Chocolate", value: "chocolate" },
                { label: "Vanilla", value: "vanilla" },
                { label: "Cookies and Cream", value: "cookies-and-cream" },
                { label: "Neopolitan", value: "Neopolitan" },
                { label: "Strawberry", value: "Strawberry" },
                { label: "Caramel", value: "Caramel" },
                { label: "Fudge", value: "Fudge" },
                { label: "Salt", value: "Salt" },
              ]}
              errorMessage={errors.createableTypeahead?.message}
            />
          )}
          defaultValue="Salt"
          control={control}
          name="createableTypeahead"
        />
        <TextInput
          {...readOnlyText}
          readOnly={true}
          inputType="text"
          defaultValue="Stone"
        ></TextInput>
        <PasswordInput {...password}>Password</PasswordInput>
        <div>
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            text="console.log()"
            size="large"
          />
        </div>
      </form>
    </LegacyCard>
  );
};
