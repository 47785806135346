import React, { useEffect, useState } from "react";
import { DefaultToast, defaultToastIcons } from "components/Toast/DefaultToast";

interface LoadingToastProps {
  isLoading: boolean;
}

export const LoadingToast: React.FC<LoadingToastProps> = ({ isLoading }) => {
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isLoading) {
      timeoutId = setTimeout(() => setVisible(true), 5000);
    } else {
      setVisible(false);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [isLoading]);

  return (
    <DefaultToast
      visible={isVisible}
      sideIcon={<defaultToastIcons.Reload />}
      onDismiss={() => setVisible(false)}
    >
      <p>Hold tight while we complete your request.</p>
    </DefaultToast>
  );
};
