import axios from "axios";
import { AboutYou1FormData, AboutYou2FormData } from "features/checkIn/types";

type AboutYouParams = AboutYou1FormData & AboutYou2FormData;
export const createAboutYouRequest = async (
  params: AboutYouParams
): Promise<void> => {
  await axios.post(`/api/checkins/about_yous`, {
    address1: params.address1,
    address2: params.address2,
    state: params.state,
    city: params.city,
    zip_code: params.zipCode,
    race: params.race,
    ethnicity: params.ethnicity,
    preferred_name: params.preferredName,
    gender: params.gender,
    preferred_pronoun: params.preferredPronoun,
    preferred_language: params.preferredLanguage,
    accommodations: params.accommodations,
  });
};

export const createAboutYouNewRequest = async (
  params: AboutYouParams
): Promise<void> => {
  await axios.post(`/api/checkins/about_yous_news`, {
    address1: params.address1,
    address2: params.address2,
    state: params.state,
    city: params.city,
    zip_code: params.zipCode,
    race: params.race,
    ethnicity: params.ethnicity,
  });
};
