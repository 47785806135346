import axios from "axios";

export interface validatePatientRequest {
  patientId: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  dateOfBirth: string | null;
  email: string | null;
}

export const validatePatient = async (
  params: validatePatientRequest
): Promise<{ status: number; patient: string | number }> => {
  const { patientId, firstName, lastName, phoneNumber, dateOfBirth, email } =
    params;

  const res = await axios.get<any>(`/api/v1/check_patients`, {
    params: {
      patient_id: patientId,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      date_of_birth: dateOfBirth,
      email,
    },
  });

  return { status: res.data.status, patient: res.data.patient };
};
