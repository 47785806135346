export const isEmail = (email: string): string | boolean =>
  Boolean(
    email.match(
      /^([^<>()[\]\\ .,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$$/
    )
  ) || "Not a valid email";

export const isValidTLDLength = (email: string): string | boolean =>
  Boolean(email.match(/[^@](([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,4})$/)) ||
  "Email must end with 4 characters or less. For example: .com";

export const acceptableCharacters = (email: string): string | boolean =>
  Boolean(email.match(/^[^!#$%&'*+/?^`{|}()~]+@/)) ||
  "Please avoid using special characters";
