import React from "react";
import { Redirect, useHistory } from "react-router";
import { Location } from "history";
import cx from "classnames";
import { useController, useForm } from "react-hook-form";
import { isNull } from "lodash";
import { Layout } from "./components/Layout";
import { Fieldset } from "./components/Fieldset";
import styles from "./AboutYou2.module.scss";
import { AboutYou1FormData, AboutYou2FormData } from "./types";
import { GenderOptions, PreferredLanguageOptions } from "./constants";
import { Navbar } from "features/checkIn/components/Navbar";
import { Introduction } from "features/checkIn/components/Introduction";
import * as paths from "features/routing/paths";
import { Button } from "components/Button";
import { TextInput } from "components/TextInput";
import { TextArea } from "components/TextArea";
import { Typeahead } from "components/Typeahead";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";
import { ConfirmNavigation } from "components/ConfirmNavigation";
import { createAboutYouRequest } from "api/createAboutYouRequest";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { CharacterCounter } from "components/CharacterCounter";

interface AboutYou2Props {
  aboutYou1Data: AboutYou1FormData | null;
}

export const AboutYou2: React.FC<AboutYou2Props> = ({ aboutYou1Data }) => {
  const history = useHistory();
  const { fetchCurrentAppointment } = useCurrentAppointment();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
    control,
    watch,
  } = useForm<AboutYou2FormData>({
    mode: "onBlur",
  });
  //useProgressGuard({ field: "aboutYou", isSubmitting });

  const { handleUnauthorized, fetchCurrentPatient } = useCurrentPatient();
  const { displayMessage } = useSnackbar();

  const onSubmit = async (data: AboutYou2FormData) => {
    if (isNull(aboutYou1Data)) {
      throw new Error("Cannot submit without aboutYou1Data.");
    }

    await handleUnauthorized(async () => {
      try {
        await createAboutYouRequest({
          ...aboutYou1Data,
          ...data,
        });
      } catch (e) {
        if (e.code === "422") {
          displayMessage({ icon: "checkmark", text: "Already submitted." });
          return;
        }

        throw e;
      }
      // Saving About You modifies the appointment and patient data, so force a fetch to get a fresh copy.
      await fetchCurrentAppointment();
      await fetchCurrentPatient();
      history.push(paths.checkInHome());
    });
  };

  const preferredName = register("preferredName");
  const watchPreferredName = watch("preferredName");
  const { field: genderField } = useController({
    name: "gender",
    rules: { required: "Please select an option" },
    control,
  });
  const preferredPronoun = register("preferredPronoun");
  const watchPreferredPronoun = watch("preferredPronoun");
  const { field: preferredLanguageField } = useController({
    name: "preferredLanguage",
    rules: { required: "Please select an option" },
    control,
  });
  const watchAccommodationsToggle = watch("accommodationsToggle");
  const shouldShowAccommodations = watchAccommodationsToggle === "yes";
  const watchAccommodations = watch("accommodations");
  const confirmNavigation = React.useCallback(
    (location: Location) => {
      if (isNull(aboutYou1Data)) {
        return false;
      }

      if (
        location.pathname === paths.checkInHome() ||
        location.pathname === paths.checkInAboutYou1()
      ) {
        // note that their changes in the second screen won't be saved here
        return false;
      }

      if (isSubmitting) {
        return false;
      }

      // user must have entered data in the first screen
      return true;
    },
    [isSubmitting, aboutYou1Data]
  );

  if (isNull(aboutYou1Data)) {
    // user didn't complete fields from first screen so redirect
    return <Redirect to={paths.checkInAboutYou1()} />;
  }

  return (
    <Layout
      navbar={<Navbar currentStep={2} totalSteps={2} title={"About You"} />}
      primaryCta={
        <Button
          onClick={handleSubmit(onSubmit)}
          renderAsDisabled={!isValid}
          loading={isSubmitting}
          disabled={isSubmitting}
          text="Save & Continue"
        />
      }
      emptyHeight={true}
    >
      <ConfirmNavigation
        enableOnLocationChange={confirmNavigation}
        enableOnExit={true}
      />
      <Introduction header="Share your personal preferences">
        Please provide the patient’s information to help us personalize the
        visit.
      </Introduction>

      <div className={styles.content}>
        <Fieldset
          legend="What name would you like to go by?"
          legendClassName={styles.alwaysVisible}
        >
          <TextInput
            {...preferredName}
            inputType="text"
            errorMessage={errors.preferredName?.message}
            className={styles.field}
            errorClassName={styles.emptyError}
            maxLength={30}
            afterInput={
              <CharacterCounter max={30} value={watchPreferredName} />
            }
          >
            Preferred Name (optional)
          </TextInput>
        </Fieldset>

        <Fieldset
          legend="How would you like to be referred?"
          legendClassName={styles.alwaysVisible}
        >
          <Typeahead
            options={GenderOptions}
            name="gender"
            label="Gender"
            field={genderField}
            creatable={false}
            clearable={true}
            className={styles.field}
            errorClassName={cx({ [styles.emptyError]: !errors.gender })}
            errorMessage={errors.gender?.message}
          />
          <TextInput
            {...preferredPronoun}
            inputType="text"
            errorMessage={errors.preferredPronoun?.message}
            className={styles.field}
            errorClassName={styles.emptyError}
            maxLength={30}
            afterInput={
              <CharacterCounter max={30} value={watchPreferredPronoun} />
            }
          >
            Preferred Pronoun (optional)
          </TextInput>
        </Fieldset>

        <Fieldset
          legend="What’s your preferred language?"
          legendClassName={styles.alwaysVisible}
        >
          <Typeahead
            options={PreferredLanguageOptions}
            name="preferredLanguage"
            label="Preferred Language"
            field={preferredLanguageField}
            creatable={false}
            clearable={true}
            className={styles.field}
            errorClassName={cx({
              [styles.emptyError]: !errors.preferredLanguage,
            })}
            errorMessage={errors.preferredLanguage?.message}
          />
        </Fieldset>

        <hr className={styles.separator} />

        <Fieldset
          legend="Do you require any special accommodations during your visit or have any information you’d like us to know?"
          legendClassName={styles.alwaysVisible}
          alwaysVertical
        >
          <RadioButtonGroup
            className={styles.toggle}
            errorMessage={errors.accommodationsToggle?.message}
            errorClassName={cx({
              [styles.emptyError]: !errors.accommodationsToggle,
            })}
          >
            <RadioButton
              {...register("accommodationsToggle", {
                required: "Please select an option",
              })}
              hasError={!!errors.accommodationsToggle}
              value="yes"
            >
              Yes
            </RadioButton>
            <RadioButton
              {...register("accommodationsToggle", {
                required: "Please select an option",
              })}
              hasError={!!errors.accommodationsToggle}
              value="no"
            >
              No
            </RadioButton>
          </RadioButtonGroup>
          {shouldShowAccommodations && (
            <>
              <TextArea
                {...register("accommodations", {
                  required: shouldShowAccommodations,
                })}
                hasError={!!errors.accommodations}
                className={styles.accommodationField}
                placeholder="Example: wheelchair access, language services, first eye exam"
                rows={3}
                maxLength={150}
                afterInput={
                  <CharacterCounter max={150} value={watchAccommodations} />
                }
              >
                Notes for the office or doctor
              </TextArea>
            </>
          )}
        </Fieldset>
      </div>
    </Layout>
  );
};
