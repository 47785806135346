import React from "react";
import { Order } from "./Order";
import styles from "./RecentPurchases.module.scss";
import { useOrders } from "features/patientDashboard/hooks/useOrders";
import { Card } from "components/Card";
import { Patient } from "types/patient";
import { Order as OrderType } from "types/order";

export const RecentPurchases: React.FC<{
  patient: undefined | Partial<Patient>;
}> = ({ patient }) => {
  const patientId = patient.id;
  const { orders } = useOrders(patientId);

  if (orders === undefined || orders.length === 0) {
    return null;
  }

  return <UnconnectedRecentPurchases orders={orders} />;
};

declare let getCLXUrl: string;

export const UnconnectedRecentPurchases: React.FC<{
  orders: OrderType[];
  statusPage?: boolean;
}> = ({ orders, statusPage }) => (
  <>
    <Card className={styles.card}>
      <h4 className={styles.headline}>Recent Purchases</h4>
      <hr className={styles.headlineSeparator} />
      <ul className={styles.list}>
        {orders.map((order, i) => (
          <li key={order.orderNum}>
            {i > 0 ? <hr className={styles.separator} /> : null}{" "}
            {/* Insert a separator between each element */}
            <Order order={order} />
          </li>
        ))}
      </ul>
    </Card>
    <div className={styles.ctas}>
      Something doesn't look right?{" "}
      <a
        href={"https://locations.myeyedr.com/search"}
        className={styles.inlineLink}
        target="_blank"
        rel="noreferrer"
      >
        Contact your local office
      </a>
    </div>
    {!statusPage && (
      <div className={styles.ctas}>
        Need access to your prescriptions?{" "}
        <a
          href={getCLXUrl}
          className={styles.inlineLink}
          target="_blank"
          rel="noreferrer"
        >
          View prescriptions
        </a>
      </div>
    )}
  </>
);
