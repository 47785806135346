import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./NearbyOffice.module.scss";
import { NearbyOfficeCard } from "./NearbyOfficeCard";
import { SectionDivider } from "components/SectionDivider";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { ExamType } from "types/examType";
import { Office } from "types/office";
import { Spinner } from "components/Button/Spinner";
import locationpinnew from "images/locationpinnew.svg";
import { externalRedirect } from "utils/externalRedirect";
import * as paths from "features/routing/paths";

interface NearbyOfficeProps {
  nearbyOffices: undefined | Office[];
  loading?: boolean;
  isMobile: boolean;
  isEditForm?: false;
  onClick?: () => void;
  version?: number;
  isLoading?: boolean;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

declare let REACT_APP_ENV: string | undefined;
export const NearbyOffice: React.FC<NearbyOfficeProps> = ({
  nearbyOffices,
  isMobile,
  isLoading,
  patientReturnStatus,
  examType,
}) => {
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const onClickVisible = () => {
    setVisible(!visible);
  };
  const onClickLocation = () => {
    const appEnv = typeof REACT_APP_ENV !== "undefined" ? REACT_APP_ENV : "";
    if (appEnv === "undefined") {
      externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
    } else {
      history.push(paths.offices());
    }
    return false;
  };

  //const noNearbyHeading = <span>No Availability Nearby</span>;
  const headingText =
    nearbyOffices && nearbyOffices.length > 1
      ? "Other Nearby Offices"
      : "Other Nearby Office";
  if (isLoading) {
    const nearbyLoadingHeading = <span>Fetching Nearby Offices</span>;
    return (
      <div className={styles.container}>
        <div className={styles.headerSection}>
          <Spinner className={styles.spinner} />
          <div className={cx(styles.heading, styles.loading)}>
            {nearbyLoadingHeading}
          </div>
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <>
        <div className={styles.mobilecontainer}>
          <div className={styles.contentSection}>
            {nearbyOffices && nearbyOffices.length === 0 && (
              <div className={cx(styles.nearbyContent, styles.closedContent)}>
                <div className={cx(styles.contentHeader, styles.noBackground)}>
                  <SectionDivider
                    visible={visible}
                    onClickVisible={onClickVisible}
                    version={2}
                  >
                    <div className={styles.heading}>{headingText}</div>
                  </SectionDivider>
                </div>
                {visible && (
                  <>
                    <div className={styles.contentLeftNoAvail}>
                      <img src={locationpinnew} alt="todo" />
                    </div>
                    <div className={styles.contentRightNoAvail}>
                      We are unable to find the availability at a nearby
                      location. To view other offices go back to our{" "}
                      <span aria-hidden="true" onClick={onClickLocation}>
                        locations
                      </span>{" "}
                      page.
                    </div>
                  </>
                )}
              </div>
            )}
            {!visible && nearbyOffices && nearbyOffices.length > 0 && (
              <SectionDivider
                visible={visible}
                onClickVisible={onClickVisible}
                version={2}
              >
                <div className={styles.heading}>{headingText}</div>
              </SectionDivider>
            )}
            {nearbyOffices &&
              nearbyOffices.map((nearby) => {
                return (
                  <>
                    {visible && (
                      <NearbyOfficeCard
                        nearby={nearby}
                        patientReturnStatus={patientReturnStatus}
                        examType={examType}
                      />
                    )}
                  </>
                );
              })}
            {visible && nearbyOffices && nearbyOffices.length > 0 && (
              <SectionDivider
                visible={visible}
                onClickVisible={onClickVisible}
                version={2}
              >
                <div className={styles.heading}>{headingText}</div>
              </SectionDivider>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerSection}>
          <div className={styles.heading}>{headingText}</div>
        </div>
        <div className={styles.contentSection}>
          {nearbyOffices &&
            nearbyOffices.map((nearby) => {
              return (
                <NearbyOfficeCard
                  nearby={nearby}
                  patientReturnStatus={patientReturnStatus}
                  examType={examType}
                />
              );
            })}
          {!isLoading && nearbyOffices && nearbyOffices.length === 0 && (
            <div className={styles.nearbyContent}>
              <div className={styles.contentHeader}>
                <span className={styles.noAvailSpan}>
                  NO AVAILABILITY NEARBY
                </span>
              </div>
              <div className={styles.contentLeftNoAvail}>
                <img src={locationpinnew} alt="todo" />
              </div>
              <div className={styles.contentRightNoAvail}>
                We are unable to find the availability at a nearby location. To
                view other offices go back to our{" "}
                <span onClick={onClickLocation} aria-hidden="true">
                  locations
                </span>{" "}
                page.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
