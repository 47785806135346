import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
//import styles from "./ValidateOfficeAPI.module.scss";
//import { calendarWeekV2 } from "./utils/calendarWeek";
import { API_DATE_FORMAT } from "api/constants";
import * as paths from "features/routing/paths";
import { LayoutV2 } from "features/layout";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { getProviderSlotRequest } from "api/getProviderSlotRequest";
import { getEyefinitySlotRequest } from "api/getEyefinitySlotRequest";

export const ValidateOfficeAPI: React.FC = () => {
  const { officeId } =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const [providerSlots, setProviderSlots] = React.useState<any>();
  const [week1, setWeek1] = React.useState<any>();
  const [week2, setWeek2] = React.useState<any>();
  const [week3, setWeek3] = React.useState<any>();
  const [week4, setWeek4] = React.useState<any>();
  const [week5, setWeek5] = React.useState<any>();
  const [week6, setWeek6] = React.useState<any>();
  const [week7, setWeek7] = React.useState<any>();
  const [week1progress, setWeek1Progress] = React.useState<boolean>(false);
  const [week2progress, setWeek2Progress] = React.useState<boolean>(false);
  const [week3progress, setWeek3Progress] = React.useState<boolean>(false);
  const [week4progress, setWeek4Progress] = React.useState<boolean>(false);
  const [week5progress, setWeek5Progress] = React.useState<boolean>(false);
  const [week6progress, setWeek6Progress] = React.useState<boolean>(false);
  const [week7progress, setWeek7Progress] = React.useState<boolean>(false);

  const today = dayjs().format(API_DATE_FORMAT);
  const { showErrorToast } = useErrorToast();

  const formatData = (newcache, calledFrom) => {
    const newLocalEyepD = {};
    for (let i = 0; i < newcache.length; i++) {
      const scheduleDate =
        calledFrom === "providerSlot"
          ? newcache[i]["schedule_date"]
          : newcache[i]["attributes"]["date"];
      if (typeof newLocalEyepD[scheduleDate] === "undefined") {
        newLocalEyepD[scheduleDate] = {};
        //console.log(scheduleDate);
      }

      //console.log(newLocalEyepD);
      const doctorId =
        calledFrom === "providerSlot"
          ? newcache[i]["provider_id"]
          : newcache[i]["attributes"]["doctor_id"];

      if (typeof newLocalEyepD[scheduleDate][doctorId] === "undefined") {
        if (calledFrom === "providerSlot" && newcache[i]["active_slot"]) {
          newLocalEyepD[scheduleDate][doctorId] = [newcache[i]];
        } else if (calledFrom === "Eyefinity") {
          newLocalEyepD[scheduleDate][doctorId] = [newcache[i]];
        }
        //newLocalEyepD[scheduleDate][doctorId] = [newcache[i]];
      } else {
        if (calledFrom === "providerSlot" && newcache[i]["active_slot"]) {
          newLocalEyepD[scheduleDate][doctorId].push(newcache[i]);
        } else if (calledFrom === "Eyefinity") {
          newLocalEyepD[scheduleDate][doctorId].push(newcache[i]);
        }
        //newLocalEyepD[scheduleDate][doctorId].push(newcache[i]);
      }
      //}
    }
    return newLocalEyepD;
  };

  const generateWeekCalender = (searchDate) => {
    const weekCalendar = [];
    ["0", "1", "2", "3", "4", "5", "6"].map((i) =>
      weekCalendar.push(
        dayjs(searchDate).add(parseInt(i), "day").format(API_DATE_FORMAT)
      )
    );
    return weekCalendar;
  };

  const weekCalender1 = generateWeekCalender(today);
  const weekCalender2 = generateWeekCalender(
    dayjs(weekCalender1[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  const weekCalender3 = generateWeekCalender(
    dayjs(weekCalender2[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  const weekCalender4 = generateWeekCalender(
    dayjs(weekCalender3[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  const weekCalender5 = generateWeekCalender(
    dayjs(weekCalender4[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  const weekCalender6 = generateWeekCalender(
    dayjs(weekCalender5[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  const weekCalender7 = generateWeekCalender(
    dayjs(weekCalender6[6]).add(1, "day").format(API_DATE_FORMAT)
  );
  React.useEffect(() => {
    async function getOfficeCache() {
      try {
        const data = await getProviderSlotRequest(officeId);
        setProviderSlots(formatData(data, "providerSlot"));
      } catch (e) {
        showErrorToast();
      }
    }
    if (!providerSlots) getOfficeCache();
  }, [officeId, providerSlots, showErrorToast]);

  React.useEffect(() => {
    async function getOfficeCache1() {
      setWeek1Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender1[0],
          weekCalender1[6]
        );
        setWeek1(formatData(data1.total_slots, "Eyefinity"));
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek1("");
          setWeek1Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week1progress && !week1) getOfficeCache1();
  }, [officeId, week1, week1progress, weekCalender1, showErrorToast]);
  React.useEffect(() => {
    async function getOfficeCache2() {
      setWeek2Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender2[0],
          weekCalender2[6]
        );
        setWeek2(formatData(data1.total_slots, "Eyefinity"));
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek2("");
          setWeek2Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week2progress) getOfficeCache2();
  }, [officeId, week2progress, week2, weekCalender2, showErrorToast]);
  React.useEffect(() => {
    async function getOfficeCache3() {
      setWeek3Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender3[0],
          weekCalender3[6]
        );
        setWeek3(formatData(data1.total_slots, "Eyefinity"));
        //setWeek3Progress(true);
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek3("");
          setWeek3Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week3progress) getOfficeCache3();
  }, [officeId, week3, week3progress, weekCalender3, showErrorToast]);
  React.useEffect(() => {
    async function getOfficeCache4() {
      setWeek4Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender4[0],
          weekCalender4[6]
        );
        //console.log(data1);
        setWeek4(formatData(data1.total_slots, "Eyefinity"));
        setWeek4Progress(true);
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek4("");
          setWeek4Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week4progress && !week4) getOfficeCache4();
  }, [officeId, week4, weekCalender4, showErrorToast, week4progress]);
  React.useEffect(() => {
    async function getOfficeCache5() {
      setWeek5Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender5[0],
          weekCalender5[6]
        );
        //console.log(data1);
        setWeek5(formatData(data1.total_slots, "Eyefinity"));
        setWeek5Progress(true);
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek5("");
          setWeek5Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week5progress && !week5) getOfficeCache5();
  }, [officeId, week5, weekCalender5, showErrorToast, week5progress]);
  React.useEffect(() => {
    async function getOfficeCache6() {
      setWeek6Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender6[0],
          weekCalender6[6]
        );
        //console.log(data1);
        setWeek6(formatData(data1.total_slots, "Eyefinity"));
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek6("");
          setWeek6Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week6progress && !week6) getOfficeCache6();
  }, [officeId, week6, weekCalender6, showErrorToast, week6progress]);
  React.useEffect(() => {
    async function getOfficeCache7() {
      setWeek7Progress(true);
      try {
        const data1 = await getEyefinitySlotRequest(
          officeId,
          weekCalender7[0],
          weekCalender7[6]
        );
        //console.log(data1);
        setWeek7(formatData(data1.total_slots, "Eyefinity"));
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          setWeek7("");
          setWeek7Progress(false);
        }, 3000);
        showErrorToast();
      }
    }
    if (!week7progress && !week7) getOfficeCache7();
  }, [officeId, week7, weekCalender7, showErrorToast, week7progress]);
  if (
    !providerSlots ||
    !week1 ||
    !week2 ||
    !week3 ||
    !week4 ||
    !week5 ||
    !week6 ||
    !week7
  ) {
    return "Loading";
  }
  const mismatchSlot = [];
  const mismatchSlot1 = [];
  //console.log(week1);
  const checkSlotByWeeks = (weekCalender, week, weekName) => {
    for (let i = 0; i < weekCalender.length; i++) {
      const date = weekCalender[i];
      if (
        typeof providerSlots[date] === "undefined" &&
        typeof week[date] === "undefined"
      ) {
        continue;
      }
      const providerIds = Object.keys(providerSlots[date]);
      for (let j = 0; j < providerIds.length; j++) {
        const pId = providerIds[j];
        const providerDate = providerSlots[date][pId];
        const weekPDate = week[date][pId];
        if (providerDate) {
          if (providerDate.length !== weekPDate.length) {
            for (let k = 0; k < providerDate.length; k++) {
              const startTime = providerDate[k]["start_time"].split(" ");
              let isPresent = false;
              for (let l = 0; l < weekPDate.length; l++) {
                if (startTime[1] === weekPDate[l]["attributes"]["start_time"]) {
                  isPresent = true;
                }
              }
              if (!isPresent) {
                mismatchSlot.push(providerDate[k]);
                //console.log(providerDate[k]);
              }
            }
            for (let k = 0; k < weekPDate.length; k++) {
              const startTime = weekPDate[k]["attributes"]["start_time"]; //providerDate[k]["start_time"].split(" ");
              let isPresent = false;
              for (let l = 0; l < providerDate.length; l++) {
                if (startTime === providerDate[l]["start_time"].split(" ")[1]) {
                  isPresent = true;
                }
              }
              if (!isPresent) {
                mismatchSlot1.push(weekPDate[k]);
                //console.log(weekPDate[k]);
              }
            }
          }
        }
      }
    }
  };
  if (week1 && providerSlots) {
    checkSlotByWeeks(weekCalender1, week1, "first");
  }
  if (week2 && providerSlots) {
    checkSlotByWeeks(weekCalender2, week2, "second");
  }
  if (week3 && providerSlots) {
    checkSlotByWeeks(weekCalender3, week3, "third");
  }
  if (week4 && providerSlots) {
    checkSlotByWeeks(weekCalender4, week4, "fourth");
  }
  if (week5 && providerSlots) {
    checkSlotByWeeks(weekCalender5, week5, "fifth");
  }
  if (week6 && providerSlots) {
    checkSlotByWeeks(weekCalender6, week6, "sixth");
  }
  if (week7 && providerSlots) {
    checkSlotByWeeks(weekCalender7, week7, "seventh");
  }
  console.log("-------mismatchSlot---------------");
  console.log(mismatchSlot);
  console.log("-------mismatchSlot1---------------");
  console.log(mismatchSlot1);
  return (
    <LayoutV2>
      <div>
        Comparison from providerSlot: Eyefinity API missing slots:{" "}
        {mismatchSlot.length === 0 && <b>matched perfect.</b>}
      </div>
      <br />
      {mismatchSlot.length > 0 && (
        <table>
          <tr>
            <th>Schedule Date</th>
            <th>Provider Id</th>
            <th>Start Time</th>
            <th>Active Slot</th>
          </tr>

          {mismatchSlot.map((slot) => {
            return (
              <tr>
                <td>{slot["schedule_date"]}</td>
                <td>{slot["provider_id"]}</td>
                <td>{slot["start_time"].split(" ")[1]}</td>
                <td>{slot["active_slot"] ? "true" : "false"}</td>
              </tr>
            );
          })}
        </table>
      )}
      <div>
        Comparison from Eyefinity: ProviderSlot missing slots:{" "}
        {mismatchSlot1.length === 0 && <b>matched perfect.</b>}
      </div>
      <br />
      {mismatchSlot1.length > 0 && (
        <table>
          <tr>
            <th>Schedule Date</th>
            <th>Provider Id</th>
            <th>Start Time</th>
          </tr>

          {mismatchSlot1.map((slot) => {
            return (
              <tr>
                <td>{slot["attributes"]["date"]}</td>
                <td>{slot["attributes"]["doctor_id"]}</td>
                <td>{slot["attributes"]["start_time"]}</td>
              </tr>
            );
          })}
        </table>
      )}
    </LayoutV2>
  );
};
