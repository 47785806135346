export const isValidDateOfBirthVEH = (value: string): string | boolean => {
  const today = new Date();
  const birthDate = new Date(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return (
    (age >= 10 && age < 130) ||
    " We’re sorry, you must be at least 10 years of age to book a VEH appointment."
  );
};
