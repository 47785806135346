type RequiredRules = {
  required: string;
  pattern: {
    value: RegExp;
    message: string;
  };
};

export const presenceNameValidation = (
  errorMessage: string
): RequiredRules => ({
  required: errorMessage,
  pattern: { value: /^\s*[-a-zA-Z-]+\s*$/, message: errorMessage },
});
