import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";
import styles from "./Timetable.module.scss";
import { NavigationButton } from "./NavigationButton";
import { OnSelectSlotHandler } from "./types";
import { TimetableSlots } from "./TimetableSlots";
import { AppointmentSelectSlotRouteProps } from "./AppointmentSelectSlot";
import { API_DATE_FORMAT } from "api/constants";
import { Waitlist } from "features/scheduling/components/Waitlist";
import { calendarWeek } from "features/scheduling/utils/calendarWeek";
import {
  isBackAvailable,
  isNextAvailable,
} from "features/scheduling/utils/availabilitySearchWindow";
import * as paths from "features/routing/paths";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";
import { doctorFullNameVEH } from "utils/doctorFullName";

interface TimetableProps {
  selectedOffice: Office;
  searchDate: string;
  doctor: Doctor | null;
  onSelectSlot: OnSelectSlotHandler;
}

export const Timetable: React.FC<TimetableProps> = ({
  selectedOffice,
  searchDate,
  doctor,
  onSelectSlot,
}) => {
  const { officeId, examType, patientReturnStatus } =
    useParams<AppointmentSelectSlotRouteProps>();
  const { trackSchedulerEvent } = useSchedulerTracking(doctor?.id);
  const trackBrowseDatesEvent = () => trackSchedulerEvent("browse-dates");
  const days = calendarWeek(searchDate);

  const buildUrls = () => {
    const searchDay = dayjs(searchDate);
    const previousWeek = searchDay.subtract(1, "week");
    const prevWeekCalendar = calendarWeek(previousWeek.format(API_DATE_FORMAT));
    const nextWeek = searchDay.add(1, "week");
    const nextWeekCalendar = calendarWeek(nextWeek.format(API_DATE_FORMAT));
    const previousWeekUrl = paths.appointmentsAvailableAppointments({
      officeId,
      examType,
      patientReturnStatus,
      query: {
        date: prevWeekCalendar[0].format(API_DATE_FORMAT),
        doctorId: doctor?.id,
      },
    });
    const nextWeekUrl = paths.appointmentsAvailableAppointments({
      officeId,
      examType,
      patientReturnStatus,
      query: {
        date: nextWeekCalendar[0].format(API_DATE_FORMAT),
        doctorId: doctor?.id,
      },
    });
    return { previousWeekUrl, nextWeekUrl };
  };
  const { previousWeekUrl, nextWeekUrl } = buildUrls();

  const buildDateStrings = () => {
    const beginningOfWeek = days[0];
    const endOfWeek = days[6];
    const startDateString = beginningOfWeek.format("MMMM D");
    const endDateFormat =
      beginningOfWeek.date() > endOfWeek.date() ? "MMMM D" : "D";
    const endDateString = endOfWeek.format(endDateFormat);
    return { startDateString, endDateString };
  };
  const { startDateString, endDateString } = buildDateStrings();

  const doctorHeadline = doctor ? (
    <span>
      {doctorFullNameVEH(doctor, selectedOffice.namechangeVEH)}
      {doctor.firstName && doctor.firstName === "Open Network"
        ? " availability"
        : "'s schedule"}{" "}
      (
      <span className={styles.date}>
        {startDateString} - {endDateString}
      </span>
      )
    </span>
  ) : (
    <ContentLoader
      speed={2}
      width={400}
      height={19}
      viewBox="0 0 400 18"
      backgroundColor="var(--gray-02)"
      foregroundColor="var(--gray-04)"
    >
      <rect x="0" y="8" rx="4" ry="4" width="400" height="8" />
    </ContentLoader>
  );

  return (
    <div>
      <div className={styles.tableHeader}>
        <h1 className={styles.doctor}>{doctorHeadline}</h1>
      </div>
      <div className={styles.grid}>
        <div className={styles.arrow}>
          <NavigationButton
            url={previousWeekUrl}
            disabled={!isBackAvailable(searchDate)}
            direction="left"
            onClick={trackBrowseDatesEvent}
          />
        </div>
        {days.map((day) => (
          <div key={day.format(API_DATE_FORMAT)} className={styles.dateHeader}>
            <span className={styles.weekDay}>{day.format("ddd")}</span>
            <span className={styles.monthDay}>{day.format("MMM D")}</span>
          </div>
        ))}
        <div className={styles.arrow}>
          <NavigationButton
            url={nextWeekUrl}
            disabled={
              !isNextAvailable(searchDate, selectedOffice.timeSlotWeeks)
            }
            direction="right"
            onClick={trackBrowseDatesEvent}
          />
        </div>

        <TimetableSlots
          days={days}
          officeId={officeId}
          doctor={doctor}
          onSelectSlot={onSelectSlot}
          examType={examType}
          patientReturnStatus={patientReturnStatus}
          fromDate={searchDate}
          selectedOffice={selectedOffice}
        />
        <Waitlist
          className={styles.waitlistCta}
          office={selectedOffice}
          selectedDoctorId={doctor?.id}
        />
      </div>
    </div>
  );
};
