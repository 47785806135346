import React from "react";
import cx from "classnames";
import styles from "./Spinner.module.scss";

interface SpinnerProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      className={cx(styles.spinner, className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 1.07324e-07C7.71997 8.60973e-08 5.97991 0.527842 4.49987 1.51677C3.01983 2.50571 1.86627 3.91131 1.18508 5.55585C0.503896 7.20038 0.325666 9.00998 0.672933 10.7558C1.0202 12.5016 1.87737 14.1053 3.13604 15.364C4.39471 16.6226 5.99836 17.4798 7.74419 17.8271C9.49002 18.1743 11.2996 17.9961 12.9442 17.3149C14.5887 16.6337 15.9943 15.4802 16.9832 14.0001C17.9722 12.5201 18.5 10.78 18.5 9L16.7 9C16.7 10.424 16.2777 11.8161 15.4866 13.0001C14.6954 14.1841 13.5709 15.107 12.2553 15.6519C10.9397 16.1969 9.49201 16.3395 8.09535 16.0617C6.69869 15.7838 5.41577 15.0981 4.40883 14.0912C3.40189 13.0842 2.71616 11.8013 2.43835 10.4047C2.16053 9.00799 2.30312 7.56031 2.84807 6.24468C3.39302 4.92905 4.31586 3.80456 5.49989 3.01342C6.68393 2.22227 8.07597 1.8 9.5 1.8L9.5 1.07324e-07Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { Spinner };
