import cx from "classnames";
import React from "react";
import styles from "components/Button/useButtonTemplate.module.scss";
import { ButtonRole, ButtonSize } from "components/Button/types";
import { Spinner } from "components/Button/Spinner";

const getClassNames = (props: {
  role: ButtonRole;
  size: ButtonSize;
  loading: boolean;

  // this won't set the disabled prop on the button, but will style as if
  // it was disabled, allowing the click event to still trigger.
  renderAsDisabled: boolean;
}) => {
  const { role, size, loading, renderAsDisabled } = props;

  return cx(styles.button, {
    [styles.buttonStandard]: role === ButtonRole.Standard,
    [styles.buttonGhost]: role === ButtonRole.Ghost,
    [styles.buttonLarge]: size === "large",
    [styles.buttonLoading]: loading,
    [styles.disabled]: renderAsDisabled,
  });
};

interface ButtonTemplateProps {
  role: ButtonRole;
  size: ButtonSize;
  loading: boolean;
  text: string;
  renderAsDisabled: boolean;
}

export const useButtonTemplateV2 = (
  props: ButtonTemplateProps
): [JSX.Element, string] => {
  const { role, size, loading, text, renderAsDisabled } = props;

  const classNames = getClassNames({
    role,
    size,
    loading,
    renderAsDisabled,
  });

  const inner = (
    <>
      {loading && <Spinner className={styles.spinner} />}
      <span className={styles.text}>{text}</span>
    </>
  );

  return [inner, classNames];
};
