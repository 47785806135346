import axios from "axios";
import { AvailabilitySummaryWithDoctor } from "types/availabilitySummary";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";

export interface GetAvailabilitySummaryResponse {
  doctor_id: string;
  predicted_available_date: string;
}

export const getAvailabilitySummaryRequest = async (params: {
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  fromDate: string;
  doctorIds: string[];
}): Promise<AvailabilitySummaryWithDoctor[]> => {
  const { officeId, examType, patientReturnStatus, fromDate, doctorIds } =
    params;
  const res = await axios.get<GetAvailabilitySummaryResponse[]>(
    `/api/offices/${officeId}/doctors/availability_summary`,
    {
      params: {
        exam_type: examType,
        patient_return_status: patientReturnStatus,
        from_date: fromDate,
        doctor_ids: doctorIds.join(","),
      },
    }
  );

  return res.data.map((summary) => ({
    doctorId: summary.doctor_id,
    predictedAvailableDate: summary.predicted_available_date,
  }));
};
