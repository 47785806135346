import React from "react";
import { CurrentAppointment } from "types/currentAppointment";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { getLastAppointmentRequest } from "api/getCurrentAppointmentRequest";
export type LastAppointmentState = CurrentAppointment | null | undefined;

export const useLastAppointment = (): {
  lastAppointment: LastAppointmentState;
  fetchLastAppointment: () => Promise<void>;
} => {
  const [lastAppointment, setLastAppointment] = React.useState<
    CurrentAppointment | null | undefined
  >();
  const { showErrorToast } = useErrorToast();

  const fetchLastAppointment = React.useCallback(async () => {
    try {
      const result = await getLastAppointmentRequest();
      setLastAppointment(result);
    } catch (e) {
      showErrorToast();
    }
  }, [showErrorToast]);

  // React.useEffect(() => {
  //   if (lastAppointment === undefined) {
  //     fetchLastAppointment();
  //   }
  // }, [lastAppointment, fetchLastAppointment]);

  return { lastAppointment, fetchLastAppointment };
};
