import axios from "axios";
import { TwoFactorPhoneNumber } from "types/twoFactorPhoneNumber";

type TwoFactorPhoneNumberResponse = [
  {
    id: string;
    masked_phone_number: string;
  }
];

export const getTwoFactorPhoneNumbers = async (): Promise<
  TwoFactorPhoneNumber[]
> => {
  const response = await axios.get<TwoFactorPhoneNumberResponse>(
    "/api/two_factor_codes"
  );
  return response.data.map((ph) => ({
    id: ph.id,
    maskedPhoneNumber: ph.masked_phone_number,
  }));
};
