import React from "react";
import styles from "./OfficeContent.module.scss";
import { OfficeAddress } from "components/OfficeAddress";
import { Office } from "types/office";

interface OfficeContentProps {
  children?: React.ReactNode;
  office: Office;
}

export const OfficeContent: React.FC<OfficeContentProps> = ({
  children,
  office,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.office}>
        <ul className={styles.officeDetails}>
          <li className={styles.officeName}>{office.displayName}</li>
          <li className={styles.officeDetail}>
            <OfficeAddress office={office} />
          </li>
        </ul>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
