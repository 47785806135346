import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { isNull, cloneDeep, isEmpty } from "lodash";
import styles from "./ConfirmInsurance.module.scss";
import { Navbar } from "./components/Navbar";
import { Layout } from "./components/Layout";
import { ConfirmInsuranceForm } from "./components/InsuranceForm";
import { InsuranceFormData } from "./types";
import { useProgressGuard } from "./hooks/useProgressGuard";
import deleteIcon from "images/delete-icon.svg";
import addCircle from "images/add-circle.svg";
import errorIcon from "images/errorIcon.svg";
import { Card } from "components/Card";
import * as paths from "features/routing/paths";
import { getInsuranceCarriersRequest } from "api/getInsuranceCarriersRequest";
import { Button } from "components/Button";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import {
  setInsuranceData,
  setCurrentInsurance,
  setIsEditForm,
  setShowAddFooter,
  selectInsuranceData,
  selectCurrentInsurance,
  selectIsEditForm,
  selectShowAddFooter,
} from "features/insuranceDataSlice";

const CancelToken = axios.CancelToken;

export const ConfirmInsurance: React.FC<{
  defaultValues: InsuranceFormData | null;
  isSubmit: Boolean; 
  isFormValidIns: any;
  getInsuranceValues: any;
  currentPatient: any;
  version?:number;
}> = ({ defaultValues, isSubmit, isFormValidIns, getInsuranceValues, currentPatient, version }) => {
  const [insuranceCarriers, setInsuranceCarriers] =
    React.useState<Array<string> | null>(null);
  const { currentAppointment } = useCurrentAppointment();
  const { showErrorToast } = useErrorToast();
  const history = useHistory();
  const [executeOnce, setExecuteOnce] = useState(false);
  const dispatch = useDispatch();
  const insuranceFormDataArr = useSelector(selectInsuranceData);
  const isShowFooter = useSelector(selectShowAddFooter);
  const [isForm, setIsForm] = useState(false);
  const [isFirstCard, setIsFirstCard] = useState(false);
  const [isSecondCard, setIsSecondCard] = useState(false);
  const [isErrorFirstFields, setErrorFirstFields] = useState(false);
  const [isErrorSecondFields, setErrorSecondFields] = useState(false);
  const [addAnotherCard, setAddAnotherCard] = useState(false);
  const [isFormRemove, setIsFormRemove] = useState(false);
  const currentInsurance = useSelector(selectCurrentInsurance);
  const isEditForm = useSelector(selectIsEditForm);
  const insuranceData = insuranceFormDataArr[currentInsurance];
  const [formValid, setFormValid] = useState(false);
  const errorMessageCard = "Enter Required Fields";

  const checkFormInsurance = (insurance: InsuranceFormData) => {
    if (
      insurance.insuranceCarrier &&
      insurance.subscriberFirstName &&
      insurance.subscriberLastName &&
      insurance.subscriberId &&
      insurance.subscriberDateOfBirth &&
      insurance.isPrimaryPolicyHolder &&
      insurance.subscriberSsn &&
      insurance.relationToPatient
    ) {
      if (insurance.insuranceCarrier === "Other Insurance") {
        return insurance.otherInsuranceName ? true : false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getValidInsurance = useCallback((insuranceDataArr) => {
    // eslint-disable-next-line array-callback-return
    let isValidForm = false;
    let isValidFormTemp = false;
    let i = 0;
    for (const insurance of insuranceDataArr) {
      if (!isEmpty(insurance)) {
        isValidFormTemp = checkFormInsurance(insurance) ? true : false;
      }

      if (i === 0) {
        isValidForm = isValidFormTemp;
        setFormValid(isValidForm);
        setErrorFirstFields(isValidForm);
      } else {
        if (isValidForm) setFormValid(isValidForm);
        setErrorSecondFields(isValidFormTemp);
      }
      i++;
    }
  }, []);

  React.useEffect(() => {
    if (!currentAppointment) return;

    const cancelSource = CancelToken.source();

     const getCarriers = async () => {
      try {
        const carriers = await getInsuranceCarriersRequest({
          officeId: currentAppointment.officeId,
          cancelToken: cancelSource.token,
        });
        setInsuranceCarriers(carriers);
      } catch (e) {
        // TODO: There is a race condition in axios's implementation of cancel tokens
        // that can cause the thrown object to not contain the relevant state. This should
        // be fixed in the next release of axios (see https://github.com/axios/axios/pull/3305)
        if (axios.isCancel(cancelSource.token.reason)) {
          return;
        }
        showErrorToast();
      }
    }; 

    getCarriers();
    
    return () => {
      cancelSource.cancel("UNMOUNTED");
    };
  }, [showErrorToast, setInsuranceCarriers, currentAppointment]);

  const resetForm = () => {
    setValue("insuranceCarrier", "");
    setValue("otherInsuranceName", "");
    setValue("subscriberFirstName", "");
    setValue("subscriberLastName", "");
    setValue("subscriberId", "");
    setValue("subscriberDateOfBirth", "");
    setValue("isPrimaryPolicyHolder", "");
    setValue("relationToPatient", undefined);
    setValue("subscriberSsn", "");
  };

  const onNext = (data: InsuranceFormData) => {
    const insuranceDataArr = cloneDeep(insuranceFormDataArr);
    // eslint-disable-next-line array-callback-return
    [getValues()].map((insurance: InsuranceFormData) => {
      insuranceDataArr[currentInsurance] = createInsuranceObj(insurance);
    });
    dispatch(setInsuranceData(insuranceDataArr));
    let isFormValid = false;
    for (const insurance of insuranceDataArr) {
      if (!isEmpty(insurance)) {
        isFormValid = checkFormInsurance(insurance) ? true : false;
        if (!isFormValid) break;
      }
    }
    dispatch(setIsEditForm(true));
  };

  const getInsuranceValues1 = (data: InsuranceFormData) => {    
    const insuranceDataArr = cloneDeep(insuranceFormDataArr);
    // eslint-disable-next-line array-callback-return
    [getValues()].map((insurance: InsuranceFormData) => {
      insuranceDataArr[currentInsurance] = createInsuranceObj(insurance);
    });    
    let isFormValid = false;
    for (const insurance of insuranceDataArr) {
      if (!isEmpty(insurance)) {
        isFormValid = checkFormInsurance(insurance) ? true : false;
        if (!isFormValid) break;
      }
    }  
    if(isFormValid){ 
      dispatch(setInsuranceData(insuranceDataArr));
    }     
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
    getValues,
    clearErrors,
    control,
    trigger,
    watch,
  } = useForm<Partial<InsuranceFormData>>({
    defaultValues: isNull(defaultValues) ? {} : {},
    mode: "onBlur",
  });
  useProgressGuard({ field: "insurance", isSubmitting });

  const insuranceFormId = "Checkin-InsuranceForm";

  const checkFormEmpty = () => {
    return isEmpty(insuranceFormDataArr[0]) && isEmpty(insuranceFormDataArr[1]);
  };

  React.useEffect(() => {
    if (!isForm && insuranceData && !executeOnce) {
      setValue("insuranceCarrier", insuranceData?.insuranceCarrier);
      if (insuranceData?.insuranceCarrier === "Other Insurance")
        setValue("otherInsuranceName", insuranceData?.otherInsuranceName);
      setValue("subscriberFirstName", insuranceData?.subscriberFirstName);
      setValue("subscriberLastName", insuranceData?.subscriberLastName);
      setValue("subscriberId", insuranceData?.subscriberId);
      setValue("subscriberDateOfBirth", insuranceData?.subscriberDateOfBirth);
      setValue("isPrimaryPolicyHolder", insuranceData?.isPrimaryPolicyHolder);
      setValue("relationToPatient", insuranceData?.relationToPatient);
      setValue("subscriberSsn", insuranceData?.subscriberSsn);
      setExecuteOnce(true);
      if (!isEmpty(insuranceFormDataArr[0]) && currentInsurance === 1) {
        setIsFirstCard(true);
      } else {
        setIsFirstCard(false);
      }
      if (!isEmpty(insuranceFormDataArr[1]) && currentInsurance === 0) {
        setIsSecondCard(true);
      } else {
        setIsSecondCard(false);
      }
    }
    if (
      isShowFooter &&
      isEmpty(insuranceFormDataArr[0]) &&
      isEmpty(insuranceFormDataArr[1])
    ) {
      setAddAnotherCard(true);
    }
    if (
      isEditForm &&
      (isEmpty(insuranceFormDataArr[0]) || isEmpty(insuranceFormDataArr[1]))
    ) {
      setAddAnotherCard(true);
    }
    if (isEditForm) {
      isEmpty(insuranceFormDataArr[1])
        ? setIsFormRemove(false)
        : setIsFormRemove(true);
    }
    getValidInsurance(insuranceFormDataArr);
  }, [
    setValue,
    currentInsurance,
    insuranceFormDataArr,
    insuranceData,
    executeOnce,
    isShowFooter,
    isEditForm,
    isForm,
    getValidInsurance,
  ]);

  const refStr = "#";
  const getSubscriber = (index: number) => {
    let subscriberText = "";
    if (insuranceFormDataArr[index].subscriberFirstName)
      subscriberText += insuranceFormDataArr[index].subscriberFirstName;
    if (insuranceFormDataArr[index].subscriberLastName)
      subscriberText += " " + insuranceFormDataArr[index].subscriberLastName;
    if (insuranceFormDataArr[index].relationToPatient)
      subscriberText +=
        " (" + insuranceFormDataArr[index].relationToPatient + ")";

    return subscriberText;
  };

  const createInsuranceObj = (insurance: InsuranceFormData) => {
    if (isEmpty(insurance)) return {};
    return {
      subscriberFirstName: insurance.subscriberFirstName,
      subscriberLastName: insurance.subscriberLastName,
      insuranceCarrier: insurance.insuranceCarrier,
      otherInsuranceName: insurance.otherInsuranceName,
      subscriberId: insurance.subscriberId,
      subscriberDateOfBirth: insurance.subscriberDateOfBirth,
      isPrimaryPolicyHolder: insurance.isPrimaryPolicyHolder,
      subscriberSsn: insurance.subscriberSsn,
      relationToPatient: insurance.relationToPatient,
    };
  };

  const cardShowHandler = (index: number, type: string) => {
    clearErrors();
    if (type === "expand") {
      if (index === 1) {
        setIsFirstCard(true);
        setIsSecondCard(false);
      } else {
        setIsSecondCard(true);
        setIsFirstCard(false);
      }
      setAddAnotherCard(false);
      setIsFormRemove(true);
    } else if (type === "add") {
      setIsSecondCard(false);
      setIsFirstCard(true);
      setAddAnotherCard(false);
      setIsFormRemove(true);
    } else {
      setIsSecondCard(false);
      setIsFirstCard(false);
      setExecuteOnce(false);
      if (!checkFormEmpty()) setAddAnotherCard(true);
      setIsFormRemove(false);
    }
  };

  const goToTop = () => {
   
  };

  const isPrimaryPolicyHolder = watch("isPrimaryPolicyHolder");
  if (isPrimaryPolicyHolder && !isShowFooter) {
    dispatch(setShowAddFooter(true));
  }

  React.useEffect(() => {
    if (isSubmit) {
      trigger();
    }
  }, [isSubmit]);

  return (
    <>      
        {isFirstCard && (
          <div className={(version && version === 2)? styles.versionBG:""}>
            <div
              className={`${styles.mobileExpand} ${
                !isErrorFirstFields ? styles.errorExpand : ""
              }`}
            >
              <div className={styles.innerLeft}>
                <span>{(insuranceFormDataArr[0]?.insuranceCarrier)?insuranceFormDataArr[0]?.insuranceCarrier:"Insurance 1"}</span>
                <a
                  href={refStr}
                  onClick={() => {
                    const insuranceDataArr = cloneDeep(insuranceFormDataArr);
                    // eslint-disable-next-line array-callback-return
                    [getValues()].map((insurance: InsuranceFormData) => {
                      insuranceDataArr[1] = createInsuranceObj(insurance);
                    });
                    dispatch(setCurrentInsurance(0));
                    dispatch(setInsuranceData(insuranceDataArr));
                    getValidInsurance(insuranceDataArr);
                    setExecuteOnce(false);
                    setIsForm(false);
                    cardShowHandler(0, "expand");
                    goToTop();
                  }}
                >
                  Expand
                </a>
              </div>
              <div className={styles.innerRight}>
                <a
                  href={refStr}
                  onClick={(e) => {
                    const insuranceArrTemp = cloneDeep(insuranceFormDataArr);
                    insuranceArrTemp[0] = insuranceArrTemp[1];
                    insuranceArrTemp[1] = {};
                    dispatch(setInsuranceData(insuranceArrTemp));
                    dispatch(setCurrentInsurance(0));
                    setIsForm(false);
                    cardShowHandler(0, "remove");
                    goToTop();
                    e.preventDefault();
                  }}
                >
                  <img src={deleteIcon} alt="Remove" />
                </a>
              </div>
              {!isErrorFirstFields && (
                <div className={styles.errorFieldsMobile}>
                  <img src={errorIcon} alt="Error" />
                  <p>{errorMessageCard}</p>
                </div>
              )}
            </div>
          </div>
        )}
        <ConfirmInsuranceForm
          register={register}
          control={control}
          insuranceCarriers={insuranceCarriers}
          isRemoveBtn={false}
          setValue={setValue}
          errors={errors}
          watch={watch}
          onSubmit={handleSubmit(onNext)}
          formId={insuranceFormId}
          getInsuranceValues={getInsuranceValues1}
          getValues={getValues}
          currentPatient={currentPatient}
          version={version}
        />
        {isFormRemove && (
          <div className={styles.removeForm}>
            <br />
            <a
              className={styles.removeExpand}
              href={refStr}
              onClick={(e) => {
                const insuranceArrTemp = cloneDeep(insuranceFormDataArr);
                const currentIndex = cloneDeep(currentInsurance);
                if (currentIndex === 1) {
                  insuranceArrTemp[1] = {};
                } else if (currentIndex === 0) {
                  insuranceArrTemp[0] = insuranceArrTemp[1];
                  insuranceArrTemp[1] = {};
                }
                dispatch(setInsuranceData(insuranceArrTemp));
                dispatch(setCurrentInsurance(0));
                setIsForm(false);
                cardShowHandler(currentIndex, "inFormRemove");
                goToTop();
                e.preventDefault();
              }}
            >
              <img src={deleteIcon} alt="Remove" />
              Remove
            </a>
          </div>
        )}
        {addAnotherCard && (
          <div className={styles.insurance}>
            <a
              className={styles.addInsurance}
              href={refStr}
              onClick={(e) => {
                e.preventDefault();
                if (isValid) {
                  setErrorFirstFields(true);
                  const insuranceDataArr = cloneDeep(insuranceFormDataArr);
                  // eslint-disable-next-line array-callback-return
                  [getValues()].map((insurance: InsuranceFormData) => {
                    insuranceDataArr[0] = createInsuranceObj(insurance);
                  });
                  dispatch(setIsEditForm(false));
                  dispatch(setCurrentInsurance(1));
                  dispatch(setInsuranceData(insuranceDataArr));
                  dispatch(setShowAddFooter(false));
                  resetForm();
                  cardShowHandler(1, "add");
                  goToTop();
                } else {
                  trigger();
                }
              }}
            >
              <h1 className={styles.header}>
                <img src={addCircle} alt="Add" />
                ADD ANOTHER INSURANCE
              </h1>
              <span className={styles.addSpan}>
                MyEyeDr. will check and apply any additional Vision or Medical
                that gives <br /> you the greatest savings benefit
              </span>
            </a>
          </div>
        )}
        {isSecondCard && (
          <div className={(version && version === 2)? styles.versionBG:""}>            
            <div
              className={`${styles.mobileExpand} ${styles.secondExpand} ${
                !isErrorSecondFields ? styles.errorExpand : ""
              }`}
            >
              <div className={styles.innerLeft}>
                <span>{(insuranceFormDataArr[1]?.insuranceCarrier)?insuranceFormDataArr[1]?.insuranceCarrier:"Insurance 2"}</span>
                <a
                  href={refStr}
                  onClick={() => {
                    dispatch(setCurrentInsurance(1));
                    setExecuteOnce(false);
                    setIsForm(false);
                    const insuranceDataArr = cloneDeep(insuranceFormDataArr);
                    // eslint-disable-next-line array-callback-return
                    [getValues()].map((insurance: InsuranceFormData) => {
                      insuranceDataArr[0] = createInsuranceObj(insurance);
                    });
                    dispatch(setInsuranceData(insuranceDataArr));
                    getValidInsurance(insuranceDataArr);
                    cardShowHandler(1, "expand");
                    goToTop();
                  }}
                >
                  Expand
                </a>
              </div>
              <div className={styles.innerRight}>
                <a
                  href={refStr}
                  onClick={(e) => {
                    const insuranceArrTemp = cloneDeep(insuranceFormDataArr);
                    insuranceArrTemp[1] = {};
                    //delete insuranceArrTemp[currentIndex];
                    dispatch(setInsuranceData(insuranceArrTemp));
                    dispatch(setCurrentInsurance(0));
                    setIsForm(false);
                    cardShowHandler(1, "remove");
                    goToTop();
                    e.preventDefault();
                  }}
                >
                  <img src={deleteIcon} alt="Remove" />
                </a>
              </div>
              {!isErrorSecondFields && (
                <div className={styles.errorFieldsMobile}>
                  <img src={errorIcon} alt="Error" />
                  <p>{errorMessageCard}</p>
                </div>
              )}
            </div>
          </div>
        )}
    </>
  );
};
