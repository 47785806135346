import React from "react";
import { UnconnectedErrorToast } from "components/Toast/ErrorToast";
import { UnconnectedSessionExpiredToast } from "features/login/SessionExpiredToast";
import { LoadingToast } from "features/scheduling/AppointmentReview/LoadingToast";
import { MedicaidToast } from "features/scheduling/components/MedicaidToast";
import { Button } from "components/Button";

type ToastType =
  | "default"
  | "error"
  | "session-expired"
  | "loading"
  | "medicaid"
  | "medicaid-loaded";

export const ToastDemo: React.FC = () => {
  const [toast, setToast] = React.useState<undefined | ToastType>();

  const hideToast = () => setToast(undefined);
  const showToast = (toast: ToastType) => () => {
    setToast(toast);
    setTimeout(hideToast, 15000);
  };

  return (
    <div>
      <p>Click below to show one of the toasts</p>
      <Button text="error" onClick={showToast("error")} />
      <UnconnectedErrorToast
        visible={toast === "error"}
        onDismiss={hideToast}
      />
      <Button onClick={showToast("session-expired")} text="Session Expired" />
      <UnconnectedSessionExpiredToast
        visible={toast === "session-expired"}
        onDismiss={hideToast}
      />
      <Button
        text="Loading (wait 5 secs to appear)"
        onClick={showToast("loading")}
      />
      <LoadingToast isLoading={toast === "loading"} />
      <Button onClick={showToast("medicaid")} text="Medicaid" />
      <MedicaidToast
        visible={toast === "medicaid"}
        onDismiss={hideToast}
        isLoading={true}
        onShowModal={() => null}
        showMoreLocation={false}
      />
      <Button onClick={showToast("medicaid-loaded")} text="Medicaid (loaded)" />
      <MedicaidToast
        visible={toast === "medicaid-loaded"}
        onDismiss={hideToast}
        isLoading={false}
        onShowModal={() => null}
        showMoreLocation={true}
      />
    </div>
  );
};
