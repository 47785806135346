import React from "react";
import styles from "./DoctorAvatarStack.module.scss";
import { DoctorAvatar } from "components/DoctorAvatar";
import { Doctor } from "types/doctor";
import { Office } from "types/office";

interface DoctorAvatarStackProps {
  doctors: Doctor[];
  office: Office;
  callFrom?: string;
}

export const DoctorAvatarStack: React.FC<DoctorAvatarStackProps> = ({
  doctors,
  office,
  callFrom,
}) => {
  return (
    <div className={styles.stack}>
      {doctors
        .slice(0)
        .reverse() // copy and reverse doctors to account for `row-reverse` flex layout.
        .map((doctor) => (
          <DoctorAvatar
            key={doctor.id}
            size="xsmall"
            className={styles.avatar}
            doctor={doctor}
            callFrom={callFrom}
            selectedOffice={office}
          />
        ))}
    </div>
  );
};
