import { useHistory } from "react-router-dom";

export function useQueryParam<DefaultValueType>(
  param: string,
  defaultVal: DefaultValueType
): [string | DefaultValueType, (newVal: string) => void] {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const currentVal = params.get(param) || defaultVal;

  const setVal = (newVal: string) => {
    params.set(param, newVal);
    history.replace({ search: params.toString() });
  };
  return [currentVal, setVal];
}
