import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Location } from "history";
import { FormProvider, useController, useForm } from "react-hook-form";
import { Layout } from "./components/Layout";
import { Fieldset } from "./components/Fieldset";
import styles from "./AboutYou1.module.scss";
import { AboutYou1FormData, DocumentType } from "./types";
import { EthnicityOptions, RaceOptions, StateOptions } from "./constants";
import { DocumentAcknowledgment } from "./components/DocumentAcknowledgment/DocumentAcknowledgment";
import { useProgressGuard } from "./hooks/useProgressGuard";
import { Navbar } from "features/checkIn/components/Navbar";
import * as paths from "features/routing/paths";
import { TextInput } from "components/TextInput";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";
import { Introduction } from "features/checkIn/components/Introduction";
import { Typeahead } from "components/Typeahead";
import { Button } from "components/Button";
import { ConfirmNavigation } from "components/ConfirmNavigation";
import { presenceValidation } from "utils/presenceValidation";
import { isValidZip } from "utils/zipValidation";

interface AboutYou1Props {
  aboutYou1Data: AboutYou1FormData | null;
  onSubmit: (aboutYou1Data: AboutYou1FormData) => void;
}
declare let env_react: string;
export const AboutYou1: React.FC<AboutYou1Props> = ({
  aboutYou1Data,
  onSubmit,
}) => {
  useProgressGuard({ field: "aboutYou" });
  const history = useHistory();
  const formMethods = useForm<AboutYou1FormData>({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    watch,
  } = formMethods;

  const onNext = (data: AboutYou1FormData) => {
    onSubmit(data);
    history.push(paths.checkInAboutYou2());
  };

  const address1 = register(
    "address1",
    presenceValidation("Not a valid address")
  );
  const address2 = register("address2");
  const city = register("city", presenceValidation("Not a valid city"));
  const zipCode = register("zipCode", { validate: isValidZip });
  const { field: stateField } = useController({
    rules: { required: true },
    name: "state",
    control,
  });

  const showRaceEthnicityFields = watch("raceEthnicityToggle") === "yes";
  const { field: raceField } = useController({
    name: "race",
    control,
  });
  const { field: ethnicityField } = useController({
    name: "ethnicity",
    control,
  });
  const confirmNavigation = React.useCallback(
    (location: Location) => {
      if (location.pathname === paths.checkInAboutYou2()) {
        return false;
      }

      return isDirty || !!aboutYou1Data;
    },
    [isDirty, aboutYou1Data]
  );

  const envReact = env_react !== "undefined" ? env_react : "production";
  const apiKey =
    envReact === "development"
      ? "AIzaSyBZoc3kW6Ygemp-m3hsaX4v-H4BDMvHDPo"
      : "AIzaSyDxt1Xdg78h4jTetBtXEt98ZlBnQv5vbHI";
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const searchInput = React.useRef(null);
  const [isVisible, setVisible] = React.useState(false);
  stateField.onChange = (value: string) => {
    setValue("state", value);
    stateField.value = value;
  };
  // load google map api js
  const extractAddress = (
    place: any,
    searchInput: { current: { value: string } }
  ) => {
    let address1 = "";
    place.address_components.forEach(
      (component: { types: string; long_name: string; short_name: string }) => {
        const types = component.types;
        const value = component.long_name;
        if (types.includes("street_number")) {
          address1 = address1 + value;
        }
        if (types.includes("route")) {
          address1 = address1 + " " + value;
        }
        if (types.includes("locality")) {
          setValue("city", value);
        }

        if (types.includes("administrative_area_level_1")) {
          stateField.value = component.short_name;
          setValue("state", component.short_name);
        }

        if (types.includes("postal_code")) {
          setValue("zipCode", value);
        }
      }
    );
    searchInput.current.value = address1;
    setValue("address1", address1);
  };
  function loadAsyncScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeAddress = (
    autocomplete: google.maps.places.Autocomplete,
    searchInput: any
  ) => {
    const place = autocomplete.getPlace();
    extractAddress(place, searchInput);
  };
  // init gmap script
  const initMapScript = React.useCallback(() => {
    // if script already loaded
    if (window.google || !apiKey) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }, [apiKey]);

  React.useEffect(() => {
    if (isVisible) {
      initMapScript().then(() => {
        if (!searchInput.current || !apiKey) return;

        const options = {
          componentRestrictions: { country: "us" },
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          searchInput.current,
          options
        );
        autocomplete.setFields([
          "address_component",
          "geometry",
          "formatted_address",
        ]);
        autocomplete.addListener("place_changed", () =>
          onChangeAddress(autocomplete, searchInput)
        );
      });
    }
  }, [apiKey, initMapScript, onChangeAddress, isVisible]);

  const handleChangeaddress1 = (event: { target: { value: any } }) => {
    if (!isVisible) {
      if (event.target.value.length > 5) setVisible(true);
    }
    setValue("address1", event.target.value);
  };

  const handleChangeCity = (event: { target: { value: string } }) => {
    setValue("city", event.target.value);
  };

  const handleChangeZip = (event: { target: { value: string } }) => {
    setValue("zipCode", event.target.value);
  };

  return (
    <FormProvider {...formMethods}>
      <Layout
        navbar={<Navbar currentStep={1} totalSteps={2} title={"About You"} />}
        primaryCta={
          <Button
            onClick={handleSubmit(onNext)}
            renderAsDisabled={!isValid}
            text="Next"
          />
        }
        emptyHeight={true}
      >
        <ConfirmNavigation
          enableOnLocationChange={confirmNavigation}
          enableOnExit={isDirty}
        />
        <Introduction header="Tell us more about yourself">
          Please provide the patient’s information to help us personalize the
          visit.
        </Introduction>

        <div className={styles.content}>
          <Fieldset
            legend="Where do you live?"
            fieldGroupClassName={styles.addressFieldGroup}
          >
            <TextInput
              {...address1}
              ref={searchInput}
              inputType="text"
              onChange={handleChangeaddress1}
              errorMessage={errors.address1?.message}
              className={cx(styles.field, styles.fieldSpan)}
              errorClassName={cx({
                [styles.emptyError]: !errors.address1?.message,
              })}
            >
              Address
            </TextInput>

            <TextInput
              {...address2}
              inputType="text"
              errorMessage={errors.address2?.message}
              className={styles.apt}
              errorClassName={cx({
                [styles.emptyError]: !errors.address2?.message,
              })}
            >
              Apt./Suite # (optional)
            </TextInput>

            <Typeahead
              field={stateField}
              name="state"
              options={StateOptions}
              label="State"
              creatable={false}
              clearable={true}
              errorMessage={errors.state?.message}
              className={styles.field}
              errorClassName={cx({
                [styles.emptyError]: !errors.state?.message,
              })}
            />

            <TextInput
              {...city}
              inputType="text"
              errorMessage={errors.city?.message}
              className={styles.field}
              onChange={handleChangeCity}
              errorClassName={cx({
                [styles.emptyError]: !errors.city?.message,
              })}
            >
              City
            </TextInput>

            <TextInput
              {...zipCode}
              inputType="text"
              inputMode="numeric"
              maxLength={5}
              errorMessage={errors.zipCode?.message}
              className={styles.field}
              onChange={handleChangeZip}
              errorClassName={cx({
                [styles.emptyError]: !errors.zipCode?.message,
              })}
            >
              Zip
            </TextInput>
          </Fieldset>

          <Fieldset
            legend="Do you feel comfortable sharing your race/ethnicity?"
            legendClassName={styles.alwaysVisible}
          >
            <RadioButtonGroup
              className={styles.buttonGroup}
              errorMessage={errors.raceEthnicityToggle?.message}
              errorClassName={cx({
                [styles.emptyError]: !errors.raceEthnicityToggle,
              })}
            >
              <RadioButton
                className={styles.radioButton}
                {...register("raceEthnicityToggle", {
                  required: "Please select an option",
                })}
                value="yes"
                hasError={!!errors.raceEthnicityToggle}
              >
                Yes, I can share
              </RadioButton>
              <RadioButton
                className={styles.radioButton}
                {...register("raceEthnicityToggle", {
                  required: "Please select an option",
                })}
                value="no"
                hasError={!!errors.raceEthnicityToggle}
              >
                No thanks, I don’t want to share
              </RadioButton>
            </RadioButtonGroup>

            {showRaceEthnicityFields && (
              <>
                <Typeahead
                  options={RaceOptions}
                  name="race"
                  label="Race (optional)"
                  field={raceField}
                  creatable={false}
                  clearable={true}
                  className={styles.field}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.race?.message,
                  })}
                />
                <Typeahead
                  options={EthnicityOptions}
                  name="ethnicity"
                  label="Ethnicity (optional)"
                  field={ethnicityField}
                  creatable={false}
                  clearable={true}
                  className={styles.field}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.ethnicity?.message,
                  })}
                />
              </>
            )}
          </Fieldset>

          <hr className={styles.separator} />
          <Fieldset
            legend="HIPAA Notice of Privacy Practices"
            legendClassName={styles.alwaysVisible}
          >
            <DocumentAcknowledgment documentType={DocumentType.HipaaNotice} />
          </Fieldset>
        </div>
      </Layout>
    </FormProvider>
  );
};
