import React from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styles from "./AppointmentSelectDoctorSlot.module.scss";
import { WithUpcomingWeek } from "./WithUpcomingWeek";
import {
  AppointmentSelectDoctorSlotRouteProps,
  Week,
  searchDateParam,
  today,
} from "./shared";
import { NavBackButton } from "./NavBackButton";
import {
  getSlots,
  selectIsLoaded,
  selectUpcomingTimeslotsForDoctorByDate,
} from "features/scheduling/timeSlotsSlice";
import { SlotButtonsSkeleton } from "features/scheduling/components/SlotButtonsSkeleton";
import { SlotButtons } from "features/scheduling/components/SlotButtons";
import { Waitlist } from "features/scheduling/components/Waitlist";
import {
  isBackAvailable,
  isNextAvailable,
} from "features/scheduling/utils/availabilitySearchWindow";
import { updateSelectedAppointment } from "features/scheduling/appointmentCreationSlice";
import * as paths from "features/routing/paths";
import { RootState } from "app/store";
import { Button, ButtonRole } from "components/Button";
import { Navbar } from "components/Navbar";
import { Layout } from "features/layout";
import { Slot } from "types/slot";
import disabledCircleLeftIcon from "images/disabled-circle-left.svg";
import disabledCircleRightIcon from "images/disabled-circle-right.svg";
import enabledCircleLeftIcon from "images/enabled-circle-left.svg";
import enabledCircleRightIcon from "images/enabled-circle-right.svg";
import { DoctorAvatar } from "components/DoctorAvatar";
import { OfficeAddress } from "components/OfficeAddress";
import { useQueryParam } from "hooks/useQueryParam";
import { API_DATE_FORMAT } from "api/constants";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { WithOffice } from "components/WithOffice";
import { WithOfficeDoctors } from "components/WithOfficeDoctors";
import { doctorFullName } from "utils/doctorFullName";

interface ComponentProps {
  doctors: Doctor[];
  selectedOffice: Office;
  week: Week;
}

const UnconnectedAppointmentSelectDoctorSlot: React.FC<ComponentProps> = ({
  doctors,
  selectedOffice,
  week,
}) => {
  const { officeId, examType, doctorId, patientReturnStatus } =
    useParams<AppointmentSelectDoctorSlotRouteProps>();

  const dispatch = useDispatch();
  const history = useHistory();
  const [searchDate, setSearchDate] = useQueryParam(searchDateParam, today());
  const selectedDoctor = doctors.find((doctor) => doctor.id === doctorId);
  const timeSlotsForDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForDoctorByDate(state, doctorId)
  );

  const startDate = week[0].format(API_DATE_FORMAT);
  const endDate = week[week.length - 1].format(API_DATE_FORMAT);
  const areSlotsLoaded = useSelector(selectIsLoaded);

  React.useEffect(() => {
    dispatch(
      getSlots({
        officeId,
        doctorIds: [doctorId],
        startDate,
        endDate,
        examType,
        patientReturnStatus,
      })
    );
  }, [
    dispatch,
    doctorId,
    startDate,
    endDate,
    officeId,
    examType,
    patientReturnStatus,
  ]);

  const searchBack = () => {
    setSearchDate(
      dayjs(searchDate).subtract(1, "week").format(API_DATE_FORMAT)
    );
  };

  const searchNext = () => {
    setSearchDate(dayjs(searchDate).add(1, "week").format(API_DATE_FORMAT));
  };

  const onSelectSlot = (slot: Slot) => {
    dispatch(
      updateSelectedAppointment({
        officeId,
        ...slot,
      })
    );
    history.push(
      paths.appointmentsSummary({ officeId, examType, patientReturnStatus })
    );
  };

  if (!selectedDoctor) {
    return null;
  }

  const hasPrevious = isBackAvailable(searchDate);
  const hasNext = isNextAvailable(searchDate, selectedOffice.timeSlotWeeks);

  const doctorSlots = week.map((date) => {
    const dateStr = date.format(API_DATE_FORMAT);
    const slotsForDate = timeSlotsForDoctor[dateStr];

    return (
      <div key={dateStr} className={styles.dateSlots}>
        <span className={styles.date}>
          {dayjs(date).format("dddd, MMM. Do")}
        </span>
        {!areSlotsLoaded && (
          <div className={styles.buttonGrid}>
            <SlotButtonsSkeleton />
          </div>
        )}
        {areSlotsLoaded && slotsForDate && (
          <div className={styles.buttonGrid}>
            <SlotButtons slots={slotsForDate} onSelectSlot={onSelectSlot} />
          </div>
        )}
        {areSlotsLoaded && !slotsForDate && (
          <div className={styles.noAvailability}>No Available Times</div>
        )}
      </div>
    );
  });

  return (
    <Layout
      navbar={
        <Navbar
          office={selectedOffice}
          primaryLink={
            <NavBackButton>
              Optometrists in {selectedOffice.displayName}
            </NavBackButton>
          }
        />
      }
    >
      <div className={styles.page}>
        <div className={styles.doctorOfficeHeader}>
          <DoctorAvatar doctor={selectedDoctor} />
          <span className={styles.doctorName}>
            {doctorFullName(selectedDoctor)}
          </span>
          <OfficeAddress office={selectedOffice} className={styles.address} />
        </div>

        <div className={styles.content}>
          <div className={styles.weekHeader}>
            <button
              onClick={searchBack}
              disabled={!hasPrevious}
              className={styles.arrowButton}
              aria-label="View previous week"
            >
              <img
                src={
                  hasPrevious ? enabledCircleLeftIcon : disabledCircleLeftIcon
                }
                alt="left arrow"
              />
            </button>
            <span className={styles.weekText}>
              {dayjs(startDate).format("MMM. D")} -{" "}
              {dayjs(endDate).format("MMM. D")}
            </span>
            <button
              onClick={searchNext}
              disabled={!hasNext}
              className={styles.arrowButton}
              aria-label="View next week"
            >
              <img
                src={hasNext ? enabledCircleRightIcon : disabledCircleRightIcon}
                alt="right arrow"
              />
            </button>
          </div>

          <div className={styles.subHeadline}>
            Choose a time from {doctorFullName(selectedDoctor)}&rsquo;s
            schedule.
          </div>
          <div>{doctorSlots}</div>
          {hasNext && (
            <div className={styles.viewNextWeekContainer}>
              <Button
                text="View next week"
                role={ButtonRole.Standard}
                onClick={searchNext}
                size="large"
              />
            </div>
          )}
          <Waitlist
            className={styles.waitlist}
            office={selectedOffice}
            selectedDoctorId={doctorId}
          />
        </div>
      </div>
    </Layout>
  );
};

export const AppointmentSelectDoctorSlot: React.FC = () => {
  return (
    <WithOffice>
      {({ office, officeId }) => (
        <WithOfficeDoctors officeId={officeId}>
          {({ doctors }) => (
            <WithUpcomingWeek>
              {({ week }) => (
                <UnconnectedAppointmentSelectDoctorSlot
                  selectedOffice={office}
                  doctors={doctors}
                  week={week}
                />
              )}
            </WithUpcomingWeek>
          )}
        </WithOfficeDoctors>
      )}
    </WithOffice>
  );
};
