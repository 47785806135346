import React, { useState } from "react";
import cx from "classnames";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import flatpickr from "flatpickr";
// eslint-disable-next-line import/no-unresolved
import { Instance as FlatpickrInstance } from "flatpickr/dist/types/instance";

import styles from "./DateInput.module.scss";
import "./flatpickr.pxp.scss";

export type Hook = flatpickr.Options.Hook;
type Options = flatpickr.Options.Options;

interface DateInputProps {
  className?: string;
  name: string;
  onChange?: Hook;
  value?: string;
  label?: string;
  maxDate: string;
}

interface DateTimePickerPropsWithLifecycle extends DateTimePickerProps {
  onCreate?: (flatpickr: FlatpickrInstance) => void;
  onDestroy?: () => void;
}

export const DateInput: React.FC<DateInputProps> = ({
  className,
  name,
  onChange,
  value,
  label,
  maxDate,
}) => {
  const options: Options = {
    altInput: true,
    altInputClass: styles.placeholderInput,
    altFormat: "M J, D.",
    clickOpens: false, // we manage this ourselves with an onClick handler
    defaultDate: "today",
    disable: [],
    disableMobile: true,
    dateFormat: "Y-m-d",
    minDate: "today",
    maxDate: maxDate,
    monthSelectorType: "static",
    onReady: (_selected, _dateStr, fp) => {
      label && fp.altInput?.setAttribute("aria-label", label);
    },
  };

  const [isOpen, setOpen] = useState(false);
  const [flatpickr, setFlatpickr] = useState<FlatpickrInstance | null>(null);

  const toggle = () => {
    flatpickr?.toggle();
  };

  const flatpickrProps = {
    name,
    onChange,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onCreate: setFlatpickr,
    onDestroy: () => setFlatpickr(null),
    options,
    value,
  };

  return (
    <button
      data-inputname={name}
      className={cx(styles.wrapper, className, {
        [styles.wrapperIsOpen]: isOpen,
      })}
      onClick={toggle}
    >
      {
        /* Workaround <Flatpickr> not having the correct ts type signature */
        React.createElement<DateTimePickerPropsWithLifecycle>(
          Flatpickr,
          flatpickrProps
        )
      }
    </button>
  );
};
