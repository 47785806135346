import React from "react";
import cx from "classnames";
import { isEmpty } from "lodash";
import styles from "./OfficeAddress.module.scss";
import { toTel } from "features/scheduling/utils/toTel"; // "./utils/toTel";
import { Office } from "types/office";

interface OfficeAddressProps {
  office: Office;
  singleLine?: boolean | undefined;
  className?: string;
  desktopInline?: boolean;
  showOfficeNumber?: boolean;
}

export const OfficeAddress: React.FC<OfficeAddressProps> = ({
  office,
  singleLine,
  className,
  desktopInline = false, // if true, display entire address on 1 line for desktop breakpoint
  showOfficeNumber,
}) => {
  const addressSeparator = desktopInline ? (
    <>
      <span className={styles.desktopOnly}>, </span>
      <br className={styles.mobileOnly} />
    </>
  ) : (
    <br />
  );

  const distanceMiles = office.distanceMiles ? office.distanceMiles : "0";
  const display_distance_miles =
    parseFloat(distanceMiles) > 0 ? (
      <span className={styles.mileTextBox}>
        {office.distanceMiles} miles away from selected office
      </span>
    ) : (
      ""
    );

  const optionalAddress2 = isEmpty(office.address2) ? null : (
    <span>
      {addressSeparator}
      {office.address2}
    </span>
  );
  if (singleLine) {
    return (
      <address className={cx(styles.address, className)}>
        {office.address1} {office.city}, {office.stateAbbr}&nbsp;
        {office.formattedZipCode}
      </address>
    );
  } else {
    return (
      <address className={cx(styles.address, className)}>
        {office.address1}
        {optionalAddress2}
        {addressSeparator}
        {office.city}, {office.stateAbbr}&nbsp; {office.formattedZipCode}
        {addressSeparator}
        {display_distance_miles}
        {showOfficeNumber && (
          <a
            href={toTel(office.formattedPhone)}
            className={styles.footerPhoneLink}
          >
            {office.formattedPhone}
          </a>
        )}
      </address>
    );
  }
};
