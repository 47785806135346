import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import styles from "./ChooseTwoFactorMethod.module.scss";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";
import { Layout } from "features/layout";
import * as paths from "features/routing/paths";
import shieldIcon from "images/shield-checkmark-icon.svg";
import { createTwoFactorCodeRequest } from "api/createTwoFactorCodeRequest";
import { getTwoFactorPhoneNumbers } from "api/getTwoFactorPhoneNumbers";
import { useQueryParam } from "hooks/useQueryParam";
import { REDIRECT_KEY } from "features/routing/AuthorizedRouter";
import { ErrorToast } from "features/patientDashboard/ErrorToast";
import { TwoFactorPhoneNumber } from "types/twoFactorPhoneNumber";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
// import { toTel } from "features/scheduling/utils/toTel";

type FormData = {
  selectedPhoneNumberId: string;
};

export const ChooseTwoFactorMethod: React.FC = () => {
  const { handleUnauthorized, sessionStatus } = useCurrentPatient();
  const history = useHistory();
  const [destination, _setDestination] = useQueryParam(
    REDIRECT_KEY,
    paths.portalHome()
  );
  const [phoneNumbers, setPhoneNumbers] = useState<
    TwoFactorPhoneNumber[] | "loading"
  >("loading");

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<FormData>();

  useEffect(() => {
    const fetchNumbers = async () => {
      await handleUnauthorized(async () => {
        const numbers = await getTwoFactorPhoneNumbers();
        setPhoneNumbers(numbers);
        setValue("selectedPhoneNumberId", numbers[0].id);
      });
    };
    fetchNumbers();
  }, [handleUnauthorized, setPhoneNumbers, setValue]);

  if (sessionStatus === "logged-out" || sessionStatus === "expired") {
    return (
      <Redirect
        to={{
          pathname: paths.portalLogin(),
          search: `?${REDIRECT_KEY}=${destination}`,
        }}
      />
    );
  }

  if (phoneNumbers === "loading") {
    return null;
  }

  const sendTwoFactorCode = async (data: FormData) => {
    const phoneNumberId = data.selectedPhoneNumberId;
    const maskedNumber =
      phoneNumbers.find((ph) => ph.id === phoneNumberId)?.maskedPhoneNumber ??
      "";
    const finalPhoneDigits = maskedNumber.substr(maskedNumber.length - 4);
    await handleUnauthorized(async () => {
      try {
        await createTwoFactorCodeRequest({ phoneNumberId });
        history.push(
          paths.enterTwoFactorCode({
            phoneNumberId,
            query: { redirectTo: destination, finalPhoneDigits },
          })
        );
      } catch (e) {
        if (e.code === "400") {
          setError("selectedPhoneNumberId", {});
        } else {
          throw e;
        }
      }
    });
  };

  const invalidPhoneNumberMessage = (
    <>
      The number you have chosen is not a mobile number, which is required to
      proceed. “Please choose a valid number or call your local office”
    </>
  );

  return (
    <Layout>
      <ErrorToast />
      <div>
        <Card className={styles.numberSelectionCard}>
          <div className={styles.cardContents}>
            <h3 className={styles.headline}>
              Select number for verification code
            </h3>
            <div className={styles.subhead}>
              <img src={shieldIcon} alt="" />
              <p>
                In order to keep your account secure, we need to verify it’s
                you.
              </p>
            </div>
            <p>How would you like to receive your verification code?</p>
            <RadioButtonGroup
              className={styles.radioGroup}
              errorMessage={
                errors.selectedPhoneNumberId && invalidPhoneNumberMessage
              }
            >
              <ul className={styles.radios}>
                {phoneNumbers.map((ph) => {
                  return (
                    <li key={ph.id}>
                      <RadioButton
                        {...register("selectedPhoneNumberId")}
                        value={ph.id}
                      >
                        Text phone number <b>{ph.maskedPhoneNumber}</b>
                      </RadioButton>
                    </li>
                  );
                })}
              </ul>
            </RadioButtonGroup>
            <Button
              className={styles.button}
              size="large"
              text="Next"
              onClick={handleSubmit(sendTwoFactorCode)}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </Card>
      </div>
    </Layout>
  );
};
