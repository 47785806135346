import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { buildOffice, OfficeApiData } from "api/util/buildOffice";
import { Office } from "types/office";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  reducerPath: "pxpApi",
  endpoints: (build) => ({
    getNearbyOfficesWithoutAvailability: build.query<Office[], string>({
      query: (officeId) => `offices/${officeId}/nearby`,
      transformResponse: (response: OfficeApiData[]): Office[] =>
        response.map((office) => buildOffice(office)),
    }),
    getNearbyOfficesV1WithoutAvailability: build.query<Office[], string>({
      query: (officeId) => `v1_offices/${officeId}/nearby`,
      transformResponse: (response: OfficeApiData[]): Office[] =>
        response.map((office) => buildOffice(office)),
    }),
  }),
});

export const {
  useGetNearbyOfficesWithoutAvailabilityQuery,
  useGetNearbyOfficesV1WithoutAvailabilityQuery,
} = api;
