import React, { ReactNode } from "react";
import {
  FieldName,
  FieldValues,
  SetFieldValue,
  SetValueConfig,
} from "react-hook-form";
import { maskDate } from "./maskDate";
import { maskPhone } from "./maskPhone";
import { ApptTextInput, TextInput } from "components/TextInput";

interface MaskedInputProps {
  defaultValue?: string;
  name: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  setValue: (
    name: FieldName<FieldValues>,
    value: SetFieldValue<FieldValues>,
    config?: SetValueConfig
  ) => void;
  children: ReactNode;
  errorClassName?: string;
  guidedTooltip?: React.ReactNode;
  guidedTooltipClassName?: string;
}

interface MaskedTextInputProps extends MaskedInputProps {
  inputType: "text" | "tel";
  placeholder: string;
  mask: (val: string) => string;
}

export const MaskedTextInput = React.forwardRef<
  HTMLInputElement,
  MaskedTextInputProps
>(
  (
    {
      defaultValue,
      name,
      onBlur,
      onChange,
      errorMessage,
      setValue,
      children,
      inputType,
      placeholder,
      mask,
      errorClassName,
      guidedTooltip,
      guidedTooltipClassName,
    },
    ref
  ) => {
    return (
      <TextInput
        inputType={inputType}
        placeholder={placeholder}
        inputMode="numeric"
        name={name}
        onBlur={onBlur}
        errorClassName={errorClassName}
        onChange={(e) => {
          setValue(name, mask(e.target.value));
          onChange(e);
          const validInputCharacters = e.currentTarget.value.match(
            /^[\d/\-()\s]+/ // Includes numbers and format chars: parens, hyphens, dashes and spaces
          );
          let cursorIndex = 0;

          if (validInputCharacters) {
            cursorIndex = validInputCharacters[0].length;

            // Detect if the user is trying to backspace a trailing non-numeric character. If so, move cursor backwards additional character(s).
            if (
              (e.nativeEvent as InputEvent).inputType ===
              "deleteContentBackward"
            ) {
              if (validInputCharacters[0].endsWith("/")) {
                cursorIndex = cursorIndex - 1;
              } else if (validInputCharacters[0].endsWith("-")) {
                cursorIndex = cursorIndex - 1;
              } else if (validInputCharacters[0].endsWith(") ")) {
                cursorIndex = cursorIndex - 2;
              }
            }
          }

          e.target.setSelectionRange(cursorIndex, cursorIndex);
        }}
        errorMessage={errorMessage}
        defaultValue={defaultValue}
        guidedTooltip={guidedTooltip}
        guidedTooltipClassName={guidedTooltipClassName}
        ref={ref}
      >
        {children}
      </TextInput>
    );
  }
);

export const ApptMaskedTextInput = React.forwardRef<
  HTMLInputElement,
  MaskedTextInputProps
>(
  (
    {
      defaultValue,
      name,
      onBlur,
      onChange,
      errorMessage,
      setValue,
      children,
      inputType,
      placeholder,
      mask,
      errorClassName,
      guidedTooltip,
      guidedTooltipClassName,
    },
    ref
  ) => {
    return (
      <ApptTextInput
        inputType={inputType}
        placeholder={placeholder}
        inputMode="numeric"
        name={name}
        onBlur={onBlur}
        errorClassName={errorClassName}
        onChange={(e) => {
          setValue(name, mask(e.target.value));
          onChange(e);
          const validInputCharacters = e.currentTarget.value.match(
            /^[\d/\-()\s]+/ // Includes numbers and format chars: parens, hyphens, dashes and spaces
          );
          let cursorIndex = 0;

          if (validInputCharacters) {
            cursorIndex = validInputCharacters[0].length;

            // Detect if the user is trying to backspace a trailing non-numeric character. If so, move cursor backwards additional character(s).
            if (
              (e.nativeEvent as InputEvent).inputType ===
              "deleteContentBackward"
            ) {
              if (validInputCharacters[0].endsWith("/")) {
                cursorIndex = cursorIndex - 1;
              } else if (validInputCharacters[0].endsWith("-")) {
                cursorIndex = cursorIndex - 1;
              } else if (validInputCharacters[0].endsWith(") ")) {
                cursorIndex = cursorIndex - 2;
              }
            }
          }

          e.target.setSelectionRange(cursorIndex, cursorIndex);
        }}
        errorMessage={errorMessage}
        defaultValue={defaultValue}
        guidedTooltip={guidedTooltip}
        guidedTooltipClassName={guidedTooltipClassName}
        ref={ref}
      >
        {children}
      </ApptTextInput>
    );
  }
);

export const MaskedPhoneInput = React.forwardRef<
  HTMLInputElement,
  MaskedInputProps
>((props, ref) => (
  <MaskedTextInput
    {...props}
    inputType="tel"
    placeholder={"(___) ___-____"}
    mask={maskPhone}
    ref={ref}
  />
));

export const ApptMaskedPhoneInput = React.forwardRef<
  HTMLInputElement,
  MaskedInputProps
>((props, ref) => (
  <ApptMaskedTextInput
    {...props}
    inputType="tel"
    placeholder={"(___) ___-____"}
    mask={maskPhone}
    ref={ref}
  />
));

export const MaskedDateInput = React.forwardRef<
  HTMLInputElement,
  MaskedInputProps
>((props, ref) => (
  <MaskedTextInput
    {...props}
    inputType="text"
    placeholder="mm/dd/yyyy"
    mask={maskDate}
    ref={ref}
  />
));

export const ApptMaskedDateInput = React.forwardRef<
  HTMLInputElement,
  MaskedInputProps
>((props, ref) => (
  <ApptMaskedTextInput
    {...props}
    inputType="text"
    placeholder="mm/dd/yyyy"
    mask={maskDate}
    ref={ref}
  />
));
