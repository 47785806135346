import axios from "axios";
import { buildOffice, OfficeApiData } from "./util/buildOffice";
import { Office } from "types/office";

export type GetOfficeApiResponse = OfficeApiData;

export const getOfficeRequest = async (officeId: string): Promise<Office> => {
  const { data } = await axios.get<GetOfficeApiResponse>(
    `/api/offices/${officeId}`
  );

  return buildOffice(data);
};

export const getOfficeV1Request = async (officeId: string): Promise<Office> => {
  const { data } = await axios.get<GetOfficeApiResponse>(
    `/api/v1_offices/${officeId}`
  );

  return buildOffice(data);
};

export const getPilotOfficeRequest = async (): Promise<any> => {
  const { data } = await axios.get(`/api/pilot_offices`);

  return data;
};
