import React from "react";

export const VERSION = "HIPAA_Notice_Privacy_Practices_2017_09_17";

export const HIPAANoticePrivacyPractices = (): React.ReactElement => (
  <>
    <div>
      <h2>Privacy Policy</h2>
    </div>
    <h4>Effective Date: 9/17/2017</h4>
    <div>
      <p>
        At MyEyeDr., we want you to look, see and be well. We believe you have
        the right to know what information we collect from you and how we
        protect your privacy. This Privacy Policy is the foundation for all
        interactions with us on this website or through the use of our
        applications. By using our services, you are agreeing to the collection,
        use, disclosure, and procedures described in this Privacy Policy. Please
        be aware that this Privacy Policy does not extend to third party
        websites and services, such as Facebook, Instagram, Twitter, Pinterest,
        Snapchat, and Linked-in, that we do not control, even if you access them
        through our websites or applications.
      </p>
      <p>
        The entities conducting business under the name “MyEyeDr.” (“MyEyeDr.”,
        “we”, “our” or “us”) make available a website having a uniform resource
        locator of www.myeyedr.com (the “Site”) and a web-based patient portal
        having a uniform resource locator of
        https://secure.myeyedr.com/my-account/login (the “Patient Portal”). By
        using the Site, the Patient Portal, or any other services provided by
        MyEyeDr. on the Site or Patient Portal (collectively, the “Services”),
        you consent to the privacy practices described in this Privacy Policy.
      </p>
      <p>
        We may periodically make changes to this Privacy Policy and in such
        event will post a new version of this Privacy Policy on the Site and/or
        the Patient Portal. It is your responsibility to review this Privacy
        Policy each time you visit the Site and/or the Patient Portal and remain
        informed about any changes to it. Your continued use of the Site, the
        Patient Portal, or the Services after any changes are made to this
        Privacy Policy constitutes your agreement to the Privacy Policy as
        modified.
      </p>
      <p>
        We understand the importance of your privacy and are committed to
        maintaining the confidentiality of your personal information, including
        your protected health information. Additional privacy practices related
        to the protection of your protected health information can be found in
        our Notice of Privacy Practices, which is located below.
      </p>
    </div>
    <div>
      <h2>
        1. PRIVACY PRACTICES RELATED TO ALL PERSONAL INFORMATION COLLECTED BY
        MYEYEDR.
      </h2>
    </div>
    <div>
      <h4>Collection of Information</h4>
      <ul>
        <li>
          Using the Site, you can request an appointment with a MyEyeDr. doctor.
          In order to request an appointment via the Site, you must provide to
          us certain information, such as the requested appointment date, the
          reason for your visit, your first and last name, email address, date
          of birth, address, phone number, and insurance information.
        </li>
        <li>
          You may choose to register to use the Patient Portal. When you
          register to use the Patient Portal, you will be required to provide
          certain information, including your first and last name, email
          address, phone number, and date of birth. In addition, during
          registration you will be assigned a username (usually your email
          address) and will be asked to create an account password (the username
          and password are collectively referred to herein as the “Credentials”)
          in order to access the Patient Portal.
        </li>
        <li>
          Using the Patient Portal, you can provide MyEyeDr. with your medical
          history, insurance information, details regarding your lifestyle and
          hobbies, your current medications, etc. You can also schedule an
          appointment with a MyEyeDr. doctor or submit a question to a MyEyeDr.
          doctor or other medical professional. Further, you can view your
          current prescription information, upcoming appointments, recent
          orders, and other personal information that we maintain about you.
        </li>
        <li>
          In addition to the information that you provide us via the Site, the
          Patient Portal and the Services, MyEyeDr. automatically receives and
          stores certain types of information when you use the Services, such as
          your computer’s IP address, browser information, the domain and host
          from which you access the Internet, etc.
        </li>
      </ul>
      <h4>Use of Your Information</h4>
      <ul>
        <li>
          If you provide information to us in order to schedule an appointment
          via the Site, we will use such information in order to schedule your
          appointment. If you register to use the Patient Portal and use the
          Patient Portal to provide MyEyeDr. with your medical history,
          insurance information, etc., your information will be used by MyEyeDr.
          to provide you with optometry services and other services offered by
          MyEyeDr. to its patients.
        </li>
        <li>
          We may use any of the information that you provide via the Site, the
          Patient Portal or the Services to provide you with the services that
          you have requested, to answer any questions you may have, and to
          assist you in using the Site, the Patient Portal, or the Services.
        </li>
        <li>
          Information collected automatically by MyEyeDr. may also be used to
          improve the content and functionality of the Site, the Patient Portal,
          and the Services, to improve customer service, to process
          transactions, or to deliver new services. Further, MyEyeDr. may use
          the anonymized, aggregated, and statistical data derived from the
          operation and use of the Site, the Patient Portal, and/or the Services
          (but not derived from your protected health information) (“Aggregated
          Data”) and may share this Aggregated Data with third parties. For
          information about how we use your protected health information, please
          see our Notice of Privacy Practices.
        </li>
      </ul>
      <h4>Disclosure and Sharing of Information</h4>
      <ul>
        <li>
          We will only sell or share your information with a third party as
          disclosed in this Privacy Policy and our Notice of Privacy Practices.
        </li>
        <li>
          We will share your information with our employees who have a
          legitimate need to use such information in the performance of their
          duties. We may also share your information with our affiliated
          companies, companies with which we have entered into a management
          agreement or other similar arrangements, and/or trusted third parties
          (such as agents and subcontractors) who assist MyEyeDr. in operating
          our Site and/or Patient Portal, providing the Services, or conducting
          business functions on our behalf.
        </li>
        <li>
          We may disclose your information if required to do so by law or in the
          good faith belief that such action is necessary to comply with a legal
          obligation, protect and defend the rights or property of MyEyeDr., act
          in urgent circumstances to protect the personal safety of users of the
          Site, the Patient Portal or the Services, or protect against legal
          liability.
        </li>
        <li>
          In the event MyEyeDr., or any of its office(s), is acquired by, or
          merges with or consolidates into, another entity, or if there is a
          sale of any of our assets, your information may be transferred to the
          entity acquiring us or our assets, or that survives the merger or
          consolidation. Moreover, in the unlikely event that MyEyeDr. goes out
          of business or enters bankruptcy, information would be one of the
          assets that is transferred or acquired by a third party. You
          acknowledge and agree that in the foregoing circumstances, your
          information may be disclosed to such third party (in whole or in
          part), and such third party may continue to use your information.
        </li>
        <li>
          Except as set forth above or elsewhere in this Privacy Policy, or as
          required by law, you will be notified when your information may be
          shared with third parties, and will be given the opportunity to object
          to, or if legally required, to authorize the sharing of this
          information.
        </li>
      </ul>
      <h4>Protection of Your Information</h4>
      <ul>
        <li>
          MyEyeDr. uses a variety of security measures to make sure your
          information is reasonably safe. We seek to secure your data through
          the programming of our Site, the Patient Portal, and the Services and
          the use of security measures that we deem appropriate for the type of
          data provided.
        </li>
        <li>
          Notwithstanding the foregoing, we cannot completely guarantee that no
          part of our system will ever fail or be compromised. If you ever
          suspect that the Site, the Patient Portal, or the Services have
          contributed to your information being compromised, please contact us
          immediately so that we can investigate and try to resolve the matter.
        </li>
      </ul>
      <p></p>
    </div>
    <div>
      <h2>2. INFORMATION WE AUTOMATICALLY COLLECT</h2>
    </div>
    <div>
      <p>
        We record certain information relating to your use of the Services, like
        the device and browser information, IP addresses, referring and exit
        pages, and search terms. Like most websites, the Site, the Patient
        Portal, and the Services may incorporate technology such as pixel tags,
        web beacons, and cookies that allow us and our service providers to
        collect information about your actions using the Services. Cookies are
        small text files stored by your browser on your computer when you use
        the Site, the Patient Portal, and the Services. Cookies permit us to
        recognize users and avoid repetitive requests for the same information,
        understand your preferences for future visits, and compile data about
        site interaction to help us provide a better experience in the future.
        You may be able to set your web browser to reject cookies entirely or
        require that it request permission from you before accepting each new
        cookie. You may also delete cookies from your web browser once you leave
        a website.
      </p>
    </div>
    <div>
      <h2>3. THIRD PARTY LINKS</h2>
    </div>
    <div>
      <p>
        The Site, the Patient Portal, and the Services may contain links to
        other third party websites or applications (each, a “Third Party Site”).
        Please be aware that we are not responsible for the privacy practices or
        policies of these Third Party Sites. We therefore have no responsibility
        or liability for the content or actions of these Third Party Sites. We
        encourage you to review the privacy policies and practices of each Third
        Party Site that you visit.
      </p>
    </div>
    <div>
      <h2>4. MINORS</h2>
    </div>
    <div>
      <p>
        The Site, the Patient Portal, and the Services are not intended for use
        by, or directed to, persons under the age of 18. Any individual who
        provides their information to MyEyeDr. represents to us that they are 18
        years of age or older.
      </p>
    </div>
    <div>
      <h2>5. CALIFORNIA DO NOT TRACK DISCLOSURE</h2>
    </div>
    <div>
      <p>
        At this time, MyEyeDr. does not respond to browser ‘Do Not Track’
        signals.
      </p>
    </div>
    <div>
      <h2>6. CALIFORNIA SHINE THE LIGHT LAW</h2>
    </div>
    <div>
      <p>
        California Civil Code Section 1798.83, known as the “Shine the Light”
        law, permits our customers who are California residents to request and
        obtain from us a list of what personal information (if any) we disclosed
        to third parties for direct marketing purposes in the preceding calendar
        year and the names and addresses of those third parties. We will never
        disclose your personal information to third parties for their direct
        marketing purposes without your authorization. All other disclosures of
        personal information to third parties shall be in accordance with this
        Privacy Policy and our Notice of Privacy Practices. However, if you
        would like to make a request for information under the “Shine the Light”
        law, please contact us by email at privacy@myeyedr.com or by mail at
        MyEyeDr., Attn: Compliance Department, 8614 Westwood Center Dr., Suite
        900, Vienna, VA 22182. Requests may be made only once a year and are
        free of charge.
      </p>
    </div>
    <div>
      <h2>7. CONTACT US</h2>
    </div>
    <div>
      <p>
        If you have questions and would like additional information, you may
        contact our Compliance Officer, Rebecca Beyer, at:
      </p>
      <p>8614 Westwood Center Dr., Suite 900, Vienna, VA 22182</p>
      <p>privacy@myeyedr.com</p>
    </div>
    <div>
      <h2>Notice of Privacy Practices</h2>
    </div>
    <div>
      <p>Notice Origination: 10/01/2001</p>
      <p>Notice Revised Effective: 09/23/2013</p>
      <p>Notice Revised Effective: 12/31/2022</p>
      <p>
        <em>
          This notice describes how medical information about you may be used
          and disclosed, and how you can get access to this information. Please
          review it carefully.
        </em>
      </p>
    </div>
    <div>
      <h2>1. PRIVACY PRACTICES RELATED TO PROTECTED HEALTH INFORMATION</h2>
    </div>
    <div>
      <p>
        In addition to the privacy practices described in our Privacy Policy, we
        are required by law to maintain the privacy and security of protected
        health information, to provide individuals with notice of our legal
        duties and privacy practices with respect to protected health
        information, and to notify affected individuals following a breach of
        unsecured protected health information. This Notice of Privacy Practices
        (“Notice”) describes how we may use and disclose your protected health
        information. It also describes your rights and our legal obligations
        with respect to your protected health information.
      </p>
      <p>
        For purposes of this Notice, “protected health information” is
        information that individually identifies you and that we create or get
        from you or from another health care provider, health plan, your
        employer, or a health care clearinghouse and that relates to (1) your
        past, present, or future physical or mental health or conditions; (2)
        the provision of health care to you; or (3) the past, present, or future
        payment for your health care.
      </p>
      <ul>
        <li>
          <p>
            <strong>Treatment, Payment, and Health Care Operations</strong>
          </p>
          <ul>
            <li>
              <p>
                The most common reason why we use or disclose your protected
                health information is to treat you, bill for your services, or
                run our organization.
              </p>
            </li>
            <li>
              <p>
                Examples of how we use or disclose information for treatment
                purposes include:
              </p>
              <ul>
                <li>
                  <p>Setting up an appointment for you;</p>
                </li>
                <li>
                  <p>Testing or examining your eyes;</p>
                </li>
                <li>
                  <p>Maintaining the Patient Portal;</p>
                </li>
                <li>
                  <p>
                    Prescribing glasses, contact lenses, or eye medications and
                    sending them to be filled;
                  </p>
                </li>
                <li>
                  <p>
                    Referring you to another doctor or clinic for eye care or
                    services; and
                  </p>
                </li>
                <li>
                  <p>
                    Getting copies of your protected health information from
                    another professional that you may have seen before us.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Examples of how we use or disclose your protected health
                information for payment purposes include:
              </p>
              <ul>
                <li>
                  <p>
                    Asking you about your health or vision care plans, or other
                    sources of payment;
                  </p>
                </li>
                <li>
                  <p>
                    Billing or sending claims for your services to get payment
                    from health plans or other entities; and
                  </p>
                </li>
                <li>
                  <p>
                    Collecting unpaid amounts (either directly or through a
                    collection agency or attorney).
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Examples of how we use or disclose your protected health
                information for health care operations include:
              </p>
              <ul>
                <li>
                  <p>Financial or billing audits;</p>
                </li>
                <li>
                  <p>Internal quality assurance to improve your care;</p>
                </li>
                <li>
                  <p>Personnel decisions;</p>
                </li>
                <li>
                  <p>Participation in managed care plans;</p>
                </li>
                <li>
                  <p>Defense of legal matters;</p>
                </li>
                <li>
                  <p>Business planning; and</p>
                </li>
                <li>
                  <p>Outside storage of our records.</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>
              Uses and Disclosures for Other Reasons Without Permission
            </strong>
          </p>
          <ul>
            <li>
              <p>
                In some limited situations, the law allows or requires us to use
                or disclose your protected health information without your
                permission – usually in ways that contribute to the public good,
                such as public health and research. Not all of these situations
                will apply to us, and some may never come up at our office(s) at
                all. We also have to meet many conditions in the law before we
                can share your information for these purposes. Such uses or
                disclosures include:
              </p>
              <ul>
                <li>
                  <p>
                    When a state or federal law requires that certain protected
                    health information be reported for a specific purpose;
                  </p>
                </li>
                <li>
                  <p>
                    For public health and safety issues, such as preventing
                    disease, helping with product recalls, or reporting adverse
                    reactions to medicine;
                  </p>
                </li>
                <li>
                  <p>
                    Reporting suspected abuse, neglect, or domestic violence;
                  </p>
                </li>
                <li>
                  <p>
                    Uses and disclosures for health oversight activities, such
                    as for the licensing of optometrists, audits by the Centers
                    for Medicare & Medicaid Services related to their programs,
                    or to the Department of Health and Human Services if it
                    wants to see that we’re complying with federal privacy law;
                  </p>
                </li>
                <li>
                  <p>
                    Disclosures for law enforcement purposes, such as to provide
                    information about someone who is or is suspected to be a
                    victim of a crime;
                  </p>
                </li>
                <li>
                  <p>
                    To provide information with a law enforcement official about
                    a crime in our office, or to report a crime that happened
                    somewhere else;
                  </p>
                </li>
                <li>
                  <p>
                    Disclosures to prevent or reduce a serious threat to
                    anyone’s health or safety;
                  </p>
                </li>
                <li>
                  <p>Disclosures addressing worker’s compensation claims;</p>
                </li>
                <li>
                  <p>
                    Disclosures or research under certain circumstances, or
                    disclosures to “Business Associates” who perform services
                    for us that involve the use or disclosure of your health
                    information, such as billing or transcription services, and
                    which commit to respect the privacy of your health
                    information;
                  </p>
                </li>
                <li>
                  <p>
                    To respond to organ and tissue donation requests from organ
                    procurement organizations;
                  </p>
                </li>
                <li>
                  <p>
                    To respond to lawsuits and legal actions, a court or
                    administrative order, or a subpoena;
                  </p>
                </li>
                <li>
                  <p>
                    For special government functions, such as military, national
                    security, and presidential protective services;
                  </p>
                </li>
                <li>
                  <p>
                    To coroners, medical examiners, or funeral directors when an
                    individual dies so that they may carry out their duties; and
                  </p>
                </li>
                <li>
                  <p>For inmates as permitted by law.</p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Also, if a breach occurs that may have compromised the privacy
                or security of your protected health information, we will use
                your protected health information to promptly notify you of such
                breach as required by law.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Your Choices for Uses and Disclosures</strong>
          </p>
          <ul>
            <li>
              <p>
                For certain health information, you can tell us your choices
                about what we share.
              </p>
            </li>
            <li>
              <p>
                You have both the right and choice to tell us to share relevant
                information about your care with your family, close friends, or
                others involved in your care.
              </p>
            </li>
            <li>
              <p>
                We may also disclose your protected health information to
                disaster relief organizations to coordinate your care or notify
                family and friends of your location or conditions in a disaster.
              </p>
            </li>
            <li>
              <p>
                If you are not able to tell us your preference for certain uses
                and disclosures, for example if you are unconscious, we may go
                ahead and share your information if we believe it is in your
                best interest.
              </p>
            </li>
            <li>
              <p>
                We will provide you with the opportunity to object or agree to
                such a disclosure whenever we practicably can do so.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>
              Appointment Reminders and Treatment Alternatives and Services
            </strong>
          </p>
          <ul>
            <li>
              <p>
                We may use and disclose your protected health information to
                contact you to remind you that you have an appointment for
                medical care, to discuss the status of an order, or to contact
                you about possible treatment options, alternatives or services
                and items that may be of interest to you.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Other Uses and Disclosures</strong>
          </p>
          <ul>
            <li>
              <p>
                Uses and disclosures of your protected health information for
                marketing purposes and disclosures that constitute a sale of
                your protected health information will only be made with your
                written authorization. We will not make any other uses or
                disclosures of your protected health information unless you sign
                a written “authorization form” with content mandated by federal
                law. We may initiate the authorization process if the use or
                disclosure is our idea, or you may initiate the process for us
                to send your information to someone else. Typically, if you
                initiate the process, we will provide you with an authorization
                form to properly complete and sign.
              </p>
            </li>
            <li>
              <p>
                If we initiate the process and ask you to sign an authorization
                form, you do not have to sign it. If you do not sign the
                authorization, we cannot make the use or disclosure. If you do
                sign the authorization form, you may revoke it at any time
                unless we have already acted in reliance upon it. Revocations
                must be in writing to the Compliance Officer at the address
                shown below in the Section entitled ‘Contact Us’.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Your Rights Regarding Your Health Information</strong>
          </p>
          <ul>
            <li>
              <p>
                <strong>
                  When it comes to your health information, you have certain
                  rights.
                </strong>{" "}
                This section explains your rights and some of our
                responsibilities to help you. You can:
              </p>
            </li>
            <li>
              <p>
                <em>Get an electronic or paper copy of your medical record.</em>{" "}
                You can ask to see or get an electronic or paper copy of your
                medical record and other protected health information we have
                about you. We will provide a copy or a summary of your protected
                health information, generally within 30 days of your request
                (although we may have one 30-day extension if we send you
                written notice of the extension). We may charge you a
                reasonable, cost-based fee to the extent permitted by state and
                federal law. We will try to provide access to your protected
                health information in the form or format that you request, if it
                is readily producible in such form or format. If you want to
                review or get paper or electronic copies of your health
                information, you must send a written request to the Compliance
                Officer at the address shown below in the section entitled
                ‘Contact Us’.
              </p>
            </li>
            <li>
              <p>
                <em>Ask us to amend your medical record.</em> You can ask us to
                correct health information about you that you think is incorrect
                or incomplete. You must send a written request that includes
                your reasons for the amendment, to the Compliance Officer at the
                address shown below in the section entitled ‘Contact Us’. If we
                do not agree with your request, we will let you know why in
                writing within 60 days.
              </p>
            </li>
            <li>
              <p>
                <em>Request confidential communications.</em> You can ask us to
                contact you in a specific way, such as by phoning you at work
                rather than at home, or to send mail to a different address. We
                will accommodate all reasonable. If you want to ask for
                confidential communications, you must send a written request to
                the Compliance Officer at the address shown below in the section
                entitled ‘Contact Us’.
              </p>
            </li>
            <li>
              <p>
                <em>Ask us to limit what we use or share.</em> You have the
                right to request restrictions on certain uses and disclosures of
                your protected health information for treatment, payment, or our
                operations, by sending a written request specifying what
                information you want to limit, and what limitations on our use
                or disclosure of that information you wish to have imposed, to
                the Compliance Officer at the address shown below in the section
                entitled ‘Contact Us’. If you ask us not to disclose information
                for the purpose of payment or our operations with your health
                insurer for a service or health care item you paid out-of-pocket
                in full, we will abide by your request unless a law requires us
                to share that information. We reserve the right to accept or
                reject any other request, especially if it would affect your
                care.
              </p>
            </li>
            <li>
              <p>
                <em>Get a list of those with whom we’ve shared information.</em>
                You can ask for a list (called an "accounting") of certain
                disclosures that we have made of your protected health
                information within the past six years (or a shorter period if
                you want), who we shared it with, and why. We will include all
                the disclosures except for those about treatment, payment or
                health care operations, and certain other disclosures, such as
                disclosures made with your authorization and disclosures
                required by law. We’ll provide one accounting a year for free
                but will charge a reasonable, cost-based fee if you ask for
                another one within 12 months. We will inform you of the costs in
                advance and you may choose to withdraw or modify your request
                before the costs are incurred. You must send a written request
                for an accounting to the Compliance Officer at the address shown
                below in the section entitled ‘Contact Us’.
              </p>
            </li>
            <li>
              <p>
                <em>Get a copy of this Privacy Notice.</em> You may ask for a
                paper copy of this Notice at any time, even if you have agreed
                to receive the notice electronically, by sending a written
                request to our Compliance Officer at the address shown below in
                the section entitled ‘Contact Us’. We will promptly provide you
                with a paper copy.
              </p>
            </li>
            <li>
              <p>
                <em>Choose someone to act for you.</em> If you have given
                someone medical power of attorney or if someone is your legal
                guardian, that person can exercise your rights and make choices
                about your health information. We will make sure the person has
                this authority and can act for you before we take any action
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>
              Additional Rights Under State Law and Other Federal Laws
            </strong>
          </p>
          <ul>
            <li>
              <p>
                If another state or federal law requires us to give more
                protection to your protected health information than stated in
                this Notice, we will comply with that law.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Changes to this Notice</strong>
          </p>
          <ul>
            <li>
              <p>
                We can change the terms of this Notice at any time as allowed by
                law. If we change the Notice, the new privacy practices will
                apply to your protected health information that we already have
                as well as to such information that we may generate in the
                future. The new Notice will be available upon request, in our
                office, and on our website.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <h2>2. CONTACT US</h2>
    </div>
    <div>
      <p>
        If you have questions and would like additional information, you may
        contact our Compliance Officer, Rebecca Beyer, at:
      </p>
      <p>8614 Westwood Center Dr., Suite 900, Vienna, VA 22182</p>
      <p>privacy@myeyedr.com</p>
      <p>
        If you believe your privacy rights have been violated, you can file a
        complaint with us by contacting our Compliance Officer. You may also
        report a complaint with the U.S. Department of Health and Human Services
        Office for Civil Rights (OCR). There will be no retaliation for filing a
        complaint with either our practice or the OCR.
      </p>
    </div>
  </>
);
