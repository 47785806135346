import axios from "axios";
import { SerializedError } from "@reduxjs/toolkit";

export const configure = (): void => {
  axios.interceptors.request.use((config) => {
    config.headers ||= {};
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Shape the thrown error to match redux-thunk's serialized error
      // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      const serializeableError: SerializedError = {
        ...error,
        code: error.response?.status?.toString(),
      };
      return Promise.reject(serializeableError);
    }
  );
};
