import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { waitListRequest } from "api/waitListRequest";
import { RootState } from "app/store";

const SLICE_NAME = "waitList";

export const joinWaitList = createAsyncThunk(
  `${SLICE_NAME}/waitList`,
  waitListRequest
);

export enum CreatedStatus {
  isPending,
  Created,
  Failed,
}

type WaitListState = {
  isLoading: boolean;
  createdStatus: CreatedStatus;
};

export const initialState: WaitListState = {
  isLoading: false,
  createdStatus: CreatedStatus.isPending,
};

export const waitListSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(joinWaitList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          createdStatus: CreatedStatus.isPending,
        };
      })
      .addCase(joinWaitList.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          createdStatus: CreatedStatus.Failed,
        };
      })
      .addCase(joinWaitList.fulfilled, (state) => {
        return {
          ...state,
          isLoading: false,
          createdStatus: CreatedStatus.Created,
        };
      });
  },
});

export const reducer = waitListSlice.reducer;

export const waitListLoadingState = (state: RootState): boolean =>
  state.waitList.isLoading;

export const waitListCreateStatusState = (state: RootState): CreatedStatus =>
  state.waitList.createdStatus;
