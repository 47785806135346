import axios from "axios";

export interface GetOrderStatusResponse {
  OrderNum: number;
  OrderDate: string;
  ExpectDate: string;
  StatusCode: string;
  OrderType: "E" | "S";
}

export const getEyefinitySlotRequest = async (
  officeId: string,
  startDate: string,
  endDate: string
): Promise<any> => {
  try {
    const { data } = await axios.get<any>(
      `/api/v2/eyefinity_provider_slots?office_id=${officeId}&start_date=${startDate}&end_date=${endDate}`
    );
    return data;
    /*
    return data.order_tracking_info.map((pref) => ({
      orderNum: pref.order_num,
      orderDate: pref.order_date,
      orderTypeName: pref.order_type_name,
      expectDate: pref.expect_date,
      fulfillmentType: pref.fulfillment_type,
      fulfillmentDestination: pref.fulfillment_destination,
      displayByline: pref.display_byline,
      displayStatus: pref.display_status,
    })); */
  } catch (err) {
    return [];
  }
};
