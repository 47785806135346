import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  clearPatientDateOfBirth,
  selectPatient,
  updatePatient,
} from "./appointmentCreationSlice";
import styles from "./AppointmentAccountSetup.module.scss";
import {
  ValidatePatientModal,
  validatePatientModalAria,
} from "./ValidatePatientModal";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { Button, ButtonRole } from "components/Button";
import * as paths from "features/routing/paths";
// import { TextInput } from "components/TextInput";
import { AppDispatch } from "app/store";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Layout } from "features/layout";
import { API_DATE_FORMAT } from "api/constants";
import {
  ApptMaskedDateInput,
  ApptMaskedPhoneInput,
  // MaskedDateInput,
  // MaskedPhoneInput,
} from "components/MaskedTextInput/MaskedTextInput";
import { Doctor } from "types/doctor";
import { maskPhone } from "components/MaskedTextInput/maskPhone";
import { Navbar } from "components/Navbar";
import { WithOffice } from "components/WithOffice";
import { Office } from "types/office";
import {
  isEmail,
  isValidTLDLength,
  acceptableCharacters,
} from "utils/emailValidation";
import { isValidDateOfBirth } from "utils/dateOfBirthValidation";
import { isValidDateOfBirthVEH } from "utils/dateOfBirthValidationVEH";
import { isValidDOBRestrictVEH } from "utils/isValidDOBRestrictVEH";
import { isValidPhoneNumber } from "utils/phoneNumberValidation";
import { presenceNameValidation } from "utils/presenceNameValidation";
import { SmsCheckbox } from "components/SmsCheckbox";
import { ApptTextInput } from "components/TextInput";
import { Modal } from "components/Modal";

interface AppointmentAccountSetupProps {
  doctor: Doctor;
  office: Office;
}

const UnconnectedAppointmentAccountSetup: React.FC<
  AppointmentAccountSetupProps
> = ({ doctor, office }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const patient = useSelector(selectPatient);
  const [showRescheduleModal, setShowRescheduleModal] = React.useState(false);
  const { officeId, examType, patientReturnStatus } =
    useParams<
      paths.RequiredSchedulingRouteParams<
        "officeId" | "examType" | "patientReturnStatus"
      >
    >();
  usePageViewTracking("step-6-reviewed-doctor-and-time", {
    doctorId: doctor.id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    formState,
  } = useForm({
    mode: "onBlur",
  });
  const firstName = register(
    "firstName",
    presenceNameValidation(
      "Not a valid first name; no special characters except '-' allowed"
    )
  );
  const lastName = register(
    "lastName",
    presenceNameValidation(
      "Not a valid last name; no special characters except '-' allowed"
    )
  );
  const phoneNumber = register("phoneNumber", {
    validate: isValidPhoneNumber,
  });

  let dateOfBirth = register("dateOfBirth", {
    validate: isValidDateOfBirth,
  });

  if (doctor?.offersVEH) {
    if (!office.ageRestrictVEH) {
      dateOfBirth = register("dateOfBirth", {
        validate: isValidDateOfBirthVEH,
      });
    } else {
      dateOfBirth = register("dateOfBirth", {
        validate: isValidDOBRestrictVEH,
      });
    }
  }
  const email = register("email", {
    required: "Not a valid email",
    validate: {
      isEmail: (value) => isEmail(value),
      isValidTLDLength: (value) => isValidTLDLength(value),
      acceptableCharacters: (value) => acceptableCharacters(value),
    },
  });
  const smsOptInNotices = register("smsOptInNotices");
  const smsOptInPromos = register("smsOptInPromos");

  const backToAvailability = () => {
    history.push(
      paths.appointmentsAvailableAppointments({
        officeId,
        examType,
        patientReturnStatus,
      })
    );
  };

  const onNext = async (_: unknown, e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();

    history.push(
      paths.appointmentsReview({ officeId, examType, patientReturnStatus })
    );
  };

  return (
    <Layout navbar={<Navbar office={office} />}>
      <Modal
        aria={validatePatientModalAria}
        isOpen={showRescheduleModal}
        onRequestClose={() => setShowRescheduleModal(false)}
        closeOnOverlayClick={false}
        className={styles.modal}
      >
        <ValidatePatientModal
          setShowRescheduleModal={setShowRescheduleModal}
          goBack={backToAvailability}
          formattedPhone={office.formattedPhone}
        />
      </Modal>
      <div className={styles.container}>
        <h1 className={styles.header}>Patient Details</h1>
        <form onSubmit={handleSubmit(onNext)} className={styles.form}>
          <div className={styles.twoColumns}>
            <ApptTextInput
              {...firstName}
              inputType="text"
              onChange={(e) => {
                dispatch(
                  updatePatient({ field: "firstName", value: e.target.value })
                );
                firstName.onChange(e);
              }}
              errorMessage={errors.firstName?.message}
              defaultValue={patient?.firstName}
            >
              First Name
            </ApptTextInput>

            <ApptTextInput
              {...lastName}
              inputType="text"
              onChange={(e) => {
                dispatch(
                  updatePatient({ field: "lastName", value: e.target.value })
                );
                lastName.onChange(e);
              }}
              errorMessage={errors.lastName?.message}
              defaultValue={patient?.lastName}
            >
              Last Name
            </ApptTextInput>
          </div>

          <div className={styles.desktopTwoColumns}>
            <ApptMaskedPhoneInput
              {...phoneNumber}
              onChange={(e) => {
                dispatch(
                  updatePatient({
                    field: "phoneNumber",
                    value: e.target.value.replace(/\D/g, ""),
                  })
                );
                phoneNumber.onChange(e);
              }}
              setValue={setValue}
              errorMessage={errors.phoneNumber?.message}
              defaultValue={
                patient?.phoneNumber && maskPhone(patient.phoneNumber)
              }
              guidedTooltip={
                <p className={styles.tooltipText}>
                  Entering a <b>mobile number</b> helps{" "}
                  <b>keep your information secure</b> because we can verify your
                  identity for future appointments and check-ins by sending you
                  a text message.
                </p>
              }
              guidedTooltipClassName={
                errors.phoneNumber?.message && styles.tooltipHavingError
              }
            >
              Phone Number (mobile preferred)
            </ApptMaskedPhoneInput>
            <ApptMaskedDateInput
              {...dateOfBirth}
              onChange={(e) => {
                const parsedDate = dayjs(e.target.value);
                if (parsedDate.isValid()) {
                  dispatch(
                    updatePatient({
                      field: "dateOfBirth",
                      value: parsedDate.format(API_DATE_FORMAT),
                    })
                  );
                } else {
                  dispatch(clearPatientDateOfBirth());
                }
                dateOfBirth.onChange(e);
              }}
              errorMessage={errors.dateOfBirth?.message}
              setValue={setValue}
              defaultValue={
                patient?.dateOfBirth &&
                dayjs(patient?.dateOfBirth).format("MM/DD/YYYY")
              }
            >
              Date of Birth
            </ApptMaskedDateInput>
          </div>

          <ApptTextInput
            {...email}
            inputType="email"
            onChange={(e) => {
              dispatch(
                updatePatient({ field: "email", value: e.target.value })
              );
              email.onChange(e);
            }}
            errorMessage={errors.email?.message}
            defaultValue={patient?.email}
          >
            Email
          </ApptTextInput>

          <SmsCheckbox
            label="I'd like to receive text reminders about my appointment, about my prescription, and when my glasses or contacts are ready. (MyEyeDr. Notices)"
            defaultChecked={false}
            {...smsOptInNotices}
            onChange={(e) => {
              dispatch(
                updatePatient({
                  field: "smsOptInNotices",
                  value: e.target.checked,
                })
              );
              smsOptInNotices.onChange(e);
            }}
          />
          <SmsCheckbox
            label="I'd like to receive promotional texts with eyewear and eyecare offers. (MyEyeDr. Promos)"
            defaultChecked={false}
            {...smsOptInPromos}
            onChange={(e) => {
              dispatch(
                updatePatient({
                  field: "smsOptInPromos",
                  value: e.target.checked,
                })
              );
              smsOptInPromos.onChange(e);
            }}
          />
          <p className={styles.smsHelp}>
            Message frequency varies. Message and data rates may apply. At any
            time you can text HELP for help or text STOP to stop notifications.{" "}
            <a
              href="https://www.myeyedr.com/sms-terms-service"
              target="_blank"
              rel="noreferrer"
            >
              SMS Terms of Service
            </a>{" "}
            |{" "}
            <a
              href="https://www.myeyedr.com/privacy-policy-practices"
              target="_blank"
              rel="noreferrer"
            >
              MyEyeDr. Privacy Policy
            </a>
          </p>

          <div className={styles.agreeText}>
            I agree that you can contact me by text at the mobile number
            provided. By agreeing I consent to receiving texts about promotional
            offers related to eyewear and eyecare offers by any type of dialing
            equipment, automated technology, or other messaging system to send
            the communications. I understand message frequency varies and that
            data and message rates may apply. I understand that I am not
            required to agree to such messaging, and it is not a condition for
            the purchase of any goods or services.
          </div>
          <br />

          <div className={styles.buttons}>
            <Button
              text="Back"
              role={ButtonRole.Ghost}
              onClick={() => {
                history.goBack();
              }}
              size="large"
              className={styles.button}
            />
            <Button
              text="Next"
              onClick={handleSubmit(onNext)}
              type="submit"
              size="large"
              className={styles.button}
              renderAsDisabled={!formState.isValid}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export const AppointmentAccountSetup: React.FC = () => (
  <NeedsSelectedAppointment>
    {({ doctor }) => (
      <WithOffice>
        {({ office }) => (
          <UnconnectedAppointmentAccountSetup office={office} doctor={doctor} />
        )}
      </WithOffice>
    )}
  </NeedsSelectedAppointment>
);
