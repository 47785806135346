import axios from "axios";
import { normalizeDate } from "./util/normalizeDate";
import { InsuranceFormData, RelationToPatient } from "features/checkIn/types";

export type CreateInsuranceCoverageArgs = InsuranceFormData[];

export const createInsuranceCoverageRequest = async (
  params: CreateInsuranceCoverageArgs
): Promise<void> => {
  const finalInsuranceData: any = [];
  // eslint-disable-next-line array-callback-return
  params.map((insuranceData) => {
    const relationToPatient =
      insuranceData.relationToPatient === RelationToPatient.ParentGuardian
        ? RelationToPatient.ChildDependent
        : insuranceData.relationToPatient;
    finalInsuranceData.push({
      insurance_carrier: insuranceData.insuranceCarrier,
      other_insurance_name: insuranceData.otherInsuranceName,
      subscriber_first_name: insuranceData.subscriberFirstName,
      subscriber_last_name: insuranceData.subscriberLastName,
      subscriber_id: insuranceData.subscriberId,
      subscriber_date_of_birth: normalizeDate(
        insuranceData.subscriberDateOfBirth
      ),
      relation_to_patient: relationToPatient,
      subscriber_ssn: insuranceData.subscriberSsn,
    });
  });

  await axios.post(`/api/checkins/insurance_coverages`, finalInsuranceData);
};

export const createInsuranceCoverageNewRequest = async (
  params: CreateInsuranceCoverageArgs
): Promise<void> => {
  const finalInsuranceData: any = [];
  // eslint-disable-next-line array-callback-return
  params.map((insuranceData) => {
    const relationToPatient =
      insuranceData.relationToPatient === RelationToPatient.ParentGuardian
        ? RelationToPatient.ChildDependent
        : insuranceData.relationToPatient;
    finalInsuranceData.push({
      insurance_carrier: insuranceData.insuranceCarrier,
      other_insurance_name: insuranceData.otherInsuranceName,
      subscriber_first_name: insuranceData.subscriberFirstName,
      subscriber_last_name: insuranceData.subscriberLastName,
      subscriber_id: insuranceData.subscriberId,
      subscriber_date_of_birth: normalizeDate(
        insuranceData.subscriberDateOfBirth
      ),
      relation_to_patient: relationToPatient,
      subscriber_ssn: insuranceData.subscriberSsn,
    });
  });

  await axios.post(`/api/checkins/insurance_coverages_news`, finalInsuranceData);
};