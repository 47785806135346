import React, { useState } from "react";
import cx from "classnames";
import styles from "./DoctorAccordion.module.scss";
import chevronDownIcon from "images/chevron-downV2.svg";

type DoctorAccordionProps = {
  title: string;
  className?: string;
};

export const DoctorAccordion: React.FC<DoctorAccordionProps> = ({
  title,
  children,
  className,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((prevExpanded) => !prevExpanded);

  return (
    <div className={className}>
      <button className={styles.headline} onClick={toggleExpanded}>
        <h3 className={styles.headlineTitle}>{title}</h3>
        <img
          className={cx(styles.headlineIcon, {
            [styles.headlineIconExpanded]: isExpanded,
          })}
          src={chevronDownIcon}
          alt={isExpanded ? "Collapse" : "Expand"}
        />
      </button>
      {isExpanded && <div className={styles.doctors}>{children}</div>}
    </div>
  );
};
