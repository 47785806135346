import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Form } from "./Form";
import { Modal } from "components/Modal";
import { SessionExpiredToast } from "features/login/SessionExpiredToast";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { createPatientLoginRequest } from "api/createPatientLoginRequest";
import { Layout } from "features/layout";
import { Card } from "components/Card";
import { TextButton } from "components/TextButton";
import { useQueryParam } from "hooks/useQueryParam";
import { REDIRECT_KEY } from "features/routing/AuthorizedRouter";

const aria = {
  labelledby: "login-modal-label",
  describedby: "login-modal-description",
};
export const Login: React.FC = () => {
  const [destination, _setDestination] = useQueryParam(REDIRECT_KEY, undefined);
  const [submittedEmail, setSubmittedEmail] = useState<null | string>(null);

  const { showErrorToast } = useErrorToast();
  const { sessionStatus } = useCurrentPatient();

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await createPatientLoginRequest({
        email,
        redirectTo: destination,
      });
      setSubmittedEmail(email);
    } catch (e) {
      showErrorToast();
    }
  };

  return (
    <Layout>
      <SessionExpiredToast />
      <div>
        <Card className={styles.loginCard}>
          <Form
            onSubmit={onSubmit}
            autoFocus={sessionStatus === "uninitialized"}
          />
        </Card>
      </div>

      <Modal
        className={styles.modal}
        aria={aria}
        isOpen={!!submittedEmail}
        onRequestClose={() => setSubmittedEmail(null)}
        closeOnOverlayClick={false}
      >
        <div className={styles.successMessage}>
          <h2 id={aria.labelledby} className={styles.heading}>
            Please check your email
          </h2>
          <p id={aria.describedby}>
            We&apos;ve sent you a link to <strong>{submittedEmail}</strong>.
            This may take a few minutes to arrive.
            <br />
            Click the link to complete the sign-in process.
          </p>
          <p>
            <span>Didn&apos;t receive the access link? </span>
            <TextButton
              onClick={() => {
                if (submittedEmail) {
                  setSubmittedEmail(null);
                  onSubmit({ email: submittedEmail });
                }
              }}
              className={styles.resend}
            >
              Resend Email
            </TextButton>
          </p>
        </div>
      </Modal>
    </Layout>
  );
};
