import React from "react";
import cx from "classnames";
import styles from "./ButtonLink.module.scss";
import { ButtonRole } from "components/Button/index";
import { useButtonTemplate } from "components/Button/useButtonTemplate";
import { CommonButtonProps } from "components/Button/types";

interface ButtonLinkProps extends CommonButtonProps {
  href: string;
  target?: string;
}

const ButtonLinkExternal: React.FC<ButtonLinkProps> = ({
  text,
  role = ButtonRole.Standard,
  href,
  className,
  size = "standard",
  loading = false,
  target,
  renderAsDisabled = false,
}) => {
  const [inner, classNames] = useButtonTemplate({
    role,
    size,
    loading,
    text,
    renderAsDisabled,
  });

  return (
    <a
      href={href}
      target={target}
      className={cx(className, classNames, styles.link)}
    >
      {inner}
    </a>
  );
};

export { ButtonLinkExternal };
