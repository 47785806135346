import React from "react";
import styles from "./Card.module.scss";
import { LegacyCard as BaseCard } from "components/LegacyCard";

export type CardProps = {
  title: string;
  caption: React.ReactNode;
};

export const Card: React.FC<CardProps> = ({ title, caption, children }) => {
  return (
    <BaseCard className={styles.card}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.content}>
        {children}
        <div className={styles.caption}>{caption}</div>
      </div>
    </BaseCard>
  );
};
