import React from "react";
import styles from "./ThankYou.module.scss";
import { Button } from "components/Button";

export const waitlistThankYouAria = {
  describedby: "waitlist-thank-you-description",
};

export const ThankYou: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className={styles.thankYou}>
      <p id={waitlistThankYouAria.describedby} className={styles.note}>
        Thank you for submitting your email!
        <br />
        We encourage booking with a nearby office or choosing the best possible
        time available.
      </p>
      <Button
        className={styles.button}
        text={"Continue scheduling"}
        size="large"
        onClick={onClose}
      />
    </div>
  );
};
