import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import cx from "classnames";
import styles from "./Form.module.scss";
import { TextInput } from "components/TextInput";
import { Button } from "components/Button";
import { Typeahead } from "components/Typeahead";
import morningSunnyBlackIcon from "images/morning-sunny-black.svg";
import morningSunnyBlueIcon from "images/morning-sunny-blue.svg";
import afternoonSunnyBlackIcon from "images/afternoon-sunny-black.svg";
import afternoonSunnyBlueIcon from "images/afternoon-sunny-blue.svg";
import nightLightRoundBlackIcon from "images/nightlight-round-black.svg";
import nightLightRoundBlueIcon from "images/nightlight-round-blue.svg";
import { SmsCheckbox } from "components/SmsCheckbox";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";
import { DesiredDayOfWeek } from "types/desiredDayOfWeek";
import { DesiredTimeOfDay } from "types/desiredTimeOfDay";
import { Office } from "types/office";
import { MaskedPhoneInput } from "components/MaskedTextInput/MaskedTextInput";
//import { SmsCheckbox } from "components/SmsCheckbox";
import { isValidPhoneNumber } from "utils/phoneNumberValidation";
import { presenceValidation } from "utils/presenceValidation";
import {
  isEmail,
  isValidTLDLength,
  acceptableCharacters,
} from "utils/emailValidation";

type FormProps = {
  onSubmit: (params: FormData) => void;
  isLoading: boolean;
  office: Office;
  selectedDoctorId?: string;
};

export interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  desiredDayOfWeek: DesiredDayOfWeek;
  desiredTimeOfDay?: string;
  smsOptInNotices?: number;
  smsOptInPromos?: number;
}

export const waitlistFormAria = {
  describedby: "waitlist-form-description",
};

export const Form: React.FC<FormProps> = ({
  onSubmit,
  isLoading,
  office,
  selectedDoctorId = "",
}) => {
  const { trackSchedulerEvent } = useSchedulerTracking(selectedDoctorId);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    setValue,
  } = useForm<FormData>({
    mode: "onSubmit",
  });
  const firstName = register(
    "firstName",
    presenceValidation("Not a valid first name")
  );
  const lastName = register(
    "lastName",
    presenceValidation("Not a valid last name")
  );
  const smsOptInNotices = register("smsOptInNotices");
  const smsOptInPromos = register("smsOptInPromos");
  const phoneNumber = register("phoneNumber", { validate: isValidPhoneNumber });

  const email = register("email", {
    required: "Not a valid email",
    validate: {
      isEmail: (value) => isEmail(value),
      isValidTLDLength: (value) => isValidTLDLength(value),
      acceptableCharacters: (value) => acceptableCharacters(value),
    },
  });

  const [selectedDesiredTimeOfDay, setSelectedDesiredTimeOfDay] = useState<
    Record<DesiredTimeOfDay, boolean>
  >({
    [DesiredTimeOfDay.Morning]: false,
    [DesiredTimeOfDay.Afternoon]: false,
    [DesiredTimeOfDay.Evening]: false,
  });

  const onSubmitForm = ({
    firstName,
    lastName,
    phoneNumber,
    email,
    desiredDayOfWeek,
    smsOptInNotices,
    smsOptInPromos,
  }: FormData) => {
    const desiredTimeOfDay = Object.keys(selectedDesiredTimeOfDay)
      .filter((a: DesiredTimeOfDay) => (selectedDesiredTimeOfDay[a] ? a : ""))
      .toString();

    onSubmit({
      firstName,
      lastName,
      phoneNumber,
      email,
      desiredDayOfWeek: parseInt(desiredDayOfWeek.toString()),
      desiredTimeOfDay,
      smsOptInNotices,
      smsOptInPromos,
    });
  };

  const toggleDesiredTimeOfDay = (desiredTimeOfDayValue: DesiredTimeOfDay) => {
    setSelectedDesiredTimeOfDay((prevState) => ({
      ...prevState,
      [desiredTimeOfDayValue]: !prevState[desiredTimeOfDayValue],
    }));
  };

  return (
    <div className={styles.form}>
      <form onSubmit={handleSubmit(onSubmitForm)} aria-label="Waitlist form">
        <div className={cx(styles.headerContent, styles.prompt)}>
          Hey! before you go...
        </div>
        <p id={waitlistFormAria.describedby} className={styles.describedBy}>
          <b>Sign-up for our waitlist.</b>
          <br />
          We&apos;ll let you know if a convenient time is available for your eye
          exam at <b>{office.displayName}.</b>
        </p>
        <div className={styles.desktopTwoColumns}>
          <TextInput
            autoFocus
            {...email}
            inputType="email"
            onChange={(e) => {
              email.onChange(e);
            }}
            errorClassName={cx({
              [styles.emptyError]: !errors.email?.message,
            })}
            errorMessage={errors.email?.message}
          >
            Email
          </TextInput>
          <MaskedPhoneInput
            {...phoneNumber}
            onChange={(e) => {
              clearErrors();
              phoneNumber.onChange(e);
            }}
            errorClassName={cx({
              [styles.emptyError]: !errors.phoneNumber?.message,
            })}
            errorMessage={errors.phoneNumber?.message}
            setValue={setValue}
          >
            Phone Number
          </MaskedPhoneInput>
        </div>
        <div className={styles.desktopTwoColumns}>
          <TextInput
            {...firstName}
            inputType="text"
            placeholder=""
            onChange={(e) => {
              clearErrors();
              firstName.onChange(e);
            }}
            errorClassName={cx({
              [styles.emptyError]: !errors.firstName?.message,
            })}
            errorMessage={errors.firstName?.message}
          >
            First Name
          </TextInput>
          <TextInput
            {...lastName}
            inputType="text"
            onChange={(e) => {
              clearErrors();
              lastName.onChange(e);
            }}
            errorClassName={cx({
              [styles.emptyError]: !errors.lastName?.message,
            })}
            errorMessage={errors.lastName?.message}
          >
            Last Name
          </TextInput>
        </div>
        <Controller
          rules={{ required: "Please select option" }}
          render={({ field, fieldState }) => (
            <Typeahead
              field={field}
              name="desiredDayOfWeek"
              label="Desired Day of Appointment"
              placeholder="Select"
              creatable={false}
              clearable={false}
              options={[
                {
                  label: "Earliest Available",
                  value: DesiredDayOfWeek.Earliest.toString(),
                },
                { label: "Monday", value: DesiredDayOfWeek.Monday.toString() },
                {
                  label: "Tuesday",
                  value: DesiredDayOfWeek.Tuesday.toString(),
                },
                {
                  label: "Wednesday",
                  value: DesiredDayOfWeek.Wednesday.toString(),
                },
                {
                  label: "Thursday",
                  value: DesiredDayOfWeek.Thursday.toString(),
                },
                { label: "Friday", value: DesiredDayOfWeek.Friday.toString() },
                {
                  label: "Saturday",
                  value: DesiredDayOfWeek.Saturday.toString(),
                },
                { label: "Sunday", value: DesiredDayOfWeek.Sunday.toString() },
              ]}
              errorMessage={fieldState.error?.message}
            />
          )}
          name="desiredDayOfWeek"
          control={control}
        />

        <div className={styles.desiredTimeOfDayRow}>
          <div className={styles.desiredTimeOfDay}>Desired Time Of Day</div>
          <div className={styles.circleRow}>
            <div>
              <button
                data-testid="morning-waitlist"
                type="button"
                className={`${styles.circleButton} ${
                  selectedDesiredTimeOfDay[DesiredTimeOfDay.Morning]
                    ? styles.selectedCircle
                    : styles.unSelectedCircle
                }`}
                onClick={() => {
                  trackSchedulerEvent("morning-waitlist", {
                    doctorId: selectedDoctorId,
                  });
                  toggleDesiredTimeOfDay(DesiredTimeOfDay.Morning);
                }}
              >
                <img
                  src={
                    !selectedDesiredTimeOfDay[DesiredTimeOfDay.Morning]
                      ? morningSunnyBlackIcon
                      : morningSunnyBlueIcon
                  }
                  width="24px"
                  height="24px"
                  alt="morning_img"
                />
              </button>
              <div className={styles.desiredTimeOfDayHeading}>Morning</div>
              <div className={styles.desiredTimeOfDaySubHeading}>
                before noon
              </div>
            </div>
            <div>
              <button
                data-testid="afternoon-waitlist"
                type="button"
                className={`${styles.circleButton} ${
                  selectedDesiredTimeOfDay[DesiredTimeOfDay.Afternoon]
                    ? styles.selectedCircle
                    : styles.unSelectedCircle
                }`}
                onClick={() => {
                  trackSchedulerEvent("afternoon-waitlist", {
                    doctorId: selectedDoctorId,
                  });
                  toggleDesiredTimeOfDay(DesiredTimeOfDay.Afternoon);
                }}
              >
                <img
                  src={
                    !selectedDesiredTimeOfDay[DesiredTimeOfDay.Afternoon]
                      ? afternoonSunnyBlackIcon
                      : afternoonSunnyBlueIcon
                  }
                  width="24px"
                  height="24px"
                  alt="afternoon_img"
                />
              </button>

              <div className={styles.desiredTimeOfDayHeading}>Afternoon</div>
              <div className={styles.desiredTimeOfDaySubHeading}>
                noon to 5pm
              </div>
            </div>
            <div>
              <button
                data-testid="evening-waitlist"
                type="button"
                className={`${styles.circleButton} ${
                  selectedDesiredTimeOfDay[DesiredTimeOfDay.Evening]
                    ? styles.selectedCircle
                    : styles.unSelectedCircle
                }`}
                onClick={() => {
                  trackSchedulerEvent("evening-waitlist", {
                    doctorId: selectedDoctorId,
                  });
                  toggleDesiredTimeOfDay(DesiredTimeOfDay.Evening);
                }}
              >
                <img
                  src={
                    !selectedDesiredTimeOfDay[DesiredTimeOfDay.Evening]
                      ? nightLightRoundBlackIcon
                      : nightLightRoundBlueIcon
                  }
                  width="24px"
                  height="24px"
                  alt="evening_img"
                />
              </button>
              <div className={styles.desiredTimeOfDayHeading}>Evening</div>
              <div className={styles.desiredTimeOfDaySubHeading}>after 5pm</div>
            </div>
          </div>
        </div>
        <div>
          <SmsCheckbox
            label="I'd like to receive text reminders about my appointment, about my prescription, and when my glasses or contacts are ready. (MyEyeDr. Notices)"
            {...smsOptInNotices}
            onChange={(e) => {
              clearErrors();
              smsOptInNotices.onChange(e);
            }}
          />
          <SmsCheckbox
            label="I'd like to receive promotional texts with eyewear and eyecare offers. (MyEyeDr. Promos)"
            {...smsOptInPromos}
            onChange={(e) => {
              clearErrors();
              smsOptInPromos.onChange(e);
            }}
          />
          <p className={styles.smsHelp}>
            Message frequency varies. Message and data rates may apply. At any
            time you can text HELP for help or text STOP to stop notifications.{" "}
            <a
              href="https://www.myeyedr.com/sms-terms-service"
              target="_blank"
              rel="noreferrer"
            >
              SMS Terms of Service
            </a>{" "}
            |{" "}
            <a
              href="https://www.myeyedr.com/privacy-policy-practices"
              target="_blank"
              rel="noreferrer"
            >
              MyEyeDr. Privacy Policy
            </a>
          </p>
        </div>
        <div className={styles.agreeText}>
          I agree that you can contact me by text at the mobile number provided.
          By agreeing I consent to receiving texts about promotional offers
          related to eyewear and eyecare offers by any type of dialing
          equipment, automated technology, or other messaging system to send the
          communications. I understand message frequency varies and that data
          and message rates may apply. I understand that I am not required to
          agree to such messaging, and it is not a condition for the purchase of
          any goods or services.
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleSubmit(onSubmitForm)}
            type="submit"
            size="large"
            text="Submit"
            className={styles.button}
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
};
