import React from "react";
import cx from "classnames";
import styles from "./PasswordInput.module.scss";
import { TextInput, TextInputProps } from "components/TextInput";
import { ReactComponent as EyeClosedIcon } from "images/eye-closed.svg";
import { ReactComponent as EyeOpenIcon } from "images/eye-open.svg";

export type PasswordInputProps = Omit<TextInputProps, "inputType">;

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(({ children, errorMessage, className, ...otherProps }, ref) => {
  const [showPassword, setPasswordVisibility] = React.useState(false);
  const iconClassName = cx(styles.visibleIcon, {
    [styles.errorIcon]: errorMessage !== undefined,
  });

  return (
    <TextInput
      {...otherProps}
      ref={ref}
      inputType={showPassword ? "text" : "password"}
      errorMessage={errorMessage}
      className={cx(styles.input, className)}
      maxLength={4}
      afterInput={
        <button
          className={styles.visibilityButton}
          type="button"
          onClick={(e) => {
            setPasswordVisibility(!showPassword);
            e.preventDefault();
          }}
        >
          {showPassword ? (
            <EyeOpenIcon className={iconClassName} />
          ) : (
            <EyeClosedIcon className={iconClassName} />
          )}
        </button>
      }
    >
      {children}
    </TextInput>
  );
});
