import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./CommunicationPreferences.module.scss";
import { Form } from "./Form";
import { CommunicationPreferencesRouteParams } from "features/routing/paths";
import { Layout } from "features/layout";
import { ButtonRole, ButtonLinkExternal } from "components/Button";
import { Navbar } from "components/Navbar";
import { getCommunicationPreferencesRequest } from "api/getCommunicationPreferencesRequest";
import { CommunicationPreference } from "types/communicationPreference";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { MyAccount } from "features/patientDashboard/MyAccount";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";

export const CommunicationPreferences: React.FC = () => {
  const { patientId } = useParams<CommunicationPreferencesRouteParams>();
  const [preferences, setPreferences] = React.useState<
    CommunicationPreference[]
  >([]);
  const [showError, setShowError] = React.useState(false);
  const { currentPatient, sessionStatus } = useCurrentPatient();
  const { showErrorToast } = useErrorToast();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const result = await getCommunicationPreferencesRequest(patientId);
        setPreferences(result);
      } catch (e) {
        setShowError(true);
      }
    };

    fetchPreferences();
  }, [patientId, showErrorToast]);

  const clinicalEmails = preferences.find(
    (pref) => pref.name === "Clinical Emails"
  );
  const promotionalEmails = preferences.find(
    (pref) => pref.name === "Promotional Emails"
  );
  const smsOptInNotices = preferences.find(
    (pref) => pref.name === "SMS Notifications"
  );
  const smsOptInPromos = preferences.find((pref) => pref.name === "SMS Promos");

  const automatedPhoneCalls = preferences.find(
    (pref) => pref.name === "Automated Phone Calls"
  );

  return (
    <Layout
      navbar={
        <Navbar
          office={currentPatient?.homeOffice}
          className={styles.myAccount}
        >
          {sessionStatus === "logged-in" ? (
            <MyAccount />
          ) : (
            <div className={styles.backToHome}>
              <ButtonLinkExternal
                href={MYEYEDR_WEBSITE_URL}
                text="Back to Home"
                role={ButtonRole.Ghost}
                size="large"
              />
            </div>
          )}
        </Navbar>
      }
    >
      <div className={styles.container}>
        <h2 className={styles.title}>Notification Settings</h2>
        <p className={styles.body}>
          MyEyeDr. cares about your eye health. Communication from MyEyeDr.
          ensures you have the services and products you need to live your best
          life.
        </p>

        {showError ? (
          <p className={styles.body}>
            A default set of communication preferences is created after you
            finish scheduling your first appointment through our online
            platform. You can then return to this page to personalize your
            notification settings at any time.
          </p>
        ) : (
          <p className={styles.body}>
            Please select your preferred communication methods. You can opt in
            or out at any time.
          </p>
        )}

        {clinicalEmails &&
          promotionalEmails &&
          smsOptInNotices &&
          smsOptInPromos && (
            <Form
              patientId={patientId}
              clinicalEmails={clinicalEmails}
              promotionalEmails={promotionalEmails}
              smsOptInNotices={smsOptInNotices}
              smsOptInPromos={smsOptInPromos}
              automatedPhoneCalls={automatedPhoneCalls}
            />
          )}
      </div>
    </Layout>
  );
};
