import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { difference, reduce } from "lodash";
import { RootState } from "app/store";
import { getSlotsRequest } from "api/getSlotsRequest";
import { createAppointment } from "features/scheduling/appointmentCreationSlice";
import { Slot } from "types/slot";
import { selectDoctorsForOffice } from "features/officesSlice";

type GetSlotsRequestMeta = {
  startDate: string;
  endDate: string;
  doctorIds: string[];
  receivedAt: string;
};

type TimeSlotsState = {
  slots?: Slot[];
  lastRequest?: GetSlotsRequestMeta;
  isLoaded: boolean;
  serverError: boolean;
};

export const initialState: TimeSlotsState = {
  slots: undefined,
  lastRequest: undefined,
  isLoaded: false,
  serverError: false,
};

const SLICE_NAME = "timeSlots";

export const getSlots = createAsyncThunk(
  `${SLICE_NAME}/getSlots`,
  getSlotsRequest
);

export const timeSlotsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTimeSlots: (state, action: PayloadAction<any>) => ({
      ...state,
      slots: action.payload,
      serverError: false,
      isLoaded: false,
    }),
    setEmptyData: (state, action: PayloadAction<any>) => ({
      ...state,
      state: action.payload,
      isLoaded: false,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlots.pending, (state) => ({
        ...state,
        lastRequest: undefined,
        isLoaded: false,
      }))
      .addCase(getSlots.fulfilled, (state, action) => ({
        ...state,
        lastRequest: {
          startDate: action.meta.arg.startDate,
          endDate: action.meta.arg.endDate,
          doctorIds: action.meta.arg.doctorIds,
          receivedAt: dayjs().toISOString(),
        },
        slots: action.payload,
        isLoaded: true,
      }))
      .addCase(getSlots.rejected, (state) => ({
        ...state,
        slots: [],
        serverError: true,
        isLoaded: true,
      }))
      .addCase(createAppointment.pending, () => initialState);
  },
});

export const reducer = timeSlotsSlice.reducer;
export const { setTimeSlots, setEmptyData } = timeSlotsSlice.actions;

export const selectTimeslots = (state: RootState): Slot[] =>
  state.timeSlots.slots || [];

export const selectUpcomingTimeslots = (
  state: RootState,
  now = dayjs().startOf("minute")
): Slot[] => {
  // There was a performance issue using dayjs to do time comparison when filtering. A lot of
  // time was spent creating and GC'ing dayjs objects. So we use string comparison of date-
  // time strings like 'YYYY-MM-DD HH:mm:ss'. This assumes the timeslots have a `date` prop
  // with format 'YYYY-MM-DD' and `startTime` with format 'HH:mm:ss'.

  const slots = selectTimeslots(state);

  if (slots.length === 0) {
    return [];
  }

  const timeZoneForOffice = slots[0].timeZoneName;
  const nowAsString = now.tz(timeZoneForOffice).format("YYYY-MM-DD HH:mm:ss");

  return selectTimeslots(state).filter(
    (slot) => nowAsString < `${slot.date} ${slot.startTime}`
  );
};

export const selectUpcomingSlotsForDoctor = (
  state: RootState,
  doctorId: string
): Slot[] => {
  return selectUpcomingTimeslots(state).filter(
    (slot) => slot.doctorId === doctorId
  );
};

export type DateToSlotsMap = {
  [date: string]: Slot[];
};

export const selectUpcomingTimeslotsForDoctorByDate = (
  state: RootState,
  doctorId: string
): DateToSlotsMap => {
  return reduce(
    selectUpcomingSlotsForDoctor(state, doctorId),
    (accumulator: DateToSlotsMap, slot) => {
      if (!accumulator[slot.date]) {
        accumulator[slot.date] = [];
      }

      accumulator[slot.date].push(slot);
      return accumulator;
    },
    {}
  );
};

export const selectUpcomingTimeslotsForOfficeByDate = (
  state: RootState,
  officeId: string
): { [doctorId: string]: DateToSlotsMap } => {
  const doctors = selectDoctorsForOffice(state, officeId) || [];
  return doctors.reduce((map, { id }) => {
    map[id] = selectUpcomingTimeslotsForDoctorByDate(state, id);
    return map;
  }, {} as { [id: string]: DateToSlotsMap });
};

export const selectIsLoaded = (state: RootState): boolean =>
  state.timeSlots.slots !== undefined;

export const selectIstimeSlotLoaded = (state: RootState): boolean =>
  state.timeSlots.isLoaded;

export const selectHasRequested = (state: RootState): boolean =>
  !!state.timeSlots.lastRequest;

export const selectServerError = (state: RootState): boolean =>
  state.timeSlots.serverError;

export const selectHasFreshSlots = (
  state: RootState,
  startDate: string,
  endDate: string,
  doctorIds: string[]
): boolean => {
  const lastRequest = state.timeSlots.lastRequest;

  return (
    lastRequest?.startDate === startDate &&
    lastRequest?.endDate === endDate &&
    difference(doctorIds, lastRequest.doctorIds).length === 0 &&
    !!lastRequest.receivedAt &&
    dayjs().diff(dayjs(lastRequest.receivedAt), "second") <= 60
  );
};
