import React from "react";
import { isUndefined } from "lodash";
import { useOffice } from "./hooks/useOffice";
import { OfficeAddress } from "components/OfficeAddress";

export const OfficeInfo: React.FC<{
  officeId: string;
  singleLine?: boolean | undefined;
  showOfficeNumber?: boolean | undefined;
}> = ({ officeId, singleLine, showOfficeNumber }) => {
  const { office } = useOffice(officeId);
  if (isUndefined(office)) return null;
  return (
    <>
      {!singleLine && <div>{office.displayName}</div>}
      <OfficeAddress
        office={office}
        singleLine={singleLine}
        showOfficeNumber={showOfficeNumber}
      />
    </>
  );
};
