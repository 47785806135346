import React from "react";
import styles from "./CancelModalContent.module.scss";
import { CancellationStatus } from "./UpcomingAppointment";
import { useCurrentPatient } from "./hooks/useCurrentPatient";
import { deleteCurrentAppointmentRequest } from "api/deleteCurrentAppointmentRequest";
import { Button } from "components/Button";

interface CancelModalContentProps {
  setCancellationStatus: (cancellationStatus: CancellationStatus) => void;
}
export const cancelModalAria = {
  labelledby: "cancel-modal-label",
};

export const CancelModalContent: React.FC<CancelModalContentProps> = ({
  setCancellationStatus,
}) => {
  const [submitLocked, setSubmitLocked] = React.useState(false);
  const { handleUnauthorized } = useCurrentPatient();

  const confirmCancellation = async (confirmed: boolean) => {
    if (confirmed) {
      setSubmitLocked(true);
      await handleUnauthorized(async () => {
        await deleteCurrentAppointmentRequest();
        setCancellationStatus(CancellationStatus.Canceled);
      });
    } else {
      setCancellationStatus(CancellationStatus.No);
    }
  };

  return (
    <>
      <p className={styles.interrogative} id={cancelModalAria.labelledby}>
        Are you sure you want to cancel?
      </p>
      <p className={styles.qualifier}>
        If you cancel your appointment you won't be able to reserve the time you
        selected.
      </p>
      <div className={styles.actions}>
        <Button
          text="Yes"
          size="large"
          onClick={() => confirmCancellation(true)}
          className={styles.button}
          disabled={submitLocked}
          loading={submitLocked}
        />
        <Button
          text="No"
          size="large"
          onClick={() => confirmCancellation(false)}
          className={styles.button}
          disabled={submitLocked}
        />
      </div>
    </>
  );
};
