import React from "react";
import cx from "classnames";
import styles from "./NavigationButton.module.scss";
import { TextLink } from "components/TextLink";
import chevronLeftIcon from "images/chevron-left.svg";
import chevronRightIcon from "images/chevron-right.svg";

interface NavigationButtonProps {
  url: string;
  disabled: boolean;
  direction: "left" | "right";
  onClick: React.MouseEventHandler;
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  url,
  disabled,
  direction,
  onClick,
}) => {
  const icon = direction === "left" ? chevronLeftIcon : chevronRightIcon;
  const altText =
    direction === "left" ? "View previous week" : "View next week";
  const imgClassName = cx(styles.arrowImage, {
    [styles.leftArrow]: direction === "left",
    [styles.rightArrow]: direction === "right",
  });

  if (disabled) {
    return (
      <span className={cx(styles.disabled)}>
        <img src={icon} alt={altText} className={imgClassName} />
      </span>
    );
  }

  return (
    <TextLink to={url} className={cx(styles.link)} onClick={onClick}>
      <img src={icon} alt={altText} className={imgClassName} />
    </TextLink>
  );
};
