import axios from "axios";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Slot } from "types/slot";

interface GetSlotsCreatorArgs {
  officeId: string;
  startDate: string;
  endDate: string;
  examType?: ExamType;
  doctorIds: string[];
  patientReturnStatus: PatientReturnStatus;
}

export interface GetSlotsResponse {
  date: string;
  start_time: string;
  end_time: string;
  doctor_id: string;
  time_zone_name: string;
}

export const getSlotsRequest = async ({
  officeId,
  doctorIds,
  startDate,
  endDate,
  examType,
  patientReturnStatus,
}: GetSlotsCreatorArgs): Promise<Slot[]> => {
  const slotsResult = await axios.get<GetSlotsResponse[]>(
    `/api/offices/${officeId}/time_slots`,
    {
      params: {
        exam_type: examType,
        patient_return_status: patientReturnStatus,
        doctor_ids: doctorIds,
        start_date: startDate,
        end_date: endDate,
      },
    }
  );

  return slotsResult.data.map((slot) => ({
    doctorId: slot.doctor_id,
    startTime: slot.start_time,
    endTime: slot.end_time,
    date: slot.date,
    timeZoneName: slot.time_zone_name,
  }));
};
