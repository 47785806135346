import React from "react";
import cx from "classnames";
import styles from "./Home.module.scss";
import { ButtonLinkInternal } from "components/Button";
import { TextLink } from "components/TextLink";
import {
  checkInInsurance,
  checkInInsuranceSkip,
  reviewYourInsurance,
} from "features/routing/paths";
import { ErrorToast } from "features/patientDashboard/ErrorToast";

interface StartInsuranceProps {
  returningPatient: boolean;
}

export const StartInsurance: React.FC<StartInsuranceProps> = ({
  returningPatient = false,
}) => {
  return (
    <div className={styles.currentAction}>
      <header className={styles.currentActionHeader}>
        <h2 className={styles.preheading}>Apply your coverage,</h2>
        <h1 className={styles.heading}>Save by adding your Insurance</h1>
        <aside className={cx(styles.aside, styles.asidePiggyBank)}>
          Patients save 40% on average, with insurance
        </aside>
      </header>

      <div className={styles.actionText}>
        You&apos;ll need access to your Vision and/or Medical Insurance details.
      </div>

      <div className={styles.action}>
        <ButtonLinkInternal
          text="Continue"
          size="large"
          to={returningPatient ? reviewYourInsurance() : checkInInsurance()}
        />
      </div>
      <div className={styles.action}>
        <TextLink to={checkInInsuranceSkip()}>
          I will not be using insurance
        </TextLink>
      </div>
      <ErrorToast />
    </div>
  );
};
