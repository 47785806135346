import React from "react";
import styles from "./CalendarLinkModal.module.scss";
import { Modal } from "components/Modal";
import appleLinkIcon from "images/calendar-link-icon-apple.svg";
import googleLinkIcon from "images/calendar-link-icon-google.svg";
import outlookLinkIcon from "images/calendar-link-icon-outlook.svg";
import { CalendarLinks } from "types/calendarLinks";

interface CalendarLinkModalProps {
  isModalOpen: boolean;
  links?: CalendarLinks;
  onRequestClose?: () => void;
}

export const CalendarLinkModal: React.FC<CalendarLinkModalProps> = ({
  isModalOpen,
  links,
  onRequestClose,
}) => {
  if (!links) {
    return null;
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      className={styles.modal}
      isOpen={isModalOpen}
    >
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <a
            download="MyEyeDr Calendar Event"
            href={links.appleUrl}
            className={styles.link}
          >
            <img src={appleLinkIcon} className={styles.icon} alt="Apple" />
            Apple Calendar
          </a>
        </li>
        <li className={styles.listItem}>
          <a
            href={links.googleUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <img src={googleLinkIcon} className={styles.icon} alt="Google" />
            Google Calendar
          </a>
        </li>
        <li className={styles.listItem}>
          <a
            href={links.outlookUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <img src={outlookLinkIcon} className={styles.icon} alt="Outlook" />
            Outlook Calendar
          </a>
        </li>
      </ul>
    </Modal>
  );
};
