import axios from "axios";
import { CommunicationPreference } from "types/communicationPreference";

export interface GetCommunicationPreferencesResponse {
  name: string;
  description: string;
  is_subscribed: boolean;
}

export const getCommunicationPreferencesRequest = async (
  patientId: string
): Promise<CommunicationPreference[]> => {
  const { data } = await axios.get<GetCommunicationPreferencesResponse[]>(
    `/api/communication_preferences/${patientId}`
  );

  return data.map((pref) => ({
    name: pref.name,
    description: pref.description,
    isSubscribed: pref.is_subscribed,
  }));
};
