import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { min, omit, reduce } from "lodash";
import dayjs from "dayjs";
import { fetchOfficeAvailabilityAction } from "./fetchOfficeAvailabilityAction";
import {
  selectUpcomingSlotsForDoctor,
  selectUpcomingTimeslotsForOfficeByDate,
} from "./timeSlotsSlice";
import { isNextAvailableToday } from "features/scheduling/utils/availabilitySearchWindow";
import { RootState } from "app/store";
import { AvailabilitySummaryWithDoctor } from "types/availabilitySummary";
import { DateString } from "types/dateString";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { getAvailabilitySummaryRequest } from "api/getAvailabilitySummaryRequest";
import { selectDoctorsForOffice } from "features/officesSlice";

export type DoctorAvailability = DateString | "loading" | null;

type DoctorMap = {
  [doctorId: string]: DateString | null;
};

export type OfficeAvailabilitySummary = {
  loaded: boolean;
  doctors: DoctorMap;
};

export type AvailabilitySummaryState = {
  [officeId: string]: OfficeAvailabilitySummary;
};

interface FetchAvailabilitySummaryArgs {
  officeId: string;
  doctorIds: string[];
  examType?: ExamType;
  patientReturnStatus: PatientReturnStatus;
  fromDate: string;
}

const SLICE_NAME = "availabilitySummary";

export const fetchAvailabilitySummary = createAsyncThunk<
  AvailabilitySummaryWithDoctor[],
  FetchAvailabilitySummaryArgs
>(`${SLICE_NAME}/fetchAvailabilitySummary`, getAvailabilitySummaryRequest);

export const fetchOfficeAvailability = createAsyncThunk(
  `${SLICE_NAME}/fetchOfficeAvailability`,
  fetchOfficeAvailabilityAction
);

interface AvailabilitySummary {
  [doctorId: string]: DoctorAvailability;
}

const initialState: AvailabilitySummaryState = {};

export const availabilitySummarySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOfficeAvailability: (state, action: PayloadAction<any>) => ({
      ...state,
      [action.payload.officeId]: action.payload.body
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOfficeAvailability.pending, (state, action) => {
        const { officeId } = action.meta.arg;
        return omit(state, officeId);
      })
      .addCase(fetchOfficeAvailability.rejected, (state, action) => {
        const { officeId } = action.meta.arg;
        return {
          ...state,
          [officeId]: {
            loaded: true,
            doctors: {},
          },
        };
      })
      .addCase(fetchAvailabilitySummary.fulfilled, (state, action) => {
        const { officeId } = action.meta.arg;
        const map = reduce(
          action.payload,
          (accum: AvailabilitySummary, x) => {
            accum[x.doctorId] = x.predictedAvailableDate;
            return accum;
          },
          {}
        );
        return {
          ...state,
          [officeId]: {
            loaded: true,
            doctors: map,
          },
        };
      })
      .addCase(fetchAvailabilitySummary.rejected, (state, action) => {
        const { officeId } = action.meta.arg;
        return {
          ...state,
          [officeId]: {
            loaded: true,
            doctors: {},
          },
        };
      });
  },
});

export const reducer = availabilitySummarySlice.reducer;
export const { 
  setOfficeAvailability
 } = availabilitySummarySlice.actions;

export const selectNextAvailableDateForDoctor = (
  state: RootState,
  officeId: string,
  doctorId: string,
  fromDate: string,
  timeSlotWeeks:number
): DoctorAvailability => {
  const availabilityFromSlots = selectUpcomingSlotsForDoctor(
    state,
    doctorId
  ).filter((slot) => dayjs(fromDate).isSameOrBefore(dayjs(slot.date)));

  if (availabilityFromSlots.length > 0) {
    return min(
      availabilityFromSlots.map((availability) => availability.date)
    ) as string;
  }

  if (state.availabilitySummary[officeId]?.loaded) {
    let availability = state.availabilitySummary[officeId].doctors[doctorId];
    if(availability && availability !== "loading" && availability !== null) {
      availability= isNextAvailableToday(availability,timeSlotWeeks)? availability:null;
    }

    if (availability && dayjs(availability).isSameOrAfter(dayjs(fromDate))) {
      return availability;
    }

    return null;
  }

  return "loading";
};

export const selectNextAvailableDatesForOffice = (
  state: RootState,
  officeId: string,
  forDate: string,
  timeSlotWeeks: number
): { [doctorId: string]: DoctorAvailability } => {
  const doctors = selectDoctorsForOffice(state, officeId) || [];

  return doctors.reduce((map, { id: doctorId }) => {
    map[doctorId] = selectNextAvailableDateForDoctor(
      state,
      officeId,
      doctorId,
      forDate,
      timeSlotWeeks
    );
    return map;
  }, {} as { [doctorId: string]: DoctorAvailability });
};

export const selectDoctorOrderedByAvailability = (
  state: RootState,
  officeId: string,
  forDate: string,
  timeSlotWeeks: number
): any[] => {
  const availabilities = selectNextAvailableDatesForOffice(
    state,
    officeId,
    forDate,
    timeSlotWeeks
  );
  const slots = selectUpcomingTimeslotsForOfficeByDate(state, officeId);

  const availSelectDate: string[] = [];
  for (const property in slots) {
    const tt = slots[property][forDate] ? slots[property][forDate].length : 0;
    if (tt > 0) {
      availSelectDate.push(property);
      delete availabilities[property];
    }
  }

  const limited: string[] = [];
  Object.keys(availabilities).forEach((key) => {
    if (availabilities[key] === null) {
      limited.push(key);
      delete availabilities[key];
    }
  });
  const nextAvailable: string[] = Object.keys(availabilities).sort(function (
    a,
    b
  ) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return dayjs(availabilities[a]).isAfter(dayjs(availabilities[b])) ? 1 : -1;
  });

  return availSelectDate.concat(nextAvailable, limited);
};

export const selectDoctorIdsOrderedByAvailability = (
  state: RootState,
  officeId: string,
  forDate: string,
  timeSlotWeeks:number
): string[] => {
  const availabilities = selectNextAvailableDatesForOffice(
    state,
    officeId,
    forDate,
    timeSlotWeeks
  );
  const slots = selectUpcomingTimeslotsForOfficeByDate(state, officeId);

  return Object.keys(slots).sort((doctor1, doctor2) => {
    // Coercing null into a string means "null" sorts after a valid date.
    const doc1NextAvailable = String(availabilities[doctor1]);
    const doc2NextAvailable = String(availabilities[doctor2]);

    // First compare against next date alone
    const dateComparison = doc1NextAvailable.localeCompare(doc2NextAvailable);
    if (dateComparison !== 0) {
      return dateComparison;
    }

    // If both doctors are next available on the same day, prefer the one with more slots
    const doc1Slots = (slots[doctor1][doc1NextAvailable] || []).length;
    const doc2Slots = (slots[doctor2][doc2NextAvailable] || []).length;

    return doc2Slots - doc1Slots;
  });
};
