import React from "react";
import { useCurrentAppointment } from "./hooks/useCurrentAppointment";
import { TaskList, Task } from "components/TaskList";
import { checkinCompleted } from "features/checkIn/helpers";

export const useEyeHealthTasks = (): Task[] => {
  const { currentAppointment } = useCurrentAppointment();
  const checkin = currentAppointment?.checkin;

  return [
    {
      isCompleted: !!currentAppointment,
      title: "Book your annual eye health exam.",
    },
    {
      isCompleted: checkinCompleted(checkin),
      title: "Check-in online before your appointment.",
      description:
        "Complete your paperwork before your visit to save time in the office.",
    },
  ];
};

export const EyeHealthChecklist: React.FC = () => {
  const tasks = useEyeHealthTasks();

  return (
    <TaskList
      title="Eye Health Checklist"
      tasks={tasks}
      completionMessage={
        <>
          You finished Check-in!
          <br />
          We can’t wait to see you.
        </>
      }
    />
  );
};
