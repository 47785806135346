import React from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { Redirect, useHistory, useParams } from "react-router";
import styles from "./Form.module.scss";
import { useTimer } from "./useTimer";
import { ReactComponent as PhoneIcon } from "images/phone.svg";
import { TextInput } from "components/TextInput";
import { Button } from "components/Button";
import { TextButton } from "components/TextButton";
import * as paths from "features/routing/paths";
import { createTwoFactorCodeRequest } from "api/createTwoFactorCodeRequest";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { REDIRECT_KEY } from "features/routing/AuthorizedRouter";
import { useQueryParam } from "hooks/useQueryParam";
import { createTwoFactorVerificationRequest } from "api/createTwoFactorVerificationRequest";
import { safeDestination } from "features/login/hooks/useCreatePatientSession";

const CODE_LENGTH = 6;

type FormData = { code: string };

export const Form: React.FC = () => {
  const {
    register,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormData>({
    mode: "onBlur",
  });
  const [isRateLimited, startRateLimitTimer] = useTimer(30 * 1000);
  const [destination, _setDestination] = useQueryParam(
    REDIRECT_KEY,
    paths.portalHome()
  );
  const [finalPhoneDigits, _setfinalPhoneDigits] = useQueryParam(
    "finalPhoneDigits",
    ""
  );
  const { phoneNumberId } = useParams<paths.EnterTwoFactorCodeRouteParams>();
  const history = useHistory();
  const phoneDigitsAreValid = (digits: string) => {
    return digits.match(/^[0-9]{4}$/) != null;
  };
  const onSubmit = async (data: FormData) => {
    await handleUnauthorized(
      async () => {
        try {
          await createTwoFactorVerificationRequest({
            code: data.code,
            phoneNumberId,
          });
          history.push(safeDestination(destination));
        } catch (e) {
          if (e.code === "400") {
            setError("code", { type: "validation", message: "Incorrect Code" });
          } else if (e.code === "410") {
            setError("code", { type: "validation", message: "Code Expired" });
          } else {
            throw e;
          }
        }
      },
      {
        onUnauthorized: () => {
          displayMessage({
            text: "Your session has expired.",
            icon: "checkmark",
          });
          expireSession();
        },
      }
    );
  };

  const { handleUnauthorized, sessionStatus, expireSession } =
    useCurrentPatient();
  const { displayMessage } = useSnackbar();
  const resendCode = async () => {
    await handleUnauthorized(
      async () => {
        try {
          await createTwoFactorCodeRequest({ phoneNumberId });
          displayMessage({
            text: "Code Sent",
            icon: "checkmark",
          });
          startRateLimitTimer();
        } catch (e) {
          if (e.code === "429") {
            setError("code", {
              type: "validation",
              message: "Max Send Attempts Reached",
            });
          } else {
            throw e;
          }
        }
      },
      {
        onUnauthorized: () => {
          displayMessage({
            text: "Your session has expired.",
            icon: "checkmark",
          });
          expireSession();
        },
      }
    );
  };

  if (sessionStatus === "logged-out" || sessionStatus === "expired") {
    return (
      <Redirect
        to={{
          pathname: paths.portalLogin(),
          search: `?${REDIRECT_KEY}=${destination}`,
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Enter your verification code</h3>
      <p>
        We sent a text message with a verification code to{" "}
        {phoneDigitsAreValid(finalPhoneDigits) ? (
          <b className={styles.phoneNumber}>
            {"(***) ***-" + finalPhoneDigits}
          </b>
        ) : (
          " your phone number on file"
        )}
        .
      </p>
      <div className={styles.icon}>
        <PhoneIcon />
      </div>
      <p className={styles.prompt}>
        Enter your 6-digit code before it expires.
      </p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register("code", {
            required: true,
            pattern: {
              value: new RegExp(`^\\d{${CODE_LENGTH}}$`),
              message: "Invalid Code",
            },
          })}
          autoFocus
          autoComplete="one-time-code"
          className={styles.input}
          errorMessage={errors.code?.message}
          errorClassName={cx(styles.codeError, {
            [styles.emptyError]: !errors.code?.message,
          })}
          ariaLabel="Code"
          inputType="tel"
          maxLength={CODE_LENGTH}
          placeholder="######"
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          renderAsDisabled={!isValid}
          loading={isSubmitting}
          disabled={isSubmitting}
          size="large"
          text="Next"
        />
      </form>
      {!isRateLimited && (
        <div className={styles.sendNewCode}>
          <TextButton onClick={resendCode}>Send new code</TextButton>
        </div>
      )}
    </div>
  );
};
