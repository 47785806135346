import React from "react";
import { useSelector } from "react-redux";
import styles from "./ErrorToast.module.scss";
import { DefaultToast, defaultToastIcons } from "./DefaultToast";
import { selectError } from "utils/selectError";

interface ErrorToastProps {
  visible: boolean;
  onDismiss: () => void;
}

export const UnconnectedErrorToast: React.FC<ErrorToastProps> = ({
  visible,
  onDismiss,
}) => {
  return (
    <DefaultToast
      visible={visible}
      sideIcon={<defaultToastIcons.Reload />}
      onDismiss={onDismiss}
    >
      <p>
        Something went wrong. If this problem continues, please contact your
        local office to book your appointment{" "}
        <a href="https://locations.myeyedr.com/search">Click Here.</a>
      </p>

      <button
        className={styles.reload}
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
    </DefaultToast>
  );
};

export const ErrorToast: React.FC = () => {
  const hasError = !!useSelector(selectError);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setVisible(hasError);
  }, [hasError]);

  return (
    <UnconnectedErrorToast
      visible={visible}
      onDismiss={() => setVisible(false)}
    />
  );
};
