import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Location } from "history";
import { FormProvider, useController, useForm } from "react-hook-form";
import { Layout } from "./components/Layout";
import { Fieldset } from "./components/Fieldset";
import styles from "./ReviewYourAddress.module.scss";
import { AboutYou1FormData, DocumentType } from "./types";
import { StateOptions } from "./constants";
import { DocumentAcknowledgment } from "./components/DocumentAcknowledgment/DocumentAcknowledgment";
import { aboutYouNewVerification } from "features/checkIn/helpers";
import { Address } from "types/address";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { createAddressVerificationRequest } from "api/createAddressVerificationRequest";
import { Navbar } from "features/checkIn/components/Navbar";
import * as paths from "features/routing/paths";
import { TextInput } from "components/TextInput";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";
import { Introduction } from "features/checkIn/components/Introduction";
import { Typeahead } from "components/Typeahead";
import { Button } from "components/Button";
import { ConfirmNavigation } from "components/ConfirmNavigation";
import { isValidZip } from "utils/zipValidation";

interface ReviewYourAddressFormData extends Address {
  addressToggle?: string;
}

export interface ReviewYourAddressProps {
  address: ReviewYourAddressFormData | null;
  onSubmit: (aboutYou1Data: AboutYou1FormData) => void;
}
declare let env_react: string;
export const ReviewYourAddress: React.FC<ReviewYourAddressProps> = ({
  address,
  onSubmit,
}) => {
  const history = useHistory();
  const formMethods = useForm<ReviewYourAddressFormData>({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty, isSubmitting },
    control,
    setValue,
    watch,
  } = formMethods;
  //useProgressGuard({ field: "aboutYou", isSubmitting });

  const { handleUnauthorized, fetchCurrentPatient } = useCurrentPatient();
  const { currentAppointment, fetchCurrentAppointment } =
    useCurrentAppointment();
  const { displayMessage } = useSnackbar();
  const checkin = currentAppointment?.checkin;

  const onSubmitNew = async (data: ReviewYourAddressFormData) => {
    let addressData: ReviewYourAddressFormData;
    if (
      shouldShowAddress &&
      data.address1 &&
      data.city &&
      data.state &&
      data.zipCode
    ) {
      addressData = data;
    } else {
      addressData = address as ReviewYourAddressFormData;
    }

    if (aboutYouNewVerification(checkin)) {
      onSubmit(addressData);
      history.push(paths.checkInAboutYou2());
    } else {
      await handleUnauthorized(async () => {
        try {
          await createAddressVerificationRequest({
            ...addressData,
          });
        } catch (e) {
          if (e.code === "422") {
            displayMessage({ icon: "checkmark", text: "Already submitted." });
            history.push(paths.checkInHome());
            return;
          }

          throw e;
        }
        // Saving About You modifies the appointment and patient data, so force a fetch to get a fresh copy.
        await fetchCurrentAppointment();
        await fetchCurrentPatient();
        history.push(paths.checkInHome());
      });
    }
  };

  const watchAddressToggle = watch("addressToggle");
  const shouldShowAddress = watchAddressToggle === "no";
  const address1 = register("address1", {
    required: { value: shouldShowAddress, message: "Not a valid address" },
  });
  const address2 = register("address2");
  const city = register("city", {
    required: { value: shouldShowAddress, message: "Not a valid city" },
  });
  const zipCode = register("zipCode", {
    validate: (value) => (shouldShowAddress ? isValidZip(value) : true),
  });
  const { field: stateField } = useController({
    rules: { required: shouldShowAddress },
    name: "state",
    control,
  });

  const confirmNavigation = React.useCallback(
    (location: Location) => {
      if (location.pathname === paths.checkInHome() || isSubmitting) {
        return false;
      }
      return true;
    },
    [isSubmitting]
  );

  const envReact = env_react !== "undefined" ? env_react : "production";
  const apiKey =
    envReact === "development"
      ? "AIzaSyBZoc3kW6Ygemp-m3hsaX4v-H4BDMvHDPo"
      : "AIzaSyDxt1Xdg78h4jTetBtXEt98ZlBnQv5vbHI";
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const [isVisible, setVisible] = React.useState(false);
  const searchInput = React.useRef(null);
  stateField.onChange = (value: string) => {
    setValue("state", value);
    stateField.value = value;
  };
  // load google map api js
  const extractAddress = (
    place: any,
    searchInput: { current: { value: string } }
  ) => {
    let address1 = "";
    place.address_components.forEach(
      (component: { types: string; long_name: string; short_name: string }) => {
        const types = component.types;
        const value = component.long_name;
        if (types.includes("street_number")) {
          address1 = address1 + value;
        }
        if (types.includes("route")) {
          address1 = address1 + " " + value;
        }

        if (types.includes("locality")) {
          setValue("city", value);
        }

        if (types.includes("administrative_area_level_1")) {
          stateField.value = component.short_name;
          setValue("state", component.short_name);
        }

        if (types.includes("postal_code")) {
          setValue("zipCode", value);
        }
      }
    );
    searchInput.current.value = address1;
    setValue("address1", address1);
  };
  function loadAsyncScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeAddress = (
    autocomplete: google.maps.places.Autocomplete,
    searchInput: any
  ) => {
    const place = autocomplete.getPlace();
    extractAddress(place, searchInput);
  };
  // init gmap script
  const initMapScript = React.useCallback(() => {
    // if script already loaded
    if (window.google || !apiKey) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }, [apiKey]);

  React.useEffect(() => {
    if (isVisible) {
      initMapScript().then(() => {
        if (!searchInput.current || !apiKey) return;

        const options = {
          componentRestrictions: { country: "us" },
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          searchInput.current,
          options
        );
        autocomplete.setFields([
          "address_component",
          "geometry",
          "formatted_address",
        ]);
        autocomplete.addListener("place_changed", () =>
          onChangeAddress(autocomplete, searchInput)
        );
      });
    }
  }, [apiKey, initMapScript, onChangeAddress, isVisible]);

  const handleChangeaddress1 = (event: { target: { value: any } }) => {
    if (!isVisible) {
      if (event.target.value.length > 5) setVisible(true);
    }
    setValue("address1", event.target.value);
  };

  const handleChangeCity = (event: { target: { value: string } }) => {
    setValue("city", event.target.value);
  };

  const handleChangeZip = (event: { target: { value: string } }) => {
    setValue("zipCode", event.target.value);
  };

  return (
    <FormProvider {...formMethods}>
      <Layout
        navbar={<Navbar title={"Address"} />}
        primaryCta={
          <Button
            onClick={handleSubmit(onSubmitNew)}
            renderAsDisabled={!isValid}
            loading={isSubmitting}
            disabled={isSubmitting}
            text="Next"
          />
        }
        emptyHeight={true}
      >
        <ConfirmNavigation
          enableOnLocationChange={confirmNavigation}
          enableOnExit={isDirty}
        />
        <Introduction header="Review your address">
          Please provide the patient’s information to help us personalize the
          visit.
        </Introduction>

        <div className={styles.content}>
          <Fieldset
            legend="Is this your current address?"
            legendClassName={styles.alwaysVisible}
          >
            <div className={styles.bodyLarge}>
              {address?.address1}
              <br />
              {address?.address2 && (
                <>
                  {address?.address2}
                  <br />
                </>
              )}
              {address?.city}
              {", "}
              {address?.state} {address?.zipCode}
              <br />
            </div>
            <br />
            <RadioButtonGroup
              errorMessage={errors.addressToggle?.message}
              errorClassName={cx({
                [styles.emptyError]: !errors.addressToggle,
              })}
            >
              <RadioButton
                {...register("addressToggle", {
                  required: "Please select an option",
                })}
                hasError={!!errors.addressToggle}
                value="yes"
              >
                Yes, this is my address
              </RadioButton>
              <RadioButton
                {...register("addressToggle", {
                  required: "Please select an option",
                })}
                hasError={!!errors.addressToggle}
                value="no"
              >
                No, I have a new address
              </RadioButton>
            </RadioButtonGroup>
          </Fieldset>
          {shouldShowAddress && (
            <>
              <Fieldset
                legend="Where do you live?"
                fieldGroupClassName={styles.addressFieldGroup}
              >
                <TextInput
                  {...address1}
                  ref={searchInput}
                  inputType="text"
                  onChange={handleChangeaddress1}
                  errorMessage={errors.address1?.message}
                  className={cx(styles.field, styles.fieldSpan)}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.address1?.message,
                  })}
                >
                  Address
                </TextInput>

                <TextInput
                  {...address2}
                  inputType="text"
                  errorMessage={errors.address2?.message}
                  className={styles.apt}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.address2?.message,
                  })}
                >
                  Apt./Suite # (optional)
                </TextInput>

                <Typeahead
                  field={stateField}
                  name="state"
                  options={StateOptions}
                  label="State"
                  creatable={false}
                  clearable={true}
                  errorMessage={errors.state?.message}
                  className={styles.field}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.state?.message,
                  })}
                />

                <TextInput
                  {...city}
                  inputType="text"
                  onChange={handleChangeCity}
                  errorMessage={errors.city?.message}
                  className={styles.field}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.city?.message,
                  })}
                >
                  City
                </TextInput>

                <TextInput
                  {...zipCode}
                  inputType="text"
                  inputMode="numeric"
                  maxLength={5}
                  onChange={handleChangeZip}
                  errorMessage={errors.zipCode?.message}
                  className={styles.field}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.zipCode?.message,
                  })}
                >
                  Zip
                </TextInput>
              </Fieldset>
            </>
          )}

          <hr className={styles.separator} />
          <Fieldset
            legend="HIPAA Notice of Privacy Practices"
            legendClassName={styles.alwaysVisible}
          >
            <DocumentAcknowledgment documentType={DocumentType.HipaaNotice} />
          </Fieldset>
        </div>
      </Layout>
    </FormProvider>
  );
};
