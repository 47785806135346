import React from "react";
import {
  Control,
  Controller,
  useController,
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import cx from "classnames";
import dayjs from "dayjs";
import styles from "./InsuranceForm.module.scss";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { InsuranceFormData, RelationToPatient } from "features/checkIn/types";
import { Fieldset } from "features/checkIn/components/Fieldset";
import { Introduction } from "features/checkIn/components/Introduction";
import { MaskedDateInput } from "components/MaskedTextInput/MaskedTextInput";
import { TextInput } from "components/TextInput";
import { Typeahead } from "components/Typeahead";
import { RadioButtonGroup, RadioButton } from "components/RadioButton";
import { isValidDateOfBirth } from "utils/dateOfBirthValidation";
import { presenceValidation } from "utils/presenceValidation";
import { PasswordInput } from "components/PasswordInput";
import { InfoTooltip } from "components/InfoTooltip";

const subscriberIdTip = (
  <ul className={styles.guideList}>
    <li>
      Your ID may also be called: Member ID, Client ID, Enrollee ID, Payer ID,
      Member Claims Number, etc.
    </li>
    <li>Some policies may use your SSN as your ID.</li>
  </ul>
);

const subscriberNameTip = (
  <ul className={styles.guideList}>
    <li>Enter the name of the primary policy holder.</li>
    <li>You may be able to reference this detail on a card (if provided).</li>
  </ul>
);

export const ConfirmInsuranceForm: React.FC<{
  register: UseFormRegister<Partial<InsuranceFormData>>;
  control: Control<Partial<InsuranceFormData>>;
  insuranceCarriers: Array<string> | null;
  isRemoveBtn: boolean;
  setValue: UseFormSetValue<Partial<InsuranceFormData>>;
  errors: DeepMap<Partial<InsuranceFormData>, FieldError>;
  watch: UseFormWatch<Partial<InsuranceFormData>>;
  onSubmit: () => void;
  formId: string;
  getInsuranceValues: any;
  getValues: UseFormGetValues<Partial<InsuranceFormData>>;
  currentPatient: any;
  version?:number;
}> = ({
  register,
  control,
  insuranceCarriers,
  isRemoveBtn,
  setValue,
  errors,
  onSubmit,
  watch,
  formId,
  getInsuranceValues,
  getValues,
  currentPatient,
  version
}) => {
  // const { currentPatient } = useCurrentPatient();
  const [isOpen, setOpen] = React.useState(false);
  const subscriberFirstName = register(
    "subscriberFirstName",
    presenceValidation("Required")
  );
  const subscriberLastName = register(
    "subscriberLastName",
    presenceValidation("Required")
  );
  const otherInsuranceName = register("otherInsuranceName", {
    required: { value: isOpen, message: "Not a valid Insurance" },
    pattern: { value: /^[a-z / A-Z]*$/, message: "Not a valid Insurance" },
  });
  const { field: insuranceField } = useController({
    rules: { required: true },
    name: "insuranceCarrier",
    control,
  });
  const subscriberId = register("subscriberId", presenceValidation("Required"));
  const subscriberDateOfBirth = register("subscriberDateOfBirth", {
    required: "Required",
    validate: isValidDateOfBirth,
  });
  const yesPrimaryPolicyHolder = register("isPrimaryPolicyHolder");
  const noPrimaryPolicyHolder = register("isPrimaryPolicyHolder");

  const isPrimaryPolicyHolder = watch("isPrimaryPolicyHolder");
  const [isInsuranceName, setIsInsuranceName] = React.useState("yes");
  const subscriberSsn = register("subscriberSsn", {
    required: "Required",
    pattern: { value: /^\d{4}$/, message: "Must have 4 digits" },
  });

  const formattedDateOfBirth = dayjs(currentPatient?.dateOfBirth).format(
    "MM/DD/YYYY"
  );

  const handleChange = (event: { target: { name: any, value: string } }) => {
    setValue(event.target.name, event.target.value);
    getInsuranceValues(getValues());
  };

  insuranceField.onChange = (value: string) => {
    if (value === "Other Insurance") {
      setOpen(true);
      // setValue("otherInsuranceName", "");
    } else {
      setOpen(false);
      setValue("otherInsuranceName", undefined);
    }
    setValue("insuranceCarrier", value);
    insuranceField.value = value;
    getInsuranceValues(getValues());
  };
  const handleSameNameChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "yes") {
      setValue("subscriberFirstName", currentPatient?.firstName || "");
      setValue("subscriberLastName", currentPatient?.lastName || "");
    } else {
      setValue("subscriberFirstName", "");
      setValue("subscriberLastName", "");
    }
    setIsInsuranceName(event.target.value);
  };

  const handlePrimaryPolicyHolderChoice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "yes") {
      setValue("subscriberDateOfBirth", formattedDateOfBirth);
      setValue("subscriberFirstName", currentPatient?.firstName || "");
      setValue("subscriberLastName", currentPatient?.lastName || "");
      setValue("relationToPatient", RelationToPatient.Self);
    } else {
      setValue("subscriberDateOfBirth", "");
      setValue("subscriberFirstName", "");
      setValue("subscriberLastName", "");
      setValue("relationToPatient", undefined);
    }
    setValue("subscriberId", "");
  };

  React.useEffect(() => {
    if (insuranceField.value === "Other Insurance") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [insuranceField]);

  return (
    <form onSubmit={onSubmit} id={formId}>      
      <div className={(version && version === 2)?cx(styles.contentNew, styles.card, styles.versionBG):cx(styles.contentNew, styles.card)}>
        <Fieldset
          legend="(Medical or Vision)" 
          legendClassName={styles.FieldLegend}
          fieldGroupClassName={styles.FieldGroup}
        >
          <Typeahead
            field={insuranceField}
            name="insuranceCarrier"
            options={(insuranceCarriers || []).map((carrier) => ({
              label: carrier,
              value: carrier,
            }))}
            label="Insurance Carrier"
            creatable={false}
            clearable={true}
            isClear={true}
            errorMessage={errors.insuranceCarrier?.message}
            errorClassName={cx({
              [styles.emptyError]: !errors.insuranceCarrier?.message,
            })}
          />
        </Fieldset>
        {isOpen && (
          <Fieldset legend="Insurance Name displayed on card">
            {/* other insurance field, working on hidden until selected*/}
            <TextInput
              {...otherInsuranceName}
              inputType="text"
              name="otherInsuranceName"
              // guidedTooltip={otherInsuranceNameTip}
              errorMessage={errors.otherInsuranceName?.message}
              placeholder="Input Name"
              onChange={handleChange}
              errorClassName={cx({
                [styles.emptyError]: !errors.otherInsuranceName?.message,
              })}
            >
              Insurance Name
            </TextInput>
          </Fieldset>
        )}
        <hr className={styles.hr} />
        <Fieldset
          legend="Are you the Subscriber?"          
          legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
          fieldGroupClassName={styles.FieldGroup}
          aside={"(Primary Policy Holder)"}
          data-testid="primaryRadioGroup"
        >
          <RadioButtonGroup className={styles.buttonGroup}>
            <RadioButton
              {...yesPrimaryPolicyHolder}
              className={styles.radioButton}
              onChange={(e) => {
                yesPrimaryPolicyHolder.onChange(e);
                handlePrimaryPolicyHolderChoice(e);
              }}
              value="yes"
            >
              Yes
            </RadioButton>
            <RadioButton
              {...noPrimaryPolicyHolder}
              className={styles.radioButton}
              onChange={(e) => {
                noPrimaryPolicyHolder.onChange(e);
                handlePrimaryPolicyHolderChoice(e);
              }}
              value="no"
            >
              No
            </RadioButton>
          </RadioButtonGroup>
        </Fieldset>
        <div>
          {isPrimaryPolicyHolder === "no" && (
            <section>
              <Fieldset
                legend="What’s the Subscriber’s information?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <div className={styles.desktopTwoColumn}>
                  <TextInput
                    {...subscriberFirstName}
                    inputType="text"
                    guidedTooltip={subscriberNameTip}
                    errorMessage={errors.subscriberFirstName?.message}
                    placeholder="First Name"
                    onChange={handleChange}
                    errorClassName={cx({
                      [styles.emptyError]: !errors.subscriberFirstName?.message,
                    })}
                  >
                    Subscriber First Name
                  </TextInput>

                  <TextInput
                    {...subscriberLastName}
                    inputType="text"
                    guidedTooltip={subscriberNameTip}
                    errorMessage={errors.subscriberLastName?.message}
                    placeholder="Last Name"
                    onChange={handleChange}
                    errorClassName={cx({
                      [styles.emptyError]: !errors.subscriberLastName?.message,
                    })}
                  >
                    Subscriber Last Name
                  </TextInput>
                </div>
                <div className={styles.desktopTwoColumn}>
                  <MaskedDateInput
                    {...subscriberDateOfBirth}
                    name="subscriberDateOfBirth"
                    setValue={setValue}
                    errorMessage={errors.subscriberDateOfBirth?.message}
                    onChange={handleChange}
                    errorClassName={cx({
                      [styles.emptyError]:
                        !errors.subscriberDateOfBirth?.message,
                    })}
                  >
                    Subscriber Date of Birth
                  </MaskedDateInput>

                  <TextInput
                    {...subscriberId}
                    inputType="text"
                    guidedTooltip={subscriberIdTip}
                    errorMessage={errors.subscriberId?.message}
                    onChange={handleChange}
                    errorClassName={cx({
                      [styles.emptyError]: !errors.subscriberId?.message,
                    })}
                  >
                    Subscriber ID
                  </TextInput>
                </div>
              </Fieldset>
              <Fieldset
                legend="What’s the Subscriber’s relation to the patient?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <Controller
                  rules={{ required: "Required" }}
                  render={({ field, fieldState }) => (
                    <Typeahead
                      field={field}
                      name="relationToPatient"
                      label="Subscriber Relation to Patient"
                      placeholder="Select an option"
                      creatable={false}
                      options={[
                        { label: "Spouse", value: RelationToPatient.Spouse },
                        {
                          label: "Parent / Guardian",
                          value: RelationToPatient.ParentGuardian,
                        },
                        {
                          label: "Domestic Partner",
                          value: RelationToPatient.DomesticPartner,
                        },
                      ]}
                      clearable={false}
                      errorMessage={fieldState.error?.message}
                      errorClassName={cx({
                        [styles.emptyError]: !fieldState.error?.message,
                      })}
                    />
                  )}
                  name="relationToPatient"
                  control={control}
                />
              </Fieldset>
              <Fieldset
                legend="What’s the Subscriber’s Social Security Number?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={cx(styles.fieldGroup,styles.FieldGroup)}
              >
                <div className={styles.fieldWithTooltip}>
                  <div className={styles.ssnContainer}>
                    <PasswordInput
                      {...subscriberSsn}
                      className={styles.ssnField}
                      errorMessage={errors.subscriberSsn?.message}
                      onChange={(e) => {
                        handleChange(e);
                        subscriberSsn.onChange(e);
                      }}
                      onBlur={(e) => {
                        handleChange(e);
                        subscriberSsn.onBlur(e);
                      }}
                      placeholder="####"
                    >
                      Last 4 digits of SSN
                    </PasswordInput>
                  </div>
                  <div className={styles.tooltipContainer}>
                    Why is this needed?
                    <InfoTooltip
                      className={styles.tooltip}
                      iconClassName={styles.tooltipCloseIcon}
                    >
                      <p>
                        We may use the last four digits of your SSN to validate
                        medical or vision insurance.
                      </p>
                    </InfoTooltip>
                  </div>
                </div>
              </Fieldset>
            </section>
          )}
          {isPrimaryPolicyHolder === "yes" && (
            <section>
              <Fieldset
                legend="Does this name match what appears on your insurance?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <div className={styles.readOnlyTwoColumn}>
                  <TextInput
                    errorClassName={styles.emptyError}
                    name="readOnlyFirstName"
                    readOnly={true}
                    inputType="text"
                    onChange={handleChange}
                    defaultValue={currentPatient?.firstName || ""}
                  >
                    First Name
                  </TextInput>
                  <TextInput
                    errorClassName={styles.emptyError}
                    name="readOnlyLastName"
                    readOnly={true}
                    inputType="text"
                    onChange={handleChange}
                    defaultValue={currentPatient?.lastName || ""}
                  >
                    Last Name
                  </TextInput>
                </div>
                <div data-testid="nameRadioButtonGroup">
                  <RadioButtonGroup className={styles.buttonGroup}>
                    <RadioButton
                      name="isInsuranceName"
                      className={styles.radioButton}
                      onChange={handleSameNameChoice}
                      value="yes"
                      checked={isInsuranceName === "yes"}
                    >
                      Yes
                    </RadioButton>
                    <RadioButton
                      name="isInsuranceName"
                      className={styles.radioButton}
                      onChange={handleSameNameChoice}
                      value="no"
                      checked={isInsuranceName === "no"}
                    >
                      No
                    </RadioButton>
                  </RadioButtonGroup>
                </div>
                {isInsuranceName === "no" && (
                  <>
                    Enter the name as it appears on your insurance.
                    <div
                      className={styles.desktopTwoColumn}
                      data-testid="subscriberName"
                    >
                      <TextInput
                        {...subscriberFirstName}
                        inputType="text"
                        guidedTooltip={subscriberNameTip}
                        errorMessage={errors.subscriberFirstName?.message}
                        placeholder="First Name"
                        onChange={(e) => {
                          handleChange(e);
                          subscriberFirstName.onChange(e);
                        }}
                        onBlur={(e) => {
                          handleChange(e);
                          subscriberFirstName.onBlur(e);
                        }}
                        errorClassName={cx({
                          [styles.emptyError]:
                            !errors.subscriberFirstName?.message,
                        })}
                      >
                        First Name
                      </TextInput>
                      <TextInput
                        {...subscriberLastName}
                        inputType="text"
                        guidedTooltip={subscriberNameTip}
                        errorMessage={errors.subscriberLastName?.message}
                        placeholder="Last Name"
                        onChange={(e) => {
                          handleChange(e);
                          subscriberLastName.onChange(e);
                        }}
                        onBlur={(e) => {
                          handleChange(e);
                          subscriberLastName.onBlur(e);
                        }}
                        errorClassName={cx({
                          [styles.emptyError]:
                            !errors.subscriberLastName?.message,
                        })}
                      >
                        Last Name
                      </TextInput>
                    </div>
                  </>
                )}
              </Fieldset>
              <Fieldset
                legend="What’s your date of birth?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <MaskedDateInput
                  {...subscriberDateOfBirth}
                  name="subscriberDateOfBirth"
                  setValue={setValue}
                  onChange={(e) => {
                    handleChange(e);
                    subscriberDateOfBirth.onChange(e);
                  }}
                  onBlur={(e) => {
                    handleChange(e);
                    subscriberDateOfBirth.onBlur(e);
                  }}                                                                                            
                  errorMessage={errors.subscriberDateOfBirth?.message}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.subscriberDateOfBirth?.message,
                  })}
                >
                  Date of Birth
                </MaskedDateInput>
              </Fieldset>
              <Fieldset
                legend="What’s your subscriber ID?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <TextInput
                  {...subscriberId}
                  inputType="text"
                  onChange={(e) => {
                    handleChange(e);
                    subscriberId.onChange(e);
                  }}
                  onBlur={(e) => {
                    handleChange(e);
                    subscriberId.onBlur(e);
                  }}
                  guidedTooltip={subscriberIdTip}
                  errorMessage={errors.subscriberId?.message}
                  errorClassName={cx({
                    [styles.emptyError]: !errors.subscriberId?.message,
                  })}
                >
                  ID
                </TextInput>
              </Fieldset>
              <Fieldset
                legend="What’s your relation to the patient?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={styles.FieldGroup}
              >
                <Controller
                  render={({ field, fieldState }) => (
                    <Typeahead
                      field={field}
                      name="relationToPatient"
                      label="Subscriber Relation to Patient"
                      placeholder="Self (Default)"
                      creatable={false}
                      options={[
                        {
                          label: "Self (Default)",
                          value: RelationToPatient.Self,
                        },
                        { label: "Spouse", value: RelationToPatient.Spouse },
                        {
                          label: "Parent / Guardian",
                          value: RelationToPatient.ParentGuardian,
                        },
                        {
                          label: "Domestic Partner",
                          value: RelationToPatient.DomesticPartner,
                        },
                        {
                          label: "Self (Student Coverage)",
                          value: RelationToPatient.Student,
                        },
                      ]}
                      clearable={false}
                      errorClassName={cx({
                        [styles.emptyError]: !fieldState.error?.message,
                      })}
                    />
                  )}
                  name="relationToPatient"
                  defaultValue={RelationToPatient.Self}
                  control={control}
                />
              </Fieldset>
              <Fieldset
                legend="What’s your Social Security Number?"
                legendClassName={cx(styles.alwaysVisible,styles.FieldLegend)}
                fieldGroupClassName={cx(styles.fieldGroup,styles.FieldGroup)}
              >
                <div className={styles.fieldWithTooltip}>
                  <div className={styles.ssnContainer}>
                    <PasswordInput
                      {...subscriberSsn}
                      inputMode="numeric"
                      onChange={(event) => {
                        handleChange(event);
                        subscriberSsn.onChange(event);
                      }}
                      className={styles.ssnField}
                      errorMessage={errors.subscriberSsn?.message}
                      placeholder="####"
                    >
                      Last 4 digits of SSN
                    </PasswordInput>
                  </div>
                  <div className={styles.tooltipContainer}>
                    Why is this needed?
                    <InfoTooltip
                      className={styles.tooltip}
                      iconClassName={styles.tooltipCloseIcon}
                    >
                      <p>
                        We may use the last four digits of your SSN to validate
                        medical or vision insurance.
                      </p>
                    </InfoTooltip>
                  </div>
                </div>
              </Fieldset>
            </section>
          )}
        </div>
        {isRemoveBtn && <div>Remove</div>}
      </div>
    </form>
  );
};
