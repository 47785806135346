import React from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Layout } from "./components/Layout";
import { Introduction } from "./components/Introduction";
import { Fieldset } from "./components/Fieldset";
import { DocumentAcknowledgment } from "./components/DocumentAcknowledgment";
import styles from "./ConfirmInsuranceSkip.module.scss";
import { DocumentType } from "./types";
import { Button } from "components/Button";
import { skipInsuranceCoverageRequest } from "api/skipInsuranceCoverageRequest";
import { Navbar } from "features/checkIn/components/Navbar";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { checkInHome } from "features/routing/paths";

export const ConfirmInsuranceSkip: React.FC = () => {
  const insuranceReviewFormMethods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = insuranceReviewFormMethods;
  const history = useHistory();
  const { handleUnauthorized } = useCurrentPatient();
  const { showErrorToast } = useErrorToast();

  const onSubmit = async () => {
    await handleUnauthorized(async () => {
      try {
        await skipInsuranceCoverageRequest();
        history.push(checkInHome());
      } catch {
        showErrorToast();
      }
    });
  };

  return (
    <FormProvider {...insuranceReviewFormMethods}>
      <Layout
        navbar={<Navbar title="Insurance" />}
        primaryCta={
          <Button
            onClick={handleSubmit(onSubmit)}
            renderAsDisabled={!isValid}
            loading={isSubmitting}
            disabled={isSubmitting}
            text="Save & Continue"
          />
        }
        emptyHeight={true}
      >
        <Introduction header="Review payment terms">
          We want to ensure you&apos;re notified on our policies around payment.
          Our office may follow up with you to ensure your status regarding
          Insurance.
        </Introduction>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Invoice Statements</h2>
          <p className={styles.sectionText}>
            We&apos;ll work with you to resolve any outstanding balance. We send
            statements to patients if there&apos;s anything that has not been
            resolved. Unresolved balances may go through a collection process,
            which are subject to fees.
          </p>
        </section>

        <hr className={styles.separator} />
        <section className={styles.agreementSection}>
          <Fieldset
            legend="Insurance Assignment Policy & Agreement"
            legendClassName={styles.alwaysVisible}
          >
            <DocumentAcknowledgment
              documentType={DocumentType.InsuranceAssignmentPolicy}
            />
          </Fieldset>
        </section>
      </Layout>
    </FormProvider>
  );
};
