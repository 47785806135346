export const isValidDOBRestrictVEH = (value: string): string | boolean => {
  const today = new Date();
  const birthDate = new Date(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const birthMonthDay = (today.getMonth() + 1) * 100 + today.getDate();
  const birthMonthDayGiven =
    (birthDate.getMonth() + 1) * 100 + birthDate.getDate();

  if (birthMonthDay < birthMonthDayGiven) {
    age--;
  }

  return (
    (age >= 18 && age < 55) ||
    "You must be between the ages of 18 - 55 years old to book a VEH appointment."
  );
};
