import React from "react";
import cx from "classnames";
import styles from "./RetiringLabel.module.scss";
import { Doctor } from "types/doctor";

interface DoctorProps {
  doctor: Doctor;
  calledFrom?: string;
}

export const RetiringLabel: React.FC<DoctorProps> = ({ calledFrom }) => {
  let clsName;
  if (calledFrom) {
    clsName = cx(styles.retiring, styles[calledFrom]);
  } else {
    clsName = styles.retiring;
  }
  return <div className={clsName}>RETIRING SOON</div>;
};
