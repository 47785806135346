import { useSelector } from "react-redux";
import { useTrackEvent } from "./useTrackEvent";
import { RootState } from "app/store";
import {
  selectUpcomingSlotsForDoctor,
  selectUpcomingTimeslots,
} from "features/scheduling/timeSlotsSlice";

interface UseSchedulerTrackingReturn {
  trackSchedulerEvent: (
    eventName: string,
    extraProperties?: Record<string, string>
  ) => void;
}

export const useSchedulerTracking = (
  selectedDoctorId?: string
): UseSchedulerTrackingReturn => {
  const { trackEvent } = useTrackEvent();
  const timeSlots = useSelector((state: RootState) =>
    selectedDoctorId
      ? selectUpcomingSlotsForDoctor(state, selectedDoctorId)
      : selectUpcomingTimeslots(state)
  );

  const trackSchedulerEvent = (
    eventName: string,
    extraProperties?: Record<string, string>
  ) => {
    const timeSlotsForEvent = timeSlots.map((timeSlot) => [
      timeSlot.date,
      timeSlot.startTime,
    ]);
    trackEvent(eventName, {
      doctorId: selectedDoctorId,
      displayedTimes: timeSlotsForEvent,
      ...extraProperties,
    });
  };

  return { trackSchedulerEvent };
};
