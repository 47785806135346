import React from "react";
import dayjs from "dayjs";
import styles from "features/componentCatalog/ComponentCatalog.module.scss";
import { API_DATE_FORMAT } from "api/constants";
import { DoctorSlots } from "features/scheduling/components/DoctorSlots";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Slot } from "types/slot";

const today = dayjs().format(API_DATE_FORMAT);
const tomorrow = dayjs().add(1, "day").format(API_DATE_FORMAT);
const timeSlots = (date = "2020-01-01") => [
  {
    date,
    startTime: "09:00",
    doctorId: "4",
    endTime: "09:05",
    timeZoneName: "America/New_York",
  },
  {
    date,
    startTime: "09:05",
    doctorId: "4",
    endTime: "09:10",
    timeZoneName: "America/New_York",
  },
  {
    date,
    startTime: "09:10",
    doctorId: "4",
    endTime: "09:15",
    timeZoneName: "America/New_York",
  },
  {
    date,
    startTime: "09:15",
    doctorId: "4",
    endTime: "09:20",
    timeZoneName: "America/New_York",
  },
];

const sharedProps = {
  doctor: {
    id: "1",
    firstName: "James",
    lastName: "McGill",
  },
  officeId: "1",
  office: {
    id: "1",
    formattedZipCode: "12345",
    stateAbbr: "AA",
    city: "City",
    formattedPhone: "555-555-5555",
    address1: "addr1",
    address2: "addr2",
    displayName: "name",
    timeZoneName: "America/New_York",
    distanceMiles: "34.4",
    schedulingAllowed: true,
    timeSlotWeeks: 3,
  },
  onSelectSlot: (slot: Slot) => {
    alert("Schedule appointment: " + JSON.stringify(slot));
  },
  examType: ExamType.Contacts,
  patientReturnStatus: PatientReturnStatus.Returning,
};

export const DoctorSlotsDemo: React.FC = () => (
  <div className={styles.section}>
    <h1>DoctorSlots</h1>
    <div className={styles.section}>
      <h2>Loading</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{}}
        availability="loading"
        forDate="2020-01-01"
      />
    </div>

    <div className={styles.section}>
      <h2>No Slots found</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{}}
        availability={null}
        forDate="2020-01-01"
      />
    </div>

    <div className={styles.section}>
      <h2>Available on the search date</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{
          "2020-01-01": timeSlots("2020-01-01"),
        }}
        availability="2020-01-01"
        forDate="2020-01-01"
      />
    </div>

    <div className={styles.section}>
      <h2>Available after the search date</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{
          "2020-01-02": timeSlots("2020-01-02"),
        }}
        availability="2020-01-02"
        forDate="2020-01-01"
      />
    </div>

    <div className={styles.section}>
      <h2>Available today, not tomorrow</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{
          [today]: timeSlots(today),
        }}
        availability={today}
        forDate={today}
      />
    </div>

    <div className={styles.section}>
      <h2>Available today and tomorrow</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{
          [today]: timeSlots(today),
          [tomorrow]: timeSlots(tomorrow),
        }}
        availability={today}
        forDate={today}
      />
    </div>

    <div className={styles.section}>
      <h2>Available tomorrow, not today</h2>
      <DoctorSlots
        {...sharedProps}
        slots={{
          [tomorrow]: timeSlots(tomorrow),
        }}
        availability={tomorrow}
        forDate={today}
      />
    </div>
  </div>
);
