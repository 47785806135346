import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { noop, isEmpty } from "lodash";
import styles from "./DoctorTimeSlotV2.module.scss";
import { selectUpcomingTimeslotsForDoctorByDate } from "features/scheduling/timeSlotsSlice";
import { toTel } from "features/scheduling/utils/toTel";
import { Slot } from "types/slot";
import { Office } from "types/office";
import { RootState } from "app/store";
import { SlotButton } from "features/scheduling/components/SlotButtons";
import {
  Button,
  ButtonV2,
  ButtonLinkExternal,
  ButtonRole,
} from "components/Button";
import { ButtonSkeleton } from "components/Button/ButtonSkeleton";
import morningIcon from "images/morningIcon.svg";
import afternoonIcon from "images/afternoonIcon.svg";
import eveningIcon from "images/eveningIcon.svg";
import { parseStartTime } from "features/appointmentTime";

interface DoctorTimeSlotV2Props {
  doctorId: string;
  searchDate: string;
  selectedOffice: Office;
  onSelect?: (x: Slot) => void;
  forDate?: string;
  availability: any;
  onSelection?: (type: string, value: Slot) => void;
  onSelectNextAvail?: (date: string) => void;
  selectedSlot: undefined | Slot;
  isMobileView?: boolean;
  onNextMobile?: () => void;
}
export const DoctorTimeSlotV2: React.FC<DoctorTimeSlotV2Props> = ({
  searchDate,
  doctorId,
  selectedOffice,
  availability,
  onSelection,
  onSelectNextAvail,
  selectedSlot,
  isMobileView,
  onNextMobile,
}) => {
  const timeSlotsForDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForDoctorByDate(state, doctorId)
  );
  if (availability === "loading") {
    return (
      <>
        {["1", "2", "3", "4", "5", "6", "7", "8"].map(() => (
          <div className={styles.slots}>
            <ButtonSkeleton />
          </div>
        ))}
      </>
    );
  }
  if (
    (isEmpty(timeSlotsForDoctor) || isEmpty(timeSlotsForDoctor[searchDate])) &&
    availability === null &&
    isMobileView
  ) {
    return (
      <>
        <div className={cx(styles.noAvailabilityContainer, styles.officeCall)}>
          <ButtonLinkExternal
            href={toTel(selectedOffice.formattedPhone)}
            text="Call The Office"
            role={ButtonRole.Ghost}
          />
        </div>
      </>
    );
  }
  if (
    (isEmpty(timeSlotsForDoctor) && availability) ||
    searchDate !== availability
  ) {
    return (
      <>
        <div className={styles.noAvailabilityContainer}>
          {!isMobileView && (
            <div className={styles.divLabel}>
              This doctor is not available on the selected date. Would you like
              to check <br />
              their next available slot?
            </div>
          )}
          {isMobileView && (
            <div className={styles.divLabel}>
              This doctor is not available on the selected date.
              <br />
              Would you like to check their next available slot?
            </div>
          )}
          <Button
            size="large"
            text="FIND NEXT AVAILABILITY"
            className={styles.button}
            onClick={() => {
              if (onSelectNextAvail) {
                onSelectNextAvail(availability);
              }
            }}
          />
        </div>
      </>
    );
  }

  const morningSlots: Slot[] = [];
  const eveningSlots: Slot[] = [];
  const afternoonSlots: Slot[] = [];
  if (
    timeSlotsForDoctor[searchDate] &&
    timeSlotsForDoctor[searchDate].length > 0
  ) {
    timeSlotsForDoctor[searchDate].map((slot) => {
      const timeInt = parseInt(parseStartTime(slot).format("h"));
      const amORpm = parseStartTime(slot).format("A");

      if (amORpm === "AM" && timeInt <= 12) {
        morningSlots.push(slot);
      } else if (amORpm === "PM" && (timeInt === 12 || timeInt < 4)) {
        afternoonSlots.push(slot);
      } else if (amORpm === "PM" && timeInt >= 4) {
        eveningSlots.push(slot);
      }
    });
  }

  /*
  const newSlots = {
    "morning" : [{"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"09:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"10:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"11:00:00","timeZoneName":"America/New_York"}],
    "afternoon" : [{"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"12:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"1:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"2:00:00","timeZoneName":"America/New_York"}],
    "evening" : [{"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"04:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"05:00:00","timeZoneName":"America/New_York"},
    {"date":"2023-01-16","doctorId":"21100","endTime":"09:05:00","startTime":"06:00:00","timeZoneName":"America/New_York"}]
}*/

  return (
    <>
      {morningSlots && morningSlots.length > 0 && (
        <div>
          <div className={styles.timeIcon}>
            <img src={morningIcon} alt="Morning" />
          </div>
          <div className={styles.timeSlotsContainer}>
            {morningSlots.map((slot) => (
              <SlotButton
                key={`${slot.date} ${slot.startTime}`}
                slot={slot}
                size="small"
                onSelectSlot={noop}
                version={2}
                onSelection={onSelection}
                selectedSlot={selectedSlot}
              />
            ))}
          </div>
        </div>
      )}
      {afternoonSlots && afternoonSlots.length > 0 && (
        <div>
          <div className={styles.timeIcon}>
            <img src={afternoonIcon} alt="Afternoon" />
          </div>
          <div className={styles.timeSlotsContainer}>
            {afternoonSlots.map((slot) => (
              <SlotButton
                key={`${slot?.date} ${slot?.startTime}`}
                slot={slot}
                size="small"
                onSelectSlot={noop}
                version={2}
                onSelection={onSelection}
                selectedSlot={selectedSlot}
              />
            ))}
          </div>
        </div>
      )}
      {eveningSlots && eveningSlots.length > 0 && (
        <div>
          <div className={styles.timeIcon}>
            <img
              src={eveningIcon}
              alt="Evening"
              className={styles.eveningIcon}
            />
          </div>
          <div className={styles.timeSlotsContainer}>
            {eveningSlots.map((slot) => (
              <SlotButton
                key={`${slot.date} ${slot.startTime}`}
                slot={slot}
                size="small"
                onSelectSlot={noop}
                version={2}
                onSelection={onSelection}
                selectedSlot={selectedSlot}
              />
            ))}
          </div>
        </div>
      )}
      {isMobileView && (
        <ButtonV2
          className={styles.scheduleExam}
          text="Next"
          onClick={onNextMobile ? onNextMobile : noop}
          role={ButtonRole.Standard}
          size={"large"}
          disabled={!(selectedSlot?.doctorId === doctorId)}
        />
      )}
    </>
  );
};
