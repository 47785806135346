import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import ContentLoader from "react-content-loader";
import { Navbar } from "./components/Navbar";
import { Layout } from "./components/Layout";
import { ReturningPatientInsuranceReviewCard } from "./components/ReturningPatientInsuranceReviewCard";
import { DocumentType } from "./types";
import { Introduction } from "./components/Introduction";
import { useProgressGuard } from "./hooks/useProgressGuard";
import { Fieldset } from "./components/Fieldset";
import { DocumentAcknowledgment } from "./components/DocumentAcknowledgment";
import styles from "./ReviewYourInsurance.module.scss";
import { InsuranceNotFound } from "./components/InsuranceNotFound";
import { Button } from "components/Button";
import { useCurrentPatientInsurance } from "features/checkIn/hooks/useCurrentPatientInsurance";
import { Insurance } from "types/insurance";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { verifyInsuranceRequest } from "api/verifyInsuranceRequest";
import * as paths from "features/routing/paths";
import { verifyPendingInsuranceRequest } from "api/verifyPendingInsuranceRequest";
import {
  setInsuranceData,
  setCurrentInsurance,
  setIsEditForm,
  setShowAddFooter,
} from "features/insuranceDataSlice";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";
import {
  insuranceNewVerification
} from "features/checkIn/helpers";

export const ReviewYourInsurance: React.FC = () => {
  //useProgressGuard({ field: "insurance" });
  const { currentPatientInsurance } = useCurrentPatientInsurance();
  const { currentAppointment, fetchCurrentAppointment } =
    useCurrentAppointment();
  const checkin = currentAppointment?.checkin;

  React.useEffect(() => {
    fetchCurrentAppointment();
  }, [fetchCurrentAppointment]);

  const history = useHistory();
  const dispatch = useDispatch();
  dispatch(setInsuranceData([{}, {}]));
  dispatch(setCurrentInsurance(0));
  dispatch(setIsEditForm(false));
  dispatch(setShowAddFooter(false));
  const { handleUnauthorized } = useCurrentPatient();
  const reviewYourInsuranceFormMethods = useForm({ mode: "all" });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = reviewYourInsuranceFormMethods;
  const { displayMessage } = useSnackbar();

  const onSubmit = async () => {
    await handleUnauthorized(async () => {
      if (currentPatientInsurance && currentPatientInsurance[0] && !insuranceNewVerification(checkin)) {
        try {
          await verifyInsuranceRequest(currentPatientInsurance[0]);
          history.push(paths.checkInHome());
        } catch (e) {
          if (e.code === "422") {
            displayMessage({ icon: "checkmark", text: "Already submitted." });
            history.push(paths.checkInHome());
            return;
          }
          throw e;
        }
      } else if (insuranceNewVerification(checkin)) {
        try {
          await verifyPendingInsuranceRequest();
          history.push(paths.checkInHome());
        } catch (e) {
          if (e.code === "422") {
            // displayMessage({ icon: "checkmark", text: "Already submitted." });
            history.push(paths.checkInHome());
            return;
          }
          throw e;
        }
      } else if (
        currentPatientInsurance &&
        currentPatientInsurance.length === 0 
      ) {
        try {
          await verifyPendingInsuranceRequest();
          history.push(paths.checkInHome());
        } catch (e) {
          if (e.code === "422") {
            displayMessage({ icon: "checkmark", text: "Already submitted." });
            history.push(paths.checkInHome());
            return;
          }
          throw e;
        }
      }
    });
  };

  return (
    <FormProvider {...reviewYourInsuranceFormMethods}>
      <Layout
        navbar={<Navbar title={"Insurance"} />}
        primaryCta={
          <Button
            onClick={handleSubmit(onSubmit)}
            renderAsDisabled={!isValid}
            loading={isSubmitting}
            disabled={isSubmitting}
            text="Save & Continue"
          />
        }
        emptyHeight={true}
      >
        <Introduction header="Review your coverage">
          We use this information to verify your coverage, please ensure details
          are accurate.
        </Introduction>
        {currentPatientInsurance ? (
          currentPatientInsurance.length > 0 ? (
            currentPatientInsurance.map(
              (insurance: Insurance, i: React.Key | null | undefined) => {
                return (
                  <ReturningPatientInsuranceReviewCard
                    key={i}
                    insurance={insurance}
                  />
                );
              }
            )
          ) : (
            <InsuranceNotFound />
          )
        ) : (
          <div className={styles.container}>
            <div className={styles.card}>
              <ContentLoader viewBox="0 0 760 254" style={{ width: "100%" }}>
                <rect x="6" y="1" rx="3" ry="3" width="216" height="15" />
                <rect x="6" y="66" rx="3" ry="3" width="137" height="16" />
                <rect x="6" y="42" rx="3" ry="3" width="137" height="16" />
                <rect x="6" y="111" rx="3" ry="3" width="137" height="16" />
                <rect x="6" y="135" rx="3" ry="3" width="137" height="16" />
                <rect x="6" y="176" rx="3" ry="3" width="137" height="16" />
                <rect x="6" y="200" rx="3" ry="3" width="137" height="16" />
                <rect x="600" y="42" rx="3" ry="3" width="137" height="16" />
              </ContentLoader>
            </div>
          </div>
        )}
        <hr className={styles.separator} />
        <div className={styles.agreement}>
          <Fieldset
            legend="Insurance Assignment Policy & Agreement"
            legendClassName={styles.alwaysVisible}
          >
            <DocumentAcknowledgment
              documentType={DocumentType.InsuranceAssignmentPolicy}
            />
          </Fieldset>
        </div>
      </Layout>
    </FormProvider>
  );
};
