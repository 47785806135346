import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import {
  fetchDoctorsForOffice,
  selectDoctorsForOffice,
} from "features/officesSlice";
import { Doctor } from "types/doctor";

interface WithOfficeDoctorsProps {
  children: (props: { doctors: Doctor[] }) => JSX.Element;
  officeId: string;
}

export const WithOfficeDoctors: React.FC<WithOfficeDoctorsProps> = ({
  officeId,
  children,
}) => {
  const dispatch = useDispatch();

  const thisOfficeDoctors = useSelector((state: RootState) =>
    selectDoctorsForOffice(state, officeId)
  );

  React.useEffect(() => {
    if (!thisOfficeDoctors) {
      dispatch(fetchDoctorsForOffice(officeId));
    }
  }, [dispatch, officeId, thisOfficeDoctors]);

  if (thisOfficeDoctors) {
    return children({ doctors: thisOfficeDoctors });
  } else {
    return null;
  }
};
