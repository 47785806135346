import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { compact } from "lodash";
import { useHistory } from "react-router";
import styles from "./DesktopView.module.scss";
import { Timetable } from "./Timetable";
import { OnSelectSlotHandler } from "./types";
import { Doctor } from "types/doctor";
import { NearbyOfficeCards } from "features/scheduling/components/NearbyOfficeCards";
import { selectSelectedAppointment } from "features/scheduling/appointmentCreationSlice";
import {
  selectSortedDoctorsForOffice,
  setSortedDoctorsByOffice,
} from "features/officesSlice";
import { DoctorSelectionPanel } from "features/scheduling/components/DoctorSelectionPanel";
import { useQueryParam } from "hooks/useQueryParam";
import { Office } from "types/office";
import { RootState } from "app/store";
import {
  selectDoctorIdsOrderedByAvailability,
  selectNextAvailableDateForDoctor,
  selectNextAvailableDatesForOffice,
} from "features/scheduling/availabilitySummarySlice";
import { selectNearbyOfficeLoadingState } from "features/scheduling/nearbyOfficesSlice";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";
import { Slot } from "types/slot";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";
import {
  selectFirstAvailableDatesForAllDoctorsInOffice,
  selectFirstAvailablityLoadedForOffice,
} from "features/scheduling/firstAvailabilitySlice";

interface DesktopViewProps {
  doctors: Doctor[];
  nearbyOffices: Office[];
  selectedOffice: Office;
  searchDate: string;
  onSelectSlot: OnSelectSlotHandler;
}

export const DesktopView: React.FC<DesktopViewProps> = ({
  doctors,
  nearbyOffices,
  selectedOffice,
  searchDate,
  onSelectSlot,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasDoctors = doctors.length > 0;
  const selectedAppointment = useSelector(selectSelectedAppointment);
  const availability = useSelector((state: RootState) =>
    selectNextAvailableDatesForOffice(
      state,
      selectedOffice.id,
      searchDate,
      selectedOffice.timeSlotWeeks
    )
  );
  const sortedDoctors = useSelector((state: RootState) =>
    selectSortedDoctorsForOffice(state, selectedOffice.id)
  );

  const doctorIdsByAvailability = useSelector(
    (state: RootState) =>
      selectDoctorIdsOrderedByAvailability(
        state,
        selectedOffice.id,
        searchDate,
        selectedOffice.timeSlotWeeks
      ),
    shallowEqual
  );

  const doctorsToDisplay = sortedDoctors || doctors;
  const nextAvailabilityForFirstDoctor = useSelector((state: RootState) =>
    selectNextAvailableDateForDoctor(
      state,
      selectedOffice.id,
      hasDoctors && sortedDoctors ? sortedDoctors[0].id : "",
      searchDate,
      selectedOffice.timeSlotWeeks
    )
  );
  const defaultSelectedDoctorId = React.useMemo(() => {
    if (selectedAppointment?.doctorId) {
      return selectedAppointment?.doctorId;
    } else if (!hasDoctors) {
      return "";
    } else if (nextAvailabilityForFirstDoctor !== "loading") {
      return doctorsToDisplay[0].id;
    } else {
      return "";
    }
  }, [
    selectedAppointment?.doctorId,
    hasDoctors,
    nextAvailabilityForFirstDoctor,
    doctorsToDisplay,
  ]);

  const [selectedDoctorId, setSelectedDoctorId] = useQueryParam(
    "doctorId",
    defaultSelectedDoctorId
  );

  const selectedDoctor = React.useMemo(
    () =>
      sortedDoctors?.find((doctor) => doctor.id === selectedDoctorId) || null,
    [sortedDoctors, selectedDoctorId]
  );

  const { trackSchedulerEvent } = useSchedulerTracking(selectedDoctorId);

  useEffect(() => {
    if (!sortedDoctors && !Object.values(availability).includes("loading")) {
      const newSortedDoctors = compact(
        doctorIdsByAvailability.map((doctorId) => {
          return doctors.find((doctor) => doctor.id === doctorId);
        })
      );
      const officeId = selectedOffice.id;
      const data = {
        officeId,
        newSortedDoctors,
      };
      dispatch(setSortedDoctorsByOffice(data));
      trackSchedulerEvent("default-selection", {
        // this overrides the default doctorId which would otherwise be
        // the selected doctor
        doctorId: newSortedDoctors[0]?.id,
      });
    }
  });

  const isNearByOfficesLoading = useSelector((state: RootState) =>
    selectNearbyOfficeLoadingState(state)
  );

  const firstAvailability = useSelector((state: RootState) =>
    selectFirstAvailableDatesForAllDoctorsInOffice(state, selectedOffice.id)
  );
  const firstAvailabilityLoaded = useSelector((state: RootState) =>
    selectFirstAvailablityLoadedForOffice(state, selectedOffice.id)
  );

  const onSelectDoctor = (doctor: Doctor) => {
    trackSchedulerEvent("select-doctor", { doctorSelected: doctor.id });
    if (areFirstAvailableApptsByDefaultEnabled() && firstAvailabilityLoaded) {
      const params = new URLSearchParams(history.location.search);
      if (firstAvailability[doctor.id]) {
        params.set("date", firstAvailability[doctor.id] as string);
      }
      params.set("doctorId", doctor.id);
      history.replace({ search: params.toString() });
    } else {
      setSelectedDoctorId(doctor.id);
    }
  };
  const onSelectSlotWithAnalytics = (slot: Slot) => {
    trackSchedulerEvent("select-date", {
      dateTimeSelected: `${slot.date} ${slot.startTime}`,
    });
    onSelectSlot(slot);
  };

  return (
    <div className={styles.desktopView}>
      <DoctorSelectionPanel
        selectedDoctorId={selectedDoctorId}
        doctors={doctorsToDisplay}
        selectedOffice={selectedOffice}
        onSelect={onSelectDoctor}
        forDate={searchDate}
      />
      {hasDoctors && (
        <Timetable
          selectedOffice={selectedOffice}
          searchDate={searchDate}
          doctor={selectedDoctor}
          onSelectSlot={onSelectSlotWithAnalytics}
        />
      )}
      <NearbyOfficeCards
        offices={nearbyOffices}
        loading={isNearByOfficesLoading}
        selectedDoctorId={selectedDoctorId}
      />
    </div>
  );
};
