import React from "react";
import cx from "classnames";
import ContentLoader from "react-content-loader";
import styles from "./DoctorAvatar.module.scss";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { doctorFullName } from "utils/doctorFullName";

interface DoctorAvatarProps {
  doctor: Doctor | undefined;
  size?: "xsmall" | "small" | "normal";
  loaded?: boolean;
  className?: string;
  selectedOffice?: Office;
  callFrom?: string;
}

// A 1x1 pixel to serve as the <img> src. This lets us use an <img> tag combined with the background-image fallbacks to handle a missing headshot image gracefully.
const transparentPixel =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
declare let originalVEHheadshot: string;
export const DoctorAvatar: React.FC<DoctorAvatarProps> = ({
  doctor,
  size = "normal",
  loaded = true,
  className,
  selectedOffice,
  callFrom,
}) => {
  if (!loaded || !doctor) {
    return (
      <ContentLoader
        speed={2}
        viewBox="0 0 44 44"
        backgroundColor="var(--gray-02)"
        foregroundColor="var(--gray-04)"
        className={cx(styles.avatar, className, {
          [styles.small]: size === "small",
          [styles.xsmall]: size === "xsmall",
        })}
      >
        <circle cx="22" cy="22" r="22" />
      </ContentLoader>
    );
  }

  const headshotImage = doctor.headshotUrl ? `url(${doctor.headshotUrl}),` : "";
  let styleAvatar;

  if (
    (selectedOffice &&
      selectedOffice.namechangeVEH &&
      doctor.offersVEH &&
      doctor.headshotUrl &&
      doctor.firstName === "Open Network") ||
    (callFrom === "nearby" &&
      selectedOffice &&
      selectedOffice.namechangeVEH &&
      doctor.offersVEH &&
      doctor.headshotUrl &&
      doctor.firstName === "Open Network")
  ) {
    originalVEHheadshot =
      typeof originalVEHheadshot !== "undefined" ? originalVEHheadshot : "";

    if (callFrom === "nearby" && originalVEHheadshot) {
      styleAvatar = {
        backgroundImage: `url(${originalVEHheadshot}), url(/images/doctor-placeholder.svg)`,
      };
    } else {
      styleAvatar = {
        backgroundImage: `${headshotImage} url(/images/doctor-placeholder.svg)`,
        borderRadius: "initial",
        backgroundColor: callFrom === "successPage" ? "#f7f7f7" : "white",
        width: "78px",
        height: "43px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      };
    }
  } else {
    styleAvatar = {
      backgroundImage: `${headshotImage} url(/images/doctor-placeholder.svg)`,
    };
  }
  return (
    <img
      alt={doctorFullName(doctor, selectedOffice?.namechangeVEH)}
      src={transparentPixel}
      className={cx(styles.avatar, className, {
        [styles.small]: size === "small",
        [styles.xsmall]: size === "xsmall",
      })}
      style={styleAvatar}
    />
  );
};
