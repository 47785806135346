import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./AppointmentSummary.module.scss";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { OfficeInfoCard } from "components/OfficeInfoCard";
import { parseStartTimeWithZone } from "features/appointmentTime";
import { Layout } from "features/layout";
import { Button, ButtonRole } from "components/Button";
import * as paths from "features/routing/paths";
import { DoctorInfoCard } from "components/DoctorInfoCard";
import { WithOffice } from "components/WithOffice";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { Office } from "types/office";
import { Doctor } from "types/doctor";
import { SelectedAppointment } from "types/selectedAppointment";
import { Navbar } from "components/Navbar";
import { InfoCard } from "components/InfoCard";

interface AppointmentSummaryProps {
  doctor: Doctor;
  office: Office;
  selectedAppointment: SelectedAppointment;
}

const UnconnectedAppointmentSummary: React.FC<AppointmentSummaryProps> = ({
  doctor,
  office,
  selectedAppointment,
}) => {
  const { officeId, examType, patientReturnStatus } =
    useParams<
      paths.RequiredSchedulingRouteParams<
        "officeId" | "examType" | "patientReturnStatus"
      >
    >();

  const history = useHistory();
  const time = parseStartTimeWithZone(selectedAppointment);

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  usePageViewTracking("step-5-selected-appointment-time", {
    doctorId: doctor.id,
  });

  return (
    <Layout
      navbar={<Navbar office={office} />}
      emptyHeight={true}
      calledFrom={"summaryPage"}
    >
      <div className={styles.container}>
        <div className={styles.summaryContainer}>
          <p className={styles.summaryHeader}>Selected Options:</p>
          <h1 className={styles.dateHeader}>
            {time.format("ddd. MMM D, YYYY")} at {time.format("h:mm A z")}
          </h1>
          <OfficeInfoCard office={office} />
          <DoctorInfoCard doctor={doctor} office={office} />
          {doctor && doctor.offersVEH && (
            <InfoCard
              details={
                <div>
                  Because the doctor performing this exam will be remote, you
                  are confirming that you meet the following qualifications by
                  checking the box below:
                  <ul>
                    {!office.ageRestrictVEH && (
                      <li>I am at least 10 years old.</li>
                    )}
                    {office.ageRestrictVEH && (
                      <li>I am between the ages of 18-55.</li>
                    )}
                    <li>
                      I understand that certain patients MAY require additional
                      follow-up care for treatment of conditions identified
                      within the visit, and that I will follow the doctor’s
                      recommendation if needed{" "}
                    </li>
                    <li>
                      This exam requires a retinal image to help assess the
                      health of the eye. The additional fee for retinal imaging
                      is $44 and is generally not covered by insurance.
                    </li>
                  </ul>
                  <input
                    type="checkbox"
                    name="teleoptometry"
                    id="teleoptometry"
                    checked={checked}
                    onChange={handleChange}
                  />
                  <label htmlFor="teleoptometry">
                    {" "}
                    I meet all the above requirements. I am ready to schedule my
                    video-assisted eye health exam.
                  </label>
                </div>
              }
              image={undefined}
            ></InfoCard>
          )}
        </div>

        <div className={styles.footerActions}>
          <div className={styles.summaryButtons}>
            <Button
              role={ButtonRole.Ghost}
              text="Back"
              onClick={() => history.goBack()}
              size="large"
              className={styles.summaryButton}
            />

            <Button
              text="Next"
              className={styles.summaryButton}
              size="large"
              disabled={doctor.offersVEH ? !checked : checked}
              onClick={() => {
                history.push(
                  paths.appointmentsAccountSetup({
                    officeId,
                    examType,
                    patientReturnStatus,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const AppointmentSummary: React.FC = () => (
  <NeedsSelectedAppointment>
    {({ selectedAppointment, doctor }) => (
      <WithOffice>
        {({ office }) => (
          <UnconnectedAppointmentSummary
            selectedAppointment={selectedAppointment}
            office={office}
            doctor={doctor}
          />
        )}
      </WithOffice>
    )}
  </NeedsSelectedAppointment>
);
