import { cloneDeep } from "lodash";

type Query = Record<string, string | undefined>;

export const toQueryString = (query: Query = {}): string => {
  const copiedQuery = cloneDeep(query);

  Object.keys(copiedQuery).forEach((key) => {
    if (copiedQuery[key] === undefined) {
      delete copiedQuery[key];
    }
  });

  const queryString = new URLSearchParams(
    copiedQuery as Record<string, string>
  ).toString();
  return queryString ? `?${queryString}` : "";
};
