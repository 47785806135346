import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { WithOfficeDoctors } from "components/WithOfficeDoctors";
import {
  updateSelectedAppointment,
  updatePatient,
  selectSelectedAppointment,
  reinitialize,
} from "features/scheduling/appointmentCreationSlice";
import * as paths from "features/routing/paths";
import { Doctor } from "types/doctor";
import { SelectedAppointment } from "types/selectedAppointment";

interface NeedsSelectedAppointmentProps {
  children: (props: {
    selectedAppointment: SelectedAppointment;
    doctor: Doctor;
  }) => JSX.Element;
}

export const NeedsSelectedAppointment: React.FC<
  NeedsSelectedAppointmentProps
> = (props) => {
  const selectedAppointment = useSelector(selectSelectedAppointment);
  const { officeId, examType, patientReturnStatus } =
    useParams<
      paths.RequiredSchedulingRouteParams<
        "officeId" | "examType" | "patientReturnStatus"
      >
    >();

  if (!selectedAppointment) {
    return (
      <Redirect
        to={paths.appointmentsAvailableAppointments({
          officeId,
          examType,
          patientReturnStatus,
        })}
      />
    );
  }

  return (
    <WithOfficeDoctors officeId={officeId}>
      {({ doctors }) => {
        const appointmentDoctor = doctors.find(
          (doctor) => doctor.id === selectedAppointment.doctorId
        );

        if (!appointmentDoctor) {
          return (
            <Redirect
              to={paths.appointmentsAvailableAppointments({
                officeId,
                examType,
                patientReturnStatus,
              })}
            />
          );
        }

        return props.children({
          selectedAppointment,
          doctor: appointmentDoctor,
        });
      }}
    </WithOfficeDoctors>
  );
};
