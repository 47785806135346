import React, { useEffect } from "react";
import dayjs from "dayjs";
import { padStart } from "lodash";
import styles from "./IdleModalContent.module.scss";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { Button, ButtonRole } from "components/Button";

export interface IdleModalContentProps {
  timer: number;
  dismiss: () => void;
}

export const IdleModalContent: React.FC<IdleModalContentProps> = ({
  timer,
  dismiss,
}) => {
  const [endTime] = React.useState(dayjs().add(timer, "millisecond"));
  const [currentTime, setCurrentTime] = React.useState(dayjs());
  const { logout } = useCurrentPatient();

  const tick = () => {
    setCurrentTime(dayjs());
  };

  useEffect(() => {
    const countdownTimer = window.setInterval(() => {
      tick();
    }, 1000);
    return () => {
      window.clearInterval(countdownTimer);
    };
  }, []);

  const countdown = () => {
    const secondsLeft = endTime.diff(currentTime, "second") % 60;
    return `${endTime.diff(currentTime, "minute")}:${padStart(
      secondsLeft.toString(),
      2,
      "0"
    )}`;
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Session Timeout Due to Inactivity</h3>
      <div className={styles.body}>
        <div>Your session is about to expire.</div>
        <div>You will be logged out in {countdown()} minutes.</div>
      </div>
      <div className={styles.buttonRow}>
        <Button
          className={styles.button}
          onClick={logout}
          role={ButtonRole.Ghost}
          size="large"
          text="Log Out"
        />
        <Button
          className={styles.button}
          onClick={dismiss}
          size="large"
          text="Continue"
        />
      </div>
    </div>
  );
};
