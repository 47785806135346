import React from "react";
import cx from "classnames";
// eslint-disable-next-line no-restricted-imports
import { toTel } from "../../utils/toTel";
import styles from "./FooterV2.module.scss";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { InfoTooltip } from "components/InfoTooltip";

export interface FooterProps {
  formattedPhone: string;
}
declare let orderContactUrl: string;
export const Footer: React.FC<FooterProps> = ({ formattedPhone }) => {
  return (
    <div className={styles.footer}>
      <section className={cx(styles.footerSection, styles.footerLeft)}>
        <div className={styles.footerHeadingWithTooltip}>
          <h3 className={styles.footerHeading}>Other eyecare/vision needs?</h3>
          <InfoTooltip>
            <p>
              Please call our office to schedule appointments for follow-ups or
              urgent concerns.
            </p>
            <p>
              Urgent concerns may include any kind of eye infection or injury
              that impairs vision. Some examples are seeing floaters or flashing
              lights.
            </p>
          </InfoTooltip>
        </div>
        <p className={styles.footerParagraph}>
          If you are experiencing any eyecare/vision needs outside of your exam,
          please call the office at{" "}
          <a href={toTel(formattedPhone)} className={styles.footerPhoneLink}>
            {formattedPhone}
          </a>{" "}
          to schedule an&nbsp;appointment.
        </p>
      </section>
      <hr className={styles.footerDivider} />
      <section className={cx(styles.footerSection, styles.footerRight)}>
        <h3 className={styles.footerHeading}>Need new glasses or contacts?</h3>
        <p className={styles.footerLinkContainer}>
          <a
            href={`${MYEYEDR_WEBSITE_URL}/eye-wear/shop-glasses`}
            target="_blank"
            rel="noreferrer"
            className={styles.footerLink}
          >
            Browse Glasses
          </a>
        </p>
        <p className={styles.footerLinkContainer}>
          <a
            href={orderContactUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.footerLink}
          >
            Order Contacts
          </a>
        </p>
      </section>
    </div>
  );
};
