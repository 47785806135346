import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./ReturningPatientInsuranceReviewCard.module.scss";
import { FieldReview } from "features/checkIn/components/FieldReview";
import { Insurance } from "types/insurance";
import { checkInInsurance } from "features/routing/paths";

export const ReturningPatientInsuranceReviewCard: React.FC<{
  insurance: Insurance;
}> = ({ insurance }) => {
  const dateOfBirth = dayjs(insurance.subscriberDateOfBirth).format(
    "MM / DD / YYYY"
  );

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.edit}>
          <Link className={styles.editLink} to={checkInInsurance()}>
            Add New
          </Link>
        </div>
        <div className={styles.cardTitle}>Most recent submission:</div>
        <FieldReview
          label="Insurance Carrier"
          value={insurance.insuranceCarrier}
        />
        <FieldReview
          label="Subscriber"
          value={`${insurance.subscriberFirstName} ${insurance?.subscriberLastName}`}
        />
        <FieldReview label="Date of Birth" value={dateOfBirth} />
      </div>
    </div>
  );
};
