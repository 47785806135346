import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./AppointmentAuthorizationFailed.module.scss";
import { LegacyCard } from "components/LegacyCard";
import { Layout } from "features/layout";
import { Button } from "components/Button";

export const AppointmentAuthorizationFailed: React.FC = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <Layout>
      <div>
        <LegacyCard className={styles.card}>
          <div>
            <h1 className={styles.header}>We're Sorry</h1>
            <p className={styles.body}>
              We are having trouble locating your records. Please verify that
              the details provided match the information we have on file.
            </p>
            <div className={styles.buttonContainer}>
              <Button
                onClick={goBack}
                type="submit"
                size="large"
                text="Try Again"
              />
            </div>
            <p className={styles.body}>
              If this problem continues, please contact your local office to
              book your appointment{" "}
              <a href="https://locations.myeyedr.com/search">Click Here.</a>
            </p>
          </div>
        </LegacyCard>
      </div>
    </Layout>
  );
};
