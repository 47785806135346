import React from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
// eslint-disable-next-line no-restricted-imports
import styles from "./AppointmentAccountSetup.module.scss";
import {
  ApptMaskedDateInput,
  ApptMaskedPhoneInput,
} from "components/MaskedTextInput/MaskedTextInput";
import { API_DATE_FORMAT } from "api/constants";
import { Doctor } from "types/doctor";
//import { Patient } from "types/patient";
import { maskPhone } from "components/MaskedTextInput/maskPhone";
import { Office } from "types/office";
import {
  isEmail,
  isValidTLDLength,
  acceptableCharacters,
} from "utils/emailValidation";
import { isValidDateOfBirth } from "utils/dateOfBirthValidation";
import { isValidDateOfBirthVEH } from "utils/dateOfBirthValidationVEH";
import { isValidDOBRestrictVEH } from "utils/isValidDOBRestrictVEH";
import { isValidPhoneNumber } from "utils/phoneNumberValidation";
import { presenceNameValidation } from "utils/presenceNameValidation";
import { SmsCheckbox } from "components/SmsCheckbox";
import { ApptTextInput } from "components/TextInput";

interface AppointmentAccountSetupProps {
  doctor?: Doctor | null;
  office: Office;
  patient: any;
  updatePatient: (fieldValue: any) => void;
  isMobileView: boolean;
}

export const AppointmentAccountSetup: React.FC<
  AppointmentAccountSetupProps
> = ({ doctor, office, patient, updatePatient, isMobileView }) => {
  const {
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onBlur",
  });
  const firstName = register(
    "firstName",
    presenceNameValidation(
      "Not a valid first name; no special characters except '-' allowed"
    )
  );
  const lastName = register(
    "lastName",
    presenceNameValidation(
      "Not a valid last name; no special characters except '-' allowed"
    )
  );
  const phoneNumber = register("phoneNumber", {
    validate: isValidPhoneNumber,
  });

  let dateOfBirth = register("dateOfBirth", {
    validate: isValidDateOfBirth,
  });

  const smsOptInNotices = register("smsOptInNotices");
  const smsOptInPromos = register("smsOptInPromos");

  if (doctor?.offersVEH) {
    if (!office.ageRestrictVEH) {
      dateOfBirth = register("dateOfBirth", {
        validate: isValidDateOfBirthVEH,
      });
    } else {
      dateOfBirth = register("dateOfBirth", {
        validate: isValidDOBRestrictVEH,
      });
    }
  }
  const email = register("email", {
    required: "Not a valid email",
    validate: {
      isEmail: (value) => isEmail(value),
      isValidTLDLength: (value) => isValidTLDLength(value),
      acceptableCharacters: (value) => acceptableCharacters(value),
    },
  });

  return (
    <div className={styles.layout}>
      <h1 className={styles.headline}>Patient Details</h1>
      <hr className={styles.divider} />
      <div
        className={
          !isMobileView ? cx(styles.twoColumns, styles.patientSpace) : ""
        }
      >
        <ApptTextInput
          {...firstName}
          maxLength={20}
          inputType="text"
          onChange={(e) => {
            updatePatient({
              field: "firstName",
              value: e.target.value,
              isFormValid: isValid,
            });
            firstName.onChange(e);
          }}
          placeholder="Eg- Jacob"
          errorMessage={errors.firstName?.message}
          defaultValue={patient?.firstName}
        >
          First Name
        </ApptTextInput>

        <ApptTextInput
          {...lastName}
          maxLength={20}
          inputType="text"
          onChange={(e) => {
            updatePatient({
              field: "lastName",
              value: e.target.value,
              isFormValid: isValid,
            });
            lastName.onChange(e);
          }}
          placeholder="Eg- John"
          errorMessage={errors.lastName?.message}
          defaultValue={patient?.lastName}
        >
          Last Name
        </ApptTextInput>
      </div>

      <div className={styles.desktopTwoColumns}>
        <ApptMaskedPhoneInput
          {...phoneNumber}
          onChange={(e) => {
            updatePatient({
              field: "phoneNumber",
              value: e.target.value.replace(/\D/g, ""),
              isFormValid: isValid,
            });
            phoneNumber.onChange(e);
          }}
          setValue={setValue}
          errorMessage={errors.phoneNumber?.message}
          defaultValue={patient?.phoneNumber && maskPhone(patient.phoneNumber)}
          guidedTooltip={
            <p className={styles.tooltipText}>
              Entering a <b>mobile number</b> helps{" "}
              <b>keep your information secure</b> because we can verify your
              identity for future appointments and check-ins by sending you a
              text message.
            </p>
          }
          guidedTooltipClassName={
            errors.phoneNumber?.message && styles.tooltipHavingError
          }
        >
          Phone Number (mobile preferred)
        </ApptMaskedPhoneInput>
        <ApptTextInput
          {...email}
          inputType="email"
          onChange={(e) => {
            updatePatient({
              field: "email",
              value: e.target.value,
              isFormValid: isValid,
            });
            email.onChange(e);
          }}
          placeholder="Eg- Joye@gmail.com"
          errorMessage={errors.email?.message}
          defaultValue={patient?.email}
        >
          Email
        </ApptTextInput>
      </div>
      <div className={!isMobileView ? cx(styles.twoColumns, styles.dob) : ""}>
        <ApptMaskedDateInput
          {...dateOfBirth}
          onChange={(e) => {
            const parsedDate = dayjs(e.target.value);
            let datestr = "";
            if (parsedDate.isValid()) {
              datestr = parsedDate.format(API_DATE_FORMAT);
            } else {
              datestr = e.target.value;
            }
            updatePatient({
              field: "dateOfBirth",
              value: datestr,
              isFormValid: isValid,
            });
            dateOfBirth.onChange(e);
          }}
          errorMessage={errors.dateOfBirth?.message}
          setValue={setValue}
          defaultValue={
            patient?.dateOfBirth &&
            dayjs(patient?.dateOfBirth).format("MM/DD/YYYY")
          }
        >
          Date of Birth
        </ApptMaskedDateInput>
      </div>
      <SmsCheckbox
        label="I'd like to receive text reminders about my appointment, about my prescription, and when my glasses or contacts are ready. (MyEyeDr. Notices)"
        defaultChecked={false}
        {...smsOptInNotices}
        onChange={(e) => {
          updatePatient({
            field: "smsOptInNotices",
            value: e.target.checked,
            isFormValid: isValid,
          });
          smsOptInNotices.onChange(e);
        }}
      />
      <SmsCheckbox
        label="I'd like to receive promotional texts with eyewear and eyecare offers. (MyEyeDr. Promos)"
        defaultChecked={false}
        {...smsOptInPromos}
        onChange={(e) => {
          updatePatient({
            field: "smsOptInPromos",
            value: e.target.checked,
          });
          smsOptInPromos.onChange(e);
        }}
      />
      <p className={styles.smsHelp}>
        Message frequency varies. Message and data rates may apply. At any time
        you can text HELP for help or text STOP to stop notifications.{" "}
        <a
          href="https://www.myeyedr.com/sms-terms-service"
          target="_blank"
          rel="noreferrer"
        >
          SMS Terms of Service
        </a>{" "}
        |{" "}
        <a
          href="https://www.myeyedr.com/privacy-policy-practices"
          target="_blank"
          rel="noreferrer"
        >
          MyEyeDr. Privacy Policy
        </a>
      </p>

      <div className={styles.agreeText}>
        I agree that you can contact me by text at the mobile number provided.
        By agreeing I consent to receiving texts about promotional offers
        related to eyewear and eyecare offers by any type of dialing equipment,
        automated technology, or other messaging system to send the
        communications. I understand message frequency varies and that data and
        message rates may apply. I understand that I am not required to agree to
        such messaging, and it is not a condition for the purchase of any goods
        or services.
      </div>
    </div>
  );
};
