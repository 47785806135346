import React from "react";
import cx from "classnames";
import styles from "./Checkbox.module.scss";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<"input"> {
  className?: string;
  theme?: "light" | "dark";
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, theme = "dark", ...nativeProps }, ref) => {
    return (
      <div className={cx(styles.checkboxContainer, className)}>
        <input
          ref={ref}
          type="checkbox"
          className={styles.nativeCheckbox}
          {...nativeProps}
        />
        <div
          className={cx(styles.checkbox, {
            [styles.lightCheckbox]: theme === "light",
            [styles.darkCheckbox]: theme === "dark",
          })}
        />
      </div>
    );
  }
);
