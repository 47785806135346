import React from "react";
import styles from "./Introduction.module.scss";

interface IntroductionProps {
  header: string;
  children: React.ReactNode;
}

export const Introduction: React.FC<IntroductionProps> = ({
  header,
  children,
}) => (
  <div className={styles.introduction}>
    <h1 className={styles.header}>{header}</h1>
    <div>{children}</div>
  </div>
);
