import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./AppointmentSelectReturnStatus.module.scss";
import {
  DesktopBreadcrumb,
  MobileAccordionContainer,
  MobileAccordionSteps,
} from "./components/nav";
import { usePageViewTracking } from "hooks/usePageViewTracking";
import { today } from "features/scheduling/utils/today";
import * as paths from "features/routing/paths";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { Layout } from "features/layout";
import { Navbar } from "components/Navbar";
import { WithOffice } from "components/WithOffice";
import { Office } from "types/office";
import { useTrackEvent } from "hooks/useTrackEvent";
import { selectBrazePatientDetail } from "features/scheduling/brazePatientDetailSlice";

interface ReturnStatusChoiceProps {
  value: PatientReturnStatus;
  onClick: (x: PatientReturnStatus) => void;
  text: string;
}

const ReturnStatusChoice: React.FC<ReturnStatusChoiceProps> = ({
  value,
  onClick,
  text,
}) => {
  return (
    <button className={styles.button} onClick={() => onClick(value)}>
      {text}
    </button>
  );
};

interface UnconnectedAppointmentSelectReturnStatusProps {
  office: Office;
  examType: ExamType;
}

const UnconnectedAppointmentSelectReturnStatus: React.FC<
  UnconnectedAppointmentSelectReturnStatusProps
> = ({ office, examType }) => {
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const brazePatientDetail = useSelector(selectBrazePatientDetail);

  /*
  React.useEffect(() => {
    if (
      office.restrictVEH ||
      (brazePatientDetail && brazePatientDetail.patientId)
    ) {
      dispatch(
        setEmptyData({
          slots: undefined,
          lastRequest: undefined,
          serverError: true,
        })
      );
    }
  }, [brazePatientDetail, dispatch, office.restrictVEH]); */

  const onClick = (selectedStatus: PatientReturnStatus) => {
    trackEvent("step-3-patient-selects-return-status", {
      patientReturnStatus: selectedStatus,
    });

    if (
      office.restrictVEH ||
      (brazePatientDetail && brazePatientDetail.patientId)
    ) {
      let pathName = paths.appointmentsAvailableAppointments({
        officeId: office.id,
        examType,
        patientReturnStatus: selectedStatus,
      });
      if (brazePatientDetail && brazePatientDetail.patientId) {
        pathName = pathName + "?eid=" + brazePatientDetail.patientId;
      }
      window.location.href = window.location.origin + pathName;

      return false;
    }
    history.push(
      paths.appointmentsAvailableAppointments({
        officeId: office.id,
        examType,
        patientReturnStatus: selectedStatus,
      })
    );
  };

  usePageViewTracking("step-3-selected-eyewear-type");

  return (
    <Layout
      navbar={
        <Navbar office={office}>
          <DesktopBreadcrumb
            step={2}
            officeId={office.id}
            examType={examType}
          />
        </Navbar>
      }
    >
      <MobileAccordionContainer>
        <MobileAccordionSteps
          step={2}
          officeId={office.id}
          examType={examType}
        />

        <div className={styles.container}>
          <div className={styles.layout}>
            <div className={styles.prompt}>
              <h1 className={styles.headline}>
                Have you previously visited a MyEyeDr. location before?
              </h1>
              <hr className={styles.divider} />
            </div>

            <div className={styles.choices}>
              <ReturnStatusChoice
                value={PatientReturnStatus.Returning}
                onClick={onClick}
                text="Yes"
              />
              <ReturnStatusChoice
                value={PatientReturnStatus.New}
                onClick={onClick}
                text="No"
              />
              <ReturnStatusChoice
                value={PatientReturnStatus.Unknown}
                onClick={onClick}
                text="Not Sure"
              />
            </div>
            <div className={styles.bg} />
          </div>
        </div>
      </MobileAccordionContainer>
    </Layout>
  );
};

interface AppointmentSelectReturnStatusRouterProps {
  examType: ExamType;
}
declare let isEnabledV2: string;
export const AppointmentSelectReturnStatus: React.FC = () => {
  const { examType } = useParams<AppointmentSelectReturnStatusRouterProps>();
  const { officeId } =
    useParams<paths.RequiredSchedulingRouteParams<"officeId">>();
  const history = useHistory();
  if (isEnabledV2) {
    history.push(paths.v2appointmentStart(officeId, today));
    return null;
  }

  return (
    <WithOffice>
      {({ office }) => {
        return (
          <UnconnectedAppointmentSelectReturnStatus
            office={office}
            examType={examType}
          />
        );
      }}
    </WithOffice>
  );
};
