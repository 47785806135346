import React from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { compact } from "lodash";
import styles from "./WeekTimeSlots.module.scss";
import { RootState } from "app/store";
import { NavigationButton } from "features/scheduling/AppointmentSelectSlot/NavigationButton";
import { Office } from "types/office";
import { Doctor } from "types/doctor";
import { Slot } from "types/slot";
import { API_DATE_FORMAT } from "api/constants";
import { WaitlistV2 } from "features/scheduling/components/Waitlist/WaitlistV2";
import { DoctorSelectionPanelV2 } from "features/scheduling/components/DoctorSelectionPanelV2";
import { selectDoctorOrderedByAvailability } from "features/scheduling/availabilitySummarySlice";
import {
  setTimeSlots,
  selectIstimeSlotLoaded,
} from "features/scheduling/timeSlotsSlice";

import chevronDownIcon from "images/chevron-downV2.svg";
import chevronUpIcon from "images/chevron-upV2.svg";

interface OfficeCardProps {
  office: Office;
  doctors: Doctor[];
  searchDate: string;
  onSelection: (type: string, value: Slot) => void;
  seeMoreScrollToTop: (visible: boolean) => void;
  setTimeslotsExecution: () => void;
  selectedSlot: undefined | Slot;
  isEditForm: false;
}

export const WeekTimeSlots: React.FC<OfficeCardProps> = ({
  office,
  doctors,
  searchDate,
  onSelection,
  seeMoreScrollToTop,
  setTimeslotsExecution,
  selectedSlot,
  isEditForm,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [executeOnce, setExecuteOnce] = React.useState(false);
  const [loadOnce, setLoadOnce] = React.useState(false);
  const [openWaitlist, setOpenWaitlist] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<undefined | string>(
    undefined
  );
  const [prevWeek, setPrevWeek] = React.useState<string>("");
  const [nextWeek, setNextWeek] = React.useState<string>("");
  const selectedDateTemp =
    selectedDate === undefined ? searchDate : selectedDate;

  const doctorIdsByAvailability = useSelector(
    (state: RootState) =>
      selectDoctorOrderedByAvailability(
        state,
        office.id,
        selectedDateTemp,
        office.timeSlotWeeks
      ),
    shallowEqual
  );

  const newSortedDoctors = compact(
    doctorIdsByAvailability.map((doctorId) => {
      return doctors.find((doctor) => doctor.id === doctorId);
    })
  );

  const doctorsToDisplay = newSortedDoctors || doctors;
  const isSlotLoaded = useSelector((state: RootState) =>
    selectIstimeSlotLoaded(state)
  );

  //const doctorsToDisplay = doctors;
  const onSelectFullView = () => {
    setVisible(!visible);
    seeMoreScrollToTop(visible);
  };

  const onSelectDate = (date: string) => {
    if (weekCalendar.length > 0 && weekCalendar.indexOf(date) > -1) {
      setSelectedDate(date);
    } else {
      const params = new URLSearchParams(history.location.search);
      params.set("date", date);
      setExecuteOnce(false);
      dispatch(setTimeSlots([]));
      setTimeslotsExecution();
      setSelectedDate(date);
      setLoadOnce(false);
      history.replace({ search: params.toString() });
    }
  };

  const onSetLoadOnce = () => {
    if (!loadOnce) setLoadOnce(true);
  };

  const onSelectWaitlist = () => {
    setOpenWaitlist(!openWaitlist);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const weekCalendar: any[] = [];
  ["0", "1", "2", "3", "4", "5", "6"].map((i) =>
    weekCalendar.push(
      dayjs(searchDate).add(parseInt(i), "day").format(API_DATE_FORMAT)
    )
  );

  const seeMoreText = visible
    ? "See less doctors in this office "
    : "See more doctors in this office ";
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const trackBrowseDatesEvent = () => {
    setExecuteOnce(false);
    setLoadOnce(false);
    setSelectedDate(undefined);
    setTimeslotsExecution();
    dispatch(setTimeSlots([]));
  };

  React.useEffect(() => {
    if (weekCalendar.length === 7 && !executeOnce) {
      if (
        dayjs(selectedDateTemp).format(API_DATE_FORMAT) ===
        dayjs().format(API_DATE_FORMAT)
      ) {
        setPrevWeek("");
      } else {
        const dateStr = dayjs(weekCalendar[0])
          .subtract(6, "day")
          .format(API_DATE_FORMAT);
        const prevDate = dayjs(weekCalendar[0])
          .subtract(7, "day")
          .format(API_DATE_FORMAT);
        if (!dayjs().isBefore(dateStr)) {
          const params = new URLSearchParams(history.location.search);
          params.set("date", dayjs().format(API_DATE_FORMAT));
          history.replace({ search: params.toString() });
        } else {
          setPrevWeek("start?date=" + prevDate);
        }
      }
      const today = dayjs().format(API_DATE_FORMAT);
      const restrictDate = 7 * office.timeSlotWeeks;
      const restrictedDay = dayjs(today)
        .add(restrictDate, "day")
        .format(API_DATE_FORMAT);
      if (dayjs(weekCalendar[6]).isBefore(restrictedDay)) {
        setNextWeek(
          "start?date=" +
            dayjs(weekCalendar[6]).add(1, "day").format(API_DATE_FORMAT)
        );
      } else {
        setNextWeek("");
      }
      setExecuteOnce(true);
    }
  }, [
    executeOnce,
    history,
    office.timeSlotWeeks,
    selectedDateTemp,
    weekCalendar,
  ]);

  React.useEffect(() => {
    if (isEditForm) {
      if (selectedSlot) setSelectedDate(selectedSlot.date);
    }
  }, [isEditForm, selectedSlot]);

  return (
    <div className={styles.container}>
      <h1 className={styles.headline}>Select doctor and time slot</h1>
      <hr className={styles.divider} />
      <div>
        <div className={styles.weekContainer}>
          <div className={cx(styles.arrow, styles.arrowLeft)}>
            <NavigationButton
              url={prevWeek}
              disabled={prevWeek === "" || !isSlotLoaded}
              direction="left"
              onClick={trackBrowseDatesEvent}
            />
          </div>
          {weekCalendar.map((day) => (
            <div
              className={
                selectedDateTemp === day
                  ? cx(styles.dayContainer, styles.daySelected)
                  : styles.dayContainer
              }
              onClick={() => onSelectDate(day)}
              aria-hidden="true"
            >
              <div className={styles.dayWords}>{dayjs(day).format("ddd")}</div>
              <div className={styles.monthYear}>
                {dayjs(day).format("MMM")} {dayjs(day).format("D")}
              </div>
            </div>
          ))}
          <div className={cx(styles.arrow, styles.arrowRight)}>
            <NavigationButton
              url={nextWeek}
              disabled={nextWeek === "" || !isSlotLoaded}
              direction="right"
              onClick={trackBrowseDatesEvent}
            />
          </div>
        </div>
      </div>
      {doctorsToDisplay && (
        <DoctorSelectionPanelV2
          doctors={doctorsToDisplay}
          selectedOffice={office}
          forDate={selectedDateTemp}
          visible={visible}
          onSelection={onSelection}
          onSelectNextAvail={onSelectDate}
          selectedSlot={selectedSlot}
          weekCalendar={weekCalendar}
          loadOnce={loadOnce}
          onSetLoadOnce={onSetLoadOnce}
          onSelectWaitlist={onSelectWaitlist}
        />
      )}
      {doctorsToDisplay.length === 1 && (
        <div className={styles.emptyHeight}></div>
      )}
      {doctorsToDisplay.length > 4 && (
        <div
          className={styles.seeMore}
          onClick={() => onSelectFullView()}
          aria-hidden="true"
        >
          {seeMoreText}
          <img
            src={visible ? chevronUpIcon : chevronDownIcon}
            alt={seeMoreText}
          />
        </div>
      )}
      <div className={styles.seeMoreWaitlist}>
        <div className={styles.underLine}></div>
        <WaitlistV2
          office={office}
          openWaitlist={openWaitlist}
          onSelectWaitlist={onSelectWaitlist}
        />
      </div>
    </div>
  );
};
