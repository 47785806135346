import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Form.module.scss";
import { Button } from "components/Button";
import { TextInput } from "components/TextInput";
import {
  isEmail,
  isValidTLDLength,
  acceptableCharacters,
} from "utils/emailValidation";

export const Form: React.FC<{
  onSubmit: (data: { email: string }) => void;
  autoFocus: boolean;
}> = ({ onSubmit, autoFocus }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const email = register("email", {
    required: "Not a valid email",
    validate: {
      isEmail: (value) => isEmail(value),
      isValidTLDLength: (value) => isValidTLDLength(value),
      acceptableCharacters: (value) => acceptableCharacters(value),
    },
  });

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      aria-label="Login form"
    >
      <h1 className={styles.heading}>Log in to my account</h1>
      <p>
        Please enter your email address to access your prescriptions, view your
        order status and manage your appointments.
      </p>
      <TextInput
        errorMessage={errors.email?.message}
        autoFocus={autoFocus}
        {...email}
        inputType="email"
      >
        Email Address
      </TextInput>
      <hr className={styles.rule} />

      <p>We’ll send you an access link to login to your existing account.</p>
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          size="large"
          text="Next"
        />
      </div>
    </form>
  );
};
