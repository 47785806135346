import React from "react";
import { Route, Switch } from "react-router-dom";
import { AboutYou1 } from "./AboutYou1";
import { AboutYou2 } from "./AboutYou2";
import { Home } from "./Home";
import { AboutYou1FormData, InsuranceFormData } from "./types";
import { Insurance } from "./Insurance";
import { InsuranceReview } from "./InsuranceReview";
import { ReviewYourInsurance } from "./ReviewYourInsurance";
import { PrescriptionsReview } from "./PrescriptionsReview";
import { ConfirmInsuranceSkip } from "./ConfirmInsuranceSkip";
import { ReviewYourAddress } from "./ReviewYourAddress";
import { Address } from "types/address";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import * as paths from "features/routing/paths";

export const Router: React.FC = () => {
  const { currentPatient } = useCurrentPatient();

  const [aboutYou1Data, setAboutYou1Data] =
    React.useState<AboutYou1FormData | null>(null);
  const [insuranceData, setInsuranceData] =
    React.useState<InsuranceFormData | null>(null);

  if (!currentPatient) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={paths.checkInHome()}>
        <Home />
      </Route>
      <Route exact path={paths.checkInAboutYou1()}>
        <AboutYou1 aboutYou1Data={aboutYou1Data} onSubmit={setAboutYou1Data} />
      </Route>
      <Route exact path={paths.checkInAboutYou2()}>
        <AboutYou2 aboutYou1Data={aboutYou1Data} />
      </Route>
      <Route exact path={paths.reviewYourAddress()}>
        <ReviewYourAddress
          address={currentPatient?.primaryAddress as Address}
          onSubmit={setAboutYou1Data}
        />
      </Route>
      <Route exact path={paths.checkInInsurance()}>
        <Insurance defaultValues={insuranceData} onSubmit={setInsuranceData} />
      </Route>
      <Route
        exact
        path={[
          "/my-account/check-in/insurance-review",
          paths.checkInInsuranceReview(),
        ]}
      >
        <InsuranceReview insuranceData={insuranceData} />
      </Route>
      <Route exact path={paths.reviewYourInsurance()}>
        <ReviewYourInsurance />
      </Route>
      <Route exact path={paths.checkInPrescriptions()}>
        <PrescriptionsReview />
      </Route>
      <Route exact path={paths.checkInInsuranceSkip()}>
        <ConfirmInsuranceSkip />
      </Route>
    </Switch>
  );
};
