import React from "react";
import { useErrorToast } from "./useErrorToast";
import { getOfficeV1Request } from "api/getOfficeRequest";
import { Office } from "types/office";

export const useOffice = (
  officeId: string
): {
  office: Office | undefined;
} => {
  const [office, setOffice] = React.useState<undefined | Office>(undefined);
  const { showErrorToast } = useErrorToast();

  React.useEffect(() => {
    const fetchOffice = async () => {
      try {
        const result = await getOfficeV1Request(officeId);
        setOffice(result);
      } catch (e) {
        showErrorToast();
      }
    };

    fetchOffice();
  }, [showErrorToast, officeId]);

  return { office };
};
