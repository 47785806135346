import dayjs from "dayjs";
import advancedFormatPlugin from "dayjs/plugin/advancedFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isTodayPlugin from "dayjs/plugin/isToday";
import minMaxPlugin from "dayjs/plugin/minMax";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

export const initializeDayjs = (): void => {
  dayjs.extend(utcPlugin);
  dayjs.extend(timezonePlugin);
  dayjs.extend(advancedFormatPlugin);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isTodayPlugin);
  dayjs.extend(minMaxPlugin);
  dayjs.extend(relativeTimePlugin);
};
