import React from "react";
import cx from "classnames";
import styles from "./SadLayout.module.scss";
import { Layout } from "features/layout";

interface SadLayoutProps {
  headline: string;
  message: React.ReactNode;
  cta: React.ReactNode;
  className?: string;
}

export const SadLayout: React.FC<SadLayoutProps> = ({
  headline,
  message,
  cta,
  className,
}) => {
  return (
    <Layout>
      <div className={cx(styles.container, className)}>
        <h2 className={styles.heading}>{headline}</h2>
        <div className={styles.message}>{message}</div>

        <div className={styles.ctaWrapper}>{cta}</div>
      </div>
    </Layout>
  );
};
