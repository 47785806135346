import { CSSTransition, TransitionGroup } from "react-transition-group";
import React from "react";
import { Message } from "./Message";
import styles from "./Snackbar.module.scss";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";

const messageAnimationStyles = {
  enter: styles.messageEnter,
  enterActive: styles.messageEnterActive,
  exit: styles.messageExit,
  exitActive: styles.messageExitActive,
};

export const Snackbar: React.FC = () => {
  const { messages } = useSnackbar();
  return (
    <TransitionGroup className={styles.snackbar}>
      {messages.map((message) => {
        const ref = React.createRef<HTMLDivElement>();
        return (
          <CSSTransition
            key={message.id}
            timeout={800}
            classNames={messageAnimationStyles}
            nodeRef={ref}
          >
            <div ref={ref}>
              <Message message={message} />
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
