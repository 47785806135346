import React from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import ReactModal from "react-modal";
import { AppRoute } from "./AppRoute";
import * as paths from "./paths";
import { generateRouteParams } from "./generateRouteParams";
import { AuthorizedRouter } from "./AuthorizedRouter";
import { CommunicationPreferences } from "features/communicationPreferences";
import { VerifyDemographics } from "features/login/VerifyDemographics";
import { Root } from "features/root";
import { ComponentCatalog } from "features/componentCatalog";
import { AppointmentBookingStart } from "features/scheduling/AppointmentBookingStart";
import { AppointmentExamDetails } from "features/scheduling/AppointmentExamDetails";
import { AppointmentSelectSlot } from "features/scheduling/AppointmentSelectSlot";
import { AppointmentSummary } from "features/scheduling/AppointmentSummary";
import { AppointmentAccountSetup } from "features/scheduling/AppointmentAccountSetup";
import {
  AppointmentFailure,
  AppointmentFailureV2,
} from "features/scheduling/AppointmentFailure";
import { AppointmentReviewV2 } from "features/scheduling/V2/AppointmentReviewV2";
import { AppointmentSuccessV2 } from "features/scheduling/V2/AppointmentSuccessV2";
import { AppointmentSuccessNewV2 } from "features/scheduling/V2/AppointmentSuccessNewV2";
import { AppointmentBookingStartV2 } from "features/scheduling/V2/AppointmentBookingStartV2";
import { OrderTracker } from "features/scheduling/OrderTracker";
import { ValidateOfficeAPI } from "features/scheduling/ValidateOfficeAPI";
import { Offices } from "features/scheduling/Offices";
import { AppointmentReview } from "features/scheduling/AppointmentReview";
import { AppointmentSelectReturnStatus } from "features/scheduling/AppointmentSelectReturnStatus";
import { AppointmentSelectDoctorSlot } from "features/scheduling/AppointmentSelectDoctorSlot";
import { AppointmentSlotTaken } from "features/scheduling/AppointmentSlotTaken";
import {
  AppointmentSuccess,
  AppointmentSuccessNew,
} from "features/scheduling/AppointmentSuccess";
import { MagicLinkLanding } from "features/login/MagicLinkLanding";
import { ChooseTwoFactorMethod } from "features/login/ChooseTwoFactorMethod";
import { EnterTwoFactorCode } from "features/login/EnterTwoFactorCode";
import { Login } from "features/login/Login";
import { AppointmentAuthorizationFailed } from "features/patientDashboard/AppointmentAuthorizationFailed";
import { ConditionalRoute } from "features/routing/ConditionalRoute";
import { areDebugRoutesEnabled } from "featureFlags/areDebugRoutesEnabled";

export const AppRouter = hot(module)(function AppRouter() {
  ReactModal.setAppElement("#root");

  return (
    <BrowserRouter>
      <Switch>
        {/* Scheduling */}
        <AppRoute
          exact
          path={paths.appointmentSuccessV2(generateRouteParams("officeId"))}
        >
          <AppointmentSuccessV2 />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentSuccessNewV2(generateRouteParams("officeId"))}
        >
          <AppointmentSuccessNewV2 />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsReviewV2(generateRouteParams("officeId"))}
        >
          <AppointmentReviewV2 />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentBookingStartV2(
            generateRouteParams("officeId")
          )}
        >
          <AppointmentBookingStartV2 />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentBookingStart(generateRouteParams("officeId"))}
        >
          <AppointmentBookingStart />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSelectType(generateRouteParams("officeId"))}
        >
          <AppointmentExamDetails />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsFailureV2(generateRouteParams("officeId"))}
        >
          <AppointmentFailureV2 />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSelectReturnStatus(
            generateRouteParams("officeId", "examType")
          )}
        >
          <AppointmentSelectReturnStatus />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsAvailableAppointments(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentSelectSlot />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsAvailableAppointmentsDoctor(
            generateRouteParams(
              "officeId",
              "examType",
              "patientReturnStatus",
              "doctorId"
            )
          )}
        >
          <AppointmentSelectDoctorSlot />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsReview(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentReview />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSummary(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentSummary />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsAccountSetup(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentAccountSetup />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSuccess(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentSuccess />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSuccessNew(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentSuccessNew />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsFailure(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentFailure />
        </AppRoute>
        <AppRoute
          exact
          path={paths.appointmentsSlotTaken(
            generateRouteParams("officeId", "examType", "patientReturnStatus")
          )}
        >
          <AppointmentSlotTaken />
        </AppRoute>
        <AppRoute
          exact
          path={paths.OrderTrackerParams(generateRouteParams("orderUID"))}
        >
          <OrderTracker />
        </AppRoute>
        <AppRoute
          exact
          path={paths.ProviderSlotParams(generateRouteParams("officeId"))}
        >
          <ValidateOfficeAPI />
        </AppRoute>
        {/* Authorization */}
        <AppRoute
          exact
          path={[
            paths.appointmentAuthorizationFailed(),
            paths.old.appointmentAuthorizationFailed(),
          ]}
        >
          <AppointmentAuthorizationFailed />
        </AppRoute>
        <AppRoute
          exact
          path={[
            paths.verifyDemographics(generateRouteParams("token")),
            paths.old.verifyDemographics(generateRouteParams("token")),
          ]}
        >
          <VerifyDemographics />
        </AppRoute>
        <AppRoute
          exact
          path={[
            paths.magicLinkLanding(generateRouteParams("token")),
            paths.old.magicLinkLanding(generateRouteParams("token")),
          ]}
        >
          <MagicLinkLanding />
        </AppRoute>
        <AppRoute
          exact
          path={[paths.portalLogin(), paths.old.patientDashboardLogin()]}
        >
          <Login />
        </AppRoute>
        <AppRoute exact path={paths.chooseTwoFactorMethod({})}>
          <ChooseTwoFactorMethod />
        </AppRoute>
        <AppRoute
          exact
          path={paths.enterTwoFactorCode(generateRouteParams("phoneNumberId"))}
        >
          <EnterTwoFactorCode />
        </AppRoute>
        {/* Portal */}
        <AppRoute
          exact
          path={[
            paths.communicationPreferences(generateRouteParams("patientId")),
            paths.old.communicationPreferences(
              generateRouteParams("patientId")
            ),
          ]}
        >
          <CommunicationPreferences />
        </AppRoute>
        <AppRoute
          path={[paths.portalFragment, paths.oldManageAppointmentFragment]}
        >
          <AuthorizedRouter />
        </AppRoute>
        {/* Remainder */}
        <ConditionalRoute
          enabled={areDebugRoutesEnabled()}
          exact
          path={paths.componentCatalog()}
        >
          <ComponentCatalog />
        </ConditionalRoute>
        <ConditionalRoute
          enabled={areDebugRoutesEnabled()}
          exact
          path={paths.offices()}
        >
          <Offices />
        </ConditionalRoute>
        <ConditionalRoute
          enabled={areDebugRoutesEnabled()}
          exact
          path={paths.root()}
        >
          <Root />
        </ConditionalRoute>
        <AppRoute path="*">
          <Redirect to={paths.root()} />
        </AppRoute>
      </Switch>
    </BrowserRouter>
  );
});
