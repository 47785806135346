import React from "react";
import cx from "classnames";
import styles from "./LogoutButton.module.scss";
import { useCurrentPatient } from "./hooks/useCurrentPatient";

export const LogoutButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { logout } = useCurrentPatient();

  return (
    <button onClick={logout} className={cx(className, styles.logoutButton)}>
      Log out
    </button>
  );
};
