import React from "react";
import ContentLoader from "react-content-loader";
import dayjs from "dayjs";
import { toTel } from "features/scheduling/utils/toTel";
//import { calendarWeek } from "features/scheduling/utils/calendarWeek";
import { DoctorAvailability } from "features/scheduling/availabilitySummarySlice";
import { Office } from "types/office";
//import { API_DATE_FORMAT } from "api/constants";
import { Slot } from "types/slot";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";
import { Doctor } from "types/doctor";
import { MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL } from "utils/constants";

interface AvailabilityForDoctorProps {
  availability: DoctorAvailability;
  forDate: string;
  office: Office;
  slots: { [date: string]: Slot[] };
  doctor: Doctor;
}

export const AvailabilityForDoctorV2: React.FC<AvailabilityForDoctorProps> = ({
  availability,
  forDate,
  office,
  slots,
  doctor,
}) => {
  if (!areFirstAvailableApptsByDefaultEnabled()) {
    /*const numSlotsInWeek = calendarWeek(forDate)
      .map((day) => day.format(API_DATE_FORMAT))
      .reduce((count, day) => count + (slots[day]?.length ?? 0), 0);*/
    const numSlotsInWeek = slots[forDate]?.length ?? 0;

    if (numSlotsInWeek && availability) {
      return (
        <>
          <span>Available From {dayjs(availability).format("ddd, MMM D")}</span>
          <br />
          <span>{`(${numSlotsInWeek} ${
            numSlotsInWeek === 1 ? "Opening" : "Openings"
          })`}</span>
          {doctor.offersVEH && (
            <>
              <br />
            </>
          )}
        </>
      );
    }
  }

  if (availability === "loading") {
    return (
      <ContentLoader
        speed={2}
        width={100}
        height={38}
        viewBox="0 0 100 36"
        backgroundColor="var(--gray-02)"
        foregroundColor="var(--gray-04)"
      >
        <rect x="0" y="8" rx="4" ry="4" width="100" height="8" />
        <rect x="0" y="28" rx="4" ry="4" width="100" height="8" />
      </ContentLoader>
    );
  }

  if (availability) {
    return (
      <>
        <span>Available From {dayjs(availability).format("ddd, MMM D")}</span>
        <br />
        {doctor.offersVEH && (
          <>
            <br />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <span>
        Limited Availability{" "}
        {doctor.offersVEH ? (
          <span>
            {" "}
            for{" "}
            <a
              href={MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL}
              target="_blank"
              rel="noreferrer"
            >
              Doctor of Optometry
            </a>
          </span>
        ) : (
          ""
        )}
      </span>
      <br />
      <span>Call </span>
      <a
        onClick={(e) => e.stopPropagation()} // don't change doctors when users click on phone number.
        href={toTel(office.formattedPhone)}
      >
        {office.formattedPhone}
      </a>
    </>
  );
};
