import React from "react";
import { PleaseBringItemProps } from "./PleaseBringItem";
import styles from "./useItems.module.scss";
import contactLensIcon from "images/contactlens-icon.svg";
import rxIcon from "images/rx-icon.svg";
import { ReactComponent as EyeIcon } from "images/eye-open.svg";
import idCardIcon from "images/idcard-icon.svg";
import creditCardIcon from "images/creditcard-icon.svg";
import listIcon from "images/list-icon.svg";
import { useCurrentAppointment } from "features/patientDashboard/hooks/useCurrentAppointment";

export const useItems = (): PleaseBringItemProps[][] => {
  const { currentAppointment } = useCurrentAppointment();

  return [
    [
      {
        icon: <img src={contactLensIcon} alt="" />,
        item: "Glasses, prescription sunglasses and/or prescription lenses",
      },
      {
        icon: <img src={rxIcon} alt="" />,
        item: "Most recent prescription",
        caveat: "*If you are a new patient",
      },
      {
        icon: <EyeIcon className={styles.eyeIcon} />,
        item: "Any existing eye medications",
      },
    ],
    [
      {
        icon: <img src={idCardIcon} alt="" />,
        item: "Government ID",
      },
      ...(currentAppointment?.checkin.insurance === "skipped"
        ? []
        : [
            {
              icon: <img src={creditCardIcon} alt="" />,
              item: "Vision & Medical insurance cards",
            },
          ]),
      {
        icon: <img src={listIcon} alt="" />,
        item: "List of medications you’re currently taking",
      },
    ],
  ];
};
