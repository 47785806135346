import axios from "axios";
import { ExamType } from "types/examType";
import { Patient } from "types/patient";
import { PatientReturnStatus } from "types/patientReturnStatus";

export interface CreateAppointmentArgs {
  doctorId: string;
  officeId: string;
  officeUid: string;
  providerId: string;
  patient: Partial<Patient>;
  date: string;
  startTime: string;
  endTime: string;
  examType: ExamType;
  allowReschedule?: boolean;
  patientReturnStatus: PatientReturnStatus;
}

interface CreateAppointmentAPIResponse {
  id: string;
  patient_id: string;
  google_calendar_url: string;
  apple_calendar_url: string;
  outlook_calendar_url: string;
  is_new_patient: string;
}

export interface CreateAppointmentResponse {
  id: string;
  patientId: string;
  googleUrl: string;
  appleUrl: string;
  outlookUrl: string;
  isNewPatient: string;
}

export const createAppointmentRequest = async (
  params: CreateAppointmentArgs
): Promise<CreateAppointmentResponse> => {
  const {
    doctorId,
    providerId,
    officeId,
    officeUid,
    patient,
    date,
    startTime,
    endTime,
    examType,
    allowReschedule,
    patientReturnStatus,
  } = params;

  const { data } = await axios.post<CreateAppointmentAPIResponse>(
    "/api/appointments",
    {
      doctor_id: doctorId,
      provider_id: providerId,
      allow_reschedule: !!allowReschedule,
      appointment: {
        office_id: officeId,
        office_uid: officeUid,
        date,
        start_time: startTime,
        end_time: endTime,
        patient: {
          first_name: patient.firstName?.trim(),
          last_name: patient.lastName?.trim(),
          date_of_birth: patient.dateOfBirth,
          email: patient.email,
          phone_number: patient.phoneNumber,
          // Note: smsOptIn starts out as `undefined` because of how
          // our forms & redux are connected (see PR #1243).
          sms_opt_in_notices: !!patient.smsOptInNotices, // Ensure boolean value
          sms_opt_in_promos: !!patient.smsOptInPromos, // Ensure boolean value
        },
        exam_type: examType,
        patient_return_status: patientReturnStatus,
      },
    }
  );

  return {
    id: data.id,
    patientId: data.patient_id,
    googleUrl: data.google_calendar_url,
    appleUrl: data.apple_calendar_url,
    outlookUrl: data.outlook_calendar_url,
    isNewPatient: data.is_new_patient,
  };
};
