import React from "react";
import cx from "classnames";
import styles from "./PleaseBringItem.module.scss";

export type PleaseBringItemProps = {
  icon: React.ReactElement;
  item: string;
  caveat?: string;
  className?: string;
};

export const PleaseBringItem: React.FC<PleaseBringItemProps> = ({
  icon,
  item,
  caveat,
  className,
}) => {
  return (
    <li className={cx(styles.container, className)}>
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.itemWrapper}>
        <div className={styles.item}>{item}</div>
        {caveat && <div className={styles.caveat}>{caveat}</div>}
      </div>
    </li>
  );
};
