import axios from "axios";
import { GetDoctorResponse } from "./getDoctorsForOfficeRequest";
import { buildOffice, OfficeApiData } from "./util/buildOffice";
import { ExamType } from "types/examType";
import { NearbyOfficeSummary } from "types/nearbyOfficeSummary";
import { PatientReturnStatus } from "types/patientReturnStatus";

interface DoctorAvailabilityApiResponse {
  doctor: GetDoctorResponse;
  predicted_available_date: string;
}

export interface GetNearbyOfficeAvailabilityApiResponse {
  office: OfficeApiData;
  doctor_availabilities: DoctorAvailabilityApiResponse[];
}

export const getNearbyOfficeAvailabilityRequest = async (params: {
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}): Promise<NearbyOfficeSummary[]> => {
  const { officeId, examType, patientReturnStatus } = params;

  const { data } = await axios.get<GetNearbyOfficeAvailabilityApiResponse[]>(
    `/api/offices/${officeId}/nearby_availability`,
    {
      params: {
        exam_type: examType,
        patient_return_status: patientReturnStatus,
      },
    }
  );

  return data.map((summary) => ({
    office: buildOffice(summary.office),
    doctorAvailabilities: summary.doctor_availabilities.map((avail) => ({
      doctor: {
        id: avail.doctor.id,
        firstName: avail.doctor.first_name,
        lastName: avail.doctor.last_name,
        headshotUrl: avail.doctor.headshot_url,
        offersVEH: avail.doctor.offers_veh,
      },
      predictedAvailableDate: avail.predicted_available_date,
    })),
  }));
};