import * as React from "react";
import cx from "classnames";
import styles from "./LegacyCard.module.scss";

type LegacyCardTheme = "desktopOnly";

type LegacyCardProps = {
  children?: React.ReactNode;
  className?: string;
  theme?: LegacyCardTheme;
};

export const LegacyCard: React.FC<LegacyCardProps> = ({
  children,
  className,
  theme,
}) => {
  return (
    <section
      className={cx(styles.card, className, {
        [styles.desktopOnly]: theme === "desktopOnly",
      })}
    >
      {children}
    </section>
  );
};
