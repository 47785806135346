import React from "react";
import { useSelector } from "react-redux";
import styles from "./ErrorToast.module.scss";
import { DefaultToast, defaultToastIcons } from "components/Toast/DefaultToast";
import { selectServerError } from "features/patientDashboard/patientDashboardSlice";

interface ErrorToastProps {
  visible: boolean;
  onDismiss: () => void;
}

export const UnconnectedErrorToast: React.FC<ErrorToastProps> = ({
  visible,
  onDismiss,
}) => {
  return (
    <DefaultToast
      visible={visible}
      sideIcon={<defaultToastIcons.Reload />}
      onDismiss={onDismiss}
    >
      <p>
        Something went wrong. To refresh your page, click{" "}
        <button
          className={styles.reload}
          onClick={() => window.location.reload()}
        >
          here
        </button>
        .
      </p>
    </DefaultToast>
  );
};

export const ErrorToast: React.FC = () => {
  const hasError = !!useSelector(selectServerError);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setVisible(hasError);
  }, [hasError]);

  return (
    <UnconnectedErrorToast
      visible={visible}
      onDismiss={() => setVisible(false)}
    />
  );
};
