import React from "react";
import cx from "classnames";
import styles from "./TimeButton.module.scss";
import { TimeButtonSize } from "./types";

interface TimeButtonProps {
  size?: TimeButtonSize;
  text: string;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
  version?: number;
  selected?: boolean;
}

export const TimeButton: React.FC<TimeButtonProps> = ({
  size,
  text,
  onClick,
  className,
  version,
  selected,
}) => {
  if (version === 2) {
    return (
      <button
        className={
          selected
            ? cx(styles.selected, className, styles.buttonV2, {
                [styles.small]: size === "small",
              })
            : cx(className, styles.buttonV2, {
                [styles.small]: size === "small",
              })
        }
        onClick={onClick}
        type="button"
      >
        {text}
      </button>
    );
  }
  return (
    <button
      className={cx(
        styles.button,
        { [styles.small]: size === "small" },
        className
      )}
      onClick={onClick}
      type="button"
    >
      {text}
    </button>
  );
};
