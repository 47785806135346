import axios from "axios";
import { Address } from "types/address";

type ReviewYourAddressParams = Address;
export const createAddressVerificationRequest = async (
  params: ReviewYourAddressParams
): Promise<void> => {
  await axios.post(`/api/checkins/address_verifications`, {
    address1: params.address1,
    address2: params.address2,
    state: params.state,
    city: params.city,
    zip_code: params.zipCode,
  });
};
