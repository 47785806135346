import React from "react";
import { useParams } from "react-router-dom";
import styles from "./OrderTracker.module.scss";
import * as paths from "features/routing/paths";
import { Layout } from "features/layout";
import { UnconnectedRecentPurchases } from "features/patientDashboard/RecentPurchases";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { getOrderStatusRequest } from "api/getOrderStatusRequest";
import { Order } from "types/order";

export const OrderTracker: React.FC = () => {
  const { orderUID } =
    useParams<paths.RequiredSchedulingRouteParams<"orderUID">>();
  const { showErrorToast } = useErrorToast();
  const [orders, setOrders] = React.useState<Order[] | undefined>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    async function getOrders() {
      try {
        const data = await getOrderStatusRequest(orderUID);
        setOrders(data);
      } catch (e) {
        showErrorToast();
      }
    }
    if (!orders) getOrders();
  }, [orderUID, orders, showErrorToast]);
  if (!orders) return "Loading";
  if (orders.length === 0) {
    return (
      <Layout>
        <div className={styles.orderNotFound}>
          Order status not available, please try again later or{" "}
          <a
            href={"https://locations.myeyedr.com/search"}
            className={styles.inlineLink}
            target="_blank"
            rel="noreferrer"
          >
            Contact your local office
          </a>{" "}
          for assistance.
        </div>
      </Layout>
    );
  }
  const date = new Date();
  const hour = date.getHours();
  return (
    <Layout>
      <div className={styles.innerContent}>
        <div className={styles.greeting}>
          {hour < 12 ? "Good Morning," : "Good evening,"}
        </div>
        <UnconnectedRecentPurchases orders={orders} statusPage={true} />
      </div>
    </Layout>
  );
};
