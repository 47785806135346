import React from "react";
import ContentLoader from "react-content-loader";
import dayjs from "dayjs";
//import { calendarWeek } from "features/scheduling/utils/calendarWeek";
import { DoctorAvailability } from "features/scheduling/availabilitySummarySlice";
import { Office } from "types/office";
//import { API_DATE_FORMAT } from "api/constants";
import { Slot } from "types/slot";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";
import { Doctor } from "types/doctor";
import { MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL } from "utils/constants";

interface AvailabilityForDoctorProps {
  availability: DoctorAvailability;
  forDate: string;
  office: Office;
  slots: { [date: string]: Slot[] };
  doctor: Doctor;
  isMobile?: boolean;
}

export const AvailabilityForDoctorMobile: React.FC<
  AvailabilityForDoctorProps
> = ({ availability, forDate, slots, doctor, isMobile }) => {
  if (!areFirstAvailableApptsByDefaultEnabled()) {
    /*const numSlotsInWeek = calendarWeek(forDate)
      .map((day) => day.format(API_DATE_FORMAT))
      .reduce((count, day) => count + (slots[day]?.length ?? 0), 0);*/
    const numSlotsInWeek = slots[forDate]?.length ?? 0;

    if (numSlotsInWeek) {
      const newDateStr = availability === null ? availability : forDate;
      return (
        <>
          <span>
            Available {doctor.offersVEH ? "" : ""}
            From{" "}
          </span>
          <span>{dayjs(newDateStr).format("ddd, MMM D")} </span>
          <span>{`(${numSlotsInWeek} ${
            numSlotsInWeek === 1 ? "Exam" : "Exams"
          })`}</span>
          {doctor.offersVEH && (
            <>
              {!isMobile && <br />}
              <a
                href={MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL}
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </>
          )}
        </>
      );
    }
  }

  if (availability === "loading") {
    return (
      <ContentLoader
        speed={2}
        width={100}
        height={38}
        viewBox="0 0 100 36"
        backgroundColor="var(--gray-02)"
        foregroundColor="var(--gray-04)"
      >
        <rect x="0" y="8" rx="4" ry="4" width="100" height="8" />
        <rect x="0" y="28" rx="4" ry="4" width="100" height="8" />
      </ContentLoader>
    );
  }

  if (availability) {
    return (
      <>
        <span>
          Available {doctor.offersVEH ? "" : ""}
          From{" "}
        </span>
        <span>{dayjs(availability).format("ddd, MMM D")} </span>
        {doctor.offersVEH && (
          <>
            {!isMobile && <br />}
            <a
              href={MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <span>
        Limited Availability{" "}
        {doctor.offersVEH && !isMobile ? (
          <span>
            {" "}
            for{" "}
            <a
              href={MYEYEDR_VIDEO_ASSISTED_EYE_HEALTH_URL}
              target="_blank"
              rel="noreferrer"
            >
              Video-Assisted Eye Exams
            </a>
          </span>
        ) : (
          ""
        )}
      </span>
    </>
  );
};
