import React from "react";

export const VERSION = "Prescription_Access_Notice_Policy_9_2021";

export const PrescriptionAccessNoticePolicy = (): React.ReactElement => (
  <>
    <div>
      <h2>Prescription Access Notice Policy Statement</h2>
    </div>
    <div>
      <ol>
        <li>
          MyEyeDr. provides convenient prescription access with all current
          eyeglass and contact lens prescriptions available digitally at
          getrx.myeyedr.com
        </li>
        <li>
          Physical copies of eyeglass and contact lens prescriptions are also
          available at the completion of your visit or any time after
        </li>
        <li>
          I acknowledge the policy and note I can (i) access my eyeglass and
          contact lens prescriptions digitally at getrx.myeyedr.com or (ii)
          obtain a paper copy at any time as well
        </li>
      </ol>
    </div>
  </>
);
