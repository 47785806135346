import React from "react";
import { noop } from "lodash";
import cx from "classnames";
import styles from "./ComponentCatalog.module.scss";
import { ToastDemo } from "./components/ToastDemo";
import { ModalDemo } from "./components/ModalDemo";
import { FormDemo } from "./components/FormDemo";
import { SnackbarDemo } from "./components/SnackbarDemo";
import { Button, ButtonRole, ButtonLinkExternal } from "components/Button";
import { OfficeAddress } from "components/OfficeAddress";
import { DoctorSlotsDemo } from "features/componentCatalog/components/DoctorSlotsDemo";
import { DoctorInfoCard } from "components/DoctorInfoCard";
import { DateInput } from "components/DateInput";
import { GuidedTooltip } from "components/GuidedTooltip";
import { TextInput } from "components/TextInput";
import { TextArea } from "components/TextArea";
import { InfoCard } from "components/InfoCard";
import { SectionDivider } from "components/SectionDivider";
import doctorPlaceholder from "images/doctor-placeholder.svg";
import { OfficeInfoCard } from "components/OfficeInfoCard";
import { OfficeContent } from "features/scheduling/components/OfficeContent";
import {
  Position,
  SchedulingStep,
} from "features/scheduling/components/nav/SchedulingStep";
import { UnconnectedNearbyOfficeCard } from "features/scheduling/components/NearbyOfficeCard";
import { Layout } from "features/layout";
import { TextLink } from "components/TextLink";
import { TextButton } from "components/TextButton";
import { DoctorAvatar } from "components/DoctorAvatar";
import { DoctorAvatarStack } from "components/DoctorAvatarStack";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { TimeButton } from "components/Button/TimeButton";
import { Doctor } from "types/doctor";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { StepStatus } from "features/checkIn/Home/StepStatus";
import { Waitlist } from "features/scheduling/components/Waitlist";
import { LogoutButton } from "features/patientDashboard/LogoutButton";
import { SmsCheckbox } from "components/SmsCheckbox";
import { Card } from "components/Card";
import { TaskList } from "components/TaskList";
import { DescriptiveCheckbox } from "components/DescriptiveCheckbox";
import { Checkbox } from "components/Checkbox";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";
import { UnconnectedRecentPurchases } from "features/patientDashboard/RecentPurchases";
import { Order } from "types/order";

const colors = [
  "black",
  "black-at-75",
  "gray-00",
  "gray-01",
  "gray-02",
  "gray-03",
  "gray-04",
  "gray-04-at-30",
  "white",
  "blue-01",
  "blue-01-at-30",
  "blue-02",
  "blue-02-at-30",
  "blue-03",
  "blue-03-at-30",
  "highlight-blue",
  "highlight-blue-at-30",
  "red",
  "red-at-50",
];
const loremIpsum =
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusamus molestiae asperiores a illo quo, maiores corrupti sequi mollitia dolore delectus. Eos id quae repellat laboriosam rerum temporibus consequuntur exercitationem eaque.";

const sampleOffice = {
  address1: "1415 Wooten Lake Road NW",
  address2: "Suite 100",
  city: "Kennesaw",
  displayName: "MyEyeDr. in Kennesaw",
  formattedPhone: "(770) 573-4345",
  formattedZipCode: "30144-1342",
  id: "554",
  logicalId: "MED-397",
  officeUid: null,
  schedulingAllowed: true,
  stateAbbr: "GA",
  timeZoneName: "America/New_York",
  timeSlotWeeks: 3,
  restrictVEH: false,
  discountVEH: false,
  namechangeVEH: false,
  distanceMiles: "0.0",
  cobrandedLogoUrl: null,
  supportsMedicaid: true,
  hours: null,
  totalSlots: null,
  startDate: null,
  startTime: null,
  totalSlotsDetails: null,
};

const sampleDoctorOne: Doctor = {
  id: "17889",
  firstName: "Madalynn",
  lastName: "Bruckner",
  headshotUrl:
    "https://a.mktgcdn.com/p/BQ5mf02EbroHUxTYzUtJQkLc9B45dXi7sRtj9gGEDLg/150x150.jpg",
};

const sampleDoctorTwo: Doctor = {
  id: "4885",
  firstName: "Stephen",
  lastName: "Coss",
  headshotUrl:
    "https://a.mktgcdn.com/p/IZdWkT8tfnjDCoSBpRAzcbTcFkMb240tTtXco-CqPFg/150x150.jpg",
};

const sampleDoctorThree: Doctor = {
  id: "4194",
  firstName: "David",
  lastName: "Corcoran",
  headshotUrl:
    "https://a.mktgcdn.com/p/yyjTXGrK-E7Uol-gZfQCsrJUXZkTrz_WEcPOyRAkUJo/150x150.jpg",
};

const orders: Order[] = [
  {
    displayByline: "Your personalized order is being processed.",
    displayStatus: "In the works",
    orderDate: "2021-03-24",
    orderNum: 378935627,
    orderTypeName: "soft_contacts",
    expectDate: "2021-03-25",
    fulfillmentType: "shipping",
    fulfillmentDestination: "123 Main St.",
  },
  {
    displayByline: "Your eyewear is being custom made just for you.",
    displayStatus: "In the works",
    orderDate: "2021-03-24",
    orderNum: 248302874,
    orderTypeName: "eyeglasses",
    expectDate: "2021-03-25",
    fulfillmentType: "pickup",
    fulfillmentDestination: "MyEyeDr. in Redding",
  },
];

export const ComponentCatalog: React.FC = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <h1>Component Catalog</h1>
        <p>Reusable design concepts and components can be rendered here.</p>

        <SnackbarDemo />

        <div className={styles.section}>
          <h1 id="form">Forms &amp; Inputs</h1>
          <FormDemo />
        </div>
        <div className={styles.section}>
          <h1>Colors</h1>
          <div className={cx(styles.section, styles.colors)}>
            {colors.map((color) => (
              <div key={color}>
                <div
                  className={styles.colors__swatch}
                  style={{ backgroundColor: `var(--${color})` }}
                />
                <code className={styles.colors__label}>{color}</code>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.section}>
          <h1>Typography</h1>
          <div className={styles.section}>
            <dl>
              <dt>heading1</dt>
              <dd className={styles.typography__heading1}>{loremIpsum}</dd>
              <dt>heading2</dt>
              <dd className={styles.typography__heading2}>{loremIpsum}</dd>
              <dt>heading3</dt>
              <dd className={styles.typography__heading3}>{loremIpsum}</dd>
              <dt>heading4</dt>
              <dd className={styles.typography__heading4}>{loremIpsum}</dd>
              <dt>heading5</dt>
              <dd className={styles.typography__heading5}>{loremIpsum}</dd>
              <dt>body</dt>
              <dd className={styles.typography__body}>{loremIpsum}</dd>
              <dt>body-semibold</dt>
              <dd className={styles.typography__bodySemibold}>{loremIpsum}</dd>
              <dt>body-semibold-link</dt>
              <dd className={styles.typography__bodySemiboldLink}>
                {loremIpsum}
              </dd>
              <dt>body-link</dt>
              <dd className={styles.typography__bodyLink}>{loremIpsum}</dd>
              <dt>body-large</dt>
              <dd className={styles.typography__bodyLarge}>{loremIpsum}</dd>
              <dt>body-large-link</dt>
              <dd className={styles.typography__bodyLargeLink}>{loremIpsum}</dd>
              <dt>caption</dt>
              <dd className={styles.typography__caption}>{loremIpsum}</dd>
              <dt>caption-link</dt>
              <dd className={styles.typography__captionLink}>{loremIpsum}</dd>
              <dt>caption-semibold</dt>
              <dd className={styles.typography__captionSemibold}>
                {loremIpsum}
              </dd>
              <dt>caption-semibold-link</dt>
              <dd className={styles.typography__captionSemiboldLink}>
                {loremIpsum}
              </dd>
              <dt>small-text</dt>
              <dd className={styles.typography__smallText}>{loremIpsum}</dd>
              <dt>small-text-link</dt>
              <dd className={styles.typography__smallTextLink}>{loremIpsum}</dd>
              <dt>button</dt>
              <dd className={styles.typography__button}>{loremIpsum}</dd>
              <dt>button-large</dt>
              <dd className={styles.typography__buttonLarge}>{loremIpsum}</dd>
              <dt>field-label</dt>
              <dd className={styles.typography__fieldLabel}>{loremIpsum}</dd>
            </dl>
          </div>
        </div>
        <div className={styles.section}>
          <h1>Button (or ButtonLinkExternal or ButtonLinkInternal)</h1>

          <div className={styles.section}>
            <h2>Role: Standard (default)</h2>

            <Button text="Button" onClick={noop} role={ButtonRole.Standard} />
          </div>

          <div className={styles.section}>
            <h2>Role: Ghost</h2>

            <Button text="Button" onClick={noop} role={ButtonRole.Ghost} />
          </div>

          <div className={styles.section}>
            <h2>Role: Ghost (and Disabled)</h2>

            <Button
              text="Button"
              onClick={noop}
              role={ButtonRole.Ghost}
              disabled={true}
            />
          </div>

          <div className={styles.section}>
            <h2>Size: Large</h2>

            <Button
              text="Button"
              onClick={noop}
              role={ButtonRole.Standard}
              size="large"
            />
          </div>

          <div className={styles.section}>
            <h2>Disabled</h2>

            <Button
              text="Button"
              onClick={noop}
              role={ButtonRole.Standard}
              disabled={true}
            />
          </div>

          <div className={styles.section}>
            <h2>Loading (and Disabled)</h2>

            <Button
              text="Button"
              onClick={noop}
              role={ButtonRole.Standard}
              disabled={true}
              loading={true}
            />
          </div>

          <div className={styles.section}>
            <h2>Ghost Loading (and Disabled)</h2>

            <Button
              text="This is a long button label"
              onClick={noop}
              role={ButtonRole.Ghost}
              disabled={true}
              loading={true}
            />
          </div>
        </div>

        <div className={styles.section}>
          <h1>Time Button</h1>

          <div className={styles.section}>
            <h2>Standard</h2>
            <TimeButton text="Button" onClick={noop} />
          </div>

          <div className={styles.section}>
            <h2>Small</h2>
            <TimeButton text="Button" onClick={noop} size="small" />
          </div>
        </div>

        <div className={styles.section}>
          <h1>ButtonLink</h1>
          <ButtonLinkExternal href={MYEYEDR_WEBSITE_URL} text="Back to Home" />
        </div>

        <div className={styles.section}>
          <h1>Checkbox - light theme</h1>
          <Checkbox
            name="core_checkbox"
            onChange={(e) => console.log("checkbox changed: ", e)}
            theme="light"
          />
          <h1>Checkbox - dark theme</h1>
          <Checkbox
            name="core_checkbox"
            onChange={(e) => console.log("checkbox changed: ", e)}
          />
          <h1>SMS Checkbox</h1>
          <SmsCheckbox label="Enable Goofy Mode" />
          <h1>Descriptive Checkbox</h1>
          <DescriptiveCheckbox
            name="silly_status"
            label="Enable Silly Status"
            description="Be reminded when you’re due to book your annual appointment as well as reminders for upcoming appointments."
          />
        </div>

        <div className={styles.section}>
          <h1>Radio Button</h1>
          <RadioButton
            name="radio-button"
            value="pizza"
            onChange={(e) => console.log("radio changed:", e)}
          >
            Pizza
          </RadioButton>
          <RadioButton
            name="radio-button"
            value="sushi"
            onChange={(e) => console.log("radio changed:", e)}
          >
            Sushi
          </RadioButton>
          <RadioButton
            name="radio-button"
            value="error"
            hasError={true}
            onChange={(e) => console.log("radio changed:", e)}
          >
            Error
          </RadioButton>
        </div>

        <div className={styles.section}>
          <h1>Radio Button Group</h1>
          <RadioButtonGroup errorMessage="Choose one">
            <RadioButton
              hasError
              name="radio-button"
              value="pizza"
              onChange={(e) => console.log("radio changed:", e)}
            >
              Pizza
            </RadioButton>
            <RadioButton
              hasError
              name="radio-button"
              value="sushi"
              onChange={(e) => console.log("radio changed:", e)}
            >
              Sushi
            </RadioButton>
          </RadioButtonGroup>
        </div>

        <div className={styles.section}>
          <h1>LogoutButton</h1>
          <LogoutButton />
        </div>

        <div className={styles.section}>
          <h1>Orders</h1>
          <UnconnectedRecentPurchases orders={orders} />
        </div>

        <div className={styles.section}>
          <h1>DoctorAvatar</h1>

          <div className={styles.section}>
            <h2>With a found doctor image</h2>

            <DoctorAvatar
              doctor={{
                id: "14472",
                firstName: "Rafael",
                lastName: "Gamba",
                headshotUrl:
                  "https://a.mktgcdn.com/p/UnKi3b45qOs3DcSR9Hkir022X1V0l6f33aBlOHC59QM/2868x2867.jpg",
              }}
            />
          </div>

          <div className={styles.section}>
            <h2>With a 404 doctor image</h2>

            <DoctorAvatar
              doctor={{ id: "404", firstName: "Rafael", lastName: "Gamba" }}
            />
          </div>

          <div className={styles.section}>
            <h2>Small</h2>

            <DoctorAvatar
              doctor={{ id: "14472", firstName: "Rafael", lastName: "Gamba" }}
              size="small"
            />
          </div>

          <div className={styles.section}>
            <h2>X-Small</h2>

            <DoctorAvatar
              doctor={{ id: "14472", firstName: "Rafael", lastName: "Gamba" }}
              size="xsmall"
            />
          </div>
        </div>

        <div className={styles.section}>
          <h1>DoctorAvatarStack</h1>
          <DoctorAvatarStack
            doctors={[sampleDoctorOne, sampleDoctorTwo, sampleDoctorThree]}
          />
        </div>

        <div className={styles.section}>
          <h1>Card</h1>
          <Card>
            <p>This is a card</p>
          </Card>
        </div>
        <div className={styles.section}>
          <h1>InfoCard</h1>

          <div className={styles.section}>
            <h2>Default</h2>

            <InfoCard
              image={<img src={doctorPlaceholder} alt="Doctor" />}
              details={
                <ul>
                  <li>Fact 1</li>
                  <li>Fact 2</li>
                </ul>
              }
            />
          </div>

          <div className={styles.section}>
            <h2>With Children</h2>
            <InfoCard
              image={<img src={doctorPlaceholder} alt="Doctor" />}
              details={
                <ul>
                  <li>Fact 1</li>
                  <li>Fact 2</li>
                </ul>
              }
            >
              <p>Some other content</p>
            </InfoCard>
          </div>
        </div>

        <div className={styles.section}>
          <h1>DoctorInfoCard</h1>

          <DoctorInfoCard
            doctor={{ id: "1", firstName: "Isamu", lastName: "Noguchi" }}
            office={sampleOffice}
          />
        </div>

        <div className={styles.section}>
          <h1>OfficeInfoCard</h1>
          <OfficeInfoCard office={sampleOffice} />
        </div>

        <DoctorSlotsDemo />

        <div className={styles.section}>
          <h1>SectionDivider</h1>
          <SectionDivider>One of the Offices</SectionDivider>
        </div>

        <div className={styles.section}>
          <h1>Office Content</h1>
          <OfficeContent office={sampleOffice}>
            <span>One</span>
            <span>Two</span>
            <span>Three</span>
          </OfficeContent>
        </div>

        <div className={styles.section}>
          <h1>NearbyOfficeCard</h1>
          <UnconnectedNearbyOfficeCard
            office={sampleOffice}
            examType={ExamType.Contacts}
            patientReturnStatus={PatientReturnStatus.New}
            doctorsAvailableToday={[]}
            doctorsAvailableLater={[]}
          />
        </div>

        <div className={styles.section}>
          <h1>SchedulingStep</h1>
          <SchedulingStep label="Current Step" />
          <SchedulingStep label="Visited Step" position={Position.Visited} />
          <SchedulingStep label="Future Step" position={Position.Future} />
        </div>

        <div className={styles.section}>
          <h1>DateInput</h1>
          <DateInput name="test" maxDate={""} />
        </div>

        <div className={styles.section}>
          <h1>TextButton</h1>
          <div>
            <TextButton onClick={noop}>Inline (default)</TextButton>
          </div>
          <div>
            <TextButton onClick={noop} theme="standard">
              Standard
            </TextButton>
          </div>
        </div>

        <div className={styles.section}>
          <h1>TextLink</h1>
          <TextLink to="#">Back</TextLink>
        </div>

        <div className={styles.section}>
          <h1>TextInput</h1>
          <TextInput
            inputType="text"
            name="text_input"
            onChange={(e) => console.log(e.target.value)}
          >
            Text Input
          </TextInput>
        </div>

        <div className={styles.section}>
          <h1>TextArea</h1>
          <TextArea
            name="textarea"
            onChange={(e) => console.log(e.target.value)}
            rows={3}
            placeholder="Lorem ipsum dolor, sit amet consectetur adipisicing elit."
          >
            Type as much as you like
          </TextArea>
        </div>

        <div className={styles.section}>
          <h1 id="toasts">Toasts</h1>
          <ToastDemo />
        </div>
        <div className={styles.section}>
          <h1>Office Address</h1>
          <OfficeAddress office={sampleOffice} />

          <h1>Inline for desktop</h1>
          <OfficeAddress office={sampleOffice} desktopInline />
        </div>
        <div className={styles.section}>
          <h1>Waitlist CTA (click for modal)</h1>
          <Waitlist office={sampleOffice} />
        </div>

        <div className={styles.section}>
          <h1>TaskList</h1>
          <TaskList
            title="The Count"
            tasks={[
              {
                isCompleted: true,
                title: "Uno",
                description: "Tasks can optionally have a description",
              },
              {
                isCompleted: "partially",
                title: "Dos",
              },
              {
                isCompleted: false,
                title: "Tres",
              },
            ]}
          />
        </div>

        <div className={styles.section}>
          <h1>Completed TaskList</h1>
          <TaskList
            title="The Count"
            tasks={[
              {
                isCompleted: true,
                title: "Uno",
                description: "Tasks can optionally have a description",
              },
              {
                isCompleted: true,
                title: "Dos",
              },
              {
                isCompleted: true,
                title: "Tres",
              },
            ]}
            completionMessage={
              <>
                You finished everything!
                <br />
                Nice work.
              </>
            }
          />
        </div>

        <div className={styles.section}>
          <ModalDemo />
        </div>

        <div className={styles.section}>
          <h1>StepStatus</h1>
          <StepStatus number={1} isSubmitted={true} name="About You" />
          <StepStatus
            number={2}
            isSubmitted={true}
            name="Insurance"
            note="Not being used"
          />
          <StepStatus number={3} isSubmitted={false} name="Prescriptions" />
        </div>

        <div className={styles.section}>
          <h1>GuidedTooltip</h1>
          <GuidedTooltip>
            Voluptas accusamus numquam. Suscipit asperiores sapiente. Earum
            nobis eum.
          </GuidedTooltip>
        </div>
      </div>
    </Layout>
  );
};
