import * as React from "react";
import styles from "./CharacterCounter.module.scss";

type CharacterCounterProps = {
  max: number;
  value?: string;
};

export const CharacterCounter: React.FC<CharacterCounterProps> = ({
  max,
  value,
}) => {
  return (
    <div className={styles.counter}>
      {value?.length || "0"}/{max}
    </div>
  );
};
