import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styles from "./AppointmentSlotTaken.module.scss";
import { RootState } from "app/store";
import { Button } from "components/Button";
import * as paths from "features/routing/paths";
import { resetAppointmentBooked } from "features/scheduling/appointmentCreationSlice";
import { NeedsSelectedAppointment } from "components/NeedsSelectedAppointment";
import { SadLayout } from "features/layout/SadLayout";
import { SelectedAppointment } from "types/selectedAppointment";
import { selectVersion } from "features/appointmentDataSlice";

const message = (
  <>
    <p className={styles.message}>This appointment time has been taken.</p>
    <p className={styles.message}>Please choose another time.</p>
  </>
);

const UnconnectedAppointmentSlotTaken: React.FC<{
  selectedAppointment: SelectedAppointment;
}> = ({ selectedAppointment }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { officeId, examType, patientReturnStatus } =
    useParams<
      paths.RequiredSchedulingRouteParams<
        "officeId" | "examType" | "patientReturnStatus"
      >
    >();
  const version = useSelector((state: RootState) => selectVersion(state));

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentBooked());
    };
  }, [dispatch]);

  const chooseAvailability = () => {
    if (version === 2) {
      history.push(
        paths.appointmentBookingStartV2({
          officeId,
        })
      );
    } else {
      history.push(
        paths.appointmentsAvailableAppointments({
          officeId,
          examType,
          patientReturnStatus,
          query: {
            date: selectedAppointment.date,
          },
        })
      );
    }
  };

  const cta = (
    <Button
      className={styles.button}
      onClick={chooseAvailability}
      size="large"
      text="Select another time"
    />
  );

  return <SadLayout headline="We're sorry!" message={message} cta={cta} />;
};

export const AppointmentSlotTaken: React.FC = () => (
  <NeedsSelectedAppointment>
    {({ selectedAppointment }) => (
      <UnconnectedAppointmentSlotTaken
        selectedAppointment={selectedAppointment}
      />
    )}
  </NeedsSelectedAppointment>
);
