import React, { useEffect } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { AppRoute } from "./AppRoute";
import { IdleModalContent } from "./components/IdleModalContent";
import styles from "./AuthorizedRouter.module.scss";
import { Modal } from "components/Modal";
import * as paths from "features/routing/paths";
import { useCurrentPatient } from "features/patientDashboard/hooks/useCurrentPatient";
import { PatientDashboard } from "features/patientDashboard/PatientDashboard";
import { Router as CheckInRouter } from "features/checkIn/Router";
import { logoutTimerMillis } from "utils/config";

export const REDIRECT_KEY = "redirectTo";

export interface RedirectQueryParams {
  query?: { [REDIRECT_KEY]?: string };
}

export const AuthorizedRouter = (): JSX.Element => {
  const IDLE_SESSION_TIMER = 10 * 60 * 1000;
  const LOGOUT_TIMER = logoutTimerMillis();

  const history = useHistory();
  const { sessionStatus, fetchCurrentPatient, expireSession } =
    useCurrentPatient();
  const [isIdleModalOpen, setIdleModalOpen] = React.useState(false);
  const [logoutTimerId, setLogoutTimer] = React.useState<number>();

  const forceLogout = () => expireSession({ requestSessionDeletion: true });
  const showIdleModal = () => {
    setIdleModalOpen(true);
    setLogoutTimer(window.setTimeout(forceLogout, LOGOUT_TIMER));
  };

  const cancelLogout = () => {
    setIdleModalOpen(false);
    clearTimeout(logoutTimerId);
  };

  // Show modal to warn patient of an expiring session
  useIdleTimer({
    timeout: IDLE_SESSION_TIMER,
    onIdle: showIdleModal,
  });

  // Forces logout on very idle tabs (eg. when a computer wakes from sleep)
  useIdleTimer({
    timeout: IDLE_SESSION_TIMER + LOGOUT_TIMER,
    onIdle: forceLogout,
  });

  // Check to see if the user is logged in
  useEffect(() => {
    if (sessionStatus === "uninitialized") {
      fetchCurrentPatient();
    }
  }, [fetchCurrentPatient, sessionStatus]);

  if (sessionStatus === "logged-out" || sessionStatus === "expired") {
    return (
      <Redirect
        to={{
          pathname: paths.portalLogin(),
          search: `?${REDIRECT_KEY}=${history.location.pathname}`,
        }}
      />
    );
  }

  return (
    <>
      <Switch>
        <AppRoute
          exact
          path={[paths.portalHome(), paths.old.currentAppointment()]}
        >
          <PatientDashboard />
        </AppRoute>
        <AppRoute path={paths.checkInBase()}>
          <CheckInRouter />
        </AppRoute>
      </Switch>
      <Modal
        isOpen={isIdleModalOpen}
        onRequestClose={cancelLogout}
        overlayClassName={styles.overlay}
      >
        <IdleModalContent timer={LOGOUT_TIMER} dismiss={cancelLogout} />
      </Modal>
    </>
  );
};
