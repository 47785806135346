import dayjs from "dayjs";
import { ExamType } from "types/examType";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { API_DATE_FORMAT } from "api/constants";

export interface AppointmentSelectDoctorSlotRouteProps {
  officeId: string;
  doctorId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

export type Week = dayjs.Dayjs[];

export const today = (): string => dayjs().format(API_DATE_FORMAT);

export const searchDateParam = "date";
